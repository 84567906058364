import React from "react";
import { Typography, Skeleton } from "antd";

const DataTable = React.lazy(() =>
  import("../../components/DataTable/DataTable")
);

const Dashboard = () => {
  return (
    <div>
      <Typography.Title level={3}>Orders</Typography.Title>
      <React.Suspense fallback={<Skeleton active />}>
        <DataTable type="orders" subType="allorders" />
      </React.Suspense>
    </div>
  );
};

export default Dashboard;
