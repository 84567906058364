import React from "react";
import { connect } from "react-redux";
import { Table, Typography } from "antd";
import { bindActionCreators } from "redux";
import { addOrder } from "../../actions/orderActionCreator";

const currencies = [
  { sym: "$", value: "USD" },
  { sym: "zl", value: "ZlATY" },
  { sym: "₽", value: "RUBL" },
  { sym: "£", value: "POUND" },
  { sym: "₪", value: "SHEKEL" },
  { sym: "₴", value: "HRYVNIA" },
  { sym: "€", value: "EURO" }
];

const OrderCost = props => {
  return (
    <Table
      size="small"
      bordered
      pagination={false}
      dataSource={props.dataSource}
      columns={props.columns}
      footer={() => (
        <Typography.Title level={4} style={{ textAlign: "center" }}>
          <small>Total:</small>{" "}
          {currencies.map((currency, i) => {
            if (currency.value === props.selectedcurrency) {
              return currency.sym;
            }
          })}
          {Number.parseFloat(props.total).toFixed(2)}
        </Typography.Title>
      )}
    />
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ addOrder }, dispatch);

export default connect(mapDispatchToProps)(OrderCost);
