import React from "react";
import { Button, Form, Input, message, Modal } from "antd";
import { axiosInstance } from "../../httpHandler";
import { store } from "../../store";
import {
  getContacts,
  getSingleCustomer,
} from "../../actions/orderActionCreator";

class CustomerMemo extends React.Component {
  state = { visible: false, memo: "", index: 0, memos: [] };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  componentDidMount() {
    axiosInstance
      .get(`/customer/${this.props.customerId}`, { handlerEnabled: true })
      .then((res) => {
        this.setState(
          { memos: res.data.customer.memo ? res.data.customer.memo : [] },
          () => {
            if (this.state.memos.length) {
              this.showMemoModal(this.state.memos);
            }
          }
        );
      })
      .catch((err) => console.log(err));
  }

  fetchData = () => {
    axiosInstance
      .get(`/customer/${this.props.customerId}`, { handlerEnabled: true })
      .then((res) => {
        if (res.data) {
          this.setState({ memos: res.data.customer?.memos });
        }
      });
  };

  handleOk = async (e) => {
    this.props.form.validateFieldsAndScroll(["memo"], async (err, values) => {
      if (!err) {
        await axiosInstance
          .put(
            "/addmemo",
            { memo: this.state.memo, customerId: this.props.customerId },
            { handlerEnabled: true }
          )
          .then((res) => {
            this.fetchData();
            store.dispatch(getContacts());
            message.success("Memo Added Successfully");
            this.setState({
              visible: false,
            });
          })
          .catch((err) => console.log(err));
      }
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };
  showMemoModal = () => {
    let thiss = this;
    Modal.confirm({
      title: "Account memo",
      content: this.state.memos[this.state.index],
      cancelText: "Dismiss",
      okText: "Delete memo",
      onCancel() {
        thiss.setState(
          { index: thiss.state.memos.length > 0 ? thiss.state.index + 1 : 0 },
          () => {
            if (thiss.state.index <= thiss.state.memos.length - 1) {
              thiss.showMemoModal(thiss.state.memos);
            }
          }
        );
      },
      onOk() {
        let a = thiss.state.memos;
        a.splice(thiss.state.index, 1);
        thiss.setState({ memos: a }, () => {
          axiosInstance
            .put(
              `/deletememo/${thiss.props.customerId}`,
              {
                memo: thiss.state.memos,
              },
              { handlerEnabled: true }
            )
            .then((res) => {
              message.success("Memo Deleted succesfully");
              store.dispatch(getSingleCustomer(thiss.props.customerId));
              store.dispatch(getContacts());
              if (thiss.state.index <= thiss.state.memos.length - 1) {
                thiss.showMemoModal(thiss.state.memos);
              }
            })
            .catch((err) => console.log(err));
        });
      },
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <>
        <Button onClick={this.showModal} icon="exclamation" shape="circle" />
        <Modal
          title="Create account memo warning"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form>
            <Form.Item label="Memo">
              {getFieldDecorator("memo", {
                rules: [
                  {
                    required: true,
                    message: "Please write Memo text!",
                  },
                ],
              })(
                <Input.TextArea
                  placeholder="Write the memo"
                  rows={7}
                  onChange={(e) => this.setState({ memo: e.target.value })}
                />
              )}
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}
const Memo = Form.create({})(CustomerMemo);

export default Memo;
