import React from "react";
import { Steps, Button, message, Icon, Form, Modal } from "antd";

import CustomerForm from "./CustomerForm";
import OrderForm from "./NewOrderForm";
import PaymentForm from "./PaymentForm";
import { connect } from "react-redux";
import { axiosInstance } from "../../httpHandler";
// prettier-ignore
import { clearData, updateOrderData, getOrderData, addCustomer, getSingleOrderData, todaysArrivals, todaysDepartures, todaysCurrentOrders } from "../../actions/orderActionCreator";
import { withRouter } from "react-router-dom";
import { store } from "../../store";
import moment from "moment";
import { size } from "lodash";
class NewOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      visible: false,
      orderData: {},
      draft: false,
      newCustomer: false,
      confirmOrderShowAdmin: false,
      confirmOrderShow: false
    };
  }
  componentDidMount() {
    if (this.props.match.params.customerId) {
      // this.next();
      store.dispatch(addCustomer("id", this.props.match.params.customerId));
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.updateOrderSuccess !== this.props.updateOrderSuccess) {
      this.setState({
        current: 0,
        loader: false
      });
      message.success(
        `KRO${this.props.orderData[0].orderId} Order updated successfully!`
      );
    }
  }

  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleOk = e => {
    this.setState({
      visible: false,
      current: 0
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
      current: 0
    });
  };
  onChange = current => {
    this.setState({ current });
  };
  next() {
    // const current = this.state.current + 1;
    // this.setState({ current });
    if (this.state.newCustomer) {
      axiosInstance
        .post(
          "/addCustomer",
          { customer: this.props.customer },
          { handlerEnabled: true }
        )
        .then(res => {
          store.dispatch(addCustomer("id", res.data.custId));
          this.setState({ visible: false });
          this.setState({
            newCustomer: false
          });
        })
        .catch(error => message.error(error));
    }
    if (this.state.current === 0) {
      this.props.form.validateFieldsAndScroll(["customer"], (err, values) => {
        if (!err) {
          const current = this.state.current + 1;
          this.setState({ current });
        }
      });
    } else if (this.state.current === 1) {
      this.props.form.validateFieldsAndScroll(
        ["dates", "arrival", "departure"],
        (err, values) => {
          if (!err) {
            const current = this.state.current + 1;
            this.setState({ current });
          }
        }
      );
    }
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  addOrderfunction = (orderData, type) => {
    axiosInstance
      .post(
        `/orders`,
        {
          odData: orderData,
          draft: orderData.draft === "draft" ? true : false
        },
        {
          handlerEnabled: true
        }
      )
      .then(async res => {
        console.info("NewOrder.js", { res: res });
        // message.success("Processing complete!");
        if (res.data.Error) {
          const resStatus = res.data.Error.split("&")
            .map(obj => obj.split("="))
            .find(v => v[0] === "xStatus")[1];
          const error = res.data.Error.split("&")
            .map(obj => obj.split("="))
            .find(v => v[0] === "xError")[1];
          message.error(error.replace(/[+]/g, " "));
        } else {
          if (orderData.status === "Confirmed") {
            // if (this.props.paymentHold === true) {
            //   message.error(
            //     "You can not confirm this order due to not doing payment yet."
            //   );
            // } else {
            message.success("Processing complete!");
            // }
          } else if (orderData.status === "draft") {
            message.success("Draft Saved!");
          } else {
            message.success("Processing complete!");
          }

          this.setState({
            visible: false,
            current: 0
          });
          await this.props.closeModel();
          await this.props.dispatch(clearData());
          await this.props.dispatch(
            todaysArrivals(moment(new Date()).format("ddd MMM DD YYYY"))
          );
          await this.props.dispatch(
            todaysDepartures(moment(new Date()).format("ddd MMM DD YYYY"))
          );
          await this.props.dispatch(todaysCurrentOrders(moment(new Date()).format("ddd MMM DD YYYY")))
          await this.props.dispatch(getOrderData());
        }
      });
  };

  handleSave = async (type = "") => {
    this.props.form.validateFieldsAndScroll(
      ["cardName", "cardNumber", "cardExpiry", "cardAmount", "cardCVV"],
      (err, values) => {
        if (!err) {
          if (!this.props.visible) {
            this.setState({ orderData: this.props }, async () => {
              await Object.entries(this.state.orderData).forEach(
                ([key, value]) => {
                  if (`${key}` && `${value}` === "undefined") {
                    this.setState({
                      orderData: {
                        ...this.state.orderData,
                        draft: type === "draft" ? true : false,
                        status: type
                      }
                    });
                  }
                }
              );

              if (
                (this.state.orderData.payment === undefined &&
                  this.state.orderData.selectedCard === undefined &&
                  this.state.orderData.creditCards === undefined) ||
                this.state.orderData.paymentHold === true
              ) {
                if (
                  this.props.authUser &&
                  this.props.authUser.user?.userType === "Admin"
                ) {
                  this.setState({ confirmOrderShowAdmin: true });
                } else {
                  this.setState({ confirmOrderShow: true });
                }
              } else if (
                (this.state.orderData.payment === undefined &&
                  this.state.orderData.selectedCard === null &&
                  this.state.orderData.creditCards === undefined) ||
                this.state.orderData.paymentHold === true
              ) {
                if (
                  this.props.authUser &&
                  this.props.authUser.user?.userType === "Admin"
                ) {
                  this.setState({ confirmOrderShowAdmin: true });
                } else {
                  this.setState({ confirmOrderShow: true });
                }
              } else {
                await this.addOrderfunction(
                  this.state.orderData,
                  this.state.orderData.type
                );
              }
              //
              // await axiosInstance
              //   .post(
              //     `/orders`,
              //     {
              //       odData: this.state.orderData,
              //       draft: type === "draft" ? true : false
              //     },
              //     {
              //       handlerEnabled: true
              //     }
              //   )
              //   .then(async res => {
              //     console.info("NewOrder.js", { res: res });
              //     // message.success("Processing complete!");
              //     if (res.data.Error) {
              //       const resStatus = res.data.Error.split("&")
              //         .map(obj => obj.split("="))
              //         .find(v => v[0] === "xStatus")[1];
              //       const error = res.data.Error.split("&")
              //         .map(obj => obj.split("="))
              //         .find(v => v[0] === "xError")[1];
              //       message.error(error.replace(/[+]/g, " "));
              //     } else {
              //       if (type === "Confirmed") {
              //         console.log(
              //           "if confirme this.props.paymentHold",
              //           this.props.paymentHold
              //         );
              //         if (this.props.paymentHold === true) {
              //           message.error(
              //             "You can not confirm this order due to not doing payment yet."
              //           );
              //         }
              //       } else if (type === "draft") {
              //         message.success("Draft Saved!");
              //       } else {
              //         message.success("Processing complete!");
              //       }

              //       this.setState({
              //         visible: false,
              //         current: 0
              //       });
              //       await this.props.closeModel();
              //       await this.props.dispatch(clearData());
              //       await this.props.dispatch(
              //         todaysArrivals(
              //           moment(new Date()).format("ddd MMM DD YYYY")
              //         )
              //       );
              //       await this.props.dispatch(
              //         todaysDepartures(
              //           moment(new Date()).format("ddd MMM DD YYYY")
              //         )
              //       );
              //       await this.props.dispatch(getOrderData());
              //     }
              //   });
            });
          } else {
            let paymentData = [];
            this.props.payment && paymentData.push(this.props.payment)
            const orderObj = {
              orderId: this.props.orderData[0].id,
              id: this.props.orderData[0].orderId,
              customer:
                (this.props.customer && this.props.customer.id) ||
                (this.props.orderData && this.props.orderData[0].customerId),
              contactPersonName:
                this.props.contactPersonName ||
                this.props.orderData[0].contactPersonName,
              contactPersonInfo:
                this.props.contactPersonInfo ||
                this.props.orderData[0].contactPersonInfo,
              note: this.props.note || this.props.orderData[0].note,
              arrivalTime:
                this.props.arrivalTime || this.props.orderData[0].arrivalTime,
              departureTime:
                this.props.departureTime ||
                this.props.orderData[0].departureTime,
              dates: this.props.dates || this.props.orderData[0].dates,
              totalCost: this.props.totalCost || this.props.orderData[0].total,
              selectedCard: this.props.selectedCard || [],
              creditCards: this.props.creditCards || [],
              selectedCardAmount: this.props.selectedCardAmount || 0,
              discount: this.props.discount || 0,
              payments: this.props.orderData[0].payments ? this.props.orderData[0].payments : [this.props.payment] 
            };

            this.setState({ orderData: this.props }, async () => {
              this.setState({ loader: true });
              await this.props.dispatch(
                updateOrderData(this.props.orderData[0].id, orderObj)
              );
              this.props.dispatch(
                getSingleOrderData(this.props.orderData[0].orderId)
              );
              this.props.dispatch(
                todaysArrivals(moment(new Date()).format("ddd MMM DD YYYY"))
              );
              this.props.dispatch(
                todaysDepartures(moment(new Date()).format("ddd MMM DD YYYY"))
              );
              this.props.dispatch(todaysCurrentOrders(moment(new Date()).format("ddd MMM DD YYYY")))
              await this.props.dispatch(getOrderData());
              this.setState({ visible: false });
            });
          }
        }
      }
    );
  };

  handleOkconfirmOrderAdmin = async type => {
    this.setState({ orderData: this.props }, async () => {
      await Object.entries(this.state.orderData).forEach(([key, value]) => {
        if (`${key}` && `${value}` === "undefined") {
          this.setState({
            orderData: {
              ...this.state.orderData,
              draft: type === "draft" ? true : false,
              status: type
            }
          });
        }
      });
    });
    await this.addOrderfunction(this.state.orderData, type);
    await this.setState({ confirmOrderShowAdmin: false, visible: false });
  };

  handleCancleConfirmOrderAdmin = async () => {
    await this.setState({ confirmOrderShowAdmin: false });
  };

  handleOkconfirmOrder = async (type) => {
    if(this.props.paymentHold === true && this.props.selectedCard !== null){
      this.setState({ orderData: this.props }, async () => {
        await Object.entries(this.state.orderData).forEach(([key, value]) => {
          if (`${key}` && `${value}` === "undefined") {
            this.setState({
              orderData: {
                ...this.state.orderData,
                draft: type === "draft" ? true : false,
                status: type
              }
            });
          }
        });
      });
      await this.addOrderfunction(this.state.orderData, type);
      this.setState({ confirmOrderShow: false, visible: false });
    } else {
      this.setState({ confirmOrderShow: false, visible: false });
    }
  };

  handleCancleconfirmOrder = () => {
    this.setState({ confirmOrderShow: false });
  };

  handleChangeStatus = async (type, id) => {
    let data = {
      status: type,
      orderId: id
    };
    await axiosInstance
      .post(
        `/changeStatus`,
        {
          data
        },
        {
          handlerEnabled: true
        }
      )
      .then(async res => {
        if (res.data.success) {
          message.success("Status has been updated successfully!");
          this.props.dispatch(getOrderData());
          this.props.closeModel();
        }
      })
      .catch(e => {
        message.error("Something went wrong!");
      });
  };

  newCustomer = res => {
    this.setState({ newCustomer: res });
  };

  render() {
    const { current } = this.state;
    const { visible } = this.props;
    const orderData = this.props.orderData;
    const steps = [
      {
        title: "Customer Information",
        content: (
          <CustomerForm newCustomer={this.newCustomer} form={this.props.form} />
        ),
        icon: "user"
      },
      {
        title: "Order",
        content: (
          <OrderForm form={this.props.form} variant={this.props.variant} />
        ),
        icon: "shopping-cart"
      },
      {
        title: "Payment",
        content: (
          <PaymentForm form={this.props.form} variant={this.props.variant} />
        ),
        icon: "credit-card"
      }
    ];
    this.props.match.params.customerId && steps.splice(0, 1);
    let isDraft = this.props.match.params.customerId ? 0 : 1;
    return (
      <div>
        <Steps current={current} type="navigation" onChange={this.onChange}>
          {steps.map(item => (
            <Steps.Step
              key={item.title}
              title={item.title}
              icon={<Icon type={item.icon} />}
              disabled={visible ? false : true}
            />
          ))}
        </Steps>
        <div className="steps-content" style={{ padding: "16px 0" }}>
          {steps[current].content}
        </div>
        <div className="steps-action">
          {current > 0 && (
            <Button style={{ marginRight: 8 }} onClick={() => this.prev()}>
              Previous
            </Button>
          )}

          {current < steps.length - 1 && (
            <>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => this.next()}
              >
                {this.state.current === 0 && this.state.newCustomer
                  ? "Save & Continue"
                  : "Next"}
              </Button>
            </>
          )}
          {"  "}
          {current === isDraft &&
            // this.props.variant !== "edit" &&
            size(this.props.dates) > 0 ? (
            <span style={{ marginLeft: 10 }}>Save as</span>
          ) : null}
          {"  "}
          {current === isDraft &&
            // this.props.variant !== "edit" &&
            size(this.props.dates) > 0 ? (
            <Button.Group>
              <Button
                htmlType="submit"
                type="primary" 
                ghost
                onClick={() =>
                  this.props.variant !== "edit"
                    ? this.handleSave("Inquiry")
                    : this.handleChangeStatus(
                      "Inquiry",
                      orderData && orderData[0].id
                    )
                }
              >
                Inquiry
              </Button>
              <Button
                htmlType="submit"
                // type="danger"
                type="primary" 
                ghost
                onClick={() =>
                  this.props.variant !== "edit"
                    ? this.handleSave("draft")
                    : this.handleChangeStatus(
                      "draft",
                      orderData && orderData[0].id
                    )
                }
              >
                Draft
              </Button>
              <Button
                htmlType="submit"
                // type="danger"
                type="primary" 
                ghost
                onClick={() =>
                  this.props.variant !== "edit"
                    ? this.handleSave("Soft Inquiry")
                    : this.handleChangeStatus(
                      "Soft Inquiry",
                      orderData && orderData[0].id
                    )
                }
              >
                Soft Inquiry
              </Button>
            </Button.Group>
          ) : null}
          {/* {current === isDraft &&
          // this.props.variant !== "edit" &&
          size(this.props.dates) > 0 ? (
            <Button
              htmlType="submit"
              type="danger"
              className="ml-2"
              onClick={() =>
                this.props.variant !== "edit"
                  ? this.handleSave("Inquiry")
                  : this.handleChangeStatus(
                      "Inquiry",
                      orderData && orderData[0].id
                    )
              }
            >
              Save As Inquiry
            </Button>
          ) : null} */}
          {"  "}
          {/* {current === isDraft &&
          this.props.variant !== "edit" &&
          size(this.props.dates) > 0 ? (
            <Button
              htmlType="submit"
              type="danger"
              className="ml-2"
              onClick={() =>
                this.props.variant !== "edit"
                  ? this.handleSave("draft")
                  : this.handleChangeStatus(
                      "draft",
                      orderData && orderData[0].id
                    )
              }
            >
              Save As Draft
            </Button>
          ) : null} */}
          {/* {current === isDraft &&
          // this.props.variant !== "edit" &&
          size(this.props.dates) > 0 ? (
            <Button
              htmlType="submit"
              type="danger"
              className="ml-2"
              onClick={() =>
                this.props.variant !== "edit"
                  ? this.handleSave("Soft Inquiry")
                  : this.handleChangeStatus(
                      "Soft Inquiry",
                      orderData && orderData[0].id
                    )
              }
            >
              Save As Soft Inquiry
            </Button>
          ) : null} */}
          {current === steps.length - 1 && size(this.props.dates) > 0 ? (
            <span>Save as</span>
          ) : null}
          {"  "}
          {current === steps.length - 1 && size(this.props.dates) > 0 ? (
            <Button.Group>
              <Button
                htmlType="submit"
                // type="danger"
                // className="ml-2"
                type="primary" 
                ghost
                onClick={() =>
                  this.props.variant !== "edit"
                    ? this.handleSave("draft")
                    : this.handleChangeStatus(
                      "draft",
                      orderData && orderData[0].id
                    )
                }
              >
                Draft
              </Button>
              <Button
                htmlType="submit"
                // type="danger"
                // className="ml-2"
                type="primary" 
                ghost
                onClick={() =>
                  this.props.variant !== "edit"
                    ? this.handleSave("Soft Inquiry")
                    : this.handleChangeStatus(
                      "Soft Inquiry",
                      orderData && orderData[0].id
                    )
                }
              >
                Soft Inquiry
              </Button>
            </Button.Group>
          ) : null}
          {/* {current === steps.length - 1 && size(this.props.dates) > 0 ? ( 
            <Button
              htmlType="submit"
              type="danger"
              className="ml-2"
              onClick={() =>
                this.props.variant !== "edit"
                  ? this.handleSave("draft")
                  : this.handleChangeStatus(
                      "draft",
                      orderData && orderData[0].id
                    )
              }
            >
              Save As Draft
            </Button>
          ) : null}
          {current === steps.length - 1 && size(this.props.dates) > 0 ? (
            <Button
              htmlType="submit"
              type="danger"
              className="ml-2"
              onClick={() =>
                this.props.variant !== "edit"
                  ? this.handleSave("Soft Inquiry")
                  : this.handleChangeStatus(
                      "Soft Inquiry",
                      orderData && orderData[0].id
                    )
              }
            >
              Save As Soft Inquiry
            </Button>
          ) : null} */}
          {current === steps.length - 1 &&
            this.props.variant !== "edit" &&
            size(this.props.dates) > 0 ? (
            <Button
              htmlType="submit"
              type="primary"
              className="ml-2"
              onClick={() =>
                this.props.variant !== "edit"
                  ? this.handleSave("Confirmed")
                  : this.handleChangeStatus(
                    "Confirmed",
                    orderData && orderData[0].id
                  )
              }
            >
              Confirm
            </Button>
          ) : null}

          {current === steps.length - 1 && (
            <Button
              className="ml-2"
              type="primary"
              onClick={() => this.handleSave("Inquiry")}
            >
              {this.props.visible ? "Update" : "Done"}
            </Button>
          )}
        </div>
        <Modal
          title="Confirm Order"
          visible={this.state.confirmOrderShowAdmin}
          onOk={this.handleOkconfirmOrderAdmin}
          onCancel={this.handleCancleConfirmOrderAdmin}
        >
          {this.props.paymentHold === true
            ? "The card will not be charged now, it will be placed on hold. Do you wish to continue?"
            : "This order is not paid and card was not put on hold, do you wish to confirm without payment?"}
        </Modal>
        <Modal
          title="Confirm Order"
          visible={this.state.confirmOrderShow}
          onOk={this.handleOkconfirmOrder}
          onCancel={this.handleCancleconfirmOrder}
        >
          {this.props.paymentHold === true ? "The card will not be charged now, it will be placed on hold. Do you wish to continue?" : "This order can not be confirmed because it is not paid and doesn't have a credit card"}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ orderReducer }) => ({
  customer: orderReducer.customer,
  type: "Lodging",
  contactPersonName: orderReducer.contactPersonName,
  contactPersonInfo: orderReducer.contactPersonInfo,
  note: orderReducer.note,
  arrivalTime: orderReducer.arrivalTime,
  departureTime: orderReducer.departureTime,
  dates: orderReducer.dates,
  discount: orderReducer.discount,
  totalCost: orderReducer.total,
  cardNumber: orderReducer.cardNumber,
  cardExpiry: orderReducer.cardExpiry,
  paymentAmount: orderReducer.paymentAmount,
  cashAmount: orderReducer.cashAmount,
  routingNo: orderReducer.routingNo,
  chequeAmount: orderReducer.chequeAmount,
  orderData: orderReducer.orderData,
  visible: orderReducer.visible,
  updateOrderSuccess: orderReducer.updateOrderSuccess,
  creditCards: orderReducer.creditCards,
  startDate: orderReducer.startDate,
  lastDate: orderReducer.lastDate,
  selectedCard: orderReducer.selectedCard,
  selectedCardAmount: orderReducer.selectedCardAmount,
  payment: orderReducer.payment,
  paymentHold: orderReducer.paymentHold,
  authUser: orderReducer.authUser
});

const NewOrderForm = Form.create({})(NewOrder);

export default connect(mapStateToProps)(withRouter(NewOrderForm));
