import React, { Suspense } from "react";
// prettier-ignore
import { Typography, Tabs, Form,  Table, Button, Tag, DatePicker, Skeleton } from "antd";

import moment from "moment";
import { Link } from "react-router-dom";
import { getOrderData } from "../../actions/orderActionCreator";
import { connect } from "react-redux";
import { axiosInstance } from "../../httpHandler";

class TodaysMeals extends React.Component {
  state = {
    date: moment(new Date()).format("ddd MMM DD YYYY"),
    data: [],
    mainData: { customer: [], data: [], order: [] },
    dataLoaded: false,
  };

  componentDidMount = async () => {
    // if (!this.props.getOrderData) {
    //   await this.props.dispatch(getOrderData());
    // }
    if (this.props.getOrderData && this.props.getOrderData.orders.length > 0) {
      console.info("TodaysMeals.js", "Hello");
      this.fetchData();
    } else {
      axiosInstance
        .get("/orders", { handlerEnabled: true })
        .then(async (response) => {
          await this.setState({ data: response.data.orders }, () => {
            this.fetchData(response.data.orders);
          });
        });
    }
  };

  changeDate = async (e) => {
    await this.setState(
      {
        date: moment(e).format("ddd MMM DD YYYY"),
        // mainData: { ...this.state.mainData,
        // data: []
        //  }
      },
      async () => {
        await this.fetchData();
      }
    );
  };

  fetchData(data) {
    if (this.props.getOrderData || data) {
      this.setState(
        {
          data:
            this.props.getOrderData && this.props.getOrderData.orders.length > 0
              ? this.props.getOrderData.orders
              : data,
        },
        () => {
          const ordData = this.state.data;
          const data = [];
          const customer = [];
          const order = [];
          ordData.length > 0
            ? ordData.map((arr, i) => {
                arr.dates &&
                  Object.entries(arr.dates).map((res, i) => {
                    if (res[0] === this.state.date) {
                      data.push(res[1]);
                      order.push(arr.orderId);
                      customer.push(arr.customer);
                      this.setState({
                        mainData: {
                          customer: customer,
                          data: data,
                          order: order,
                          dataLoaded: true,
                        },
                      });
                    } else {
                      this.setState({
                        dataLoaded: true,
                      });
                    }
                  });
              })
            : this.setState({ dataLoaded: true });
        }
      );
    }

    // this.setState({ data: [] }, () => {
    //   axiosInstance
    //     .get("/orders", { handlerEnabled: true })
    //     .then(async response => {
    //       await this.setState({ data: response.data.orders });

    //       const ordData = this.state.data;
    //       const data = [];
    //       const customer = [];
    //       const order = [];
    //       ordData.length > 0 &&
    //         ordData.map((arr, i) => {
    //           Object.entries(arr.dates).map((res, i) => {
    //             if (res[0] === this.state.date) {
    //               data.push(res[1]);
    //               order.push(arr.orderId);
    //               customer.push(arr.customer);
    //               this.setState({
    //                 mainData: {
    //                   customer: customer,
    //                   data: data,
    //                   order: order
    //                 }
    //               });
    //             }
    //           });
    //         });
    //     })
    //     .catch(error => {});
    // });
  }

  render() {
    const { mainData } = this.state;
    const mealData =
      this.state.mainData.data.length > 0 &&
      this.state.mainData.data.map((data, i) => ({
        key: i,
        type: data.food,
        preferences: data.food,
        customer:
          mainData.customer[i].firstName + " " + mainData.customer[i].lastName,
        orderId: mainData.order[i],
      }));

    const breakfastDt = [];
    const lunchDt = [];
    const dinnerDt = [];
    console.info("TodaysMeals.js", { breakfastDt: breakfastDt });
    console.info("TodaysMeals.js", { lunchDt: lunchDt });
    console.info("TodaysMeals.js", { dinnerDt: dinnerDt });
    const brekfastData =
      this.state.mainData.data.length > 0 &&
      this.state.mainData.data.map((data, i) => {
        if (data.food.breakfast.people > 0) {
          breakfastDt.push({
            key: i,
            type: data.food.breakfast.VIP,
            people: data.food.breakfast.people,
            preferences: data.food.breakfast.preferences,
            customer:
              mainData.customer[i].firstName +
              " " +
              mainData.customer[i].lastName,
            orderId: mainData.order[i],
          });
        }
      });
    const lunchData =
      this.state.mainData.data.length > 0 &&
      this.state.mainData.data.map((data, i) => {
        if (data.food.lunch.people > 0) {
          lunchDt.push({
            key: i,
            type: data.food.lunch.VIP,
            people: data.food.lunch.people,
            preferences: data.food.lunch.preferences,
            customer:
              mainData.customer[i].firstName +
              " " +
              mainData.customer[i].lastName,
            orderId: mainData.order[i],
          });
        }
      });
    const dinnerData =
      this.state.mainData.data.length > 0 &&
      this.state.mainData.data.map((data, i) => {
        if (data.food.dinner.people > 0) {
          dinnerDt.push({
            key: i,
            type: data.food.dinner.VIP,
            people: data.food.dinner.people,
            preferences: data.food.dinner.preferences,
            customer:
              mainData.customer[i].firstName +
              " " +
              mainData.customer[i].lastName,
            orderId: mainData.order[i],
          });
        }
      });

    const dataSource = [
      {
        key: "1",
        type: "Regular",
        preferences: [],
        customer: "Yaakov Green",
        orderId: "KR2356",
      },
      {
        key: "2",
        type: "Regular",
        preferences: ["Fish"],
        customer: "Yaakov Green",
        orderId: "KR2356",
      },
      {
        key: "3",
        type: "Regular",
        preferences: ["Chicken"],
        customer: "Yaakov Green",
        orderId: "KR2356",
      },
      {
        key: "4",
        type: "Regular",
        preferences: [],
        customer: "Shmiel Szoffer",
        orderId: "KR3165",
      },
      {
        key: "5",
        type: "VIP",
        preferences: [],
        customer: "Shmiel Szoffer",
        orderId: "KR3165",
      },
      {
        key: "7",
        type: "Regular",
        preferences: ["Beef"],
        customer: "Shulem Gross",
        orderId: "KR36215",
      },
      {
        key: "8",
        type: "VIP",
        preferences: ["Beef"],
        customer: "Shulem Gross",
        orderId: "KR36215",
      },
      {
        key: "9",
        type: "Regular",
        preferences: ["Beef"],
        customer: "Shulem Gross",
        orderId: "KR36215",
      },
    ];

    const breakfastColumns = [
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        render: (type) => {
          return <strong>{type ? `VIP` : "Regular"}</strong>;
        },
      },
      {
        title: "People",
        dataIndex: "people",
        key: "people",
        render: (people) => <strong>{people}</strong>,
      },
      {
        title: "Preferences",
        dataIndex: "preferences",
        key: "preferences",
        render: (p) => {
          return (
            (p && p.length > 0) &&
            p.map((pr) => {
              return <Tag>{pr}</Tag>;
            })
          );
        },
      },
      {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        render: (customer) => <strong>{customer}</strong>,
      },
      {
        title: "Order ID",
        dataIndex: "orderId",
        key: "orderId",
        render: (orderId) => {
          return (
            <Link to={{ pathname: `/orders/` + orderId }}>
              <strong>{"KRO" + orderId}</strong>
            </Link>
          );
        },
      },
    ];
    const lunchColumns = [
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        render: (type) => {
          // const tp = type.lunch.VIP;
          return <strong>{type ? "VIP" : "Regular"}</strong>;
        },
      },
      {
        title: "People",
        dataIndex: "people",
        key: "people",
        render: (people) => {
          return <strong>{people}</strong>;
        },
      },
      {
        title: "Preferences",
        dataIndex: "preferences",
        key: "preferences",
        render: (p) => {
          return (
            (p && p.length > 0) &&
            p.map((pr) => {
              return <Tag>{pr}</Tag>;
            })
          );
        },
      },
      {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        render: (customer) => <strong>{customer}</strong>,
      },
      {
        title: "Order ID",
        dataIndex: "orderId",
        key: "orderId",
        render: (orderId) => {
          return (
            <Link to={{ pathname: `orders/` + orderId }}>
              <strong>{"KRO" + orderId}</strong>
            </Link>
          );
        },
      },
    ];

    const dinnerColumns = [
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        render: (type) => {
          return <strong>{type ? "VIP" : "Regular"}</strong>;
        },
      },
      {
        title: "People",
        dataIndex: "people",
        key: "people",
        render: (people) => {
          return <strong>{people}</strong>;
        },
      },
      {
        title: "Preferences",
        dataIndex: "preferences",
        key: "preferences",
        render: (p) => {
          return (
            p.length > 0 &&
            p.map((pr) => {
              return <Tag>{pr}</Tag>;
            })
          );
        },
      },
      {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        render: (customer) => <strong>{customer}</strong>,
      },
      {
        title: "Order ID",
        dataIndex: "orderId",
        key: "orderId",
        render: (orderId) => {
          return (
            <Link to={{ pathname: `/orders/` + orderId }}>
              <strong>{"KRO" + orderId}</strong>
            </Link>
          );
        },
      },
    ];
    return (
      <React.Fragment>
        <Typography.Title level={3}>
          Meals for {moment(this.state.date).format("dddd DD/MM/YYYY")}
        </Typography.Title>
        <Form layout="inline">
          <Form.Item label="Switch date">
            <DatePicker
              onChange={(e) => {
                this.changeDate(e);
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary">Submit</Button>
          </Form.Item>
        </Form>
        <Tabs defaultActiveKey="1" animated={false}>
          <Tabs.TabPane tab="Breakfest" key="1">
            {this.state.mainData.data.length > 0 ? (
              <Table
                dataSource={breakfastDt}
                columns={breakfastColumns}
                style={{ marginTop: "24px" }}
              />
            ) : (
              <Skeleton active />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Lunch" key="2">
            {this.state.mainData.data.length > 0 ? (
              <Table
                dataSource={lunchDt}
                columns={lunchColumns}
                style={{ marginTop: "24px" }}
              />
            ) : (
              <Skeleton active />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Dinner" key="3">
            {this.state.mainData.data.length > 0 ? (
              <Table
                dataSource={dinnerDt}
                columns={dinnerColumns}
                style={{ marginTop: "24px" }}
              />
            ) : (
              <Skeleton active />
            )}
          </Tabs.TabPane>
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ orderReducer }) => ({
  getOrderData: orderReducer.getOrderData,
});

export default connect(mapStateToProps)(TodaysMeals);
