import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { connect } from "react-redux";
import { getRooms } from "../../actions/orderActionCreator";
import { Skeleton, TreeSelect } from "antd";
import { size, countBy, groupBy } from "lodash";
import { withRouter } from "react-router-dom";
import { axiosInstance } from "../../httpHandler";
const localizer = momentLocalizer(moment);

class AvailabilityCalendar extends Component {
  state = {
    date: new Date(),
    view: "month",
    roomData: [],
    room: {},
    events: [],
    roomEvents: [],
    buildingId: "",
    roomId: "",
    dropdownkey: [],
    loading: false,
    treeSelectValue: undefined,
  };

  componentDidMount() {
    this.props.dispatch(getRooms());
    this.props.roomData && this.setState({ roomData: this.props.roomData });
  }

  countBeds = (visitors, beds) => {
    let b = beds;
    visitors &&
      Object.keys(visitors).map((obj, i) => {
        if (visitors) {
          if (
            visitors[obj].type === "couple" ||
            visitors[obj].type === "private"
          ) {
            b = b - 2;
          }
          if (
            visitors[obj].type === "boys" ||
            visitors[obj].type === "woman" ||
            visitors[obj].type === "girls" ||
            visitors[obj].type === "men"
          ) {
            b = b - 1;
          }
        }
      });
    return b;
  };

  getRoom = async (building, room) => {
    let events = [];
    let dates = [];
    const { date } = this.state;
    let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0);
    for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
      dates.push(new Date(day));
    }
    let roomData = {};
    // if (isEmpty(this.state.room)) {
    roomData = await axiosInstance
      .get(`/getroom/${building}/${room}`, {
        handlerEnabled: true,
      })
      .then((res) => {
        if (res.data) {
          this.setState({ room: res.data.data, loading: false });
          return res.data.data;
        }
      });
    // }
    roomData = this.state.room;
    dates.forEach((day) => {
      let room = roomData.dates.find(
        (date) =>
          moment(day).format("MM/DD/YYYY") ===
          moment(date.id).format("MM/DD/YYYY")
      );
      if (room && size(room.visitors) > 0) {
        let avBeds = this.countBeds(room.visitors, roomData.beds);
        events.push({
          title: avBeds + (avBeds > 1 ? " Beds Available" : " Bed Available"),
          start: new Date(day),
          end: new Date(day),
        });
      } else {
        events.push({
          title: `${roomData.beds} Beds Available`,
          start: new Date(day),
          end: new Date(day),
        });
      }
    });
    this.setState({ roomEvents: events });
  };
  componentWillReceiveProps(props) {
    props.roomData && this.setState({ roomData: props.roomData });
  }
  changeRoom = (e) => {
    let s = e.split(",");
    let room = s[0];
    let building = s[1];
    this.setState({
      buildingId: building,
      roomId: room,
      treeSelectValue: e,
      loading: true,
    });
    this.getRoom(building, room);
  };
  setCalenderData = () => {
    let events = [];
    let data = [];
    let dates = [];
    const { roomData, date } = this.state;
    let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0);
    for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
      dates.push(new Date(day));
    }
    dates.forEach((day) => {
      roomData &&
        roomData.map((room, k) => {
          if (room.dates) {
            room.dates.map((dt1) => {
              if (size(dt1.visitors) > 0) {
                if (
                  moment(day).format("MM/DD/YYYY") ===
                  moment(dt1.id).format("MM/DD/YYYY")
                ) {
                  events.push({
                    title: room.name,
                    start: new Date(day),
                    end: new Date(day),
                  });
                }
              }
            });
          }
        });
    });
    let e = countBy(events, "start");
    let newArray2 = roomData.filter((room) => room.name && room.beds > 0);
    dates.forEach((date) => {
      Object.keys(e).map((obj) => {
        if (
          moment(date).format("MM/DD/YYYY") === moment(obj).format("MM/DD/YYYY")
        ) {
          let count = newArray2.length - e[obj];
          data.push({
            title: count + (count > 1 ? " Rooms Available" : " Room Available"),
            start: new Date(date),
            end: new Date(date),
          });
        }
      });
      data.push({
        title: newArray2.length + "  Rooms Available",
        start: new Date(date),
        end: new Date(date),
      });
    });

    let newArray = data.filter(
      (v, i, b) =>
        b.findIndex(
          (t) =>
            moment(t.start).format("MM/DD/YYYY") ===
            moment(v.start).format("MM/DD/YYYY")
        ) === i
    );
    return newArray;
  };

  render() {
    let events = this.setCalenderData();

    const { roomEvents, room, roomData, loading } = this.state;
    let buildings = groupBy(roomData, "buildingName");
    return (
      <div>
        {roomData.length ? (
          <>
            {!loading ? (
              <>
                <TreeSelect
                  style={{ width: 200, position: "absolute", left: "480px" }}
                  value={this.state.treeSelectValue}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Please select Room"
                  onChange={this.changeRoom}
                >
                  {buildings &&
                    Object.keys(buildings).map((building) => (
                      <TreeSelect.TreeNode
                        selectable={false}
                        value={building}
                        title={building}
                        className="tree-parent"
                      >
                        {buildings[building].map((room) => (
                          <TreeSelect.TreeNode
                            isLeaf
                            value={`${room.id},${room.building}`}
                            title={room.name}
                          />
                        ))}
                      </TreeSelect.TreeNode>
                    ))}
                </TreeSelect>
                {/* <Select
              style={{ width: 200, position: "absolute", left: "480px" }}
              placeholder="Select a room"
              optionFilterProp="children"
              onChange={(e) => this.changeRoom(e)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option value="1#" disabled>
                <strong>Building | Room </strong>
              </Select.Option>
              {this.state.roomData &&
                this.state.roomData.map((room, i) => (
                  <Select.Option key={i} value={room.id + "," + room.building}>
                    {room.buildingName} | {room.name}{" "}
                  </Select.Option>
                ))}
            </Select> */}

                <Calendar
                  localizer={localizer}
                  defaultDate={new Date()}
                  views={["month"]}
                  date={this.state.date}
                  onNavigate={(date) => {
                    this.setState(
                      { date },
                      () =>
                        roomEvents.length &&
                        this.getRoom(this.state.buildingId, this.state.roomId)
                    );
                  }}
                  defaultView="month"
                  events={roomEvents.length ? roomEvents : events}
                  style={{ height: "100vh" }}
                  eventPropGetter={(event, start, end, isSelected) => {
                    let bedsAvailable = Number(event.title.split(" ")[0]);
                    let totalBeds = Number(room.beds);
                    let newStyle = {
                      backgroundColor: "lightgreen",
                      color: "black",
                      borderRadius: "0px",
                      border: "none",
                    };
                    if (roomEvents.length) {
                      if (bedsAvailable === totalBeds) {
                        newStyle.backgroundColor = "lightgreen";
                        newStyle.color = "black";
                      } else if (bedsAvailable < 1) {
                        newStyle.backgroundColor = "red";
                        newStyle.color = "#fff";
                      } else {
                        newStyle.backgroundColor = "#3174ad";
                        newStyle.color = "#fff";
                      }
                    } else {
                      if (event.title === "0 Room Available") {
                        newStyle.backgroundColor = "red";
                        newStyle.color = "#fff";
                      }
                    }
                    return {
                      className: "",
                      style: newStyle,
                    };
                  }}
                  onSelectEvent={(a, b) => {
                    this.props.history.push({
                      pathname: "/room-placement",
                      state: {
                        adate: a.start,
                      },
                    });
                  }}
                />
              </>
            ) : (
              <Skeleton active />
            )}
          </>
        ) : (
          <Skeleton active />
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ orderReducer }) => {
  return {
    roomData: orderReducer.roomData,
  };
};

export default connect(mapStateToProps)(withRouter(AvailabilityCalendar));
