import React from 'react';

import './widget.css';

const Widget = (props) => {
    return (
        <div className="widget" style={{ padding: (props.padding ? props.padding : '16px') }}>
            {props.children}
        </div>
    )
}

export default Widget;