import { Modal, Button, Form, Row, Col, Typography, Input, Select } from "antd";
import React from "react";
import { addUser } from "../../actions/orderActionCreator";
import { connect } from "react-redux";

class AddUser extends React.Component {
  state = {
    visible: false,
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    userType: "",
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    const { firstName, lastName, email, phoneNo, userType } = this.state;
    this.props.dispatch(
      addUser({
        firstName,
        lastName,
        email,
        phoneNo,
        userType,
      })
    );
    this.setState({
      visible: false,
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      userType: "",
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };
  componentDidMount() {}
  render() {
    // const validateMessages = {
    //   required: "${label} is required!",
    //   types: {
    //     email: "${label} is not validate email!",
    //   },
    // };
    return (
      <>
        <Button
          type="primary"
          shape="round"
          onClick={() => {
            this.showModal();
          }}
        >
          + Add User
        </Button>
        <Modal
          title="ADD USER"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form
            name="nest-messages"
            // onFinish={onFinish}
            // validateMessages={validateMessages}
            style={{ margin: "24px auto" }}
          >
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Form.Item
                  name={["user", "fname"]}
                  label="First Name"
                  rules={[{ required: true }]}
                >
                  <Input
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={["user", "lname"]}
                  label="Last Name"
                  rules={[{ required: true }]}
                >
                  <Input
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="phone"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => this.setState({ phoneNo: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={["user", "email"]}
                  label="Email"
                  rules={[{ type: "email", required: true }]}
                >
                  <Input
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={["user", "type"]} label="Type">
                  <Select onChange={(e) => this.setState({ userType: e })}>
                    <Select.Option
                      value="Admin"
                      disabled={this.props.authUser?.user?.userType !== "Admin"}
                    >
                      Admin
                    </Select.Option>
                    <Select.Option value="Secractery">Secractery</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = ({ orderReducer }) => {
  return {
    authUser: orderReducer.authUser,
  };
};
export default connect(mapStateToProps)(AddUser);
