import React, { Component } from "react";
import { Button, Form, Input, InputNumber, message, Typography } from "antd";
import { axiosInstance } from "../../../httpHandler";
import { connect } from "react-redux";
import { getCurrency } from "../../../actions/orderActionCreator";

class Currencies extends Component {
  state = {
    Shekel: 0,
    Pound: 0,
    Rubl: 0,
    Hryvnia: 0,
    Zlaty: 0,
    Euro: 0,
  };
  componentWillReceiveProps(nextProps) {
    let currency = nextProps.currency.data;

    this.setState({
      Shekel: currency.Shekel,
      Pound: currency.Pound,
      Rubl: currency.Rubl,
      Hryvnia: currency.Hryvnia,
      Zlaty: currency.Zlaty,
      Euro: currency.Euro,
    });
  }
  componentDidMount() {
    this.props.dispatch(getCurrency());
  }
  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };
  addCurrency = (e) => {
    e.preventDefault();
    axiosInstance
      .patch("/editcurrency", this.state, { handlerEnabled: true })
      .then((res) => {
        message.success("Currency has been updated successfully.");
        this.props.dispatch(getCurrency());
      });
  };
  render() {
    return (
      <>
        <Typography.Title level={3}>Currency settings</Typography.Title>
        <Form onSubmit={this.addCurrency}>
          <Form.Item label="₪ Shekel">
            <InputNumber
              onChange={(e) => this.handleChange("Shekel", e)}
              value={this.state.Shekel}
            />
          </Form.Item>
          <Form.Item label="£ Pound">
            <InputNumber
              onChange={(e) => this.handleChange("Pound", e)}
              value={this.state.Pound}
            />
          </Form.Item>
          <Form.Item label="₽ Rubl">
            <InputNumber
              onChange={(e) => this.handleChange("Rubl", e)}
              value={this.state.Rubl}
            />
          </Form.Item>
          <Form.Item label="₴ Hryvnia">
            <InputNumber
              onChange={(e) => this.handleChange("Hryvnia", e)}
              value={this.state.Hryvnia}
            />
          </Form.Item>
          <Form.Item label="zł Zlaty">
            <InputNumber
              onChange={(e) => this.handleChange("Zlaty", e)}
              value={this.state.Zlaty}
            />
          </Form.Item>
          <Form.Item label="€ Euro">
            <InputNumber
              onChange={(e) => this.handleChange("Euro", e)}
              value={this.state.Euro}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit" shape="round">
            Save
          </Button>
        </Form>
      </>
    );
  }
}
const mapStateToProps = ({ orderReducer }) => {
  return {
    currency: orderReducer.currency,
  };
};
export default connect(mapStateToProps)(Currencies);
