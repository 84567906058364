import React from "react";
import { Button, Modal, Form, DatePicker, Input, message } from "antd";
import { axiosInstance } from "../../httpHandler";
import moment from "moment";
import { connect } from "react-redux";
import { getNotes } from "../../actions/orderActionCreator";

class NewNote extends React.Component {
  state = { visible: false, date: moment(new Date()).format("L"), note: "" };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = async (e) => {
    await axiosInstance
      .post(`/addNote`, this.state, { handlerEnabled: true })
      .then((res) => {
        message.success("Note Added");
        this.props.dispatch(getNotes());
        this.setState({
          visible: false,
          note: "",
          date: moment(new Date()).format("L"),
        });
      })
      .catch((error) => message.error("error"));
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };
  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };
  render() {
    return (
      <>
        <Button type="primary" shape="round" onClick={this.showModal}>
          New note
        </Button>
        <Modal
          title="New note"
          visible={this.state.visible}
          onOk={this.handleOk}
          okText="Add"
          onCancel={this.handleCancel}
        >
          <Form
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            labelAlign="left"
          >
            <Form.Item label="Date">
              <DatePicker
                defaultValue={moment(this.state.date)}
                value={moment(this.state.date)}
                onChange={(e) =>
                  this.handleChange("date", moment(e).format("L"))
                }
              />
            </Form.Item>
            <Form.Item label="Note">
              <Input.TextArea
                rows="5"
                value={this.state.note}
                onChange={(e) => this.handleChange("note", e.target.value)}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = ({ orderReducer }) => {
  return {
    notes: orderReducer.notes,
  };
};

export default connect(mapStateToProps)(NewNote);
