import {
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Tabs,
  Button
} from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addOrder,
  addPayment,
  paymentTypePayNow
} from "../../actions/orderActionCreator";
import { handleCountCurrency } from "../../config/currencyConvertor";
import { store } from "../../store";

class PaymentMethods extends Component {
  state = {
    paymentType: "cash",
    paidCurrency: "USD",
    userCards: [],
    currency: "",
    currencyamount: "",
    cardsData: [],
    cheque: {
      amount: this.props.total - (this.props.donation || 0),
      chequeName: "",
      chequeDate: "",
      accountNumber: "",
      currency: "USD",
      routingNo: ""
    },
    amount: this.props.total,
    cashAmount: "",
    discount: 0,
    refNumber: "",
    grandTotal: 0,
    donation: 0
  };
  handlePaymentTypeChange = e => {
    this.setState({
      paymentType: e.target.value
    });
    store.dispatch(paymentTypePayNow(e.target.value));
  };
  handleCheque = (key, value) => {
    const { cheque } = this.state;
    let a = cheque;

    a = {
      ...a,
      [key]: value,
      type: "cheque"
    };
    console.log("a", a);
    this.setState({ cheque: a }, () => this.onChangeData());
    // store.dispatch(addOrder("cheque", a));
  };
  handleChange2 = (key, value) => {
    this.setState({ [key]: value }, () => this.onChangeData());
  };

  handleCashchange = e => {
    this.setState({ cashAmount: e.target.value }, () => this.onChangeData());
  };
  onChangeData = () => {
    if (this.state.paymentType === "cash") {
      this.props.dispatch(
        addPayment({
          cash: this.state.cashAmount,
          currency: this.state.currency,
          paidCurrency: this.state.paidCurrency,
          currencyamount:
            this.state.currencyamount !== " "
              ? this.state.currencyamount
              : this.state.cashAmount,
          amount: this.state.amount,
          type: "cash"
        })
      );
    } else if (this.state.paymentType === "cheque") {
      console.log("this.state.cheque", this.state.cheque);
      this.props.dispatch(addPayment(this.state.cheque));
    } else if (this.state.paymentType === "quickpay") {
      console.log("this.state.refNumber", this.state.refNumber);
      this.props.dispatch(
        addPayment({
          refNumber: this.state.refNumber,
          amount: this.state.amount,
          type: "quickpay"
        })
      );
    } else if (this.state.paymentType === "offlinecard") {
      this.props.dispatch(
        addPayment({
          cardRefNumber: this.state.cardRefNumber,
          amount: this.state.amount,
          type: "offlinecard"
        })
      );
    }
  };
  countCurrency = (amount, e) => {
    const currency = this.props.currency.data;

    let value = 0;
    value = handleCountCurrency(amount, e, currency);
    this.setState({ currencyamount: value }, () =>
      this.props.dispatch(
        addPayment({
          cash: this.state.cashAmount,
          currency: this.state.currency,
          paidCurrency: this.state.paidCurrency,
          currencyamount:
            this.state.currencyamount !== " "
              ? Number.parseFloat(this.state.currencyamount).toFixed(2)
              : this.state.cashAmount,
          amount: this.state.amount,
          type: "cash"
        })
      )
    );

    store.dispatch(addOrder("grandTotal", value));
    store.dispatch(addOrder("selectedcurrency", e));
    store.dispatch(addOrder("currencyTotal", Math.round(value * 100) / 100));
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    let currencies = [
      { sym: "$", value: "USD" },
      { sym: "zl", value: "ZlATY" },
      { sym: "₽", value: "RUBL" },
      { sym: "£", value: "POUND" },
      { sym: "₪", value: "SHEKEL" },
      { sym: "₴", value: "HRYVNIA" },
      { sym: "€", value: "EURO" }
    ];
    const currencyOptions = (
      <Select
        // disabled={this.props.disabled}
        value={this.state.paidCurrency}
        style={{ width: 80 }}
        className="select-before"
        onChange={e => {
          this.setState({ currency: e, paidCurrency: e }, () =>
            this.props.dispatch(
              addPayment({
                cash: this.state.cashAmount,
                currency: this.state.currency,
                paidCurrency: this.state.paidCurrency,
                amount: this.state.amount
              })
            )
          );
          this.countCurrency(this.props.total, e);
        }}
      >
        {currencies.map((currency, i) => (
          <Select.Option value={currency.value} key={i}>
            {currency.sym}
          </Select.Option>
        ))}
      </Select>
    );

    return (
      <div>
        <Form.Item label="Payment type">
          <Radio.Group
            disabled={this.props.disabled}
            defaultValue="Pay now"
            buttonStyle="solid"
            onChange={e => this.handlePaymentTypeChange(e)}
          >
            <Radio.Button value="cash">Cash</Radio.Button>
            <Radio.Button value="cheque">Check</Radio.Button>
            {/* <Radio.Button value="card">Credit card</Radio.Button> */}
            <Radio.Button value="quickpay">Quickpay</Radio.Button>
            <Radio.Button value="offlinecard">Offline Credit Card</Radio.Button>
          </Radio.Group>
        </Form.Item>{" "}
        {this.state.paymentType === "cash" ? (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                {" "}
                {getFieldDecorator("currencyTotal", {
                  value: this.props.grandTotal
                    ? this.props.grandTotal
                    : this.props.total
                })(
                  <>
                    <Input
                      /* disabled={true} */
                      size="large"
                      style={{ width: "100%" }}
                      addonBefore={currencyOptions}
                      onChange={this.handleCashchange}
                    />
                  </>
                )}
              </Form.Item>
            </Col>
          </Row>
        ) : null}
        {this.state.paymentType === "cheque" ? (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Check name">
                {getFieldDecorator("chequename", {
                  rules: [
                    {
                      required: true,
                      message: "Add Name Written On Check!"
                    }
                  ]
                })(
                  <Input
                    placeholder="Name on check"
                    onChange={e => {
                      this.handleCheque("chequeName", e.target.value);
                    }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Check date">
                {getFieldDecorator("chequedate", {
                  rules: [
                    {
                      required: true,
                      message: "Please Select date written On Check!"
                    }
                  ]
                })(
                  <DatePicker
                    placeholder="Check date"
                    onChange={e => {
                      this.handleCheque(
                        "chequeDate",
                        moment(e).format("ddd MMM DD YYYY")
                      );
                    }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Account number">
                {getFieldDecorator("accountNumber", {
                  rules: [
                    {
                      required: true,
                      message: "Please add Your Account Number!"
                    }
                  ]
                })(
                  <Input
                    placeholder="Account number"
                    onChange={e =>
                      this.handleCheque("accountNumber", e.target.value)
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Routing number">
                {getFieldDecorator("routingNo", {
                  rules: [
                    {
                      required: true,
                      message: "Please add Routing Number!"
                    }
                  ]
                })(
                  <Input
                    placeholder="Routing number"
                    onChange={e =>
                      this.handleCheque("routingNo", e.target.value)
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        ) : null}
        {this.state.paymentType === "quickpay" ? (
          <Form.Item label="Reference number">
            {getFieldDecorator("ref Number", {
              rules: [
                {
                  required: true,
                  message: "Please enter Reference number!"
                }
              ]
            })(
              <Input
                placeholder="Reference number"
                onChange={e => this.handleChange2("refNumber", e.target.value)}
              />
            )}
          </Form.Item>
        ) : null}
        {this.state.paymentType === "offlinecard" ? (
          <>
            <Form.Item label="Reference number">
              {getFieldDecorator("card ref Number", {
                rules: [
                  {
                    required: true,
                    message: "Please enter Reference number!"
                  }
                ]
              })(
                <Input
                  placeholder="Reference number"
                  onChange={e =>
                    this.handleChange2("cardRefNumber", e.target.value)
                  }
                />
              )}
            </Form.Item>
            <Form.Item rules={[{ required: true }]}>
              {getFieldDecorator("offcardAmount", {
                rules: [
                  {
                    required: true,
                    message: "Please enter amount!"
                  }
                ]
              })(
                <Input.Search
                  // defaultValue="$750"
                  enterButton="Process"
                  // onSearch={() => this.handleCard(card)}
                  size="large"
                  onChange={e => {
                    this.setState({ cardAmount: e.target.value });
                  }}
                />
              )}
            </Form.Item>
          </>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = ({ orderReducer }) => ({
  total: orderReducer.total,
  donation: orderReducer.donation,
  grandTotal: orderReducer.grandTotal,
  currencyTotal: orderReducer.currencyTotal,
  authUser: orderReducer.authUser,
  currency: orderReducer.currency
});
export default connect(mapStateToProps)(PaymentMethods);
