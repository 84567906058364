import React from "react";
// prettier-ignore
import { Row, Col, Typography, DatePicker, Tag, Skeleton, message, Modal, Button, Tabs, Icon, } from "antd";
import Widget from "../../components/Widget/Widget";
import moment from "moment";
import { axiosInstance } from "../../httpHandler";
import { connect } from "react-redux";
import {
  getRooms,
  getOrderByDate,
  updateReducer,
  getSingleOrderData,
  getOrderData
} from "../../actions/orderActionCreator";
import { Link } from "react-router-dom";
import { chunk } from "lodash";

const { confirm } = Modal;
const { CheckableTag } = Tag;


const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    const context = this;
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}

class RoomPlacement extends React.Component {
  state = {
    date: moment(new Date()).format("ddd MMM DD YYYY"),
    roomData: [],
    orderId: 0,
    type: "",
    rooms: [],
    orderNo: "",
    roomId: "",
    oldVisitors: [],
    OldBuildingId: null,
    drag: false,
    color: "grey",
    rowId: "",
    rowData: "",
    roomD: [],
    fetch: true,
    lastName: "",
    ordersData: "",
    custId: "",
    checked: false
  };

  componentWillReceiveProps(nextProps) {
    nextProps.ordersData?.orders &&
      this.setState({
        ordersData: nextProps.ordersData
      });
    if (this.state.fetch) {
      this.setRoomData(this.state.date, nextProps.roomData);
    }
  }

  setRoomData = (dt, roomDataa) => {
    let rData = [];
    roomDataa &&
      roomDataa.map((d, i) => {
        if (d.dates) {
          let index = d.dates.findIndex(
            item => item.id === moment(dt).format("ddd MMM DD YYYY")
          );
          if (index !== -1) {
            rData.push({
              key: i,
              room: d.name,
              data: {
                room: d.name,
                id: d.id,
                buildingId: d.building,
                beds: d.beds,
                buildingName: d.buildingName,
                av: this.calculateAvailibility(d.dates[index].visitors, d.beds),
                avail:
                  this.calculateAvailibility(d.dates[index].visitors, d.beds) +
                  "/" +
                  d?.beds,
                visitors: d.dates[index].visitors,
                date: moment(dt).format("ddd MMM DD YYYY"),
                orderId: this.state.orderId,
                type: this.state.type,
                preferences: d.preferences
              }
            });
          } else {
            rData.push({
              key: i,
              room: d.name,
              data: {
                room: d.name,
                id: d.id,
                buildingId: d.building,
                beds: d.beds,
                buildingName: d.buildingName,
                avail: d?.beds + "/" + d?.beds,
                visitors: [],
                date: moment(dt).format("ddd MMM DD YYYY"),
                orderId: this.state.orderId,
                type: this.state.type,
                preferences: d.preferences
              }
            });
          }
        }
      });

    this.setState({ rooms: roomDataa, roomD: rData });
  };
  componentDidMount() {
    let setDate =
      this.props.location.state && this.props.location.state.adate
        ? this.props.location.state.adate
        : this.state.date;
    this.props.dispatch(getOrderData())
    this.setState(
      {
        date: moment(new Date(setDate)).format("ddd MMM DD YYYY")
      },
      () => this.fetchData()
    );
  }

  fetchData = () => {
    this.props.dispatch(getRooms());
    this.props.dispatch(getOrderByDate(this.state.date));
  };

  dragStart = (
    t,
    id,
    orderNo,
    lastName = "",
    roomId = null,
    oldVisitors = [],
    OldBuildingId = "",
    oldData
  ) => {
    this.setState({
      type: t,
      orderId: id,
      orderNo,
      lastName,
      roomId,
      oldVisitors,
      OldBuildingId,
      oldData,
      drag: true
    });
  };
  dragOver = (e, index, data) => {
    e.preventDefault();
    let row = document.getElementsByClassName(`row${index}`);

    if (
      ((this.state.type === "couple" || this.state.type === "private") &&
        data.data.av < 2) ||
      data.data.av === 0
    ) {
      if (row && row[0]) row[0].style.backgroundColor = "red";
    } else {
      if (row && row[0]) row[0].style.backgroundColor = "grey";
    }
  };

  onDrop = async (e, data, i) => {
    const {
      type,
      orderId,
      orderNo,
      lastName,
      roomId,
      oldVisitors,
      OldBuildingId,
      roomD,
      oldData,
      custId
    } = this.state;
    data.type = type;
    data.orderNo = orderNo;
    data.orderId = orderId;
    data.lastName = lastName;
    data.OldRoom = roomId;
    data.oldVisitors = oldVisitors;
    data.OldBuildingId = OldBuildingId;
    data.custId = custId;
    this.setState({
      rowId: "",
      data: "",
      cColor: "",
      cIndex: ""
    });
    if (
      (type === "couple" && data.av < 2) ||
      (type === "private" && data.av < 2) ||
      (type === "boys" && data.av < 1) ||
      (type === "girls" && data.av < 1) ||
      (type === "men" && data.av < 1) ||
      (type === "women" && data.av < 1)
    ) {
      // e.preventDefault();
      message.error("Not Enough Beds In This Room");
    } else {
      if (data.id !== data.OldRoom) {
        if (data.OldRoom) {
          let b = oldVisitors;
          let visiIndex;

          visiIndex = b.findIndex(
            obj => obj.type === type && obj.orderId === orderId
          );
          if (visiIndex >= 0) {
            data.roomOrderToRemove = b[visiIndex].roomFeeOrder;
            b.splice(visiIndex, 1);
          }
          data.visitors.push({
            type: data.type,
            orderId: orderId,
            order: orderNo,
            lastName: lastName,
            new: true
          });
          data.avail =
            this.calculateAvailibility(data.visitors, data.beds) +
            "/" +
            data.beds;
          data.av = this.calculateAvailibility(data.visitors, data.beds);
          oldData.av = this.calculateAvailibility(b, oldData.beds);
          data.oldAv = this.calculateAvailibility(b, oldData.beds);

          oldData.avail =
            this.calculateAvailibility(b, oldData.beds) + "/" + oldData.beds;
          this.props.ordersData.orders.map(order => {
            data.visitors.map(visitor => {
              if (visitor.order === order.orderId) {
                let selectedDate = data.date
                let a = order.dates[selectedDate].visitors
                let objIndex = a.findIndex(obj => obj.type === type && obj.placed === true && obj.room === data.OldRoom);
                if (objIndex >= 0) {
                  a[objIndex].room = data.id;
                }
              }
            }
            )
          })

        } else {
          roomD.forEach((room, i) => {
            if (
              room.room === data.room &&
              room.data.buildingName === data.buildingName
            ) {
              roomD[i].data.visitors.push({
                type: data.type,
                orderId: orderId,
                order: orderNo,
                lastName: data.lastName,
                new: true
              });
              roomD[i].data.avail =
                this.calculateAvailibility(
                  roomD[i].data.visitors,
                  roomD[i].data.beds
                ) +
                "/" +
                roomD[i].data.beds;

              roomD[i].data.av = this.calculateAvailibility(
                roomD[i].data.visitors,
                roomD[i].data.beds
              );
            }
          });
          let orderDates = {};
          let orderDatesArr = [];
          // setTimeout(
            this.props.ordersData.orders.map(order => {
              data.visitors.map(visitor => {
                if (visitor.order === order.orderId) {
                  let selectedDate = data.date
                  let a = order.dates[selectedDate].visitors
                  let objIndex = a.findIndex(obj => obj.type === type && obj.placed === false);
                  if (objIndex >= 0) {
                    a[objIndex].placed = true;
                    a[objIndex].room = data.id;
                  }
                  //  orderDatesArr.push(visitor);
                  orderDates = order.dates
                }

              }
              )
            })
            // , 1000)
          data.orderDates = orderDates;
        }
        axiosInstance
          .post(`/placeRoom`, data, { handlerEnabled: true })
          .then(async res => {
            if (res) {
              this.setState({ fetch: false });
              this.props.dispatch(getOrderByDate(this.state.date, "placeRoom"));
              // this.props.dispatch(getRooms());
            }
            message.success("Visitor has been placed successfully!");
          })
          .catch(() => message.error("Error"))

        this.delayedQuery()
      }
    }
    let row = document.getElementsByClassName(`row${i}`);

    if (row && row[0]) row[0].style.backgroundColor = "";
  };

  delayedQuery = debounce((q, data) => this.sendQuery(q, data), 5000);
 
  sendQuery = () => {
    axiosInstance
      .get("/getrooms", { handlerEnabled: true })
      .then(res => {
        if (res.data) {
          this.props.dispatch(updateReducer(res.data.data));
          this.setRoomData(this.state.date, res.data.data);
        }
      })
  };


  calculateAvailibility = (visitors, beds) => {
    let b = beds;
    visitors &&
      Object.keys(visitors).map((obj, i) => {
        if (visitors) {
          if (
            visitors[obj].type === "couple" ||
            visitors[obj].type === "private"
          ) {
            b = b - 2;
          }
          if (
            visitors[obj].type === "boys" ||
            visitors[obj].type === "woman" ||
            visitors[obj].type === "girls" ||
            visitors[obj].type === "men"
          ) {
            b = b - 1;
          }
        }
      });
    return b;
  };
  calculateRequired = visitors => {
    let b = 0;
    visitors &&
      Object.keys(visitors).map((obj, i) => {
        if (visitors) {
          if (
            visitors[obj].type === "couple" ||
            visitors[obj].type === "private"
          ) {
            b = b + 2;
          }
          if (
            visitors[obj].type === "boys" ||
            visitors[obj].type === "woman" ||
            visitors[obj].type === "girls" ||
            visitors[obj].type === "men"
          ) {
            b = b + 1;
          }
        }
      });
    return b;
  };

  changeDate = dt => {
    let e = dt ? dt : moment(new Date()).format("ddd MMM DD YYYY");
    this.setState({ date: e }, () => {
      this.props.dispatch(getOrderByDate(e));
    });
    this.setRoomData(e, this.state.rooms);
  };
  DragEnd = (e, i, d) => {
    // this.setState({ color: "white", cColor: "", cIndex: "" });
    let row = document.getElementsByClassName(`row${i}`);
    if (row && row[0]) row[0].style.backgroundColor = "";
  };

  removeVisitor = async (
    type,
    id,
    orderNo,
    lastName = "",
    roomId = null,
    oldVisitors = [],
    OldBuildingId = "",
    data
  ) => {
    data.type = type;
    data.orderNo = orderNo;
    data.orderId = id;
    data.OldRoom = roomId;
    data.oldVisitors = oldVisitors;
    data.OldBuildingId = OldBuildingId;
    let b = oldVisitors;
    let visiIndex;
    let thiss = this;
    confirm({
      okText: "Yes",
      cancelText: "No",
      title: "Remove Visitor",
      content: "Are You Sure You Want To Remove This Visitor?",
      async onOk() {
        visiIndex = b.findIndex(obj => obj.type === type && obj.orderId === id);

        if (visiIndex >= 0) {
          data.roomFeeOrder = b[visiIndex].roomFeeOrder;
          b.splice(visiIndex, 1);
        }
        data.visitors = b;
        data.avail =
          thiss.calculateAvailibility(data.visitors, data.beds) +
          "/" +
          data.beds;
        data.av = thiss.calculateAvailibility(data.visitors, data.beds);
        data.av = thiss.calculateAvailibility(b, data.beds);
        data.avail =
          thiss.calculateAvailibility(b, data.beds) + "/" + data.beds;

        thiss.props.ordersData.orders.map(order => {
          if (data.orderNo === order.orderId) {
            let selectedDate = data.date
            let a = order.dates[selectedDate].visitors
            let objIndex = a.findIndex(obj => obj.type === type && obj.placed === true);
            if (objIndex >= 0) {
              a[objIndex].placed = false;
              a[objIndex].room = "";
            }
          }
        })

        await axiosInstance
          .put(`/removeVisitor`, data, { handlerEnabled: true })
          .then(async res => {
            if (res) {
              thiss.setState({ fetch: false });
              
              thiss.props.dispatch(getOrderByDate(thiss.state.date, "removeRoom"));
               axiosInstance
                .get("/getrooms", { handlerEnabled: true })
                .then(res => {
                  if (res.data) {
                    thiss.props.dispatch(updateReducer(res.data.data));
                    thiss.setRoomData(thiss.state.date, res.data.data);
                  }
                });
                message.success("Visitor Removed");
            }
            
          })
          .catch(error => console.log(error));
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };

  applyToRooms = async order => {

    const { rooms, date } = this.state;
    let dd = moment(date).format("ddd MMM DD YYYY");
    let orderData = this.props.ordersData.orders.filter(o => o.id === order.id);
    let dat = order.dates.filter(d => d !== dd);
    dat.sort((a, b) => {
      return new Date(b) - new Date(a);
    });
    let allDatesExceptCurrent = dat;

    dat.shift();

    let dates = dat.filter(d => orderData[0].dates[d].visitors.length > 0);

    let visitors = order.order.visitors.filter(visitor => visitor.placed);

    const rm = rooms.filter(elem =>
      visitors.find(({ room }) => elem.id === room)
    );
    const otherRooms = rooms.filter(elem => rm.every(f => f.id !== elem.id));
    let visitorsToSet = [];

    rm.forEach(room => {
      let index = room.dates.findIndex(ro => ro.id === dd);
      if (index !== -1) {
        let visitors = room.dates[index].visitors;
        let a = visitors.filter(v => v.orderId === order.id);
        visitorsToSet.push({ room, visitors: a });
      }
    });

    let a = [];
    rm.forEach(room => {
      dates.map(dt => {
        let i = room.dates.findIndex(dates => dates.id === dt);
        if (i > -1) {
          a.push({
            date: dt,
            room,
            availableBeds: room.dates[i].availableBeds
          });
        } else {
          a.push({
            date: dt,
            room,
            availlableBeds: room.beds
          });
        }
      });
    });

    let overRide = false;
    let visitorsWithoutOverWrite = [];
    let visitorsWithOverWrite = [];
    let copyvisitorsWithOverWrite = [];
    a.forEach(dates => {
      visitorsToSet.forEach(room => {
        if (dates.room.id === room.room.id) {
          let i = room.room.dates.findIndex(dt => dt.id === dates.date);
          if (i !== -1) {
            // let countRequired = this.calculateRequired(
            //   room.room.dates[i].visitors
            // );

            if (room.room.dates[i].visitors?.length) {
              overRide = true;
              visitorsWithOverWrite.push({ date: dates.date, room });
            } else {
              visitorsWithoutOverWrite.push({ date: dates.date, room });
            }
          } else {
            visitorsWithoutOverWrite.push({ date: dates.date, room });
          }
        }
      });
    });

    let data = {
      overRide,
      withOverWrite: visitorsWithOverWrite,
      withoutOverWrite: visitorsWithoutOverWrite,
      order: this.props.ordersData.orders.filter(ord => ord.id === order.id),
      rooms: rm,
      currentVisitors: order.order.visitors,
      otherRooms,
      dates: order.dates,
      allRooms: rooms,
      allDatesExceptCurrent,
    };

    let thiss = this;
    confirm({
      okText: "Yes",
      cancelText: "No",
      title: "Apply Visitors",
      content: overRide
        ? "Are You Sure You Want To Overwrite This Visitors ?"
        : "Are You Sure You Want To Continue ?",
      async onOk() {
        thiss.setState({ fetch: true });
        await axiosInstance
          .put("/applyToRooms", data, { handlerEnabled: true })
          .then(response => {
            // thiss.fetchData();
            thiss.props.dispatch(getOrderByDate(thiss.state.date, "applyToRoom"));
            axiosInstance
              .get("/getrooms", { handlerEnabled: true })
              .then(res => {
                if (res.data) {
                  thiss.props.dispatch(updateReducer(res.data.data));
                  thiss.setRoomData(thiss.state.date, res.data.data);
                }
              });
            message.success("Visitors Applied To All dates");
          })
          .catch(err => console.log(err));
      }
    });
  };

  onDragLeave = (e, i, d) => {
    let row = document.getElementsByClassName(`row${i}`);
    if (row && row[0]) row[0].style.backgroundColor = "";
  };

  renderOrders = (orders, status) => {
    let ord = [];

    orders.forEach(or => {
      let ordersNew = or.order.visitors.every(({ placed }) => placed === true);
      if (!ordersNew) {
        ord.push(or);
      }
    });

    return this.props.ordersData ? (
      (ord && this.state.checked ? ord : orders).map((order, i) =>
        order.order.visitors?.length > 0 ? (
          <Widget key={i}>
            <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
              <Link to={"/orders/KRO" + order.orderId}>
                #KRO{order.orderId}
              </Link>{" "}
              by{" "}
              <Link to={`/customers/${order.customerId}`}>
                {order.customerName}
              </Link>{" "}
              <Tag style={{ textTransform: "capitalize" }}>{order.status}</Tag>
            </p>
            {order.order?.placement && (
              <>
                <p style={{ fontSize: "12px", marginBottom: "5px" }}>
                  Private Rooms({order.order?.placement.privateRooms}){"  "}
                  Reguler Beds( {order.order?.placement.regulerBeds}){"  "}
                  Dorm Beds({order.order?.placement.dormBeds})
                </p>
              </>
            )}
            <div>
              {order.order?.visitors?.map((visitor, i) => (
                <Tag
                  style={{ marginBottom: 2 }}
                  key={i}
                  draggable={visitor.placed ? false : true}
                  onDragStart={() => {
                    this.dragStart(
                      visitor.type,
                      order.id,
                      order.orderId,
                      order.lastName
                    );
                    this.setState({ custId: order.customerId });
                  }}
                  onDragEnd={e => this.DragEnd()}
                  color={visitor.placed ? "lightgrey" : "black"}
                  id={`order-${order.id}-${i}`}
                >
                  {visitor.type}
                </Tag>
              ))}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  style={{ fontSize: 12, marginTop: "3px" }}
                  size="small"
                  onClick={() => this.applyToRooms(order)}
                >
                  Apply To All Dates
                </Button>
              </div>
            </div>
          </Widget>
        ) : null
      )
    ) : (
      <Skeleton active />
    );
  };
  roomsTable = (data, id) => {
    return (
      <table className="table table-hover">
        <thead className="antd-table-thead">
          <tr>
            <th scope="col" style={{ width: "15%" }}>
              Building
            </th>
            <th scope="col" style={{ width: "15%" }}>
              Room
            </th>
            <th scope="col" style={{ width: "10%" }}>
              Availability
            </th>
            <th scope="col" style={{ width: "60%" }}>
              Visitors
            </th>
          </tr>
        </thead>
        <tbody className="antd-table-tbody">
          {data &&
            data.map((d, i) => (
              <tr
                key={i}
                className={`row${i + id} room-table-row`}
                onDrop={e => this.onDrop(e, d.data, i + id)}
                onDragOver={e => this.dragOver(e, i + id, d)}
                onDragLeave={e => this.onDragLeave(e, i + id, d)}
              >
                <td>{d.data.buildingName}</td>
                <td style={{ width: "25%" }}>
                  <strong>{d.data.room}</strong>
                </td>
                <td>{d.data.avail}</td>
                <td>
                  {d.data.visitors.length > 0 ? (
                    Object.keys(d.data.visitors).map((obj, j) => (
                      <Tag
                        onDragOver={e => this.dragOver(e, i + id, d)}
                        onDragEnd={e => this.DragEnd(e, i + id, d)}
                        onDragLeave={e => this.onDragLeave(e, i + id, d)}
                        key={j}
                        color="black"
                        draggable={true}
                        onDragStart={() => {
                          this.dragStart(
                            d.data.visitors[obj].type,
                            d.data.visitors[obj].orderId,
                            d.data.visitors[obj].order,
                            d.data.visitors[obj].lastName,
                            d.data.id,
                            d.data.visitors,
                            d.data.buildingId,
                            d.data
                          );
                          let order = this.state.ordersData.orders?.find(
                            ord => ord.id === d.data.visitors[obj].orderId
                          );
                          this.setState({
                            custId: (order && order.customerId) || ""
                          });
                        }}
                        onDoubleClick={e =>
                          this.removeVisitor(
                            d.data.visitors[obj].type,
                            d.data.visitors[obj].orderId,
                            d.data.visitors[obj].order,
                            d.data.visitors[obj].lastName,
                            d.data.id,
                            d.data.visitors,
                            d.data.buildingId,
                            d.data
                          )
                        }
                      >
                        {d.data.visitors[obj].type +
                          "" +
                          (" - " + d.data.visitors[obj].lastName || " ") +
                          " - " +
                          d.data.visitors[obj].order}
                      </Tag>
                    ))
                  ) : (
                    <div
                      onDragOver={e => this.dragOver(e, i + id, d)}
                      onDragEnd={e => this.DragEnd(e, i + id, d)}
                      onDragLeave={e => this.onDragLeave(e, i + id, d)}
                    ></div>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };
  render() {
    const orderData = { ...this.props.ordersData };
    let data;
    let orders = [];
    let otherOrders = [];
    orderData?.orders &&
      orderData.orders.forEach(order => {
        let keys = Object.keys(order.dates);
        keys.forEach(key => {
          if (key === moment(this.state.date).format("ddd MMM DD YYYY")) {
            if (order.status === "Confirmed") {
              orders.push({
                id: order.id,
                order: order.dates[key],
                customerName:
                  order.customer.firstName + " " + order.customer.lastName,
                orderId: order.orderId,
                customerId: order.customerId,
                lastName: order.customer.lastName || "",
                dates: keys,
                status: order.status
              });
            } else {
              otherOrders.push({
                id: order.id,
                order: order.dates[key],
                customerName:
                  order.customer?.firstName + " " + order.customer?.lastName,
                orderId: order.orderId,
                customerId: order.customerId,
                lastName: order.customer?.lastName || "",
                dates: keys,
                status: order.status
              });
            }
          }
        });
      });
    data = this.state.roomD;
    let makeChunk = chunk(data, data.length / 2);
    let roomData1 = makeChunk[0];
    let roomData2 = makeChunk[1];
    return (
      <Row gutter={16}>
        <Col span={24}>
          <Widget>
            <Typography.Title level={3}>
              Room placement For{" "}
              {moment(this.state.date).format("dddd MM/DD/YYYY")}
            </Typography.Title>
            <Button
              style={{
                border: "0",
                top: "-4px"
              }}
              type="default"
              onClick={() => {
                let d = moment(new Date(this.state.date)).add(-1, "days");
                this.changeDate(d);
              }}
            >
              <Icon type="caret-left" />
            </Button>

            <DatePicker
              onChange={e => {
                this.changeDate(e);
              }}
              value={moment(this.state.date)}
            />
            <Button
              style={{
                border: "0",
                top: "-4px"
              }}
              type="default"
              onClick={() => {
                let d = moment(new Date(this.state.date)).add(1, "days");
                this.changeDate(d);
              }}
            >
              <Icon type="caret-right" />
            </Button>
          </Widget>
        </Col>
        <Col span={6}>
          <Typography.Title level={3}>
            Orders <Tag color="black">Not Placed</Tag>{" "}
            <Tag color="lightgrey">Placed</Tag>
          </Typography.Title>
          <Tabs defaultActiveKey="1" style={{ position: "relative" }}>
            <Tabs.TabPane
              tab="Confirmed"
              key="Confirmed"
              style={{ maxHeight: "80vh", overflowX: "auto" }}
            >
              {this.props.loading ? <Skeleton active /> : this.props.ordersData ? this.props.ordersData.orders.length > 0 && this.renderOrders(orders) : <Skeleton active />} 
              {/* {this.props.ordersData && this.renderOrders(orders)} */}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab="Unconfirmed"
              key="unConfirmed"
              style={{ maxHeight: "80vh", overflowX: "auto" }}
            >
              {this.props.loading ? <Skeleton active /> : this.props.ordersData ? this.props.ordersData.orders.length > 0 && this.renderOrders(otherOrders) : <Skeleton active />} 
              {/* {this.props.ordersData && this.renderOrders(otherOrders)} */}
            </Tabs.TabPane>
            <CheckableTag
              // {...this.props}
              style={{
                position: "absolute",
                top: "13px",
                right: "13px"
              }}
              checked={this.state.checked}
              onChange={() => this.setState({ checked: !this.state.checked })}
            >
              Not Placed
            </CheckableTag>
          </Tabs>
        </Col>

        <Col span={18}>
          <Typography.Title level={3}>
            Available rooms{" "}
            <Tag color="lightblue">Double click a visitor to remove</Tag>
          </Typography.Title>
          {this.props.roomData ? (
            <Row>
              <Col span={12}>{this.roomsTable(roomData1, "")}</Col>
              <Col span={12}>{this.roomsTable(roomData2, "8gh")}</Col>
            </Row>
          ) : (
            <Skeleton active />
          )}
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = ({ orderReducer }) => {
  return {
    ordersData: orderReducer.ordersByDate,
    roomData: orderReducer.roomData,
    loading: orderReducer.loading,
    getOrderData: orderReducer.getOrderData,
    singleOrder: orderReducer.singleOrder
  };
};
export default connect(mapStateToProps)(RoomPlacement);
