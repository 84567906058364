import React, { Component, lazy, Suspense } from "react";
import { Typography, Skeleton } from "antd";
const UsersDataTable = lazy(() =>
  import("../../components/DataTable/UsersDataTable")
);

class Users extends Component {
  render() {
    return (
      <div>
        <Typography.Title level={3}>Users</Typography.Title>
        <Suspense fallback={<Skeleton active />}>
          <UsersDataTable />
        </Suspense>
      </div>
    );
  }
}

export default Users;
