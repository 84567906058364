import React, { Component } from "react";
import { Button, Table, Tabs, DatePicker } from "antd";
import Orders from "../../components/Reports/Orders";
import { store } from "../../store";
import { getOrderData } from "../../actions/orderActionCreator";
import Transactions from "../../components/Reports/Transactions";

class Reports extends Component {
  componentDidMount() {
    store.dispatch(getOrderData());
  }
  render() {
    return (
      <div>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Transactions" key="1">
            <Transactions />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Orders" key="2">
            <Orders />
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}

export default Reports;
