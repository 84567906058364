import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";

import { Button, DatePicker, Skeleton, Table } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { getOrderData } from "../../actions/orderActionCreator";
import { axiosInstance } from "../../httpHandler";
import font from "../../assets/Fonts/Rubik-Regular.ttf";
Font.register({
  family: "Rubik",
  src: font,
});
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    padding: 45,
    fontFamily: "Rubik",
  },
  section: {
    margin: 5,
  },
  text: {
    border: 1,
    fontSize: 16,
  },
  texttitle: {
    marginBottom: 10,
  },

  table: {
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { margin: "0", flexDirection: "row" },
  tableRowHeader: {
    margin: "0",
    flexDirection: "row",
    backgroundColor: "lightblue",
  },
  tableCol: {
    width: "7.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2: {
    width: "5.8%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColPhone: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 8,
  },
});

class Orders extends Component {
  state = {
    dateRange: [],
    orders: [],
    generatePdf: false,
  };
  componentWillReceiveProps(props) {
    props.getOrderData && this.getOrdersData();
  }

  generatePDF = (data) => {
    return this.state.generatePdf ? (
      <Document>
        <Page size="LETTER" orientation="landscape" style={styles.page}>
          <View style={styles.table}>
            <View style={styles.tableRowHeader}>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>No.</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Number</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Type</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Customer</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Date</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>End Date</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Total</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Status</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>People</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Paid</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Balance</Text>
              </View>
              <View style={styles.tableColPhone}>
                <Text style={styles.tableCell}>Phone</Text>
              </View>
              <View
                style={{
                  width: "12.8%",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderLeftWidth: 0,
                  borderTopWidth: 0,
                }}
              >
                <Text style={styles.tableCell}>Email</Text>
              </View>
            </View>
            {data.map((dt) => (
              <View style={styles.tableRow}>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{dt.key}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{dt.number}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{dt.Type}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{dt.customer}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{dt.Date}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{dt.endDate}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{dt.total}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{dt.Status}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{dt.People}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{dt.Paid}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{dt.Balance}</Text>
                </View>
                <View style={styles.tableColPhone}>
                  <Text style={styles.tableCell}>{dt.Phone}</Text>
                </View>
                <View
                  style={{
                    width: "12.8%",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderLeftWidth: 0,
                    borderTopWidth: 0,
                  }}
                >
                  <Text style={styles.tableCell}>{dt.Email}</Text>
                </View>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    ) : (
      ""
    );
  };
  getOrdersData = () => {
    const { dateRange } = this.state;
    const { getOrderData } = this.props;

    let from = new Date(moment(dateRange[0]).format("MM/DD/YYYY"));
    let to = new Date(moment(dateRange[1]).format("MM/DD/YYYY"));
    let dateToCheck;
    let orders = [];
    getOrderData &&
      getOrderData.orders.map((order) => {
        if (order.dates) {
          const dt = Object.keys(order.dates).map((res, i) => {
            return new Date(res);
          });
          const arrivalDate = new Date(Math.min.apply(null, dt));
          const departureDate = new Date(Math.max.apply(null, dt));
          if (arrivalDate <= to && departureDate >= from) {
            orders.push(order);
          }
        } else {
          dateToCheck = new Date(
            moment(order.date || order.orderDate).format("MM/DD/YYYY")
          );
          if (dateToCheck <= to && dateToCheck >= from) {
            orders.push(order);
          }
        }
      });
    this.setState({ orders });
  };
  componentDidMount() {
    let dates = [];
    let date = new Date();
    dates.push(
      moment(
        new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7),
        "MM/DD/YYYY"
      )
    );
    dates.push(moment(date, "MM/DD/YYYY"));
    this.setState({ dateRange: dates, generatePdf: true });
    this.props.dispatch(getOrderData());
  }
  countAmount = (arr) => {
    let d = 0;
    if (arr) {
      arr.length > 0 &&
        arr.reduce((sum, { amount }) => (d = sum + amount++), 0);
    }
    return d;
  };
  countPeople = (input) => {
    if (typeof input === "object") {
      const data = Object.values(input);
      const p = (data && data[data.length - 1].people) || {};
      const title = Object.keys(p).map((type, i) => (
        <span key={i}>
          <strong>
            {type[0].toUpperCase() + type.slice(1).toLowerCase()}:{" "}
          </strong>
          {p[type]} {Object.keys(p).length !== i + 1 ? <br /> : ""}
        </span>
      ));
      return p.girls + p.woman + p.men + p.couple * 2 + p.babies + p.boys;
    } else {
      return null;
    }
  };
  updateRecords = async (orders) => {
    let data = [];
    orders.forEach((el) => {
      data.push(el.id);
    });
    await axiosInstance
      .put(`/updateDownload`, data, { handlerEnabled: true })
      .then((res) => {
        this.props.dispatch(getOrderData());
      })
      .catch((error) => console.log(error));
  };
  render() {
    const { orders } = this.state;
    const orderDataSource =
      orders &&
      orders.map((order, i) => ({
        key: i,
        number: order.orderId,
        customer: order.customer,
        Date: order.startDate,
        endDate: order.lastDate,
        total: order.total,
        Status: order.status,
        People: order.dates,
        Paid: this.countAmount(order.payments),
        Balance: order.total - this.countAmount(order.payments),
        Phone: (order.customer?.phones && order.customer.phones[0]) || "",
        Email: (order.customer?.emails && order.customer.emails[0]) || "",
        Downloaded: order.downloaded || "No",
        Type: order.type,
      }));
    const csvData =
      orders &&
      orders.map((order, i) => ({
        key: i + 1,
        number: order.orderId,
        id: order.id,
        Type: order.type,
        customer:
          typeof order.customer === "object"
            ? order.customer.firstName + " " + order.customer.lastName
            : order.customer,
        Date: moment(order.startDate).format("MM/DD/YYYY"),
        endDate: moment(order.lastDate).format("MM/YY/YYYY"),
        total: "$" + order.total,
        Status: order.status,
        People: this.countPeople(order.dates),
        Paid: "$" + this.countAmount(order.payments),
        Balance: "$" + (order.total - this.countAmount(order.payments)),
        Phone: (order.customer?.phones && order.customer.phones[0]) || "",
        Email: (order.customer?.emails && order.customer.emails[0]) || "",
      }));

    const orderColumns = [
      {
        title: "Order #",
        dataIndex: "number",
        key: "number",
      },
      {
        title: "Type",
        dataIndex: "Type",
        key: "Type",
      },
      {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        render: (customer) =>
          typeof customer === "object" ? (
            <strong>
              {customer.firstName && customer.firstName}{" "}
              {customer.lastName && customer.lastName}
            </strong>
          ) : (
            <strong>{customer}</strong>
          ),
      },
      {
        title: "Date",
        dataIndex: "Date",
        key: "Date",
        render: (date) => moment(date).format("MM/DD/YYYY"),
      },
      {
        title: "End date",
        dataIndex: "endDate",
        key: "endDate",
        render: (date) => moment(date).format("MM/DD/YYYY"),
      },
      {
        title: "Status",
        dataIndex: "Status",
        key: "Status",
      },
      {
        title: "People",
        dataIndex: "People",
        key: "People",
        render: (input) => {
          if (typeof input === "object") {
            const data = Object.values(input);
            const p = (data && data[data.length - 1].people) || {};
            const title = Object.keys(p).map((type, i) => (
              <span key={i}>
                <strong>
                  {type[0].toUpperCase() + type.slice(1).toLowerCase()}:{" "}
                </strong>
                {p[type]} {Object.keys(p).length !== i + 1 ? <br /> : ""}
              </span>
            ));
            return (
              <p>
                {p.girls + p.woman + p.men + p.couple * 2 + p.babies + p.boys}
              </p>
            );
          } else {
            return null;
          }
        },
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        render: (total) => "$" + total.toLocaleString(),
      },
      {
        title: "Paid",
        dataIndex: "Paid",
        key: "Paid",
        render: (Paid) => "$" + Paid.toLocaleString(),
      },
      {
        title: "Balance",
        dataIndex: "Balance",
        key: "Balance",
        render: (Balance) => "$" + Balance.toLocaleString(),
      },
      {
        title: "Phone",
        dataIndex: "Phone",
        key: "Phone",
      },
      {
        title: "Email",
        dataIndex: "Email",
        key: "Email",
      },
      {
        title: "Downloaded",
        dataIndex: "Downloaded",
        key: "Downloaded",
      },
    ];
    const headers = [
      {
        label: "No",
        key: "key",
      },
      {
        label: "Number",
        key: "number",
      },
      {
        label: "Type",
        key: "Type",
      },
      {
        label: "Customer",
        key: "customer",
      },
      {
        label: "Date",
        key: "Date",
      },
      {
        label: "End Date",
        key: "endDate",
      },
      {
        label: "Total",
        key: "total",
      },
      {
        label: "Status",
        key: "Status",
      },
      {
        label: "People",
        key: "People",
      },
      {
        label: "Paid",
        key: "Paid",
      },
      {
        label: "Balance",
        key: "Balance",
      },
      {
        label: "Phone",
        key: "Phone",
      },
      {
        label: "Email",
        key: "Email",
      },
    ];

    return (
      <div>
        <DatePicker.RangePicker
          onChange={(e) => {
            this.setState({ dateRange: e }, () => this.getOrdersData());
          }}
          value={this.state.dateRange}
        />
        <Button
          shape="round"
          type="primary"
          style={{ margin: "0 0 16px 16px" }}
          onClick={() => this.updateRecords(csvData)}
          disabled={this.state.orders.length > 0 ? false : true}
        >
          {this.state.generatePdf && (
            <PDFDownloadLink
              document={this.generatePDF(csvData)}
              fileName={
                "Orders_" +
                moment(this.state.dateRange[0]).format("MM/DD/YYYY") +
                " to " +
                moment(this.state.dateRange[1]).format("MM/DD/YYYY") +
                ".pdf"
              }
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download PDF"
              }
            </PDFDownloadLink>
          )}
        </Button>

        <Button
          shape="round"
          type="primary"
          style={{ margin: "0 0 16px 16px" }}
          disabled={this.state.orders.length > 0 ? false : true}
        >
          <CSVLink
            data={csvData}
            filename={
              "Orders_" +
              moment(this.state.dateRange[0]).format("MM/DD/YYYY") +
              " to " +
              moment(this.state.dateRange[1]).format("MM/DD/YYYY") +
              ".csv"
            }
            headers={headers}
            onClick={() => this.updateRecords(csvData)}
            style={{ textDecoration: "none" }}
            // enclosingCharacter={`"`}
          >
            Download CSV
          </CSVLink>
        </Button>

        {this.state.orders.length > 0 ? (
          <Table dataSource={orderDataSource} columns={orderColumns} />
        ) : (
          <Skeleton active />
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ orderReducer }) => {
  return {
    getOrderData: orderReducer.getOrderData,
  };
};
export default connect(mapStateToProps)(Orders);
