import axios from "axios";
import firebaseApp from "./config/fbConfig";

export const axiosInstance = axios.create({
  // baseURL: "http://localhost:5001/kerestir-168d8/us-central1/api",
  baseURL: "https://us-central1-kerestir-168d8.cloudfunctions.net/api",
});

const isHandlerEnabled = (config = {}) =>
  config.hasOwnProperty("handlerEnabled") && config.handlerEnabled;

const requestHandler = async (request) => {
  if (isHandlerEnabled(request)) {
    const token = await firebaseApp.auth().currentUser.getIdToken();
    request.headers["Authorization"] = `Bearer ${token}`;
  }
  return request;
};

axiosInstance.interceptors.request.use((request) => requestHandler(request));

const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    // Handle errors
  }
  return Promise.reject(error);
};

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses
  }
  return response;
};

axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
