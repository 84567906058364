import React, { Component, lazy, Suspense } from "react";
// prettier-ignore
import { Tabs, Typography, Steps, Button, Row, Col, Icon, Descriptions, Skeleton, Modal, message, Input, Form, DatePicker, Select, Table, InputNumber } from "antd";
import { Link } from "react-router-dom";

import {
  getOrderData,
  showModal,
  getRoomPrice,
  getFoodPrice,
  getSingleOrderData,
  getTransactionsByOrderId,
  addOrder
} from "../../actions/orderActionCreator";
import { connect } from "react-redux";
import moment from "moment";
import { axiosInstance } from "../../httpHandler";
import { store } from "../../store";
import CustEditOrder from "../../components/Customer/CustEditOrder";
import TextArea from "antd/lib/input/TextArea";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import { KEY } from "../../AppConstants";
import ReactPhoneInput from "react-phone-input-2";
import { handleCountCurrency } from "../../config/currencyConvertor";

const Widget = lazy(() => import("../../components/Widget/Widget"));
const SingleTableData = lazy(() => import("./SingleTableData"));
const SingleOrderCost = lazy(() => import("./SingleOrderCost"));
const { confirm } = Modal;
const { Text } = Typography;

class SingleOrder extends Component {
  state = {
    orderData: [],
    loading: true,
    type: "cash",
    cost: {
      food: {},
      lodging: {
        regular: 0,
        couple: 0,
        private: 0,
        pref: {}
      }
    },
    ostatus: false,
    position: 0,
    visible: false,
    confirmLoading: false,
    xRefNum: null,
    amount: null,
    openCard: false,
    openCash: false,
    openCheck: false,
    cardName: "",
    cardNumber: "",
    cardCVV: "",
    cardExpiry: "",
    paymentAmount: "",
    cashAmount: 0,
    cashCurrency: "",
    // cash: [0],
    check: [0],
    cashDetails: [],
    cashData: [],
    chequeData: [],
    transactionId: "",
    routingNo: "",
    accountNo: "",
    chequeAmount: "",
    accountName: "",
    repeatNo: "",
    customerPayments: "",
    paidAmount: "",
    btnLoader: false,
    firstName: "",
    lastName: "",
    phones: [],
    emails: [],
    tags: [],
    customerType: "1-Time",
    kvitel: "",
    addresses: [{ address: "", address2: "", city: "", state: "", zip: "" }],
    search: "",
    searchVal: "",
    index: null,
    editCustomer: false,
    customerId: "",
    currencyTotal: 0,
    paidCurrency: "USD",
    additional: 0,
    total: 0,
    selectedCard: [],
    creditCards: {
      cardName: "",
      cardNumber: "",
      cardCVV: "",
      cardExpiry: "",
      paymentAmount: ""
    },
    paidData: [],
    refNumber: "",
    additionalFees: [],
    cardsData:[]
  };

  isLoading = loading => this.setState({ loading });

  componentDidMount = async () => {
    this.isLoading(true);
    await this.fetchData();
    // await this.fetchTransaction();
  };
  fetchTransaction = () => {
    const { orderData } = this.state;
    axiosInstance
      .get(`/transactions/${orderData[0].id}`, { handlerEnabled: true })
      .then(res => {
        let transactions = [];
        let cheques = [];
        res.data.transactions.forEach((transaction, i) => {
          if (transaction.type === "cash") {
            transactions.push(transaction);
          } else if (transaction.type === "cheque") {
            cheques.push(transaction);
          }
        });
        this.setState({ cashData: transactions, chequeData: cheques });
      })
      .catch(error => console.log(error));
  };
  componentWillReceiveProps(props) {
    let transactions = [];
    let cheques = [];
    props.cost &&
      this.setState({
        cost: {
          ...this.state.cost,
          lodging: {
            ...this.state.cost.lodging,
            regular: props.cost[0].regular,
            couple: props.cost[0].couple,
            private: props.cost[0].private
          }
        }
      });
    props.foodPrices &&
      props.foodPrices.map(p => {
        if (p.id === "breakfast") {
          this.setState({ breakfast: p });
        } else if (p.id === "lunch") {
          this.setState({ lunch: p });
        } else if (p.id === "dinner") {
          this.setState({ dinner: p });
        } else if (p.id === "seudes") {
          this.setState({ seudes: p });
        }
      });
    props.order?.order &&
      this.setState(
        {
          orderData: props.order.order
        },
        () => {
          let data = [];
          let _paidData = [];
          let fees = [];
          if (props.order?.order) {
            this.state.orderData &&
              this.state.orderData.map(d => {
                console.info("SingleOrder.js", { d: d });
                const rec = {
                  id: d.id,
                  amount: 0,
                  total: d.total,
                  orderId: d.orderId
                };
                _paidData.push(rec);
                // return data.push(d.payments);
                // d.payments[0].paymentStatus = d.paymentStatus;
                // console.log("d.payments", d.payments);
                data = d.payments ? d.payments : [];
                // return d.payments && d.payments?.map((r) => {
                //   data.push(r);
                // });
                fees = d.additionalOrder

              });
            this.setState({ customerPayments: data, paidData: _paidData, additionalFees: fees }, () => console.log("additionalFees", this.state.additionalFees));
          }
        }
      );

    props.transactions?.transactions &&
      props.transactions.transactions.forEach((transaction, i) => {
        if (transaction.type === "cash") {
          transactions.push(transaction);
        } else if (transaction.type === "cheque") {
          cheques.push(transaction);
        }
      });
    this.setState({ cashData: transactions, chequeData: cheques });
  }

  fetchData = async () => {
    const id = this.props.match.params.id;
    const orderId = id.replace("KRO", "");

    this.props.dispatch(getRoomPrice());
    this.props.dispatch(getFoodPrice());
    this.props.dispatch(getSingleOrderData(orderId));
    this.props.dispatch(getTransactionsByOrderId(orderId, true));

    await axiosInstance
      .get("/getpreferences", { handlerEnabled: true })
      .then(res => {
        let pref = {};
        res.data.data.map(r => {
          pref[r.name] = r.price;
        });
        this.setState({
          cost: {
            ...this.state.cost,
            lodging: { ...this.state.cost.lodging, pref }
          }
        });
      })
      .catch(err => console.log(err));

    await axiosInstance
      .get("/getfoodpreferences", { handlerEnabled: true })
      .then(async res => {
        const breakfastData = res.data.data.breakfast;
        const lunchData = res.data.data.lunch;
        const dinnerData = res.data.data.dinner;
        const seudesData = res.data.data.seudes;

        let obj = {
          breakfest: {
            pref: {}
          },
          lunch: {
            pref: {}
          },
          dinner: { pref: {} },
          seuda: { pref: {} }
        };
        breakfastData &&
          breakfastData.map(r => {
            obj.breakfest.pref[r.name] = r.price;
          });
        lunchData &&
          lunchData.map(r => {
            obj.lunch.pref[r.name] = r.price;
          });
        dinnerData &&
          dinnerData.map(r => {
            obj.dinner.pref[r.name] = r.price;
          });
        seudesData &&
          seudesData.map(r => {
            obj.seuda.pref[r.name] = r.price;
          });
        obj.breakfest.reg = this.state.breakfast
          ? this.state.breakfast.regular
          : 0;
        obj.breakfest.vip = this.state.breakfast ? this.state.breakfast.vip : 0;
        obj.lunch.reg = this.state.lunch ? this.state.lunch.regular : 0;
        obj.lunch.vip = this.state.lunch ? this.state.lunch.vip : 0;
        obj.dinner.reg = this.state.dinner ? this.state.dinner.regular : 0;
        obj.dinner.vip = this.state.dinner ? this.state.dinner.vip : 0;
        obj.seuda.reg = this.state.seudes ? this.state.seudes.regular : 0;
        obj.seuda.vip = this.state.seudes ? this.state.seudes.vip : 0;

        this.setState({
          cost: {
            ...this.state.cost,
            food: obj
          }
        });
      })
      .catch(err => console.log(err));
    // this.setState({cardsData: this.props.order.order[0].customerCards})

    await axiosInstance.get("/contacts", { handlerEnabled: true }).then(res => {
      console.log("res....", res)
      res.data.contacts.map(d => {
        const customerId = this.props.order.order[0].customerId 
        console.log("customerId", customerId)
        if (d.id === customerId) {
          console.info("PaymentForm.js", { true: d });
          console.log("d.customerCards", d.customerCards)
          this.setState({
            cardsData: d.customerCards ? d.customerCards : []
          });
        }
      });
    });

    this.isLoading(false);

  };

  increaseMethod = type => {
    const oldState = [...this.state[type]];
    oldState.push(oldState.length);
    this.setState({
      [type]: oldState
    });
  };

  removePayment(type, i) {
    const oldState = [...this.state[type]];
    const updatedState = oldState.filter(val => {
      return val !== i;
    });
    this.setState({
      [type]: updatedState
    });
  }

  showModal = xRefNum => {
    this.setState({
      visible: true,
      xRefNum
    });
  };

  showCard = () => {
    this.setState({
      openCard: true
    });
  };

  showCash = () => {
    this.setState({
      openCash: true
    });
  };

  showCheck = () => {
    this.setState({
      openCheck: true
    });
  };

  handleOk = (refNumber, orderId) => {
    this.setState({
      confirmLoading: true
    });
    const { amount } = this.state;
    const refNo = { refNumber, orderId, amount };
    console.info("SingleOrder.js", { refno: refNo });
    axiosInstance
      .post(`/editAmount`, refNo, {
        handlerEnabled: true
      })
      .then(async res => {
        console.info("SingleOrder.js", { res: res });
        const resstts = res.data.body
          .split("&")
          .map(obj => obj.split("="))
          .find(v => v[0] === "xStatus")[1];
        const error = res.data.body
          .split("&")
          .map(obj => obj.split("="))
          .find(v => v[0] === "xError")[1];
        if (resstts === "Approved") {
          await store.dispatch(getOrderData());
          await this.fetchData();
          message.success("Amount has been updated successfully!");
        } else if (resstts === "Error") {
          message.error(error.replace(/[+]/g, " "));
        }
      });
    setTimeout(() => {
      this.setState(
        {
          visible: false,
          confirmLoading: false,
          amount: null
        },
        async () => {
          await store.dispatch(getOrderData());
        }
      );
    }, 2000);
  };

  fixCard = () => {
    let data = [];
    let cardAmount = this.state.selectedCardAmount || 0;
    let amount = "xAuthAmount";
    let object = this.state.object;
    object[amount] = cardAmount;
    data.push(object);
    this.setState({ selectedCard: data }, () => {
      store.dispatch(addOrder("selectedCard", this.state.selectedCard));
    });
  };

  handleSelectedCard = (v) => {
    let data = [];
    if (v === undefined) {
      this.setState({ selectedCard: null }, () =>
        store.dispatch(addOrder("selectedCard", this.state.selectedCard))
      );
    }
    let object = JSON.parse(v);

    this.setState(
      {
        object: object
      },
      () => {
        this.fixCard();
      }
    );
  }

  handleAddCash = orderId => {
    this.setState({
      confirmLoading: true
    });
    const {
      cashAmount,
      cashCurrency,
      orderData,
      paidCurrency,
      currencyTotal
    } = this.state;
    const refNo = {
      cashAmount,
      cashCurrency,
      orderId: orderData[0].id,
      customerId: orderData[0].customerId,
      paidCurrency,
      currencyTotal
    };

    axiosInstance
      .post(`/addcash`, refNo, {
        handlerEnabled: true
      })
      .then(async res => {
        console.info("SingleOrder.js", { res: res });
        if (res.data.success) {
          message.success("Amount has been added successfully!");
          // await store.dispatch(getOrderData());
          // await this.fetchData();
          // await this.fetchTransaction();
          this.props.dispatch(getSingleOrderData(orderData[0].orderId));
          this.props.dispatch(getTransactionsByOrderId(orderData[0].id, false));
        }
      });
    setTimeout(() => {
      this.setState(
        {
          openCash: false,
          confirmLoading: false,
          cashAmount: null
        },
        async () => {
          await store.dispatch(getOrderData());
        }
      );
    }, 2000);
  };
  handleAddCheque = orderId => {
    this.setState({
      confirmLoading: true
    });
    const {
      accountName,
      routingNo,
      accountNo,
      chequeAmount,
      transactionId,
      orderData
    } = this.state;
    const refNo = {
      accountName,
      routingNo,
      accountNo,
      chequeAmount,
      transactionId,
      customerId: orderData[0].customerId,
      orderId: orderData[0].id
    };

    axiosInstance
      .post(`/addcheque`, refNo, {
        handlerEnabled: true
      })
      .then(async res => {
        console.info("SingleOrder.js", { res: res });
        if (res.data.success) {
          message.success("Cheque has been added successfully!");
          await store.dispatch(getOrderData());
        }
      });
    setTimeout(() => {
      this.setState(
        {
          openCheck: false,
          confirmLoading: false,
          chequeAmount: "",
          accountName: "",
          routingNo: "",
          accountNo: "",
          repeatNo: ""
        },
        async () => {
          await store.dispatch(getOrderData());
          await this.fetchData();
          await this.fetchTransaction();
        }
      );
    }, 2000);
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
  };
  handleCloseCard = () => {
    this.setState({
      openCard: false
    });
  };

  handleCloseCash = () => {
    this.setState({
      openCash: false
    });
  };

  handleCloseCheck = () => {
    this.setState({
      openCheck: false
    });
  };

  showConfirm = (id, key) => {
    let confirmThis = this;
    confirm({
      title: `Do you want to delete KRO${id} order?`,
      content: "When clicked the OK button, this order will be deleted.",

      async onOk() {
        try {
          const response = await axiosInstance.delete(`/order/${key}`, {
            handlerEnabled: true
          });
          if (!response) throw new Error("Empty response");

          store.dispatch(getOrderData());
          message.success("Order has been deleted successfully!");
          confirmThis.props.history.push(`/dashboard`);
        } catch (error) {
          console.warn(error);
        }
      }
    });
  };
  showCancel = (id, key) => {
    let confirmThis = this;
    confirm({
      title: `Do you want to cancel KRO${id} order?`,
      content: "When clicked the OK button, this order will be Cancelled.",
      async onOk() {
        try {
          const response = await axiosInstance.put(
            `/orderCancel`,
            { orderId: key },
            {
              handlerEnabled: true
            }
          );
          if (!response) throw new Error("Empty response");

          store.dispatch(getOrderData());
          message.success("Order has been Cancelled successfully!");
          confirmThis.props.history.push(`/dashboard`);
        } catch (error) {
          console.warn(error);
        }
      }
    });
  };

  orderConfirm = (status, key, data, content) => {
    let confirmThis = this;
    confirm({
      title: `Confirm Order`,
      content: content,
      okText: "Yes",
      cancelText: "No",
      async onOk() {
        axiosInstance
          .patch(
            `/orderStatus/${key}`,
            { status, order: data },
            { handlerEnabled: true }
          )
          .then(async res => {
            confirmThis.props.dispatch(getOrderData());
            // confirmThis.fetchData();
            message.success(
              `Order has been ${status.toLowerCase()} successfully!`
            );
            const stts =
              status === "Confirmed" ? 1 : status === "Closed" ? 4 : 0;
            const id = confirmThis.props.match.params.id;
            const orderId = id.replace("KRO", "");
            confirmThis.props.dispatch(getSingleOrderData(orderId));

            this.setState({
              ostatus: true,
              position: stts
            });
          })
          .catch(() => console.log("Oops errors!"));
      }
    });
  };
  updateStatus = (key, status) => {
    const { orderData } = this.state;
    let data = orderData[0];
    if (
      !data.contactPersonName ||
      !data.contactPersonInfo ||
      !data.customer.phones?.length ||
      !data.customer.emails?.length
    ) {
      message.error("Customer Don't Have Contact Information ");
    } else if (data.payments) {
      let totalAmount;
      let dt = [];
      data.payments.map(r => {
        dt.push(r);
      });
      this.setState({ customerPayments: dt }, () => {
        const sumTotal = arr =>
          arr?.reduce((sum, { amount }) => sum + amount++, 0);
        totalAmount = sumTotal(this.state.customerPayments);

        if (totalAmount < data.total && !data.customer.customerCards.length) {
          if (
            this.props.authUser &&
            this.props.authUser.user?.userType === "Admin"
          ) {
            let content =
              "The order is not paid and the customer doesn't have a card on file. Do you wish to continue?";
            this.orderConfirm(status, key, data, content);
          } else {
            message.error("Customer Have Some Payments Remaining");
          }
        } else if (!data.customer.customerCards.length) {
          if (
            this.props.authUser &&
            this.props.authUser.user?.userType === "Admin"
          ) {
            let content =
              "the customer doesn't have a card on file. Do you wish to continue? ";
            this.orderConfirm(status, key, data, content);
          } else {
            message.error("Customer Doesn't Have Cards On File");
          }
        } else {
          axiosInstance
            .patch(
              `/orderStatus/${key}`,
              { status, order: data },
              { handlerEnabled: true }
            )
            .then(async res => {
              this.props.dispatch(getOrderData());
              const id = this.props.match.params.id;
              const orderId = id.replace("KRO", "");
              this.props.dispatch(getSingleOrderData(orderId));
              message.success(
                `Order has been ${status.toLowerCase()} successfully!`
              );
              const stts =
                status === "Confirmed" ? 3 : status === "Closed" ? 8 : 0;
              this.setState({
                ostatus: true,
                position: stts
              });
            })
            .catch(() => console.log("Oops errors!"));
        }
      });
    } else if (!data.payments) {
      if (
        this.props.authUser &&
        this.props.authUser.user?.userType === "Admin"
      ) {
        if (!data.customer.customerCards.length) {
          let content =
            " The order is not paid and the customer doesn't have a card on file. Do you wish to continue? ";
          this.orderConfirm(status, key, data, content);
        } else {
          let content = " The order is not paid. Do you wish to continue?";
          this.orderConfirm(status, key, data, content);
        }
      } else {
        message.error("Customer Have Some Payments Remaining");
      }
    } else if (!data.customer.customerCards?.length) {
      message.error("Customer Don't Have Cards In File ");
    }
  };

  handleChargeCard = (refNumber, orderId) => {
    const refNo = { refNumber, orderId };
    axiosInstance
      .post(`/chargecard`, refNo, {
        handlerEnabled: true
      })
      .then(async res => {
        console.info("SingleOrder.js", { res: res });
        console.info("SingleOrder.js", { resData: res.data });
        const resstts = res.data
          .split("&")
          .map(obj => obj.split("="))
          .find(v => v[0] === "xStatus")[1];
        const error = res.data
          .split("&")
          .map(obj => obj.split("="))
          .find(v => v[0] === "xError")[1];
        if (resstts === "Approved") {
          message.success("Payment has done successfully!");
          setTimeout(() => {
            this.setState(
              {
                openCard: false,
                confirmLoading: false
              },
              async () => {
                await store.dispatch(getOrderData());
              }
            );
          }, 2000);
          await store.dispatch(getOrderData());
          await this.fetchData();
        } else if (resstts === "Error") {
          message.error(error.replace(/[+]/g, " "));
        }
      });
  };

  addCreditCards = () => {
    const { creditCards } = this.state;

    creditCards.push({
      cardName: "",
      cardNumber: "",
      cardCVV: "",
      cardExpiry: "",
      paymentAmount: ""
    });
    this.setState({ creditCards });
  };

  removeCard = key => {
    const { creditCards } = this.state;
    creditCards.splice(key, 1);
    this.setState({ creditCards });
  };

  handleCreditcards = (key, value, i) => {
    this.setState({ [key]: value });
  };

  realesepayment = (orderId, data, paymentArr) => {
    paymentArr[data.key].paymentStatus = "realesed";
    const refNo = {
      orderId: orderId,
      refNumber: data.id,
      amount: data.amount,
      data,
      paymentArr
    };
    axiosInstance
      .post(`/realesepayment`, refNo, {
        handlerEnabled: true
      })
      .then(async res => {
        if (res.data.success === true) {
          // message.success("We received your payment, Thank You!");
          message.success("Payment is realese successfully");
          this.setState({ customerPayments: paymentArr });
        } else {
          message.error(res.data.error);
        }
      });
  };

  processPayment = (orderId, data, paymentArr) => {
    paymentArr[data.key].paymentStatus = "Processed";
    const refNo = {
      orderId: orderId,
      refNumber: data.id,
      amount: data.amount,
      data,
      paymentArr
    };
    axiosInstance
      .post(`/processPayment`, refNo, {
        handlerEnabled: true
      })
      .then(async res => {
        if (res.data.success === true) {
          message.success("We received your payment, Thank You!");
          this.setState({ customerPayments: paymentArr });
        } else {
          message.error(res.data.error);
        }
      });
  };

  handleAddCard = (orderId, custId) => {
    const { paymentAmount, cardCVV, cardExpiry, cardNumber } = this.state;
    const ref = {
      orderId,
      paymentAmount,
      cardCVV,
      cardExpiry,
      cardNumber,
      custId
    };

    axiosInstance
      .post(`/addNewCard`, ref, {
        handlerEnabled: true
      })
      .then(async res => {
        const resstts = res.data
          .split("&")
          .map(obj => obj.split("="))
          .find(v => v[0] === "xStatus")[1];
        const error = res.data
          .split("&")
          .map(obj => obj.split("="))
          .find(v => v[0] === "xError")[1];
        if (resstts === "Approved") {
          message.success("Cards has been added successfully!");
          this.setState({
            openCard: false
          });
          await store.dispatch(getOrderData());
          await this.fetchData();
        } else if (resstts === "Error") {
          message.error(error.replace(/[+]/g, " "));
        }
      });
  };
  calculateRoomCosts = (entity, cost) => {
    if (!entity) return 0;
    const { regular, couple, private: privatec } = cost;
    let regularCost = entity.regular * regular;
    let coupleCost = entity.couple * couple;
    let privateCost = entity.private * privatec;
    return regularCost + coupleCost + privateCost;
  };
  calculateCosts = (entity, cost, isVip = false, preferences = []) => {
    if (!entity) return 0;
    const { pref, vip, reg } = cost;
    const price = isVip ? vip : reg;

    return (
      (price +
        preferences
          .map(item => pref[item])
          .reduce((previous, current) => previous + current, 0)) *
      entity
    );
  };
  countAmount = arr => {
    let d = 0;
    if (arr) {
      arr.length > 0 &&
        arr.reduce((sum, { amount }) => (d = sum + amount++), 0);
    }
    return d;
  };
  sendInvoice = order => {
    this.setState({ btnLoader: true });

    let total = 0;
    let couple = 0;
    let men = 0;
    let privates = 0;
    let women = 0;
    let boys = 0;
    let girls = 0;
    let babies = 0;
    let breakfast = 0;
    let VIPbreakfast = 0;
    let lunch = 0;
    let VIPlunch = 0;
    let VIPdinner = 0;
    let VIPseudes = 0;
    let dinner = 0;
    let seudes = 0;
    let breakfastVip = false;
    let lunchVip = false;
    let dinnerVip = false;
    let seudesVip = false;
    let prefLunch = [];
    let prefBreakfast = [];
    let pref = 0;
    let prefDinner = [];
    let rooms = 0;
    let room = [];
    let result = 0;
    let lodgingData = 0;
    let roomPreferences = [];
    Object.values(order.dates).forEach((res, i) => {
      console.info({ res });
      roomPreferences.push(res.room);
      res.room.forEach((res, i) => {
        result += res.preferences.filter(x => x).length;
      });

      privates += res.people.private;
      couple += res.people.couple;
      men += res.people.men;
      women += res.people.woman;
      boys += res.people.boys;
      girls += res.people.girls;
      babies += res.people.babies;

      const lodging = {
        couple: couple,
        regular: men + women + boys + girls + babies,
        private: privates
      };
      lodgingData = lodging;
      rooms += res.room.length;
      Object.values(res).forEach((r, i) => {
        r.breakfast && r.breakfast.VIP
          ? (VIPbreakfast += r.breakfast ? r.breakfast.people : 0)
          : (breakfast += r.breakfast ? r.breakfast.people : 0);
        r.lunch && r.lunch.VIP
          ? (VIPlunch += r.lunch ? r.lunch.people : 0)
          : (lunch += r.lunch ? r.lunch.people : 0);
        r.dinner && r.dinner.VIP
          ? (VIPdinner += r.dinner ? r.dinner.people : 0)
          : (dinner += r.dinner ? r.dinner.people : 0);
        r.seudes && r.seudes.VIP
          ? (VIPseudes += r.seudes ? r.seudes.people : 0)
          : (seudes += r.seudes ? r.seudes.people : 0);
      });

      if (res.food.breakfast.VIP) breakfastVip = true;
      if (res.food.lunch.VIP) lunchVip = true;
      if (res.food.dinner.VIP) dinnerVip = true;
      if (res.food.seudes && res.food.seudes.VIP) seudesVip = true;

      if (res.food.lunch.preferences && res.food.lunch.preferences.length > 0)
        Object.values(res.food.lunch.preferences).forEach((item, i) => {
          prefLunch.push(item);
        });
      if (
        res.food.breakfast.preferences &&
        res.food.breakfast.preferences.length > 0
      ) {
        Object.values(res.food.breakfast.preferences).forEach((item, i) => {
          prefBreakfast.push(item);
        });
      }

      if (
        res.food.dinner.preferences &&
        res.food.dinner.preferences.length > 0
      ) {
        prefDinner = res.food.dinner.preferences;
      }
    });

    let invoice = [
      {
        product: "Reg Breakfast",
        qty: breakfast,
        price: this.state.cost.food.breakfest.reg,
        total: this.calculateCosts(
          breakfast,
          this.state.cost.food.breakfest,
          false,
          prefBreakfast
        )
      },
      {
        product: "Reg Lunch",
        qty: lunch,
        price: this.state.cost.food.lunch.reg,
        total: this.calculateCosts(
          lunch,
          this.state.cost.food.lunch,
          false,
          prefLunch
        )
      },
      {
        product: "Reg Dinner",
        qty: dinner,
        price: this.state.cost.food.dinner.reg,
        total: this.calculateCosts(
          dinner,
          this.state.cost.food.dinner,
          false,
          prefDinner
        )
      },
      {
        product: "Reg Seudes",
        qty: seudesVip ? 0 : seudes,
        price: this.state.cost.food.seuda.reg,
        total: this.calculateCosts(seudes, this.state.cost.food.seuda, false)
      },
      {
        product: "VIP Breakfast",
        qty: VIPbreakfast,
        price: this.state.cost.food.breakfest.vip,
        total: breakfastVip
          ? VIPbreakfast * this.state.cost.food.breakfest.vip
          : 0
      },
      {
        product: "VIP Lunch",
        qty: VIPlunch,
        price: this.state.cost.food.lunch.vip,

        total: lunchVip
          ? this.calculateCosts(
            VIPlunch,
            this.state.cost.food.lunch,
            lunchVip,
            prefLunch
          )
          : 0
      },
      {
        key: "13",
        product: "VIP Dinner",
        qty: VIPdinner,
        price: this.state.cost.food.dinner.vip,
        total: dinnerVip
          ? this.calculateCosts(
            VIPdinner,
            this.state.cost.food.dinner,
            dinnerVip,
            prefDinner
          )
          : 0
      },
      {
        product: "VIP Seudes",
        qty: seudesVip ? seudes : 0,
        price: this.state.cost.food.seuda.vip,
        total: seudesVip
          ? this.calculateCosts(
            VIPseudes,
            this.state.cost.food.seuda,
            seudesVip
          )
          : 0
      },

      {
        product: "Lodging",
        qty:
          lodgingData.regular +
          lodgingData.couple * 2 +
          lodgingData.private * 2,
        price: "-",
        total: this.calculateRoomCosts(lodgingData, this.state.cost.lodging)
      }
    ];
    let rows = [];
    invoice.map(inv => {
      if (inv.qty > 0) {
        rows.push({
          product: inv.product,
          qty: inv.qty,
          price: inv.price,
          total: inv.total
        });
      }
    });
    let totalAmount = order.total;
    let paidAmount = this.countAmount(order.payments);
    let balanceAmount = order.total - paidAmount;

    let data = {
      orderNo: order.orderId,
      customer: order.customer,
      rows: rows,
      total: totalAmount,
      paid: paidAmount,
      balance: balanceAmount
    };
    if (order.customer.emails.length > 0) {
      axiosInstance
        .post("/sendInvoice", data, { handlerEnabled: true })
        .then(res => {
          this.setState({ btnLoader: false });
          if (res) {
            message.success("Invoice Sent.");
          }
        })
        .catch(err => {
          message.success(err);
          this.setState({ btnLoader: false });
        });
    } else {
      message.error("Customer Do Not have Any Emails in File");
      this.setState({ btnLoader: false });
    }
  };
  handleEdit = async (data, key, order) => {
    const { orderData } = this.state;
    let dataa;
    if (key === "contactPersonName") {
      dataa = {
        contactPersonName: data,
        contactPersonInfo: order.contactPersonInfo
      };
    } else {
      dataa = {
        contactPersonName: order.contactPersonName,
        contactPersonInfo: data
      };
    }
    await axiosInstance
      .put(`/update/order/${orderData[0].id}`, dataa, { handlerEnabled: true })
      .then(async res => {
        this.props.dispatch(getSingleOrderData(orderData[0].orderId));
        this.props.dispatch(getOrderData());

        message.success(`${key} Updated`);
      })
      .catch(error => console.log(error));
  };

  handleNoResult = () => {
    console.log("No results for ", this.state.search);
  };

  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    this.setState({
      search: { [this.state.index]: "" },
      searchVal: geocodedPrediction.formatted_address
    });
    this.handleAddress(
      "address",
      geocodedPrediction.formatted_address,
      this.state.index
    );
  };
  showEditModal = data => {
    this.setState(
      {
        orderId: data.id,
        customerId: data.customerId,
        firstName: data.firstName,
        lastName: data.lastName,
        customerType: data.customerType || "1-Time",
        tags: data.tags,
        kvitel: data.kvitel,
        phones: data.phones ? data.phones : [],
        emails: data.emails ? data.emails : [],
        addresses: data.addresses ? data.addresses : this.state.addresses
      },
      () =>
        this.setState({
          editCustomer: true
        })
    );
  };
  handleCancel = e => {
    this.setState({
      ...this.state,
      editCustomer: false
    });
  };
  handleAddress = (key, value, i) => {
    const { addresses } = this.state;
    addresses[i] = { ...addresses[i], [key]: value };
    this.setState({ addresses });
    // store.dispatch(addCustomer("addresses", addresses));
  };
  handleOk = async e => {
    const {
      orderData,
      firstName,
      lastName,
      customerType,
      tags,
      kvitel,
      emails,
      phones,
      addresses
    } = this.state;
    let data = {
      firstName,
      lastName,
      customerType,
      tags,
      kvitel,
      emails,
      phones,
      addresses
    };
    await axiosInstance
      .patch(`/contacts/${orderData[0].customerId}`, data, {
        handlerEnabled: true
      })
      .then(async res => {
        message.success("Customer Updated");
        this.setState({
          edit: false
        });
        this.props.dispatch(getSingleOrderData(orderData[0].id));
        this.props.dispatch(getOrderData());

        this.setState({
          editCustomer: false
        });
      });
  };

  countAmount = arr => {
    let d = 0;
    if (arr) {
      arr.length > 0 &&
        arr.reduce((sum, { amount }) => (d = sum + amount++), 0);
    }
    return d;
  };

  showPaymentModal = () => {
    this.setState({ paymentModal: true });
  };
  handlePaymentCancel = () => {
    this.setState(
      {
        paymentModal: false,
        selectedCard: [],
        paidData: [],
        type: "cash",
        paidCurrency: "USD",
        additional: 0,
        accountName: "",
        accountNo:"",
        repeatNo: "",
        routingNo: "",
        cardNumber: "",
        creditCards: {
          cardName: "",
          cardNumber: "",
          cardCVV: "",
          cardExpiry: "",
          paymentAmount: "",
          refNumber: "",
          cardAmount:""
        },
      });
  };

  handlePaymentModalOk = (currencyTotal = 0) => {
    const data = this.state.paidData.filter(r => r.amount > 0).map(d => d);
    const card = {
      data: data,
      creditCards: this.state.object,
      card: this.state.selectedCard.length > 0 ? this.state.selectedCard : this.state.creditCards,
      type: this.state.type,
      additional: this.state.additional,
      customer: this.state.customer || this.state.orderData[0].customer  //this.state.customer
    };
    const cash = {
      cashAmount: this.state.cashAmount,
      cashCurrency: this.state.paidCurrency,
      currencyTotal,
      data: data,
      type: this.state.type,
      additional: this.state.additional,
      customer: this.state.customer || this.state.orderData[0].customer
    };
    const cheque = {
      data: data,
      type: this.state.type,
      additional: this.state.additional,
      accountName: this.state.accountName,
      routingNo: this.state.routingNo,
      accountNo: this.state.accountNo,
      customer: this.state.customer || this.state.orderData[0].customer
    };
    const quickpay = {
      data: data,
      type: this.state.type,
      refNumber: this.state.refNumber,
      customer: this.state.customer || this.state.orderData[0].customer
    };
    const offlinepay = {
      data: data,
      type: this.state.type,
      refNumber: this.state.refNumber,
      customer: this.state.customer || this.state.orderData[0].customer
    };
    const body =
      this.state.type === "card"
        ? card
        : this.state.type === "cash"
        ? cash
        : this.state.type === "cheque"
        ? cheque
        : this.state.type === "quickpay"
        ? quickpay
        : this.state.type === "offlinecard"
        ? offlinepay
        : null;

    if (data.length > 0) {
      axiosInstance
        .post("/addpayment", body, { handlerEnabled: true })
        .then(res => {
          if (res.data.success) {
            this.setState({
              paymentModal: false
            });
            message.success("Payment has been done successfully!");
            // this.props.form.resetFields();

            this.setState(
              {
                paymentModal: false,
                selectedCard: [],
                paidData: [],
                type: "cash",
                paidCurrency: "USD",
                customerPayments: [],
                additional: 0
              },
              () => {
                this.state.paidData.map((d, i) => {
                  this.updateRecord(i, `amount`);
                });
                this.fetchData();
              }
            );
          } else {
            message.error("Something went wrong!");
          }
        });
    }
  };

  updateRecord = (id, key) => {
    var index = this.state.paidData.findIndex(x => x.id === id);
    let g = this.state.paidData[index];
    g[key] = 0;
    if (index === -1) {
      console.log("no match");
    } else {
      this.setState({
        paidData: [
          ...this.state.paidData.slice(0, index),
          g,
          ...this.state.paidData.slice(index + 1)
        ]
      });
    }
  };

  changeAmount = (id, value) => {
    let state = [...this.state.paidData];
    state[id].amount = value;
    this.setState({
      paidData: state
    });
  };

  render() {
    const {
      orderData,
      loading,
      cost,
      ostatus,
      position,
      phones,
      emails
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { confirmLoading } = this.state;
    if (loading) return <Skeleton active />;
    const status = orderData[0]?.status;
    const date = orderData[0]?.dates;
    const dt =
      date &&
      Object.keys(date).map((res, i) => {
        return new Date(res);
      });
    const CurrentDate = new Date();
    const arrivalDate = new Date(Math.min.apply(null, dt));
    const departureDate = new Date(Math.max.apply(null, dt));

    const statusNumberMap = {
      Inquiry: 0,
      draft: 1,
      "Soft Inquiry": 2,
      Confirmed: 3,
      InRange: 4,
      "Checked in": 5,
      "Checked out": 6,
      Past: 7,
      Closed: 8
    };

    const statusKey =
      status === "Confirmed" &&
        CurrentDate <= departureDate &&
        CurrentDate >= arrivalDate
        ? "InRange"
        : status || "Inquiry";

    let currencies = [
      { sym: "$", value: "USD" },
      { sym: "zl", value: "ZlATY" },
      { sym: "₽", value: "RUBL" },
      { sym: "£", value: "POUND" },
      { sym: "₪", value: "SHEKEL" },
      { sym: "₴", value: "HRYVNIA" },
      { sym: "€", value: "EURO" }
    ];
    const currencyOptions = (
      <Select
        // defaultValue="USD"
        value={this.state.paidCurrency}
        style={{ width: 80 }}
        className="select-before"
        onChange={e => {
          this.setState({ currency: e, paidCurrency: e });
          handleCountCurrency(
            this.state.cashAmount,
            e,
            this.props.currency?.data
          );
        }}
      >
        {currencies.map((currency, i) => (
          <Select.Option value={currency.value} key={i}>
            {currency.sym}
          </Select.Option>
        ))}
      </Select>
    );

    const transactionColumns = [
      {
        title: "Payment ID",
        dataIndex: "id",
        key: "id",
        render: id => id
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: amount => (
          <strong>${amount ? amount.toLocaleString() : amount}</strong>
        )
      },
      {
        title: "Payment Type",
        dataIndex: "type",
        key: "type",
        render: type => (
          <strong style={{ textTransform: "capitalize" }}>{type}</strong>
        )
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: date => <strong>{date}</strong>
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: status => <strong>{status}</strong>
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "actions",
        render: data =>
          (data.type == "card" &&
            data.status == "On Hold") ? (
            <div>
              <Button
                type="primary"
                onClick={() =>
                  this.processPayment(
                    this.state.orderData[0].id,
                    data,
                    this.state.orderData[0].payments
                  )
                }
              >
                Process
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                type="primary"
                onClick={() =>
                  this.realesepayment(
                    this.state.orderData[0].id,
                    data,
                    this.state.orderData[0].payments
                  )
                }
              >
                Realese
              </Button>
            </div>
          ) : ""
      }
    ];

    const transactionData =
      this.state.customerPayments.length > 0 &&
      this.state.customerPayments?.map((d, i) => ({
        key: i,
        id: d.paymentId,
        amount: d.amount,
        type: d.type,
        date: d.date ? moment(d.date).format("MM/DD/YYYY") : "",
        status: d.paymentStatus
      }));
    const paymentColumns = [
      {
        title: "Order ID",
        dataIndex: "id",
        key: "id",
        render: id => (
          <strong>
            <Link to={"/orders/" + id.orderId}>{id.orderId}</Link>
          </strong>
        )
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        render: total => <span>${total}</span>
      },
      {
        title: "Paid",
        dataIndex: "id",
        key: "id",
        render: id => <span>${id.paid}</span>
      },
      {
        title: "Balance",
        dataIndex: "id",
        key: "id",
        render: id => <span>${id.total - id.remainingAmount}</span>
      },
      {
        title: "Amount",
        dataIndex: "id",
        key: "id",
        render: id => (
          <InputNumber
            disabled={id.total - id.remainingAmount < 0}
            max={id.total - id.remainingAmount}
            onChange={e => {
              this.changeAmount(id.index, e);
            }}
          />
        )
      }
    ];

    const remainingData =
      this.state.orderData &&
      this.state.orderData?.map((arr, i) => ({
        key: arr.id,
        id: {
          orderId: arr.orderId,
          mainId: arr.id,
          index: i,
          total: arr.total,
          paid: this.countAmount(arr.payments),
          remainingAmount: this.countAmount(arr.payments)
        },
        total: arr.total
      }));

    console.info("SingleOrder.js", { remainingData: remainingData });

    const additinalFeeColumns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Person",
        dataIndex: "cust_type",
        key: "cust_type",
      },
      {
        title: "Building Name",
        dataIndex: "buildingName",
        key: "buildingName",
      },
      {
        title: "Room",
        dataIndex: "room",
        key: "room",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Diffrence",
        dataIndex: "diff",
        key: "diff",
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        render: total => <span>${total}</span>
        // render: id => <span>${id.total}</span>
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
      },
    ]

    const feesData =
      this.state.additionalFees &&
      this.state.additionalFees?.map((d, i) => ({
        id: i + 1,
        cust_type: d.custType,
        buildingName: d.buildingName,
        room: d.room,
        date: d.date,
        type: d.type,
        diff: d.diffrence ? d.diffrence : 0,
        total: d.total,  
      }));

    const addifeeTotal = this.state.additionalFees && this.state.additionalFees.reduce((a,curr) => a + curr.total, 0);

    const sumTotal = arr => arr?.reduce((sum, { amount }) => sum + amount++, 0);
    const totalAmount = sumTotal(this.state.paidData) + this.state.additional;
    let paidCurrencyTotal = handleCountCurrency(
      totalAmount,
      this.state.paidCurrency,
      this.props.currency?.data
    );

    console.log("this.state.orderData", this.state.orderData)

    return (
      <Suspense fallback={<Skeleton active />}>
        <div>
          <Typography.Title level={3} style={{ marginBottom: "36px" }}>
            Order #KRO{orderData && orderData[0].orderId} By{" "}
            {orderData[0].customer &&
              orderData[0].customer.firstName +
              " " +
              orderData[0].customer.lastName}
          </Typography.Title>
          <Steps
            size="small"
            current={ostatus ? position : statusNumberMap[statusKey]}
          >
            <Steps.Step title="Soft Inquiry" />
            <Steps.Step title="Draft" />
            <Steps.Step title="Inquiry" />
            <Steps.Step title="Confirmed" />
            <Steps.Step title="Current" />
            <Steps.Step title="Checked in" />
            <Steps.Step title="Checked out" />
            <Steps.Step title="Past" />
            <Steps.Step title="Closed" />
          </Steps>
          <div style={{ padding: "36px 0" }}>
            <Row gutter={16}>
              <Col span={16}>
                <Row gutter={16}>
                  <Col span={6}>
                    <p>
                      <strong>Quick Actions</strong>
                    </p>
                    <div className="btnQuickActions">
                      {status === "Confirmed" ? null : (
                        <Button
                          type="primary"
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            this.updateStatus(orderData[0].id, "Confirmed");
                          }}
                        >
                          Confirm
                        </Button>
                      )}
                      {["Closed", "Inquiry", "Confirmed"].includes(
                        status
                      ) ? null : (
                        <Button
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            this.updateStatus(orderData[0].id, "Closed");
                          }}
                        >
                          Close
                        </Button>
                      )}
                      <Button
                        style={{
                          marginRight: "10px",
                          backgroundColor: "#4ba74b",
                          color: "#fff",
                          borderColor: "#4ba74b"
                        }}
                        disabled={
                          this.props.getOrderData &&
                          !this.props.getOrderData.orders.length
                        }
                        onClick={() => {
                          this.props.dispatch(
                            showModal(true, orderData, orderData[0].type)
                          );
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        type="danger"
                        onClick={() =>
                          this.showConfirm(
                            orderData[0].orderId,
                            orderData[0].id
                          )
                        }
                      >
                        Delete
                      </Button>
                      <Button
                        type="danger"
                        onClick={() =>
                          this.showCancel(orderData[0].orderId, orderData[0].id)
                        }
                      >
                        Cancel
                      </Button>
                      {"  "}
                      <Button
                        type="primary"
                        onClick={() => this.sendInvoice(orderData[0])}
                        loading={this.state.btnLoader}
                      >
                        Send Invoice
                      </Button>
                    </div>
                  </Col>
                  <Col span={12}>
                    <Widget>
                      <Typography.Title
                        level={3}
                        style={{ color: "#4285F4", marginBottom: "25px" }}
                      >
                        Order Notes
                      </Typography.Title>
                      <strong>{orderData[0].note}</strong>
                    </Widget>
                  </Col>
                  <Col span={6}>
                    <Widget>
                      <Typography.Title
                        level={3}
                        style={{ color: "#4285F4", marginBottom: "25px" }}
                      >
                        Total Cost
                      </Typography.Title>
                      <strong>{"$" + (orderData[0].total + (addifeeTotal ? addifeeTotal : 0))} </strong>
                    </Widget>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Tabs defaultActiveKey="1" animated={false}>
            <Tabs.TabPane tab="Order Details" key="1">
              <Row gutter={16}>
                <Col span={16}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Widget>
                        <Typography.Title
                          level={3}
                          style={{ color: "#4285F4", marginBottom: "25px" }}
                        >
                          <Icon type="login" /> Arrival
                        </Typography.Title>
                        <strong>
                          {moment(arrivalDate).format("MM/DD/YYYY")}
                        </strong>
                      </Widget>
                    </Col>
                    <Col span={12}>
                      <Widget>
                        <Typography.Title
                          level={3}
                          style={{ color: "#4285F4", marginBottom: "25px" }}
                        >
                          <Icon type="logout" /> Departure
                        </Typography.Title>
                        <strong>
                          {moment(departureDate).format("MM/DD/YYYY")}
                        </strong>
                      </Widget>
                    </Col>
                  </Row>

                  <SingleTableData
                    data={orderData[0].dates}
                    endDate={moment(this.state.orderData[0].lastDate).format(
                      "MM/DD/YYYY"
                    )}
                  />
                </Col>
                <Col span={8}>
                  <SingleOrderCost
                    chosenDates={orderData[0].dates}
                    cost={cost}
                    getTotal={this.getTotal}
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Customer Details" key="2">
              <Row>
                <Col span={24}>
                  <Button
                    type="primary"
                    shape="circle"
                    icon="edit"
                    onClick={() => this.showEditModal(orderData[0].customer)}
                  />
                </Col>
                <br />
                <Col span={12}>
                  <Descriptions bordered column={2}>
                    <Descriptions.Item label="First name">
                      {orderData[0].customer && orderData[0].customer.firstName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Middle name"></Descriptions.Item>
                    <Descriptions.Item label="Last name">
                      {orderData[0].customer && orderData[0].customer.lastName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Phone">
                      {orderData[0].customer.phones &&
                        orderData[0].customer.phones[0]}
                    </Descriptions.Item>
                    <Descriptions.Item label="Email">
                      {orderData[0].customer.emails &&
                        orderData[0].customer.emails[0]}
                    </Descriptions.Item>
                    <Descriptions.Item label="Address">
                      {orderData[0].customer.addresses &&
                        orderData[0].customer.addresses[0].address}
                    </Descriptions.Item>
                    <Descriptions.Item label="Person Name">
                      <Typography.Text
                        editable={{
                          onChange: e =>
                            this.handleEdit(
                              e,
                              "contactPersonName",
                              orderData[0]
                            )
                        }}
                      >
                        {orderData[0] && orderData[0].contactPersonName}
                      </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Person Contact">
                      <Typography.Text
                        editable={{
                          onChange: e =>
                            this.handleEdit(
                              e,
                              "contactPersonInfo",
                              orderData[0]
                            )
                        }}
                      >
                        {orderData[0] && orderData[0].contactPersonInfo}
                      </Typography.Text>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Additional Fees" key="3">
              <Table
                columns={additinalFeeColumns}
                dataSource={feesData}
                footer={() => (
                  <Typography.Title level={4} style={{ textAlign: "center" }}>
                    <small>Total:</small>{" "}
                    ${addifeeTotal}.00
                  </Typography.Title>
                )}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Payments &amp; Billing" key="4">
              <div>
                <Button type="primary" onClick={this.showPaymentModal}>
                  Add New Transaction
                </Button>
                <Modal
                  title="New Payment"
                  visible={this.state.paymentModal}
                  onOk={() => this.handlePaymentModalOk(paidCurrencyTotal)}
                  onCancel={this.handlePaymentCancel}
                >
                  {remainingData ? (
                    <div>
                      <Table
                        columns={paymentColumns}
                        dataSource={remainingData}
                      />{" "}
                      <p style={{ textAlign: "center" }}>
                        <strong>
                          Total:{" "}
                          {this.state.type === "cash"
                            ? currencies.map((currency, i) =>
                              currency.value === this.state.paidCurrency
                                ? currency.sym
                                : ""
                            )
                            : "$"}{" "}
                          {this.state.type === "cash"
                            ? paidCurrencyTotal
                            : totalAmount}
                        </strong>
                      </p>
                    </div>
                  ) : (
                    <Skeleton active />
                  )}

                  <InputNumber
                    placeholder="Additional amount"
                    onChange={e => {
                      this.setState({ additional: e });
                    }}
                    value={this.state.additional}
                  />
                  <Select
                    placeholder="Payment type"
                    value={this.state.type}         
                    // defaultValue={this.state.type}
                    style={{ width: 120 }}
                    onChange={e => {
                      this.setState({ type: e });
                    }}
                  >
                    <Select.Option value="card">Credit card</Select.Option>
                    <Select.Option value="cash">Cash</Select.Option>
                    <Select.Option value="cheque">cheque</Select.Option>
                    <Select.Option value="quickpay">Quickpay</Select.Option>
                    <Select.Option value="offlinecard">
                      Offline Credit Card
                    </Select.Option>
                  </Select>
                  {this.state.type === "cash" ? currencyOptions : ""}
                  <br />
                  <br />
                  {this.state.type === "card" ? (
                    <div>
                      <Form.Item>
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Choose existing card"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          // value={this.state.selectedCard[0]}
                          onChange={this.handleSelectedCard}
                          allowClear
                        >
                          {this.state.cardsData &&
                            this.state.cardsData.length > 0 &&
                            this.state.cardsData.map((d, i) => {
                              return (
                                <Select.Option
                                  value={JSON.stringify(d)}
                                  key={i}
                                >
                                  {d.cardEnding.substr(12, 4)}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]}>
                      {getFieldDecorator("cardName", {
                          rules: [
                            {
                              required: false,
                              message: "Please enter card name!"
                            }
                          ]
                        })(
                          <Input
                          value={this.state.creditCards.cardName}
                          placeholder="Name on card"
                          onChange={e => {
                            this.setState({
                              creditCards: {
                                ...this.state.creditCards,
                                cardName: e.target.value
                              }
                            });
                          }}
                        />
                          // <Input
                          //   value={this.state.creditCards[ind].cardName}
                          //   placeholder="Name on card"
                          //   onChange={e => {
                          //     this.handleCreditcards(
                          //       "cardName",
                          //       e.target.value,
                          //       ind
                          //     );
                          //     this.setState({ index: ind });
                          //   }}
                          // />
                        )}
                        
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]}>
                      {getFieldDecorator("cardNumber", {
                          rules: [
                            {
                              required: false,
                              message: "Please enter card number!"
                            },
                            {
                              min: 15,
                              message:
                                "Card number must be minimum 15 characters long."
                            },
                            {
                              max: 16,
                              message:
                                "Card number must be maximum 16 characters long."
                            }
                          ]
                        })(
                          <Input
                          value={this.state.creditCards.cardNumber}
                          placeholder="Card number"
                          onChange={e => {
                            this.setState({
                              creditCards: {
                                ...this.state.creditCards,
                                cardNumber: e.target.value
                              }
                            });
                          }}
                        />
                        )}                 
                      </Form.Item>
                      <Row>
                        <Col span={12}>
                          <Form.Item rules={[{ required: true }]}>
                            <DatePicker.MonthPicker
                              value={this.state.creditCards.cardExpiry}
                              placeholder="Exp."
                              format="MMYY"
                              onChange={e => {
                                this.setState({
                                  creditCards: {
                                    ...this.state.creditCards,
                                    cardExpiry: e
                                  }
                                });
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item rules={[{ required: true }]}>
                            <Input
                              placeholder="CCV"
                              value={this.state.creditCards.cardCVV}
                              onChange={e => {
                                this.setState({
                                  creditCards: {
                                    ...this.state.creditCards,
                                    cardCVV: e.target.value
                                  }
                                });
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <hr />
                    </div>
                  ) : this.state.type === "cheque" ? (
                    <div>
                      <Form.Item>
                        <Input
                          placeholder="Account name"
                          value={this.state.accountName}
                          onChange={e =>
                            this.setState({ accountName: e.target.value })
                          }
                        />
                      </Form.Item>
                      <Form.Item>
                        <Input
                          placeholder="Routing number"
                          value={this.state.routingNo}
                          onChange={e =>
                            this.setState({
                              routingNo: e.target.value
                            })
                          }
                        />
                        <Row>
                          <Col span={12}>
                            <Input
                              placeholder="Account number"
                              value={this.state.accountNo}
                              onChange={e =>
                                this.setState({
                                  accountNo: e.target.value
                                })
                              }
                            />
                          </Col>
                          <Col span={12}>
                            <Input
                              placeholder="Repeat account number"
                              value={this.state.repeatNo}
                              onChange={e =>
                                this.setState({
                                  repeatNo: e.target.value
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                      {/* <Form.Item>
                          <Input disabled size="large" value={totalAmount} />
                        </Form.Item> */}
                      <hr />
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.type === "quickpay" ? (
                    <Form.Item label="Reference number">
                      {getFieldDecorator("ref Number", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Reference number!"
                          }
                        ]
                      })(
                        <Input
                          placeholder="Reference number"
                          onChange={e =>
                            this.setState({ refNumber: e.target.value })
                          }
                        />
                      )}
                    </Form.Item>
                  ) : this.state.type === "offlinecard" ? (
                    <>
                      <Form.Item label="Reference number">
                        {getFieldDecorator("card ref Number", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter Reference number!"
                            }
                          ]
                        })(
                          <Input
                            placeholder="Reference number"
                            onChange={e =>
                              this.setState({ refNumber: e.target.value })
                            }
                          />
                        )}
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]}>
                        {getFieldDecorator("offcardAmount", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter amount!"
                            }
                          ]
                        })(
                          <Input.Search
                            // defaultValue="$750"
                            enterButton="Process"
                            // onSearch={() => this.handleCard(card)}
                            size="large"
                            onChange={e => {
                              this.setState({ cardAmount: e.target.value });
                            }}
                          />
                        )}
                      </Form.Item>
                    </>
                  ) : null}
                </Modal>
                &nbsp;&nbsp;
                <Button onClick={this.showCard} type="primary">
                  Add New Card
                </Button>
                <Modal
                  title="Add New Card"
                  visible={this.state.openCard}
                  onOk={() =>
                    this.handleAddCard(orderData[0].id, orderData[0].customerId)
                  }
                  confirmLoading={confirmLoading}
                  onCancel={this.handleCloseCard}
                >
                  <div>
                    <Form.Item>
                      <Input
                        placeholder="Name on card"
                        onChange={e => {
                          this.handleCreditcards("cardName", e.target.value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item>

                    {getFieldDecorator("cardNumber", {
                          rules: [
                            {
                              required: false,
                              message: "Please enter card number!"
                            },
                            {
                              min: 15,
                              message:
                                "Card number must be minimum 15 characters long."
                            },
                            {
                              max: 16,
                              message:
                                "Card number must be maximum 16 characters long."
                            }
                          ]
                        })(
                        //   <Input
                        //   value={this.state.creditCards.cardNumber}
                        //   placeholder="Card number"
                        //   onChange={e => {
                        //     this.setState({
                        //       creditCards: {
                        //         ...this.state.creditCards,
                        //         cardNumber: e.target.value
                        //       }
                        //     });
                        //   }}
                        // />
                        <Input
                        placeholder="Card number"
                        onChange={e => {
                          this.handleCreditcards("cardNumber", e.target.value);
                        }}
                      />
                        )}  
                      
                    </Form.Item>
                    <Row>
                      <Col span={12}>
                        <Form.Item>
                          <DatePicker.MonthPicker
                            placeholder="Exp."
                            format="MMYY"
                            onChange={e => {
                              this.handleCreditcards("cardExpiry", e);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item>
                          <Input
                            placeholder="CCV"
                            onChange={e => {
                              this.handleCreditcards("cardCVV", e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item>
                      <Form.Item>
                        <Input.Search
                          enterButton="Process card"
                          size="large"
                          onChange={e => {
                            this.handleCreditcards(
                              "paymentAmount",
                              parseInt(e.target.value)
                            );
                          }}
                        />
                      </Form.Item>
                    </Form.Item>
                    <hr />
                  </div>
                </Modal>
              </div>
              <br />
              {/* {this.state.customerPayments?.length > 0 ? ( */}
              <Table
                columns={transactionColumns}
                dataSource={transactionData}
              // loading={{
              //   spinning: this.state.customerPayments?.length > 0,
              //   indicator: <Skeleton active />,
              // }}
              />
              {/* // ) : ( // <Skeleton active />
              // )} */}
              {/* Content of Tab Pane 3 */}
            </Tabs.TabPane>
          </Tabs>
          <Modal
            title="Edit Customer"
            visible={this.state.editCustomer}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            width={1290}
            okText="Update"
          >
            <Form>
              <Row>
                <Col span={24}>
                  <Typography.Title level={3}>
                    Customer Information
                  </Typography.Title>
                </Col>
                <Col span={12}>
                  <Form.Item rules={[{ required: true }]}>
                    <Input
                      placeholder="First name"
                      onChange={e =>
                        this.setState({ firstName: e.target.value })
                      }
                      // defaultValue={this.state.firstName}
                      value={this.state.firstName}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item rules={[{ required: true }]}>
                    <Input
                      placeholder="Last name"
                      onChange={e =>
                        this.setState({ lastName: e.target.value })
                      }
                      value={this.state.lastName}
                    // defaultValue={this.state.lastName}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <Select
                      mode="multiple"
                      placeholder="Please select tags"
                      onChange={e => this.setState({ tags: e })}
                      style={{ width: "100%" }}
                      value={this.state.tags}
                    >
                      <Select.Option key={1} value="Reguler">
                        Reguler
                      </Select.Option>
                      <Select.Option key={2} value="VIP">
                        VIP
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <Select
                      placeholder="Select Customer Type"
                      onChange={e => this.setState({ customerType: e })}
                      defaultValue={this.state.customerType}
                      style={{ width: "100%" }}
                    >
                      <Select.Option key={1} value="1-Time">
                        1-Time
                      </Select.Option>
                      <Select.Option key={2} value="Recurring">
                        Recurring
                      </Select.Option>
                      <Select.Option key={3} value="Travel agent">
                        Travel agent
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item rules={[{ required: true }]}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <ReactPhoneInput
                        country={"us"}
                        inputClass="phoneInputStyle"
                        buttonClass="phoneDropDown2"
                        value={phones && phones[0]}
                        onChange={(value, country, e, v) => {
                          phones.length > 0 ? (phones[0] = v) : phones.push(v);
                          this.setState({ phones });
                        }}
                      />
                      <Button
                        type="primary"
                        style={{
                          borderBottomLeftRadius: "none !important",
                          borderTopLeftRadius: "none !important"
                        }}
                        onClick={() => {
                          let a = phones;
                          a.push("");
                          this.setState({ phones });
                        }}
                      >
                        Add Phone
                      </Button>
                    </div>

                    {phones &&
                      phones.map((phone, i) =>
                        i > 0 ? (
                          <div className="mt-2">
                            <ReactPhoneInput
                              country={"us"}
                              inputClass="phoneInputStyle"
                              buttonClass="phoneDropDown2"
                              key={i}
                              value={phone || ""}
                              onChange={(value, country, e, v) => {
                                phones[i] = v;

                                this.setState({ phones });
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )
                      )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item rules={[{ required: true }]}>
                    <Input.Search
                      type="email"
                      placeholder="Email"
                      enterButton="Add email"
                      defaultValue={emails && emails[0]}
                      onChange={v => {
                        emails.length > 0
                          ? (emails[0] = v.target.value)
                          : emails.push(v.target.value);

                        this.setState({ emails });
                      }}
                      onSearch={v => {
                        emails.push("");
                        this.setState({ emails });
                      }}
                    />
                    {emails &&
                      emails.map((email, i) =>
                        i > 0 ? (
                          <Input
                            key={i}
                            type="tel"
                            value={email || ""}
                            onChange={e => {
                              emails[i] = e.target.value;
                              this.setState({
                                emails: emails
                              });
                            }}
                          />
                        ) : (
                          ""
                        )
                      )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  {this.state.addresses.length > 0 &&
                    this.state.addresses.map((address, i) => (
                      <Form.Item rules={[{ required: true }]} key={i}>
                        <ReactGoogleMapLoader
                          params={{
                            key: KEY.MAP_KEY,
                            libraries: "places,geocode"
                          }}
                          render={googleMaps =>
                            googleMaps && (
                              <ReactGooglePlacesSuggest
                                googleMaps={googleMaps}
                                autocompletionRequest={{
                                  input: this.state.search[i]
                                }}
                                onNoResult={this.handleNoResult}
                                onSelectSuggest={this.handleSelectSuggest}
                                onStatusUpdate={this.handleStatusUpdate}
                                textNoResults="No Address Found"
                                customRender={prediction => (
                                  <div className="customWrapper">
                                    {prediction
                                      ? prediction.description
                                      : "No Address Found"}
                                  </div>
                                )}
                              >
                                <Form.Item>
                                  <Input
                                    placeholder={"Address "}
                                    value={this.state.addresses[i].address}
                                    onChange={e => {
                                      this.handleAddress(
                                        "address",
                                        e.target.value,
                                        i
                                      );
                                      this.setState({
                                        search: { [i]: e.target.value }
                                      });
                                      this.setState({ index: i });
                                    }}
                                  />
                                </Form.Item>
                              </ReactGooglePlacesSuggest>
                            )
                          }
                        />
                        {/* <Input
                            placeholder={"Address "}
                            onChange={e =>
                              this.handleAddress("address", e.target.value, i)
                            }
                          /> */}
                      </Form.Item>
                    ))}
                </Col>
                <Col span={24} label="kvitel">
                  <Form.Item>
                    <TextArea
                      allowClear
                      placeholder="Enter kvitel"
                      onChange={e => this.setState({ kvitel: e.target.value })}
                      rows={5}
                      value={this.state.kvitel}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </div>
        <CustEditOrder />
      </Suspense>
    );
  }
}

// const mapStateToProps = state => {
//   return { allOrderData: state.getOrderData };
// };
const mapStateToProps = ({ orderReducer }) => ({
  allOrderData: orderReducer.getOrderData,
  cost: orderReducer.cost,
  preferences: orderReducer.preferences,
  authUser: orderReducer.authUser,
  currency: orderReducer.currency,
  foodPrices: orderReducer.foodPrices,
  order: orderReducer.singleOrder,
  transactions: orderReducer.transactionsByOrder,
  paymentHold: orderReducer.paymentHold
});
const SingleOD = Form.create({})(SingleOrder);

export default connect(mapStateToProps)(SingleOD);
