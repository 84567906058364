export const ADD_ORDER = "ADD_ORDER";
export const ADD_ORDER_REQUEST = "ADD_ORDER_REQUEST";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_ERROR = "ADD_ORDER_ERROR";

export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_ERROR = "UPDATE_ORDER_ERROR";

export const GET_SINGLE_ORDER_REQUEST = "GET_SINGLE_ORDER_REQUEST";
export const GET_SINGLE_ORDER_SUCCESS = "GET_SINGLE_ORDER_SUCCESS";
export const GET_SINGLE_ORDER_ERROR = "GET_SINGLE_ORDER_ERROR";

export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const CLEAR_DATA = "CLEAR_DATA";
export const SHOW_MODAL = "SHOW_MODAL";

export const GET_CONTACTS = "GET_CONTACTS";

export const GET_ORDER = "GET_ORDER";

export const ADD_USER = "ADD_USER";
export const ADD_USER_ERROR = "ADD_USER_ERROR";

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS = "GET_USERS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

export const GET_ROOM_PRICE = "GET_ROOM_PRICE";
export const GET_PREFERENCES = "GET_PREFERENCES";
export const GET_BUILDINGS = "GET_BUILDINGS";

export const GET_ROOMS_REQUEST = "GET_ROOMS_REQUEST";
export const GET_ROOMS_ERROR = "GET_ROOMS_ERROR";
export const GET_ROOMS = "GET_ROOMS";

export const GET_FOOD_PRICE = "GET_FOOD_PRICE";
export const GET_FOOD_PREFERENCES = "GET_FOOD_PREFERENCES";

export const GET_SINGLE_CUSTOMER = "GET_SINGLE_CUSTOMER";

export const GET_ORDER_BY_DATE_REQUEST = "GET_ORDER_BY_DATE_REQUEST";
export const GET_ORDER_BY_DATE = "GET_ORDER_BY_DATE";
export const GET_ORDER_BY_DATE_ERROR = "GET_ORDER_BY_DATE_ERROR";

export const ADD_DONATION_REQUEST = "ADD_DONATION_REQUEST";
export const ADD_DONATION_SUCCESS = "ADD_DONATION_SUCCESS";
export const ADD_DONATION_ERROR = "ADD_DONATION_ERROR";

export const GET_NOTES = "GET_NOTES";

export const GET_CURRENCY = "GET_CURRENCY";

export const GET_SOFT_INQUIRIES = "GET_SOFT_INQUIRIES";

export const GET_AUTH_USER_DATA = "GET_AUTH_USER_DATA";

export const GET_TRANSACTIONS = "GET_TRANSACTIONS";

export const GET_TRANSACTIONS_ORDER_ID = "GET_TRANSACTIONS_ORDER_ID";

export const TODAYS_ARRIVALS = "TODAYS_ARRIVALS";

export const TODAYS_DEPARTURES = "TODAYS_DEPARTURES";

export const TODAYS_CURRENT_ORDERS = "TODAYS_CURRENT_ORDERS";

export const UPDATE_REDUCER = "UPDATE_REDUCER";

export const ADD_PAYMENT = "ADD_PAYMENT";

export const HOLD_RELESE_HANDLER = "HOLD_RELESE_HANDLER";

export const PAYMENT_TYPE_PAY_NOW = "PAYMENT_TYPE_PAY_NOW";
