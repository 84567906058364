import React, { Component } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Skeleton,
  Table,
} from "antd";
import { getInquiries } from "../../actions/orderActionCreator";
import { connect } from "react-redux";
import moment from "moment";
import { axiosInstance } from "../../httpHandler";
const { confirm } = Modal;
class SoftInquiries extends Component {
  state = {
    inquiries: [],
    visible: false,
    customerName: "",
    date: "",
    description: "",
    id: "",
  };
  componentDidMount() {
    this.props.dispatch(getInquiries());
  }
  showConfirm = (id) => {
    let confirmThis = this;
    confirm({
      title: `Do you want to delete This Inquiry?`,
      content: "When clicked the OK button, this Inquiry will be deleted.",
      onOk() {
        axiosInstance
          .delete(`/softInquiry/${id}`, { handlerEnabled: true })
          .then((res) => {
            message.success("Inquiry has been deleted successfully!");
            if (res) {
              confirmThis.props.dispatch(getInquiries());
            }
          })
          .catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };
  componentWillReceiveProps(nextProps) {
    nextProps && this.setState({ inquiries: nextProps.inquiries.data });
  }
  handleCancel = () => {
    this.setState({ visible: false });
  };
  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };
  handleUpdate = async (e) => {
    e.preventDefault();
    await axiosInstance
      .put(`/updateSoftInquiry/${this.state.id}`, this.state, {
        handlerEnabled: true,
      })
      .then((res) => {
        this.props.dispatch(getInquiries());
        this.setState({ visible: false });
        message.success("Inquiry Updated Successfuully");
      });
  };
  handleEditModal = (data) => {
    this.setState(
      {
        customerName: data.customerName,
        date: data.date,
        description: data.description,
        id: data.id,
      },
      () => this.setState({ visible: true })
    );
  };

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "customerName",
        key: "name",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Actions",
        key: "actions",
        render: (data) => (
          <span>
            <Button
              type="primary"
              shape="circle"
              icon="edit"
              onClick={() => this.handleEditModal(data)}
            />
            <Button
              type="danger"
              shape="circle"
              icon="delete"
              style={{ marginLeft: "5px" }}
              onClick={() => this.showConfirm(data.id)}
            />
          </span>
        ),
      },
    ];
    return (
      <>
        <Modal
          title="EditSoftInquiry"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>
          }
          width={"500px"}
        >
          <Form>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Name">
                  <Input
                    placeholder="Customer name"
                    value={this.state.customerName}
                    onChange={(e) =>
                      this.handleChange("customerName", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Date">
                  <DatePicker
                    placeholder="Choose date"
                    style={{ width: "100%" }}
                    value={moment(this.state.date, "DD/MM/YYYY")}
                    onChange={(e) =>
                      this.handleChange("date", moment(e).format("DD/MM/YYYY"))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Description">
              <Input.TextArea
                placeholder="Order description"
                rows={6}
                value={this.state.description}
                onChange={(e) =>
                  this.handleChange("description", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                onClick={this.handleUpdate}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        {this.state.inquiries.length > 0 ? (
          <Table dataSource={this.state.inquiries} columns={columns} />
        ) : (
          <Skeleton active />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ orderReducer }) => {
  return {
    inquiries: orderReducer.inquiries,
  };
};

export default connect(mapStateToProps)(SoftInquiries);
