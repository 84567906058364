import React, { Component } from "react";
import moment from "moment";
// prettier-ignore
import { Form, DatePicker, Row, Col, Radio, InputNumber, TimePicker, Select, Switch, Input,Modal, Table, Typography, Button, Checkbox, message, Skeleton } from "antd";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import { KEY } from "../../AppConstants";
import { axiosInstance } from "../../httpHandler";
import TextArea from "antd/lib/input/TextArea";
import { store } from "../../store";
import {
  addCustomer,
  getContacts,
  addOrder,
  getOrderData,
} from "../../actions/orderActionCreator";
import { connect } from "react-redux";
import ReactPhoneInput from "react-phone-input-2";
import { handleCountCurrency2 } from "../../config/currencyConvertor";

class FoodForm extends Component {
  state = {
    searchAdd: "",
    deliveryAddress: "",
    orderDate: moment(new Date()).format("ddd MMM DD YYYY"),
    orderType: "eatIN",
    cost: {
      food: {},
    },
    food: {
      breakfest: { people: 0, time: "", VIP: false, preferences: [] },
      lunch: { people: 0, time: "", VIP: false, preferences: [] },
      dinner: { people: 0, time: "", VIP: false, preferences: [] },
    },
    lunchData: [],
    breakfastData: [],
    dinnerData: [],
    paymentType: "cash",
    existingCustomer: true,
    currency: "USD",
    amount: "",
    customer: "",
    totalAmount: 0,
    addresses: [{ address: "", address2: "", city: "", state: "", zip: "" }],
    creditCards: [
      {
        cardName: "",
        cardNumber: "",
        cardCVV: "",
        cardExpiry: "",
        paymentAmount: "",
      },
    ],
    cheque: {
      amount: "",
      chequeName: "",
      chequeDate: "",
      accountNumber: "",
      currency: "USD",
      routingNo: "",
    },
    refNumber: "",
    search: "",
    searchVal: "",
    index: null,
    userCards: [],
    card: [],
    selectedCardAmount: "",
    discount: 0,
    loading: false,
    currencyTotal: 0,
    paidCurrency: "USD",
    customerData: "",
    memos: [],
    customerId: "",
    index2: 0,
    donation: 0,
    usdDonation: 0,
    grandTotal: 0,
  };

  componentDidMount = async () => {
    const { foodPreferences, foodPrice } = this.props;
    this.setState({ loading: true });
    await this.props.dispatch(getContacts());
    this.setState({
      breakfastData: foodPreferences.breakfast,
      lunchData: foodPreferences.lunch,
      dinnerData: foodPreferences.dinner,
      seudesData: foodPreferences.seudes,
    });
    foodPrice.map((p) => {
      if (p.id === "breakfast") {
        this.setState({ breakfast: p });
      } else if (p.id === "lunch") {
        this.setState({ lunch: p });
      } else if (p.id === "dinner") {
        this.setState({ dinner: p });
      } else if (p.id === "seudes") {
        this.setState({ seudes: p });
      }
    });

    const breakfastData = foodPreferences.breakfast;
    const lunchData = foodPreferences.lunch;
    const dinnerData = foodPreferences.dinner;
    const seudesData = foodPreferences.seudes;

    let obj = {
      breakfest: {
        pref: {},
      },
      lunch: {
        pref: {},
      },
      dinner: { pref: {} },
      seuda: { pref: {} },
    };
    breakfastData &&
      breakfastData.map((r) => {
        obj.breakfest.pref[r.name] = r.price;
      });
    lunchData.map((r) => {
      obj.lunch.pref[r.name] = r.price;
    });
    dinnerData.map((r) => {
      obj.dinner.pref[r.name] = r.price;
    });
    seudesData.map((r) => {
      obj.seuda.pref[r.name] = r.price;
    });

    obj.breakfest.reg = this.state.breakfast ? this.state.breakfast.regular : 0;
    obj.breakfest.vip = this.state.breakfast.vip;
    obj.lunch.reg = this.state.lunch.regular;
    obj.lunch.vip = this.state.lunch.vip;
    obj.dinner.reg = this.state.dinner.regular;
    obj.dinner.vip = this.state.dinner.vip;
    obj.seuda.reg = this.state.seudes.regular;
    obj.seuda.vip = this.state.seudes.vip;

    await this.setState({
      cost: {
        ...this.state.cost,
        food: obj,
      },
      loading: false,
    });

    if (this.props.order) {
      let order = this.props.order[0];
      this.setState({
        orderDate: moment(order.orderDate).format("ddd MMM DD YYYY"),
        food: order.food,
        orderType: order.orderType,
        deliveryAddress: order.deliveryAddress,
        discount: order.discount,
        currencyTotal: order.currencyTotal,
        paidCurrency: order.paidCurrency,
        customerId: order.customerId,
        grandTotal: order.currencyTotal,
      });
    }
  };

  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    this.setState({
      searchAdd: "",
      deliveryAddress: geocodedPrediction.formatted_address,
    });
  };

  handleSelectSuggest2 = (geocodedPrediction, originalPrediction) => {
    this.setState({
      search: { [this.state.index]: "" },
      searchVal: geocodedPrediction.formatted_address,
    });
    this.handleAddress(
      "address",
      geocodedPrediction.formatted_address,
      this.state.index
    );
  };

  addPeopleQuantity = async (qty, type) => {
    const newFood = { ...this.state.food };
    newFood[type].people = qty;
    this.setState({ food: newFood });
  };

  handleChange = (key, value) => {
    store.dispatch(addCustomer(key, value));
  };

  handleAddNewCustomer = (e) => {
    this.setState({
      existingCustomer: !e.target.checked,
      customer: "",
    });
    store.dispatch(addCustomer("customerType", "1-time"));
  };

  handleChange2 = (key, value) => {
    this.setState({ [key]: value });

    this.props.form.setFields({
      donation: {
        value: this.state.donation,
      },
    });
  };

  handleCustomerChange = (key, value) => {
    const { contactsData } = this.props;
    this.setState({ index2: 0 }, () => {
      contactsData.map((contact) => {
        if (contact.id === value) {
          this.setState(
            {
              customerData: contact,
              memos: contact.memo ? contact.memo : [],
              customerId: contact.id,
            },
            () => {
              if (this.state.memos.length) {
                this.showMemoModal(this.state.memos);
              }
            }
          );
        }
      });
    });
    this.setState({ [key]: value });
  };
  handleSelectedCard = (v) => {
    let data = [];
    let object = v ? JSON.parse(v) : null;
    data.push(object);

    this.setState({
      card: v ? data : [],
    });
  };
  addFoodVIP = async (checked, type) => {
    const newFood = { ...this.state.food };
    newFood[type].VIP = checked;
    this.setState({ food: newFood });
  };

  addFoodTime = async (time, type) => {
    const newFood = { ...this.state.food };
    newFood[type].time = moment(time).format("h:mm a");
    this.setState({ food: newFood });
  };

  addFoodPreferences = async (preferences, type) => {
    const newFood = { ...this.state.food };
    var temp = [...preferences];
    newFood[type].preferences = temp;
    this.setState({ food: newFood });
  };

  handlePaymentTypeChange = (e) => {
    this.setState({
      paymentType: e.target.value,
    });
  };

  submitFood = (e, totalAmount, currencyTotal) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        if (this.props.variant === "edit") {
          const data = {
            food: this.state.food,
            deliveryAddress: this.state.deliveryAddress,
            orderType: this.state.orderType,
            totalAmount: totalAmount,
            currencyTotal: currencyTotal,
            discount: this.state.discount,
            orderDate: this.state.orderDate,
            total: totalAmount,
            paidCurrency: this.state.paidCurrency,
            customer: this.state.customerId,
          };

          await axiosInstance
            .patch(`/foods/${this.props.order[0].id}`, data, {
              handlerEnabled: true,
            })
            .then(async (res) => {
              this.props.dispatch(getOrderData());

              message.success("Order Updated !");
              await this.props.closeModel();
            });
        } else {
          await axiosInstance
            .post(
              `/addFood`,
              {
                customer: this.props.customer || [],
                data: this.state,
                totalAmount: totalAmount,
                currencyTotal: currencyTotal,
              },
              {
                handlerEnabled: true,
              }
            )
            .then(async (res) => {
              if (res.data.Error) {
                const error = res.data.Error.split("&")
                  .map((obj) => obj.split("="))
                  .find((v) => v[0] === "xError")[1];
                message.error(error.replace(/[+]/g, " "));
              } else {
                this.props.dispatch(getOrderData());

                message.success("Processing complete!");
                await this.props.closeModel();
              }
            });
        }
      }
    });
  };
  handleCountCurrency = (amount, e) => {
    const currency = this.props.currency?.data;
    let value = 0;

    if (e === "RUBL") {
      value = amount * currency.Rubl;
    } else if (e === "POUND") {
      value = amount * currency.Pound;
    } else if (e === "ZlATY") {
      value = amount * currency.Zlaty;
    } else if (e === "SHEKEL") {
      value = amount * currency.Shekel;
    } else if (e === "HRYVNIA") {
      value = amount * currency.Hryvnia;
    } else if (e === "EURO") {
      value = amount * currency.Euro;
    } else if (e === "USD") {
      value = amount;
    }

    return value;
  };
  handleAddress = (key, value, i) => {
    const { addresses } = this.state;
    addresses[i] = { ...addresses[i], [key]: value };
    this.setState({ addresses });
    store.dispatch(addCustomer("addresses", addresses));
  };

  fixCard = () => {
    let data = [];
    let cardAmount = this.state.selectedCardAmount || 0;
    let amount = "xAuthAmount";
    let object = this.state.object;
    object[amount] = cardAmount;
    data.push(object);
    this.setState({ selectedCard: data }, () => {
      store.dispatch(addOrder("selectedCard", this.state.selectedCard));
    });
  };

  getUserCards = (e) => {
    let cards = [];
    this.props.contactsData.map(
      (cust, i) => cust.id === e && cards.push(cust.customerCards)
    );
    this.setState({ userCards: cards[0] || [] });
  };

  handleCreditcards = (key, value, i) => {
    const { creditCards } = this.state;
    creditCards[i] = {
      ...creditCards[i],
      [key]: value,
    };
    this.setState({ creditCards });
    // store.dispatch(addCustomer("creditCards", creditCards));
  };

  handleCheque = (key, value) => {
    const { cheque } = this.state;
    let a = cheque;
    a = {
      ...a,
      [key]: value,
    };
    this.setState({ cheque: a });
    // store.dispatch(addOrder("cheque", a));
  };

  calculateCosts = (entity, cost, isVip = false, preferences = []) => {
    if (!entity) return 0;
    const { pref, vip, reg } = cost;
    const price = isVip ? vip : reg;

    return (
      (price +
        preferences
          .map((item) => pref[item])
          .reduce((previous, current) => previous + current, 0)) *
      entity
    );
  };

  showMemoModal = () => {
    let thiss = this;
    Modal.confirm({
      title: "Account memo",
      content: this.state.memos[this.state.index2],
      cancelText: "Dismiss",
      okText: "Delete memo",
      onCancel() {
        thiss.setState(
          { index2: thiss.state.memos.length > 0 ? thiss.state.index2 + 1 : 0 },
          () => {
            if (thiss.state.index2 <= thiss.state.memos.length - 1) {
              thiss.showMemoModal(thiss.state.memos);
            }
          }
        );
      },
      onOk() {
        let a = thiss.state.memos;
        a.splice(thiss.state.index2, 1);
        thiss.setState({ memos: a }, () => {
          axiosInstance
            .put(
              `/deletememo/${thiss.state.customerId}`,
              {
                memo: thiss.state.memos,
              },
              { handlerEnabled: true }
            )
            .then((res) => {
              message.success("Memo Deleted succesfully");
              thiss.props.dispatch(getContacts());
              if (thiss.state.index2 <= thiss.state.memos.length - 1) {
                thiss.showMemoModal(thiss.state.memos);
              }
            })
            .catch((err) => console.log(err));
        });
      },
    });
  };

  handleDonation = (e, currencyTotal) => {
    let donation = Math.round((e - currencyTotal) * 100) / 100;
    let usdDonation = handleCountCurrency2(
      donation,
      this.state.paidCurrency,
      this.props.currency?.data
    );
    this.setState({
      donation,
      usdDonation,
      grandTotal: parseInt(e),
    });
    this.props.form.setFields({
      donation: { value: donation },
    });
  };
  render() {
    const { getFieldDecorator, setFields } = this.props.form;

    const dataSource = Object.entries(this.state.food).map((d, i) => ({
      key: i,
      item: d[0],
      vip: d[1].VIP,
      price:
        Object.keys(this.state.cost.food).length !== 0 && d[1].VIP
          ? this.state.cost.food[d[0]].vip
          : Object.keys(this.state.cost.food).length !== 0 && !d[1].VIP
          ? this.state.cost.food[d[0]].reg
          : 0,
      qty: d[1].people,
      total:
        Object.keys(this.state.cost.food).length !== 0
          ? this.calculateCosts(
              d[1].people,
              this.state.cost.food[d[0]],
              d[1].VIP,
              d[1].preferences
            )
          : 0,
    }));
    const sumTotal = (arr) => arr?.reduce((sum, { total }) => sum + total++, 0);
    const totalAmount = sumTotal(dataSource) - this.state.discount;

    const paidCurrencyTotal = this.handleCountCurrency(
      totalAmount,
      this.state.paidCurrency
    );
    let grandTotal =
      this.handleCountCurrency(totalAmount, this.state.paidCurrency) +
      this.state.donation;

    const columns = [
      {
        title: "Item",
        dataIndex: "item",
        key: "item",
        render: (item) => (
          <span style={{ textTransform: "capitalize" }}>{item}</span>
        ),
      },
      {
        title: "VIP",
        dataIndex: "vip",
        key: "vip",
        render: (vip) => (
          <span style={{ textTransform: "capitalize" }}>
            {vip ? "Yes" : "No"}
          </span>
        ),
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (price) => {
          return <span>{price}</span>;
        },
      },
      {
        title: "Qty",
        dataIndex: "qty",
        key: "qty",
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        render: (total) => "$" + total.toLocaleString(),
      },
    ];
    let currencies = [
      { sym: "$", value: "USD" },
      { sym: "zl", value: "ZlATY" },
      { sym: "₽", value: "RUBL" },
      { sym: "£", value: "POUND" },
      { sym: "₪", value: "SHEKEL" },
      { sym: "₴", value: "HRYVNIA" },
      { sym: "€", value: "EURO" },
    ];
    const currencyOptions = (
      <Select
        disabled={this.props.disabled}
        value={this.state.paidCurrency}
        style={{ width: 80 }}
        className="select-before"
        onChange={(e) => {
          // this.handleCountCurrency(this.state.amount, e);
          this.setState({
            currency: e,
            paidCurrency: e,
            // donation: this.state.grandTotal - paidCurrencyTotal,
          });
          setFields({
            donation: { value: this.state.grandTotal - paidCurrencyTotal },
          });
        }}
      >
        {currencies.map((currency, i) => (
          <Select.Option value={currency.value} key={i}>
            {currency.sym}
          </Select.Option>
        ))}
      </Select>
    );

    return !this.state.loading ? (
      <>
        <Form>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Date">
                <DatePicker
                  value={moment(this.state.orderDate)}
                  disabled={this.props.disabled}
                  onChange={(e) => {
                    this.setState({
                      orderDate: moment(e).format("ddd MMM DD YYYY"),
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Order type">
                <Radio.Group
                  defaultValue={this.state.orderType}
                  disabled={this.props.disabled}
                  onChange={(e) => {
                    this.setState({ orderType: e.target.value });
                  }}
                  value={this.state.orderType}
                  buttonStyle="solid"
                >
                  <Radio.Button value="eatIN">Eat in</Radio.Button>
                  <Radio.Button value="delivery">Delivery</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Delivery address">
                <ReactGoogleMapLoader
                  params={{
                    key: KEY.MAP_KEY,
                    libraries: "places,geocode",
                  }}
                  render={(googleMaps) =>
                    googleMaps && (
                      <ReactGooglePlacesSuggest
                        googleMaps={googleMaps}
                        autocompletionRequest={{
                          input: this.state.searchAdd,
                        }}
                        onNoResult={this.handleNoResult}
                        onSelectSuggest={this.handleSelectSuggest}
                        onStatusUpdate={this.handleStatusUpdate}
                        textNoResults="No Data"
                        customRender={(prediction) => (
                          <div className="customWrapper">
                            {prediction
                              ? prediction.description
                              : "No Address Found"}
                          </div>
                        )}
                      >
                        <Form.Item>
                          <Input
                            defaultValue={this.state.deliveryAddress}
                            disabled={this.props.disabled}
                            placeholder={"Address"}
                            value={this.state.deliveryAddress}
                            onChange={(e) => {
                              this.setState({
                                searchAdd: e.target.value,
                                deliveryAddress: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </ReactGooglePlacesSuggest>
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Breakfest">
                <InputNumber
                  value={this.state.food.breakfest.people}
                  disabled={this.props.disabled}
                  min={0}
                  placeholder="people"
                  style={{ width: "100px" }}
                  onChange={(e) => {
                    this.addPeopleQuantity(e, "breakfest");
                  }}
                />
                <TimePicker
                  value={
                    this.state.food.breakfest.time
                      ? moment(this.state.food.breakfest.time, "LT")
                      : moment("9:00", "h:mm a")
                  }
                  disabled={this.props.disabled}
                  placeholder="Time"
                  format="h:mm a"
                  use12Hours
                  defaultOpenValue={moment("9:00", "h:mm a")}
                  minuteStep={15}
                  style={{ width: "100px" }}
                  onChange={(time) => this.addFoodTime(time, "breakfest")}
                />
                <Select
                  value={this.state.food.breakfest.preferences}
                  disabled={this.props.disabled}
                  mode="multiple"
                  style={{ minWidth: "100px" }}
                  placeholder="Preferences"
                  dropdownMatchSelectWidth={false}
                  onChange={(e) => this.addFoodPreferences(e, "breakfest")}
                >
                  {this.state.breakfastData.map((d, i) => (
                    <Select.Option value={d.name} key={i}>
                      {d.name}
                    </Select.Option>
                  ))}
                  {/* <Select.Option value="Fish">Fish</Select.Option>
                  <Select.Option value="Beef">Beef</Select.Option>
                  <Select.Option value="Chicken">Chicken</Select.Option> */}
                </Select>
                <br />
                <Switch
                  checked={this.state.food.breakfest.VIP}
                  disabled={this.props.disabled}
                  checkedChildren="VIP"
                  unCheckedChildren="VIP"
                  onChange={(checked) => {
                    this.addFoodVIP(checked ? true : false, "breakfest");
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Lunch">
                <InputNumber
                  value={this.state.food.lunch.people}
                  disabled={this.props.disabled}
                  min={0}
                  placeholder="people"
                  style={{ width: "100px" }}
                  onChange={(e) => {
                    this.addPeopleQuantity(e, "lunch");
                  }}
                />
                <TimePicker
                  value={
                    this.state.food.lunch.time
                      ? moment(this.state.food.lunch.time, "LT")
                      : moment("13:00", "h:mm a")
                  }
                  disabled={this.props.disabled}
                  placeholder="Time"
                  format="h:mm a"
                  use12Hours
                  defaultOpenValue={moment("13:00", "h:mm a")}
                  minuteStep={15}
                  style={{ width: "100px" }}
                  onChange={(time) => this.addFoodTime(time, "lunch")}
                />
                <Select
                  value={this.state.food.lunch.preferences}
                  disabled={this.props.disabled}
                  mode="multiple"
                  style={{ minWidth: "100px" }}
                  placeholder="Preferences"
                  dropdownMatchSelectWidth={false}
                  onChange={(e) => this.addFoodPreferences(e, "lunch")}
                >
                  {this.state.lunchData.map((d, i) => (
                    <Select.Option value={d.name} key={i}>
                      {d.name}
                    </Select.Option>
                  ))}
                  {/* <Select.Option value="Fish">Fish</Select.Option>
                  <Select.Option value="Beef">Beef</Select.Option>
                  <Select.Option value="Chicken">Chicken</Select.Option> */}
                </Select>
                <br />
                <Switch
                  checked={this.state.food.lunch.VIP}
                  disabled={this.props.disabled}
                  checkedChildren="VIP"
                  unCheckedChildren="VIP"
                  onChange={(checked) => {
                    this.addFoodVIP(checked ? true : false, "lunch");
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Dinner">
                <InputNumber
                  value={this.state.food.dinner.people}
                  disabled={this.props.disabled}
                  min={0}
                  placeholder="people"
                  style={{ width: "100px" }}
                  onChange={(e) => {
                    this.addPeopleQuantity(e, "dinner");
                  }}
                />
                <TimePicker
                  value={
                    this.state.food.dinner.time
                      ? moment(this.state.food.dinner.time, "LT")
                      : moment("18:00", "h:mm a")
                  }
                  disabled={this.props.disabled}
                  placeholder="Time"
                  format="h:mm a"
                  use12Hours
                  defaultOpenValue={moment("18:00", "h:mm a")}
                  minuteStep={15}
                  style={{ width: "100px" }}
                  onChange={(time) => this.addFoodTime(time, "dinner")}
                />
                <Select
                  value={this.state.food.dinner.preferences}
                  disabled={this.props.disabled}
                  mode="multiple"
                  style={{ minWidth: "100px" }}
                  placeholder="Preferences"
                  dropdownMatchSelectWidth={false}
                  onChange={(e) => this.addFoodPreferences(e, "dinner")}
                >
                  {this.state.dinnerData.map((d, i) => (
                    <Select.Option value={d.name} key={i}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
                <br />
                <Switch
                  checked={this.state.food.dinner.VIP}
                  disabled={this.props.disabled}
                  checkedChildren="VIP"
                  unCheckedChildren="VIP"
                  onChange={(checked) => {
                    this.addFoodVIP(checked ? true : false, "dinner");
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          size="small"
          pagination={false}
          dataSource={dataSource}
          columns={columns}
        />
        {this.props.authUser &&
        this.props.authUser.user?.userType === "Admin" ? (
          <Col span={24}>
            <Form.Item className="mt-2">
              <InputNumber
                defaultValue={this.state.discount}
                disabled={this.props.disabled}
                placeholder="Discount"
                style={{ width: "100%" }}
                onChange={(e) => {
                  // this.countDiscount(e);
                  this.setState({ discount: e });
                }}
                type="number"
                size="large"
                min={0}
              />{" "}
            </Form.Item>
          </Col>
        ) : null}
        <Typography.Title level={3} style={{ textAlign: "center" }}>
          Total:{" "}
          {this.state.paymentType === "cash"
            ? currencies.map((currency, i) =>
                currency.value === this.state.paidCurrency ? currency.sym : ""
              )
            : "$"}{" "}
          {this.state.paymentType === "cash" ? paidCurrencyTotal : totalAmount}
        </Typography.Title>
        <Form.Item>
          {getFieldDecorator("donation", {
            initialValue: this.state.donation,
            value: this.state.donation,
          })(
            <InputNumber
              disabled={this.props.disabled}
              placeholder="Donation"
              style={{ width: "100%" }}
              onChange={(e) => {
                this.handleChange2("donation", e);
                setFields({
                  currencyTotal: {
                    value: paidCurrencyTotal + e,
                  },
                });
              }}
              step={0.01}
              type="number"
              size="large"
              min={0}
            />
          )}
        </Form.Item>
        <Form
          layout="vertical"
          onSubmit={(e) => this.submitFood(e, totalAmount, paidCurrencyTotal)}
        >
          {this.state.existingCustomer ? (
            <Form.Item label="Choose existing customer">
              <Select
                value={this.state.customerId}
                disabled={this.props.disabled}
                showSearch
                placeholder="Choose existing customer"
                onChange={(e) => {
                  this.handleCustomerChange("customerId", e);
                  this.getUserCards(e);
                }}
                filterOption={(input, option) => {
                  return option.props.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {this.props.contactsData?.map((contact, i) => (
                  <Select.Option value={contact.id} key={i}>
                    {contact.firstName + " " + contact.lastName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="First name">
                  <Input
                    placeholder="First name"
                    onChange={(e) =>
                      this.handleChange("firstName", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Last name">
                  <Input
                    placeholder="Last name"
                    onChange={(e) =>
                      this.handleChange("lastName", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Customer Type">
                  <Select
                    placeholder="Select Customer Type"
                    onChange={(e) => this.handleChange("customerType", e)}
                    defaultValue="1-Time"
                  >
                    <Select.Option key={1} value="1-Time">
                      1-Time
                    </Select.Option>
                    <Select.Option key={2} value="Recurring">
                      Recurring
                    </Select.Option>
                    <Select.Option key={3} value="Travel agent">
                      Travel agent
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="tags" labelCol={6} wrapperCol={8}>
                  <Select
                    mode="multiple"
                    placeholder="Please select tags"
                    onChange={(e) => this.handleChange("tags", e)}
                    style={{ width: "100%" }}
                  >
                    <Select.Option key={1} value="Reguler">
                      Reguler
                    </Select.Option>
                    <Select.Option key={2} value="VIP">
                      VIP
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Phone">
                  <ReactPhoneInput
                    inputClass="phoneInputStyle"
                    buttonClass="phoneDropDown"
                    country={"us"}
                    // value={"919898363748"}
                    onChange={(value, country, e, v) => {
                      let a = [];
                      a.push(v);
                      this.handleChange("phones", a);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Email">
                  <Input
                    placeholder="Email"
                    onChange={(e) => {
                      let a = [];
                      a.push(e.target.value);
                      this.handleChange("emails", a);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                {this.state.addresses.length > 0 &&
                  this.state.addresses.map((address, i) => (
                    <Form.Item rules={[{ required: true }]}>
                      <ReactGoogleMapLoader
                        params={{
                          key: KEY.MAP_KEY,
                          libraries: "places,geocode",
                        }}
                        render={(googleMaps) =>
                          googleMaps && (
                            <ReactGooglePlacesSuggest
                              googleMaps={googleMaps}
                              autocompletionRequest={{
                                input: this.state.search[i],
                              }}
                              onNoResult={this.handleNoResult}
                              onSelectSuggest={this.handleSelectSuggest2}
                              onStatusUpdate={this.handleStatusUpdate}
                              textNoResults="No Address Found"
                              customRender={(prediction) => (
                                <div className="customWrapper">
                                  {prediction
                                    ? prediction.description
                                    : "No Address Found"}
                                </div>
                              )}
                            >
                              <Form.Item>
                                <Input
                                  placeholder={"Address"}
                                  value={this.state.addresses[i].address}
                                  onChange={(e) => {
                                    this.handleAddress(
                                      "address",
                                      e.target.value,
                                      i
                                    );
                                    this.setState({
                                      search: { [i]: e.target.value },
                                    });
                                    this.setState({ index: i });
                                  }}
                                />
                              </Form.Item>
                            </ReactGooglePlacesSuggest>
                          )
                        }
                      />
                    </Form.Item>
                  ))}
              </Col>{" "}
              <Col span={24}>
                <Form.Item label="kvitel">
                  <TextArea
                    allowClear
                    onChange={(e) =>
                      this.handleChange("kvitel", e.target.value)
                    }
                    rows={5}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {this.props.variant !== "edit" && (
            <Form.Item>
              <Checkbox
                onChange={(e) => this.handleAddNewCustomer(e)}
                disabled={this.props.disabled}
              >
                Add new customer
              </Checkbox>
            </Form.Item>
          )}
          {!this.props.disabled && (
            <>
              <Form.Item label="Payment type">
                <Radio.Group
                  disabled={this.props.disabled}
                  defaultValue="cash"
                  buttonStyle="solid"
                  onChange={(e) => this.handlePaymentTypeChange(e)}
                >
                  <Radio.Button value="cash">Cash</Radio.Button>
                  <Radio.Button value="cheque">Check</Radio.Button>
                  <Radio.Button value="card">Credit card</Radio.Button>
                  <Radio.Button value="quickpay">Quickpay</Radio.Button>
                  <Radio.Button value="offlinecard">
                    Offline Credit Cards
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              {this.state.paymentType === "cash" ? (
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item>
                      {" "}
                      {getFieldDecorator("currencyTotal", {
                        initialValue: grandTotal,
                        value: grandTotal,
                      })(
                        <Input
                          disabled={this.props.disabled}
                          size="large"
                          style={{ width: "100%" }}
                          addonBefore={currencyOptions}
                          onChange={(e) =>
                            this.handleDonation(
                              e.target.value,
                              paidCurrencyTotal
                            )
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}
              {this.state.paymentType === "cheque" ? (
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Check name">
                      {getFieldDecorator("chequename", {
                        rules: [
                          {
                            required: true,
                            message: "Add Name Written On Check!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Name on check"
                          onChange={(e) =>
                            this.handleCheque("chequeName", e.target.value)
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Check date">
                      {getFieldDecorator("chequedate", {
                        rules: [
                          {
                            required: true,
                            message: "Please Select date written On Check!",
                          },
                        ],
                      })(
                        <DatePicker
                          placeholder="Check date"
                          onChange={(e) =>
                            this.handleCheque(
                              "chequeDate",
                              moment(e).format("ddd MMM DD YYYY")
                            )
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Account number">
                      {getFieldDecorator("accountNumber", {
                        rules: [
                          {
                            required: true,
                            message: "Please add Your Account Number!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Account number"
                          onChange={(e) =>
                            this.handleCheque("accountNumber", e.target.value)
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Routing number">
                      {getFieldDecorator("routingNo", {
                        rules: [
                          {
                            required: true,
                            message: "Please add Routing Number!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Routing number"
                          onChange={(e) =>
                            this.handleCheque("routingNo", e.target.value)
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}
              {this.state.paymentType === "card" ? (
                <Row gutter={16}>
                  {this.state.existingCustomer &&
                  this.state.userCards.length ? (
                    <Col span={24}>
                      <Form.Item label="Choose existing card">
                        <Select
                          onChange={(e) => this.handleSelectedCard(e)}
                          placeholder="Choose existing card"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          allowClear
                        >
                          {this.state.userCards.map((card, i) => (
                            <Select.Option key={i} value={JSON.stringify(card)}>
                              Card ending with {card.cardEnding.substr(12, 4)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <div>or</div>
                    </Col>
                  ) : null}

                  {this.state.creditCards.length > 0 &&
                    this.state.creditCards.map((card, ind) => {
                      return (
                        <div key={ind}>
                          <Col span={24}>
                            <Form.Item label="Name on card">
                              {getFieldDecorator("cardname", {
                                rules: [
                                  {
                                    required:
                                      this.state.card?.length > 0
                                        ? false
                                        : true,
                                    message: "Please enter Card Name!",
                                  },
                                ],
                              })(
                                <Input
                                  value={this.state.creditCards[ind].cardName}
                                  placeholder="Name on card"
                                  onChange={(e) => {
                                    this.handleCreditcards(
                                      "cardName",
                                      e.target.value,
                                      ind
                                    );
                                    this.setState({ index: ind });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item label="Card number">
                              {getFieldDecorator("cardNumber", {
                                rules: [
                                  {
                                    required:
                                      this.state.card?.length > 0
                                        ? false
                                        : true,
                                    message: "Please enter card number!",
                                  },
                                  {
                                    min: 15,
                                    message:
                                      "Card number must be minimum 15 characters long.",
                                  },
                                  {
                                    max: 16,
                                    message:
                                      "Card number must be maximum 16 characters long.",
                                  },
                                ],
                              })(
                                <Input
                                  value={this.state.creditCards[ind].cardNumber}
                                  placeholder="Card number"
                                  onChange={(e) => {
                                    this.handleCreditcards(
                                      "cardNumber",
                                      e.target.value,
                                      ind
                                    );
                                    this.setState({ index: ind });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Exp">
                              {getFieldDecorator("expirydate", {
                                rules: [
                                  {
                                    required:
                                      this.state.card?.length > 0
                                        ? false
                                        : true,
                                    message: "Please select Expiry date!",
                                  },
                                ],
                              })(
                                <DatePicker.MonthPicker
                                  value={this.state.creditCards[ind].cardExpiry}
                                  placeholder="Exp."
                                  format="YYYY/MM"
                                  onChange={(e) => {
                                    this.handleCreditcards(
                                      "cardExpiry",
                                      e,
                                      ind
                                    );
                                    this.setState({ index: ind });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="CCV">
                              {getFieldDecorator("cardCCV", {
                                rules: [
                                  {
                                    required:
                                      this.state.card?.length > 0
                                        ? false
                                        : true,
                                    message: "Please enter cvv number!",
                                  },
                                ],
                              })(
                                <Input
                                  placeholder="CCV"
                                  value={this.state.creditCards[ind].cardCVV}
                                  onChange={(e) => {
                                    this.handleCreditcards(
                                      "cardCVV",
                                      e.target.value,
                                      ind
                                    );
                                    this.setState({ index: ind });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item rules={[{ required: true }]}>
                              {getFieldDecorator("cardAmount", {
                                rules: [
                                  {
                                    required:
                                      this.state.selectedCard?.length > 0
                                        ? false
                                        : true,
                                    message: "Please enter amount!",
                                  },
                                ],
                              })(
                                <Input.Search
                                  // defaultValue="$750"
                                  enterButton="Process card"
                                  // onSearch={() => this.handleCard(card)}
                                  size="large"
                                  onChange={(e) => {
                                    this.state.selectedCard?.length > 0
                                      ? this.setState(
                                          {
                                            selectedCardAmount: e.target.value,
                                          },
                                          () => {
                                            this.fixCard();
                                          }
                                        )
                                      : this.handleCreditcards(
                                          "paymentAmount",
                                          parseInt(e.target.value),
                                          ind
                                        );
                                    this.setState({ index: ind });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </div>
                      );
                    })}
                </Row>
              ) : null}
              {this.state.paymentType === "quickpay" ? (
                <Form.Item label="Reference number">
                  {getFieldDecorator("ref Number", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter Reference number!",
                      },
                    ],
                  })(
                    <Input
                      placeholder="Reference number"
                      onChange={(e) =>
                        this.handleChange2("refNumber", e.target.value)
                      }
                    />
                  )}
                </Form.Item>
              ) : null}
              {this.state.paymentType === "offlinecard" ? (
                <>
                  <Form.Item label="Reference number">
                    {getFieldDecorator("card ref Number", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Reference number!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Reference number"
                        onChange={(e) =>
                          this.handleChange2("cardRefNumber", e.target.value)
                        }
                      />
                    )}
                  </Form.Item>
                  <Form.Item rules={[{ required: true }]}>
                    {getFieldDecorator("offcardAmount", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter amount!",
                        },
                      ],
                    })(
                      <Input.Search
                        // defaultValue="$750"
                        enterButton="Process"
                        // onSearch={() => this.handleCard(card)}
                        size="large"
                        onChange={(e) => {
                          this.setState({ cardAmount: e.target.value });
                        }}
                      />
                    )}
                  </Form.Item>
                </>
              ) : null}
              <Form.Item>
                <Button
                  disabled={this.props.disabled}
                  type="primary"
                  htmlType="submit"
                >
                  {this.props.variant === "edit" ? "Update" : "Submit"}
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </>
    ) : (
      <Skeleton active />
    );
  }
}

const mapStateToProps = ({ orderReducer }) => {
  return {
    contactsData: orderReducer.contacts,
    customer: orderReducer.customer,
    authUser: orderReducer.authUser,
    foodPrice: orderReducer.foodPrices,
    foodPreferences: orderReducer.foodPreferences,
    currency: orderReducer.currency,
    order: orderReducer.orderData,
  };
};

const FoodsForm = Form.create({ name: "products" })(FoodForm);
export default connect(mapStateToProps)(FoodsForm);
