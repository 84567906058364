import React from "react";

const calculateRoomCosts = (entity, cost) => {
  if (!entity) return 0;
  const { regular, couple, private: privatec } = cost;
  let regularCost = entity.regular * regular;
  let coupleCost = entity.couple * couple;
  let privateCost = entity.private * privatec;
  return regularCost + coupleCost + privateCost;
};
const calculateCosts = (entity, cost, isVip = false, preferences = []) => {
  if (!entity) return 0;
  const { pref, vip, reg } = cost;
  const price = isVip ? vip : reg;

  return (
    (price +
      preferences
        .map((item) => pref[item])
        .reduce((previous, current) => previous + current, 0)) *
    entity
  );
};

const calculateRooms = (costs, dates = []) => {
  const { pref } = costs;

  const preferences = dates
    .map((date) =>
      date
        .map((room) => room.preferences)
        .filter((preferences) => preferences)
        .reduce((prev, curr) => [...prev, ...curr], [])
    )
    .reduce((prev, curr) => [...prev, ...curr], []);

  if (preferences.length < 1) return 0;

  const cost = preferences
    .map((preference) => pref[preference])
    .reduce((prev, curr) => prev + curr, 0);

  return cost;
};

export const fromatOrder = (params) => {
  let total = 0;
  let couple = 0;
  let men = 0;
  let women = 0;
  let privates = 0;
  let boys = 0;
  let girls = 0;
  let babies = 0;
  let breakfast = 0;
  let VIPbreakfast = 0;
  let lunch = 0;
  let VIPlunch = 0;
  let VIPdinner = 0;
  let VIPseudes = 0;
  let dinner = 0;
  let seudes = 0;
  let breakfastVip = false;
  let lunchVip = false;
  let dinnerVip = false;
  let seudesVip = false;
  let prefLunch = [];
  let prefBreakfast = [];
  let pref = 0;
  let prefDinner = [];
  let rooms = 0;
  let room = [];
  let result = 0;
  let lodgingData = 0;
  let roomPreferences = [];
  Object.values(params.chosenDates).forEach((res, i) => {
    roomPreferences.push(res.room);
    res.room.forEach((res, i) => {
      result += res.preferences.filter((x) => x).length;
    });
    couple += res.people.couple ? res.people.couple : 0;
    privates += res.people.private ? res.people.private : 0;
    men += res.people.men ? res.people.men : 0;
    women += res.people.woman ? res.people.woman : 0;
    boys += res.people.boys ? res.people.boys : 0;
    girls += res.people.girls ? res.people.girls : 0;
    babies += res.people.babies ? res.people.babies : 0;

    const lodging = {
      couple: couple,
      private: privates,
      regular: men + women + boys + girls + babies,
    };
    lodgingData = lodging;
    // breakfast += res.food.breakfast.people;
    // lunch += res.food.lunch.people;
    // dinner += res.food.dinner.people;
    // seudes += res.food.seudes.people;
    rooms += res.room.length;
    Object.values(res).forEach((r, i) => {
      r.breakfast && r.breakfast.VIP
        ? (VIPbreakfast += r.breakfast ? r.breakfast.people : 0)
        : (breakfast += r.breakfast ? r.breakfast.people : 0);
      r.lunch && r.lunch.VIP
        ? (VIPlunch += r.lunch ? r.lunch.people : 0)
        : (lunch += r.lunch ? r.lunch.people : 0);
      r.dinner && r.dinner.VIP
        ? (VIPdinner += r.dinner ? r.dinner.people : 0)
        : (dinner += r.dinner ? r.dinner.people : 0);
      r.seudes && r.seudes.VIP
        ? (VIPseudes += r.seudes ? r.seudes.people : 0)
        : (seudes += r.seudes ? r.seudes.people : 0);
    });

    if (res.food.breakfast.VIP) breakfastVip = true;
    if (res.food.lunch.VIP) lunchVip = true;
    if (res.food.dinner.VIP) dinnerVip = true;
    if (res.food.seudes && res.food.seudes.VIP) seudesVip = true;

    if (res.food.lunch.preferences && res.food.lunch.preferences.length > 0)
      // prefLunch = res.food.lunch.preferences;
      Object.values(res.food.lunch.preferences).forEach((item, i) => {
        prefLunch.push(item);
      });
    if (
      res.food.breakfast.preferences &&
      res.food.breakfast.preferences.length > 0
    ) {
      Object.values(res.food.breakfast.preferences).forEach((item, i) => {
        prefBreakfast.push(item);
      });
    }

    if (res.food.dinner.preferences && res.food.dinner.preferences.length > 0) {
      prefDinner = res.food.dinner.preferences;
    }
  });

  const orderData = {
    columns: [
      {
        title: "Product",
        dataIndex: "product",
        key: "product",
        render: (product) => <strong>{product}</strong>,
      },
      {
        title: "Qty",
        dataIndex: "qty",
        key: "qty",
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (price) => "" + price,
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        render: (total) => "$" + total,
      },
    ],
    dataSource: [
      // {
      //   key: "1",
      //   product: "Couple",
      //   qty: couple,
      //   price: params.cost.lodging && params.cost.lodging.reg * 2,
      //   total: params.cost.lodging && params.cost.lodging.reg * (couple * 2)
      // },
      // {
      //   key: "2",
      //   product: "Men",
      //   qty: men,
      //   price: params.cost.lodging && params.cost.lodging.reg,
      //   total: params.cost.lodging && params.cost.lodging.reg * men
      // },
      // {
      //   key: "3",
      //   product: "Women",
      //   qty: women,
      //   price: params.cost.lodging && params.cost.lodging.reg,
      //   total: params.cost.lodging && params.cost.lodging.reg * women
      // },
      // {
      //   key: "4",
      //   product: "Boys",
      //   qty: boys,
      //   price: params.cost.lodging && params.cost.lodging.reg,
      //   total: params.cost.lodging && params.cost.lodging.reg * boys
      // },
      // {
      //   key: "5",
      //   product: "Girls",
      //   qty: girls,
      //   price: params.cost.lodging && params.cost.lodging.reg,
      //   total: params.cost.lodging && params.cost.lodging.reg * girls
      // },
      // {
      //   key: "6",
      //   product: "Babies",
      //   qty: babies,
      //   price: params.cost.lodging && params.cost.lodging.reg,
      //   total: params.cost.lodging && params.cost.lodging.reg * babies
      // },
      {
        key: "7",
        product: "Reg Breakfast",
        qty: breakfast,
        price: params.cost.food.breakfest && params.cost.food.breakfest.reg,
        // total: params.cost.food.breakfest.reg * breakfast
        total: calculateCosts(
          breakfast,
          params.cost.food.breakfest,
          false,
          prefBreakfast
        ),
      },
      {
        key: "8",
        product: "Reg Lunch",
        qty: lunch,
        price: params.cost.food.lunch && params.cost.food.lunch.reg,
        total: calculateCosts(lunch, params.cost.food.lunch, false, prefLunch),
        // total:
        //   props.cost.food.lunch.reg * lunch +
        //   pref * (lunch * props.cost.food.lunch.pref.fish)
      },
      {
        key: "9",
        product: "Reg Dinner",
        qty: dinner,
        price: params.cost.food.dinner && params.cost.food.dinner.reg,
        total: calculateCosts(
          dinner,
          params.cost.food.dinner,
          false,
          prefDinner
        ),
        // total: dinnerVip ? 0 : props.cost.food.dinner.reg * dinner
      },
      {
        key: "10",
        product: "Reg Seudes",
        qty: seudes,
        price: params.cost.food.seuda && params.cost.food.seuda.reg,
        total: calculateCosts(seudes, params.cost.food.seuda, false),
        // total: dinnerVip ? 0 : props.cost.food.dinner.reg * dinner
      },
      {
        key: "11",
        product: "VIP Breakfast",
        qty: VIPbreakfast,
        price: params.cost.food.breakfest && params.cost.food.breakfest.vip,
        total: breakfastVip
          ? calculateCosts(
              VIPbreakfast,
              params.cost.food.breakfest,
              breakfastVip,
              prefBreakfast
            )
          : 0,
      },
      {
        key: "12",
        product: "VIP Lunch",
        qty: VIPlunch,
        price: params.cost.food.lunch && params.cost.food.lunch.vip,
        // total:
        //   VIPlunch * props.cost.food.lunch.vip +
        //   pref * (VIPlunch * props.cost.food.lunch.pref.fish)
        total: lunchVip
          ? calculateCosts(
              VIPlunch,
              params.cost.food.lunch,
              lunchVip,
              prefLunch
            )
          : 0,
      },
      {
        key: "13",
        product: "VIP Dinner",
        qty: VIPdinner,
        price: params.cost.food.dinner && params.cost.food.dinner.vip,
        total: dinnerVip
          ? calculateCosts(
              VIPdinner,
              params.cost.food.dinner,
              dinnerVip,
              prefDinner
            )
          : 0,
      },
      {
        key: "14",
        product: "VIP Seudes",
        qty: VIPseudes,
        price: params.cost.food.seuda && params.cost.food.seuda.vip,
        total: seudesVip
          ? calculateCosts(VIPseudes, params.cost.food.seuda, seudesVip)
          : 0,
      },
      // {
      //   key: "15",
      //   product: "Room",
      //   qty: rooms,
      //   price: props.cost.lodging.reg,
      //   total:
      //     (couple > 0 ? couple * 2 * props.cost.lodging.reg : 0) +
      //     (men > 0 ? props.cost.lodging.reg * men : 0) +
      //     (women > 0 ? props.cost.lodging.reg * women : 0) +
      //     (boys > 0 ? props.cost.lodging.reg * boys : 0) +
      //     (babies > 0 ? props.cost.lodging.reg * babies : 0) +
      //     (girls > 0 ? props.cost.lodging.reg * girls : 0)
      // },
      // {
      //   key: "16",
      //   product: "Room Preferences",
      //   qty: result,
      //   price: "-",
      //   total: calculateRooms(params.cost.lodging, roomPreferences),
      //   // price: params.cost.lodging.pref.privateRoom || 0,
      //   // total: result * params.cost.lodging.pref.privateRoom || 0
      // },
      {
        key: "17",
        product: "Lodging",
        qty:
          lodgingData.regular +
          lodgingData.couple * 2 +
          lodgingData.private * 2,
        price: "-",
        total: calculateRoomCosts(lodgingData, params.cost.lodging),
      },
    ],
  };
  if (
    params.variant === "edit" &&
    [
      "Confirmed",
      "Current",
      "Checked in",
      "Checked out",
      "Past",
      "Closed",
    ].includes(params.order.status)
  ) {
    total = params.order.total;
  } else {
    orderData.dataSource.forEach((res, i) => {
      total += res.total;
    });
  }

  return { ...orderData, total };
};
