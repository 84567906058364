import React, { Component } from "react";
// prettier-ignore
import { Row, Col, Table, Typography,  Tag, Button, Descriptions, Skeleton, Modal, message, Form, Input, InputNumber, Select, DatePicker, } from "antd";
import { KEY } from "../../AppConstants";
import Widget from "../../components/Widget/Widget";
import {
  getSingleCustomer,
  getOrderData,
  showModal as editModal,
  getContacts,
} from "../../actions/orderActionCreator";
import { connect } from "react-redux";
import { axiosInstance } from "../../httpHandler";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import moment from "moment";
import TransactionModal from "../../components/NewOrder/TransactionModal";
import CustEditOrder from "../../components/Customer/CustEditOrder";
import { Link } from "react-router-dom";
import CustomerMemo from "./CustomerMemo";
import { GiMeal } from "react-icons/gi";
import {
  FaBed,
  FaBusAlt,
  FaMoneyBillAlt,
  FaMoneyBillWaveAlt,
  FaShoppingBag,
  FaSwimmingPool,
} from "react-icons/fa";
import ReactPhoneInput from "react-phone-input-2";
import TextArea from "antd/lib/input/TextArea";
import { handleCountCurrency } from "../../config/currencyConvertor";

const { confirm } = Modal;

class SingleCustomer extends Component {
  state = {
    paidData: [],
    customerPayments: [],
    btnLoader: false,
    additional: 0,
    customerData: null,
    memos: [],
    firstName: "",
    lastName: "",
    phones: [],
    emails: [],
    customerType: "1-Time",
    tags: [],
    kvitel: "",
    addresses: [{ address: "", address2: "", city: "", state: "", zip: "" }],
    id: "",
    modal: false,
    orderArray: [],
    paymentModal: false,
    search: "",
    searchVal: "",
    ordersData: null,
    usersOrders: [],
    type: "cash",
    selectedCard: [],
    creditCards: {
      cardName: "",
      cardNumber: "",
      cardCVV: "",
      cardExpiry: "",
      paymentAmount: "",
    },
    cashAmount: "",
    cashCurrency: "",
    routingNo: "",
    accountNo: "",
    chequeAmount: "",
    accountName: "",
    repeatNo: "",
    paidCurrency: "USD",
    searchDate: "",
    searchedColumn: "",
    totalAmount: 0,
    refNumber: "",
  };
  componentDidMount() {
    this.fetchdata();
  }

  componentWillReceiveProps(nextProps) {
    let data = [];
    let _paidData = [];
    if (this.props.match.params.customerId === "General") {
      this.setState({
        customerType: "General",
        firstName: "",
        lastName: "",
        phones: [],
        emails: [],
        addresses: [],
        id: "",
        memos: [],
      });
    } else {
      nextProps.customerData &&
        this.setState({
          customer: nextProps.customerData.customer,
          firstName: nextProps.customerData.customer.firstName,
          lastName: nextProps.customerData.customer.lastName,
          phones: nextProps.customerData.customer.phones || [],
          emails: nextProps.customerData.customer.emails || [],
          customerType:
            nextProps.customerData.customer.customerType || "1-Time",
          tags: nextProps.customerData.customer.tags,
          kvitel: nextProps.customerData.customer.kvitel,
          addresses:
            nextProps.customerData.customer.addresses || this.state.addresses,
          id: nextProps.customerData.customer.id,
          memos: nextProps.customerData.customer.memo
            ? nextProps.customerData.customer.memo
            : [],
        });
    }
    nextProps.ordersData &&
      nextProps.ordersData.orders.map((ord) => {
        if (ord.customer === this.props.match.params.customerId) {
          data.push(ord);
          const rec = {
            id: ord.id,
            amount: 0,
            total: ord.total,
            orderId: ord.orderId,
          };
          _paidData.push(rec);
        } else if (ord.customerId === this.props.match.params.customerId) {
          data.push(ord);
          const rec = {
            id: ord.id,
            amount: 0,
            total: ord.total,
            orderId: ord.orderId,
          };
          _paidData.push(rec);
        }
      });

    this.setState(
      {
        usersOrders: data,
        paidData: _paidData,
      },
      () => {
        let data = [];
        this.state.usersOrders.map((d) => {
          return d.payments?.map((r) => {
            data.push(r);
          });
        });
        this.setState({ customerPayments: data });
      }
    );
    nextProps.ordersData &&
      this.setState({
        ordersData: nextProps.ordersData.orders,
      });
  }

  fetchdata = async () => {
    const id = this.props.match.params.customerId;
    await this.props.dispatch(getSingleCustomer(id));
    await this.props.dispatch(getOrderData());
    await axiosInstance
      .get("/contacts", { handlerEnabled: true })
      .then((res) => {
        this.setState({ loading: false });
        res.data.contacts.map((d) => {
          const customerId = this.props.match.params.customerId;

          if (d.id === customerId) {
            this.setState({
              cardsData: d.customerCards ? d.customerCards : [],
            });
          }
        });
      });
  };
  updateRecord = (id, key) => {
    var index = this.state.paidData.findIndex((x) => x.id === id);
    let g = this.state.paidData[index];
    g[key] = 0;
    if (index === -1) {
      console.log("no match");
    } else {
      this.setState({
        paidData: [
          ...this.state.paidData.slice(0, index),
          g,
          ...this.state.paidData.slice(index + 1),
        ],
      });
    }
  };
  showModal = () => {
    this.setState({ modal: true });
  };

  showPaymentModal = () => {
    this.setState({ paymentModal: true });
  };
  handlePaymentCancel = () => {
    this.setState({ paymentModal: false });
  };
  handlePaymentModalOk = (currencyTotal = 0) => {
    this.props.form.validateFieldsAndScroll(
      ["cardName", "cardNumber", "cardExpiry", "cardAmount", "cardCVV"],
      (err, values) => {
        if (!err) {
          const data = this.state.paidData
            .filter((r) => r.amount > 0)
            .map((d) => d);
          const card = {
            data: data,
            creditCards: this.state.object,
            card: this.state.creditCards,
            type: this.state.type,
            additional: this.state.additional,
            customer: this.state.customer,
          };
          const cash = {
            cashAmount: this.state.cashAmount,
            cashCurrency: this.state.paidCurrency,
            currencyTotal,
            data: data,
            type: this.state.type,
            additional: this.state.additional,
            customer: this.state.customer,
          };
          const cheque = {
            data: data,
            type: this.state.type,
            additional: this.state.additional,
            accountName: this.state.accountName,
            routingNo: this.state.routingNo,
            accountNo: this.state.accountNo,
            customer: this.state.customer,
          };
          const quickpay = {
            data: data,
            type: this.state.type,
            refNumber: this.state.refNumber,
            customer: this.state.customer,
          };
          const offlinepay = {
            data: data,
            type: this.state.type,
            refNumber: this.state.refNumber,
            customer: this.state.customer,
          };
          const body =
            this.state.type === "card"
              ? card
              : this.state.type === "cash"
              ? cash
              : this.state.type === "cheque"
              ? cheque
              : this.state.type === "quickpay"
              ? quickpay
              : this.state.type === "offlinecard"
              ? offlinepay
              : null;
          this.setState({
            paymentModal: false,
          });
          axiosInstance
            .post("/addpayment", body, { handlerEnabled: true })
            .then((res) => {
              if (res.data.success) {
                message.success("Payment has been done successfully!");
                this.props.form.resetFields();

                this.setState(
                  {
                    paymentModal: false,
                    selectedCard: [],
                    paidData: [],
                    type: "cash",
                    paidCurrency: "USD",
                    customerPayments: [],
                    additional: 0,
                  },
                  () => {
                    this.state.paidData.map((d, i) => {
                      this.updateRecord(i, `amount`);
                    });
                    this.fetchdata();
                  }
                );
              } else {
                message.error("Something went wrong!");
              }
            });
        }
      }
    );
  };

  showConfirm = () => {
    let thiss = this;

    confirm({
      title: `Do you want to delete ${thiss.state.firstName}?`,
      content: "When clicked the OK button, this order will be deleted.",
      onOk() {
        axiosInstance
          .delete(`/contacts/${thiss.state.id}`, {
            handlerEnabled: true,
          })
          .then((res) => {
            message.success("Customer has been deleted successfully!");
            this.props.history.push("/customers");
          })
          .catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  showConfirmDelete = (key) => {
    let confirmThis = this;
    confirm({
      title: `Do you want to delete  order?`,
      content: "When clicked the OK button, this order will be deleted.",
      onOk() {
        axiosInstance
          .delete(`/order/${key}`, { handlerEnabled: true })
          .then((res) => {
            message.success("Order has been deleted successfully!");
            if (res) {
              confirmThis.props.dispatch(getOrderData());
            }
          })
          .catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  handleOk = async (e) => {
    const {
      firstName,
      lastName,
      phones,
      emails,
      customerType,
      tags,
      kvitel,
      addresses,
    } = this.state;
    let customer = {
      firstName,
      lastName,
      phones,
      emails,
      customerType,
      tags,
      kvitel,
      addresses,
    };
    await axiosInstance
      .patch(`/contacts/${this.state.id}`, customer, {
        handlerEnabled: true,
      })
      .then((res) => {
        this.props.dispatch(getContacts());
        this.props.dispatch(getOrderData());
        message.success("Customer Updated");
        this.setState({
          modal: false,
        });
      });
  };

  handleCancel = (e) => {
    this.setState({
      modal: false,
    });
  };

  handleAddress = (key, value, i) => {
    const { addresses } = this.state;
    addresses[0] = { ...addresses[0], [key]: value };
    this.setState({ addresses });
  };
  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    this.setState({
      search: { 0: "" },
      searchVal: geocodedPrediction.formatted_address,
    });
    this.handleAddress("address", geocodedPrediction.formatted_address, 0);
  };

  changeAmount = (id, value) => {
    let state = [...this.state.paidData];
    state[id].amount = value;
    const sumTotal = (arr) =>
      arr?.reduce((sum, { amount }) => sum + amount++, 0);
    this.setState({
      paidData: state,
      totalAmount: sumTotal(state),
    });
  };

  handleSelectedCard = (v) => {
    let data = [];

    let object = JSON.parse(v);

    this.setState(
      {
        object: object,
      },
      () => {
        this.fixCard();
      }
    );
  };

  fixCard = () => {
    let data = [];
    let cardAmount = this.state.selectedCardAmount || 0;
    let amount = "xAuthAmount";
    let object = this.state.object;
    object[amount] = cardAmount;
    data.push(object);
    this.setState({ selectedCard: data });
    //, () => {
    //   store.dispatch(addOrder("selectedCard", this.state.selectedCard));
    // });
  };

  countAmount = (arr) => {
    let d = 0;
    if (arr) {
      arr.length > 0 &&
        arr.reduce((sum, { amount }) => (d = sum + amount++), 0);
    }
    return d;
    // arr.length > 0 &&
    //   arr
    //     .filter((elt, eltIndex) =>
    //       arr.some(
    //         (sameNameElt, sameNameEltIndex) =>
    //           sameNameElt.paymentId === elt.paymentId &&
    //           sameNameEltIndex === eltIndex
    //       )
    //     )
    //     .reduce((sum, { amount }) => sum + amount++, 0);
  };

  sendStatement = () => {
    this.setState({ btnLoader: true });

    let statementData = this.state.usersOrders?.map((arr, i) => ({
      orderId: arr.orderId,
      mainId: arr.id,
      index: i,
      total: arr.total,
      type: arr.type || arr.orderType,
      date: moment(arr.startDate || arr.date).format("MM/DD/YYYY"),
      payments: this.countAmount(arr.payments),
      balance: arr.total - this.countAmount(arr.payments),
    }));
    let rows = [];
    statementData.map((stm) => {
      rows.push({
        orderId: stm.orderId,
        type: stm.type,
        date: stm.date,
        total: stm.total,
        balance: stm.balance,
      });
    });
    const sumTotal = (arr) => arr?.reduce((sum, { total }) => sum + total++, 0);
    const sumPayment = (arr) =>
      arr?.reduce((sum, { payments }) => sum + payments++, 0);

    let totalAmount = sumTotal(statementData);
    let payments = sumPayment(statementData);
    let balanceAmount = totalAmount - payments;

    let data = {
      customer: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.emails[0],
      },
      rows: rows,
      total: totalAmount,
      payments: payments,
      balance: balanceAmount,
    };
    if (data.customer.email) {
      axiosInstance
        .post("/sendstatement", data, { handlerEnabled: true })
        .then((res) => {
          message.success("Statement Sent.");
          this.setState({ btnLoader: false });
        })
        .catch((err) => {
          message.success(err);
          this.setState({ btnLoader: false });
        });
    } else {
      message.error("Customer Do Not have Any Emails in File");
    }
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchDate: selectedKeys,
      searchedColumn: dataIndex,
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <DatePicker.RangePicker
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys}
          onChange={(e) => setSelectedKeys(e)}
          style={{ width: 250, marginBottom: 8, display: "block" }}
        />

        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 110, height: 30, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 110, height: 30 }}
        >
          Reset
        </Button>
      </div>
    ),

    onFilter: (value, record) => {
      const { searchDate } = this.state;
      let from = new Date(moment(searchDate[0]).format("MM/DD/YYYY"));
      let to = new Date(moment(searchDate[1]).format("MM/DD/YYYY"));
      let dateToCheck;
      if (typeof record[dataIndex] === "object") {
        const dt = Object.keys(record[dataIndex]).map((res, i) => {
          return new Date(res);
        });
        const arrivalDate = new Date(Math.min.apply(null, dt));
        const departureDate = new Date(Math.max.apply(null, dt));
        if (arrivalDate <= to && departureDate >= from) {
          return record[dataIndex];
        }
      } else {
        dateToCheck = new Date(moment(record[dataIndex]).format("MM/DD/YYYY"));
        if (dateToCheck <= to && dateToCheck >= from && record[dataIndex]) {
          return record[dataIndex];
        }
      }
    },
  });
  orderConfirm = (status, key, data, content) => {
    let confirmThis = this;
    confirm({
      title: `Confirm Order`,
      content: content,
      okText: "Yes",
      cancelText: "No",
      async onOk() {
        axiosInstance
          .patch(
            `/orderStatus/${key}`,
            { status, order: data },
            { handlerEnabled: true }
          )
          .then(async (res) => {
            confirmThis.props.dispatch(getOrderData());

            message.success(
              `Order has been ${status.toLowerCase()} successfully!`
            );
          })
          .catch(() => console.log("Oops errors!"));
      },
    });
  };

  updateStatus = (orderData, status) => {
    let data = orderData[0];
    // let key = orderData.id;
    const key = data.id;

    if (
      !data.contactPersonName ||
      !data.contactPersonInfo ||
      !data.customer.phones?.length ||
      !data.customer.emails?.length
    ) {
      message.error("Customer Don't Have Contact Information ");
    } else if (data.payments) {
      let totalAmount;
      let dt = [];
      data.payments.map((r) => {
        dt.push(r);
      });
      this.setState({ customerPayments: dt }, () => {
        const sumTotal = (arr) =>
          arr?.reduce((sum, { amount }) => sum + amount++, 0);
        totalAmount = sumTotal(this.state.customerPayments);

        if (totalAmount < data.total && !data.customer.customerCards.length) {
          if (
            this.props.authUser &&
            this.props.authUser.user?.userType === "Admin"
          ) {
            let content =
              "The order is not paid and the customer doesn't have a card on file. Do you wish to continue?";
            this.orderConfirm(status, key, data, content);
          } else {
            message.error("Customer Have Some Payments Remaining");
          }
        } else if (!data.customer.customerCards.length) {
          if (
            this.props.authUser &&
            this.props.authUser.user?.userType === "Admin"
          ) {
            let content =
              "the customer doesn't have a card on file. Do you wish to continue? ";
            this.orderConfirm(status, key, data, content);
          } else {
            message.error("Customer Doesn't Have Cards On File");
          }
        } else {
          let confirmThis = this;
          console.info("SingleOrder.js", { status: status });
          axiosInstance
            .patch(
              `/orderStatus/${key}`,
              { status, order: data },
              { handlerEnabled: true }
            )
            .then(async (res) => {
              confirmThis.props.dispatch(getOrderData());

              message.success(
                `Order has been ${status.toLowerCase()} successfully!`
              );
            })
            .catch(() => console.log("Oops errors!"));
        }
      });
    } else if (!data.payments) {
      if (
        this.props.authUser &&
        this.props.authUser.user?.userType === "Admin"
      ) {
        if (!data.customer.customerCards?.length) {
          let content =
            " The order is not paid and the customer doesn't have a card on file. Do you wish to continue? ";
          this.orderConfirm(status, key, data, content);
        } else {
          let content = " The order is not paid. Do you wish to continue?";
          this.orderConfirm(status, key, data, content);
        }
      } else {
        message.error("Customer Have Some Payments Remaining");
      }
    } else if (!data.customer.customerCards?.length) {
      message.error("Customer Don't Have Cards In File ");
    }
  };

  render() {
    const {
      phones,
      emails,
      firstName,
      lastName,
      addresses,
      customerType,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const iconMap = {
      Foods: <GiMeal fontSize={22} />,
      Lodging: <FaBed fontSize={22} />,
      Donation: <FaMoneyBillAlt fontSize={22} />,
      Shuttle: <FaBusAlt fontSize={22} />,
      Products: <FaShoppingBag fontSize={22} />,
      Mikvah: <FaSwimmingPool fontSize={22} />,
      roomCharges: <FaMoneyBillWaveAlt fontSize={25} />,
    };
    const orderData = this.state.usersOrders?.map((arr, i) => ({
      key: arr.id,
      id: arr.orderId,
      date: arr.dates || arr.orderDate,
      orderType: arr.type,
      status: arr.status || "",
      total: arr.total,
      paid: this.countAmount(arr.payments),
      balance: arr.total - this.countAmount(arr.payments),
    }));
    const remainingData = this.state.usersOrders?.map((arr, i) => ({
      key: arr.id,
      id: {
        orderId: arr.orderId,
        mainId: arr.id,
        index: i,
        total: arr.total,
        paid: this.countAmount(arr.payments),
        remainingAmount: this.countAmount(arr.payments),
      },
      total: arr.total,
    }));
    let currencies = [
      { sym: "$", value: "USD" },
      { sym: "zl", value: "ZlATY" },
      { sym: "₽", value: "RUBL" },
      { sym: "£", value: "POUND" },
      { sym: "₪", value: "SHEKEL" },
      { sym: "₴", value: "HRYVNIA" },
      { sym: "€", value: "EURO" },
    ];
    // const sumTotal = (arr) =>
    //   arr?.reduce((sum, { amount }) => sum + amount++, 0);
    // console.log(this.state.paidData);
    // const totalAmount = sumTotal(this.state.paidData) + this.state.additional;
    let paidCurrencyTotal = handleCountCurrency(
      this.state.totalAmount + this.state.additional,
      this.state.paidCurrency,
      this.props.currency?.data
    );
    const currency = (
      <Select
        defaultValue="USD"
        value={this.state.paidCurrency}
        style={{ width: 80 }}
        onChange={(e) => {
          this.setState({ cashCurrency: e, paidCurrency: e });
        }}
      >
        {currencies.map((currency, i) => (
          <Select.Option value={currency.value} key={i}>
            {currency.sym}
          </Select.Option>
        ))}
      </Select>
    );

    const transactionColumns = [
      {
        title: "Payment ID",
        dataIndex: "id",
        key: "id",
        render: (id) => <a>{id}</a>,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (amount) => <strong>${amount.toLocaleString()}</strong>,
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: (date) => <strong>{date}</strong>,
      },
    ];

    const transactionData =
      this.state.customerPayments.length > 0 &&
      this.state.customerPayments?.map((d, i) => ({
        key: i,
        id: d.paymentId,
        amount: d.amount,
        date: moment(d.date).format("MM/DD/YYYY"),
      }));
    const ordersColumns = [
      {
        title: "Order ID",
        dataIndex: "id",
        key: "id",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.id - b.id,
        render: (id, od) =>
          od.orderType !== "Lodging" ? (
            <Link
              // to="/orders"
              onClick={() =>
                this.props.dispatch(
                  editModal(
                    true,
                    this.state.ordersData?.filter(order => order.id === od.key),
                    od.orderType,
                    true
                  )
                )
              }
            >
              <strong>{id}</strong>
            </Link>
          ) : (
            <strong>
              <Link to={"/orders/" + id}>{id}</Link>
            </strong>
          )
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => {
          let first;
          let second;
          if (typeof a.date === "object") {
            const dt = Object.keys(a.date).map((res, i) => new Date(res));
            first = new Date(Math.min.apply(null, dt));
          } else {
            first = a.date;
          }
          if (typeof b.date === "object") {
            const dt = Object.keys(b.date).map((res, i) => new Date(res));
            second = new Date(Math.min.apply(null, dt));
          } else {
            second = b.date;
          }
          return moment(first).unix() - moment(second).unix();
        },

        render: date => {
          if (typeof date === "object") {
            const dt = date && Object.keys(date).map((res, i) => new Date(res));
            const ldt = new Date(Math.min.apply(null, dt));
            return <strong>{moment(ldt).format("MM/DD/YYYY")}</strong>;
          } else {
            return <strong>{moment(date).format("MM/DD/YYYY")}</strong>;
          }
        },
        ...this.getColumnSearchProps("date")
      },
      {
        title: "Order Type",
        dataIndex: "orderType",
        key: "orderType",
        render: orderType => iconMap[orderType]
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: status => {
          let color = "green";
          const colors = {
            Inquiry: "#f06292",
            Confirmed: "#66bb6a",
            Current: "#03a9f4",
            Past: "grey",
            Closed: "lightgrey"
          };
          Object.keys(colors).forEach(function(clr) {
            if (status === clr) {
              color = colors[clr];
            }
          });
          return status ? (
            <Tag color={color} key={status}>
              {status}
            </Tag>
          ) : (
            ""
          );
        },
        filters: [
          { text: "Inquiry", value: "Inquiry" },
          { text: "Confirmed", value: "Confirmed" },
          { text: "Current", value: "Current" },
          { text: "Past", value: "Past" },
          { text: "Closed", value: "Closed" },
          { text: "Checked in", value: "Checked in" },
          { text: "Checked out", value: "Checked out" },
          { text: "Soft Inquiry", value: "Soft Inquiry" },
          { text: "Draft", value: "draft" }
        ],
        onFilter: (value, record) => record.status?.indexOf(value) === 0
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        render: total => <span>${total}</span>
      },
      {
        title: "Paid",
        dataIndex: "paid",
        key: "paid",
        render: total => <span>${total}</span>
      },
      {
        title: "Balance",
        dataIndex: "balance",
        key: "balance",
        filters: [{ text: "Unpaid", value: "0" }],
        onFilter: (value, record) =>
          record.balance.toString().indexOf(value) !== 0,

        render: balance => <span>${balance}</span>
      },
      {
        title: "Actions",
        dataIndex: "key",
        key: "key",
        render: (key, order) => (
          <div style={{ float: "right" }}>
            {order.status === "Inquiry" ? (
              <Button
                type="default"
                shape="circle"
                icon="check"
                onClick={() =>
                  this.updateStatus(
                    this.state.usersOrders.filter(order => order.id === key),
                    "Confirmed"
                  )
                }
              />
            ) : (
              ""
            )}{" "}
            <Button
              type="primary"
              shape="circle"
              icon="edit"
              onClick={() => {
                this.props.dispatch(
                  editModal(
                    true,
                    this.state.ordersData?.filter(order => order.id === key),
                    order.orderType
                  )
                );
              }}
            />
            <Button
              type="danger"
              shape="circle"
              icon="delete"
              style={{ marginLeft: "5px" }}
              onClick={() => this.showConfirmDelete(key)}
            />
          </div>
        )
      }
    ];
    const paymentColumns = [
      {
        title: "Order ID",
        dataIndex: "id",
        key: "id",
        render: (id) => (
          <strong>
            <Link to={"/orders/" + id.orderId}>{id.orderId}</Link>
          </strong>
        ),
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        render: (total) => <span>${total}</span>,
      },
      {
        title: "Paid",
        dataIndex: "id",
        key: "paid",
        render: (id) => <span>${id.paid}</span>,
      },
      {
        title: "Balance",
        dataIndex: "id",
        key: "balance",
        render: (id) => <span>${id.total - id.remainingAmount}</span>,
      },
      {
        title: "Amount",
        dataIndex: "id",
        key: "amount",
        render: (id) => (
          <InputNumber
            disabled={id.total - id.remainingAmount < 0}
            max={id.total - id.remainingAmount}
            onChange={(e) => {
              this.changeAmount(id.index, e);
            }}
          />
        ),
      },
    ];
    return (
      <div>
        {this.state.id || this.props.match.params.customerId === "General" ? (
          <>
            <Row>
              <Col span={22}>
                <Typography.Title level={3}>
                  {firstName + " " + lastName}
                </Typography.Title>
              </Col>
              <Col span={2}>
                <Button
                  type="danger"
                  shape="round"
                  onClick={this.sendStatement}
                  style={{ marginRight: "auto" }}
                  loading={this.state.btnLoader}
                  disabled={this.props.match.params.customerId === "General"}
                >
                  Send Statement
                </Button>
              </Col>
            </Row>
            <Descriptions bordered column={4}>
              <Descriptions.Item label={<strong>Customer Type</strong>}>
                <span>
                  {" "}
                  {this.props.match.params.customerId === "General"
                    ? "General"
                    : customerType}{" "}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label={<strong>Phone</strong>}>
                {phones ? <strong>{phones.join(",")}</strong> : null}
              </Descriptions.Item>
              <Descriptions.Item label={<strong>Email</strong>}>
                {emails?.map((email, i) => (
                  <a href={`mailto:${email}`} key={i}>
                    {email}
                  </a>
                ))}
              </Descriptions.Item>
              <Descriptions.Item span={0} label={<strong>Address</strong>}>
                {addresses?.map((address, i) => (
                  <div key={i}>
                    <span> {address.address} </span>
                    <br />
                    <span> {address.address2} </span>
                  </div>
                ))}
              </Descriptions.Item>
              {this.props.match.params.customerId === "General" ? (
                ""
              ) : (
                <Descriptions.Item label={<strong>Actions</strong>}>
                  <div style={{ display: "inline-flex" }}>
                    <Button
                      type="primary"
                      shape="circle"
                      icon="edit"
                      onClick={this.showModal}
                      style={{ marginRight: "5px" }}
                    />
                    <Button
                      type="danger"
                      shape="circle"
                      icon="delete"
                      onClick={this.showConfirm}
                      style={{ marginRight: "5px" }}
                    />
                    <CustomerMemo
                      customerId={this.props.match.params.customerId}
                      memos={this.state.memos}
                    />
                  </div>
                </Descriptions.Item>
              )}
            </Descriptions>
          </>
        ) : (
          <Skeleton active />
        )}
        <Row gutter={16} style={{ marginTop: "24px" }}>
          <Col span={16}>
            <Widget>
              <Row>
                <Col span={12}>
                  <Typography.Title level={3}>Customer orders</Typography.Title>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <TransactionModal btnName="New Order" />
                </Col>
              </Row>
              {(this.props.ordersData &&
                this.props.ordersData.orders.length > 0) ||
              this.state.usersOrders.length > 0 ? (
                <Table columns={ordersColumns} dataSource={orderData} />
              ) : (
                <Skeleton active />
              )}
            </Widget>
          </Col>
          <Col span={8}>
            <Widget>
              <Row>
                <Col span={12}>
                  <Typography.Title level={3}>
                    Customer payments
                  </Typography.Title>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    shape="round"
                    onClick={this.showPaymentModal}
                  >
                    New payment
                  </Button>
                  <Modal
                    title="New Payment"
                    visible={this.state.paymentModal}
                    onOk={() => this.handlePaymentModalOk(paidCurrencyTotal)}
                    onCancel={this.handlePaymentCancel}
                  >
                    {this.props.ordersData ? (
                      <div>
                        <Table
                          columns={paymentColumns}
                          dataSource={remainingData}
                        />{" "}
                        <p style={{ textAlign: "center" }}>
                          <strong>
                            Total:{" "}
                            {this.state.type === "cash"
                              ? currencies.map((currency, i) =>
                                  currency.value === this.state.paidCurrency
                                    ? currency.sym
                                    : ""
                                )
                              : "$"}{" "}
                            {this.state.type === "cash"
                              ? paidCurrencyTotal
                              : this.state.totalAmount + this.state.additional}
                          </strong>
                        </p>
                      </div>
                    ) : (
                      <Skeleton active />
                    )}

                    <InputNumber
                      placeholder="Additional amount"
                      onChange={(e) => {
                        this.setState({
                          additional: e,
                        });
                      }}
                      value={this.state.additional}
                    />
                    <Select
                      placeholder="Payment type"
                      defaultValue="cash"
                      style={{ width: 120 }}
                      onChange={(e) => {
                        this.setState({ type: e });
                      }}
                    >
                      <Select.Option value="card">Credit card</Select.Option>
                      <Select.Option value="cash">Cash</Select.Option>
                      <Select.Option value="cheque">cheque</Select.Option>
                      <Select.Option value="quickpay">Quickpay</Select.Option>
                      <Select.Option value="offlinecard">
                        Offline Credit Card
                      </Select.Option>
                    </Select>
                    {this.state.type === "cash" ? currency : ""}
                    <br />
                    <br />
                    {this.state.type === "card" ? (
                      <div>
                        <Form.Item>
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Choose existing card"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            // value={this.state.selectedCard[0]}
                            onChange={this.handleSelectedCard}
                            allowClear
                          >
                            {this.state.cardsData &&
                              this.state.cardsData.length > 0 &&
                              this.state.cardsData.map((d, i) => {
                                return (
                                  <Select.Option
                                    value={JSON.stringify(d)}
                                    key={i}
                                  >
                                    {d.cardEnding.substr(12, 4)}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                        <Form.Item>
                          {getFieldDecorator("cardName", {
                            initialValue: this.state.creditCards.cardName,
                            value: this.state.creditCards.cardName,
                            rules: [
                              {
                                required:
                                  this.state.selectedCard.length > 0
                                    ? false
                                    : true,
                                message: "Please enter card name!",
                              },
                            ],
                          })(
                            <Input
                              placeholder="Name on card"
                              onChange={(e) => {
                                this.setState({
                                  creditCards: {
                                    ...this.state.creditCards,
                                    cardName: e.target.value,
                                  },
                                });
                              }}
                            />
                          )}
                        </Form.Item>
                        <Form.Item>
                          {getFieldDecorator("cardNumber", {
                            rules: [
                              {
                                required:
                                  this.state.selectedCard.length > 0
                                    ? false
                                    : true,
                                message: "Please enter card number!",
                              },
                              {
                                min: 15,
                                message:
                                  "Card number must be minimum 15 characters long.",
                              },
                              {
                                max: 16,
                                message:
                                  "Card number must be maximum 16 characters long.",
                              },
                            ],
                          })(
                            <Input
                              value={this.state.creditCards.cardNumber}
                              placeholder="Card number"
                              onChange={(e) => {
                                this.setState({
                                  creditCards: {
                                    ...this.state.creditCards,
                                    cardNumber: e.target.value,
                                  },
                                });
                              }}
                            />
                          )}
                        </Form.Item>
                        <Row>
                          <Col span={12}>
                            <Form.Item>
                              {getFieldDecorator("cardExpiry", {
                                rules: [
                                  {
                                    required:
                                      this.state.selectedCard.length > 0
                                        ? false
                                        : true,
                                    message: "Please enter card expiry!",
                                  },
                                ],
                              })(
                                <DatePicker.MonthPicker
                                  value={this.state.creditCards.cardExpiry}
                                  placeholder="Exp."
                                  format="MMYY"
                                  onChange={(e) => {
                                    this.setState({
                                      creditCards: {
                                        ...this.state.creditCards,
                                        cardExpiry: e,
                                      },
                                    });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item>
                              {getFieldDecorator("cardCVV", {
                                rules: [
                                  {
                                    required:
                                      this.state.selectedCard.length > 0
                                        ? false
                                        : true,
                                    message: "Please enter cvv!",
                                  },
                                ],
                              })(
                                <Input
                                  placeholder="CCV"
                                  value={this.state.creditCards.cardCVV}
                                  onChange={(e) => {
                                    this.setState({
                                      creditCards: {
                                        ...this.state.creditCards,
                                        cardCVV: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>

                        <hr />
                      </div>
                    ) : this.state.type === "cheque" ? (
                      <div>
                        <Form.Item>
                          <Input
                            placeholder="Account name"
                            value={this.state.accountName}
                            onChange={(e) =>
                              this.setState({ accountName: e.target.value })
                            }
                          />
                        </Form.Item>
                        <Form.Item>
                          <Input
                            placeholder="Routing number"
                            value={this.state.routingNo}
                            onChange={(e) =>
                              this.setState({
                                routingNo: e.target.value,
                              })
                            }
                          />
                          <Row>
                            <Col span={12}>
                              <Input
                                placeholder="Account number"
                                value={this.state.accountNo}
                                onChange={(e) =>
                                  this.setState({
                                    accountNo: e.target.value,
                                  })
                                }
                              />
                            </Col>
                            <Col span={12}>
                              <Input
                                placeholder="Repeat account number"
                                value={this.state.repeatNo}
                                onChange={(e) =>
                                  this.setState({
                                    repeatNo: e.target.value,
                                  })
                                }
                              />
                            </Col>
                          </Row>
                        </Form.Item>
                        <Form.Item>
                          <Input
                            disabled
                            size="large"
                            value={
                              this.state.totalAmount + this.state.additional
                            }
                          />
                        </Form.Item>
                        <hr />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.type === "quickpay" ? (
                      <Form.Item label="Reference number">
                        {getFieldDecorator("ref Number", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter Reference number!",
                            },
                          ],
                        })(
                          <Input
                            placeholder="Reference number"
                            onChange={(e) =>
                              this.setState({ refNumber: e.target.value })
                            }
                          />
                        )}
                      </Form.Item>
                    ) : null}
                    {this.state.type === "offlinecard" ? (
                      <>
                        <Form.Item label="Reference number">
                          {getFieldDecorator("card ref Number", {
                            rules: [
                              {
                                required: true,
                                message: "Please enter Reference number!",
                              },
                            ],
                          })(
                            <Input
                              placeholder="Reference number"
                              onChange={(e) =>
                                this.setState({ refNumber: e.target.value })
                              }
                            />
                          )}
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]}>
                          {getFieldDecorator("offcardAmount", {
                            rules: [
                              {
                                required: true,
                                message: "Please enter amount!",
                              },
                            ],
                          })(
                            <Input.Search
                              // defaultValue="$750"
                              enterButton="Process"
                              // onSearch={() => this.handleCard(card)}
                              size="large"
                              onChange={(e) => {
                                this.setState({ cardAmount: e.target.value });
                              }}
                            />
                          )}
                        </Form.Item>
                      </>
                    ) : null}
                  </Modal>
                </Col>
              </Row>
              {this.state.customerPayments?.length > 0 ? (
                <Table
                  columns={transactionColumns}
                  dataSource={transactionData}
                />
              ) : (
                <Skeleton active />
              )}
            </Widget>
          </Col>
          <CustEditOrder />
        </Row>
        <Modal
          title="Edit Customer"
          visible={this.state.modal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={1290}
          okText="Update"
        >
          <Form>
            <Row>
              <Col span={24}>
                <Typography.Title level={3}>
                  Customer Information
                </Typography.Title>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Input
                    placeholder="First name"
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                    value={this.state.firstName}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Input
                    placeholder="Last name"
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                    value={this.state.lastName}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Select
                    mode="multiple"
                    placeholder="Please select tags"
                    onChange={(e) => this.setState({ tags: e })}
                    style={{ width: "100%" }}
                    value={this.state.tags}
                  >
                    <Select.Option key={1} value="Reguler">
                      Reguler
                    </Select.Option>
                    <Select.Option key={2} value="VIP">
                      VIP
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Select
                    placeholder="Select Customer Type"
                    onChange={(e) => this.setState({ customerType: e })}
                    defaultValue={this.state.customerType}
                    style={{ width: "100%" }}
                  >
                    <Select.Option key={1} value="1-Time">
                      1-Time
                    </Select.Option>
                    <Select.Option key={2} value="Recurring">
                      Recurring
                    </Select.Option>
                    <Select.Option key={3} value="Travel agent">
                      Travel agent
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ReactPhoneInput
                      country={"us"}
                      inputClass="phoneInputStyle"
                      buttonClass="phoneDropDown2"
                      value={phones && phones[0]}
                      onChange={(value, country, e, v) => {
                        phones.length > 0 ? (phones[0] = v) : phones.push(v);

                        this.setState({ phones });
                      }}
                    />
                    <Button
                      type="primary"
                      style={{
                        borderBottomLeftRadius: "0px !important",
                        borderTopLeftRadius: "0px !important",
                      }}
                      onClick={() => {
                        let a = phones;
                        a.push("");
                        this.setState({ phones });
                      }}
                    >
                      Add Phone
                    </Button>
                  </div>

                  {phones &&
                    phones.map((phone, i) =>
                      i > 0 ? (
                        <ReactPhoneInput
                          country={"us"}
                          key={i}
                          inputClass="phoneInputStyle"
                          buttonClass="phoneDropDown2"
                          value={phones && phones[0]}
                          onChange={(value, country, e, v) => {
                            phones[i] = v;
                            this.setState({ phones });
                          }}
                        />
                      ) : (
                        ""
                      )
                    )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  <Input.Search
                    type="email"
                    placeholder="Email"
                    enterButton="Add email"
                    defaultValue={emails[0]}
                    onChange={(v) => {
                      emails.length > 0
                        ? (emails[0] = v.target.value)
                        : emails.push(v.target.value);

                      this.setState({ emails });
                    }}
                    onSearch={(v) => {
                      emails.push("");
                      this.setState({ emails });
                    }}
                  />
                  {emails &&
                    emails.map((email, i) =>
                      i > 0 ? (
                        <Input
                          key={i}
                          type="tel"
                          value={email || ""}
                          onChange={(e) => {
                            emails[i] = e.target.value;
                            this.setState({
                              emails: emails,
                            });
                          }}
                        />
                      ) : (
                        ""
                      )
                    )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  <ReactGoogleMapLoader
                    params={{
                      key: KEY.MAP_KEY,
                      libraries: "places,geocode",
                    }}
                    render={(googleMaps) =>
                      googleMaps && (
                        <ReactGooglePlacesSuggest
                          googleMaps={googleMaps}
                          autocompletionRequest={{
                            input: this.state.search[0],
                          }}
                          onNoResult={this.handleNoResult}
                          onSelectSuggest={this.handleSelectSuggest}
                          onStatusUpdate={this.handleStatusUpdate}
                          textNoResults="No Address Found"
                          customRender={(prediction) => (
                            <div className="customWrapper">
                              {prediction
                                ? prediction.description
                                : "No Address Found"}
                            </div>
                          )}
                        >
                          <Form.Item>
                            <Input
                              placeholder={"Address "}
                              value={this.state.addresses?.[0].address}
                              onChange={(e) => {
                                this.handleAddress(
                                  "address",
                                  e.target.value,
                                  0
                                );
                                this.setState({
                                  search: { 0: e.target.value },
                                });
                              }}
                            />
                          </Form.Item>
                        </ReactGooglePlacesSuggest>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={24} label="kvitel">
                <Form.Item>
                  <TextArea
                    allowClear
                    placeholder="Enter kvitel"
                    onChange={(e) => this.setState({ kvitel: e.target.value })}
                    rows={5}
                    value={this.state.kvitel}
                  />
                </Form.Item>
              </Col>
            </Row>{" "}
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ orderReducer }) => {
  return {
    customerData: orderReducer.cust,
    ordersData: orderReducer.getOrderData,
    currency: orderReducer.currency,
    authUser: orderReducer.authUser,
  };
};

const Customer = Form.create({})(SingleCustomer);

export default connect(mapStateToProps)(Customer);
