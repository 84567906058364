import { Typography } from "antd";
import React, { Component } from "react";
import AvailabilityCalendar from "../../components/AvailabilityCalendar/AvailabilityCalendar";

export default class RoomAvailability extends Component {
  render() {
    return (
      <div>
        <Typography.Title level={3}>
          Room Availability Calendar
        </Typography.Title>
        <AvailabilityCalendar />
      </div>
    );
  }
}
