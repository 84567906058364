import React from "react";
// prettier-ignore
import { Row, Col,  Typography, DatePicker, Collapse, TimePicker, Form, Input, Skeleton, Button } from "antd";

// import { WarningOutlined } from "@ant-design/icons";
import moment from "moment";
import { connect } from "react-redux";
import { addOrder, getOrderData } from "../../actions/orderActionCreator";
import DatePanel from "./DatePanel";
import OrderCost from "./OrderCost";
import { cloneDeep, times, sortBy } from "lodash";
import { store } from "../../store";
import { fromatOrder } from "./_calc";
import { withRouter } from "react-router-dom";
class NewOrderForm extends React.Component {
  state = {
    loading: true,
    chosenDates: {},
    cost: {
      food: {},
      lodging: {
        regular: 0,
        couple: 0,
        private: 0,
        pref: {},
      },
    },
    total: 0,
    breakfast: {},
    lunch: {},
    dinner: {},
    seudes: {},
    filledRooms: [],
    booked: null,
    datas: null,
    prdata: null,
    contactPersonName: "",
    contactPersonInfo: "",
  };
  formattedData = {};

  isLoading = (loading) => this.setState({ loading });

  updateState = (newState) => {
    console.info("NewOrderForm.js", {
      newSTate: newState,
      state: this.state.cost,
    });
    const orderStatus = [
      "Confirmed",
      "Current",
      "Checked in",
      "Checked out",
      "Past",
      "Closed",
    ].includes(this.props.orderData && this.props.orderData[0].status);
    const state = { ...this.state, ...newState };
    this.formattedData = fromatOrder({
      chosenDates: state.chosenDates,
      cost: this.state.cost,
      variant: this.props.variant,
      order: this.props.orderData && this.props.orderData[0],
    });
    state.total = this.formattedData.total;
    this.setState(state, () => {
      store.dispatch(addOrder("dates", this.state.chosenDates));
      store.dispatch(addOrder("total", this.state.total));
    });
  };
  // componentWillReceiveProps(nextProps) {
  //   nextProps &&
  //     nextProps.roomPrice.map((d) => {
  //       this.setState({
  //         cost: {
  //           lodging: {
  //             ...this.state.cost.lodging,
  //             regular: d.regular,
  //             couple: d.couple,
  //           },
  //         },
  //       });
  //     });
  // }
  async componentDidMount() {
    this.isLoading(true);
    await this.props.dispatch(getOrderData());
    let rooms = [];
    this.props.allOrders &&
      this.props.allOrders.orders.map((order) => {
        rooms.push(order.dates);
      });
    this.props.roomPrice &&
      this.props.roomPrice.map((d) => {
        this.setState({
          cost: {
            lodging: {
              ...this.state.cost.lodging,
              regular: d.regular,
              couple: d.couple,
              private: d.private,
            },
          },
        });
      });
    let pref = {};
    this.props.preferences.map((r) => {
      pref[r.name] = r.price;
    });
    this.setState({ cost: { lodging: { ...this.state.cost.lodging, pref } } });

    this.setState({ filledRooms: rooms });
    this.props.foodPrice.map((p) => {
      if (p.id === "breakfast") {
        this.setState({ breakfast: p });
      } else if (p.id === "lunch") {
        this.setState({ lunch: p });
      } else if (p.id === "dinner") {
        this.setState({ dinner: p });
      } else if (p.id === "seudes") {
        this.setState({ seudes: p });
      }
    });
    const breakfastData = this.props.foodPreferences.breakfast;
    const lunchData = this.props.foodPreferences.lunch;
    const dinnerData = this.props.foodPreferences.dinner;
    const seudesData = this.props.foodPreferences.seudes;

    let obj = {
      breakfest: {
        pref: {},
      },
      lunch: {
        pref: {},
      },
      dinner: { pref: {} },
      seuda: { pref: {} },
    };
    breakfastData.map((r) => {
      obj.breakfest.pref[r.name] = r.price;
    });
    lunchData.map((r) => {
      obj.lunch.pref[r.name] = r.price;
    });
    dinnerData.map((r) => {
      obj.dinner.pref[r.name] = r.price;
    });
    seudesData.map((r) => {
      obj.seuda.pref[r.name] = r.price;
    });

    obj.breakfest.reg = this.state.breakfast ? this.state.breakfast.regular : 0;
    obj.breakfest.vip = this.state.breakfast.vip;
    obj.lunch.reg = this.state.lunch.regular;
    obj.lunch.vip = this.state.lunch.vip;
    obj.dinner.reg = this.state.dinner.regular;
    obj.dinner.vip = this.state.dinner.vip;
    obj.seuda.reg = this.state.seudes.regular;
    obj.seuda.vip = this.state.seudes.vip;

    await this.setState({
      cost: {
        ...this.state.cost,
        food: obj,
      },
    });

    const { orderData, visible, dates } = this.props;
    (await dates) && this.setState({ chosenDates: dates });

    await this.updateState({
      ...(visible && { chosenDates: orderData[0].dates }),
    });
    this.isLoading(false);
  }

  onTimeChange = async (date, dateString) => {
    const startDate = new Date(dateString[0]);
    const endDate = new Date(dateString[1]);
    console.info("NewOrderForm.js", { startDate: startDate, endDate: endDate });
    store.dispatch(addOrder("startDate", startDate));
    store.dispatch(addOrder("lastDate", endDate));

    const getDateArray = (start, end) => {
      const dateObject = {};
      const thisDate = new Date(start);

      while (thisDate <= end) {
        const date = new Date(thisDate).toDateString();

        dateObject[date] = {
          food: {
            breakfast: { people: 0, time: "", VIP: false },
            lunch: { people: 0, time: "", VIP: false, preferences: [] },
            dinner: { people: 0, time: "", VIP: false, preferences: [] },
            seudes: { people: 0, VIP: false },
          },
          visitors: [],
          placement: { privateRooms: 0, regulerBeds: 0, dormBeds: 0 },
          people: {
            couple: 0,
            men: 0,
            woman: 0,
            boys: 0,
            girls: 0,
            babies: 0,
            private: 0,
          },
          rooms: [],
          room: [],
          discount: 0,
        };
        thisDate.setDate(thisDate.getDate() + 1);
      }
      return dateObject;
    };

    this.updateState({ chosenDates: getDateArray(startDate, endDate) });
  };

  checkVIP = async (checked, meal, date) => {
    const newDates = { ...this.state.chosenDates };
    newDates[date].food.foodVIP[meal] = checked;
    this.updateState({ chosenDates: newDates });
  };

  addFood = async (date, qty, type) => {
    const newDates = { ...this.state.chosenDates };
    newDates[date].food[type].people = qty;
    this.updateState({ chosenDates: newDates });
  };

  addFoodTime = async (date, time, type) => {
    const newDates = { ...this.state.chosenDates };
    newDates[date].food[type].time = time;
    this.updateState({ chosenDates: newDates });
  };

  addFoodVIP = async (date, checked, type) => {
    const newDates = { ...this.state.chosenDates };
    newDates[date].food[type].VIP = checked;
    this.updateState({ chosenDates: newDates });
  };

  addFoodPreferences = async (date, preferences, type) => {
    const newDates = { ...this.state.chosenDates };
    var temp = [...preferences];
    newDates[date].food[type].preferences = temp;
    this.updateState({ chosenDates: newDates });
  };

  addRooms = async (date, room, key, pref, roomData) => {
    let res = [];
    let res1 = [];
    let res3 = [];
    let res4 = [];
    roomData.forEach((room) => room.rooms.forEach((rm) => res.push(rm)));

    pref.forEach((prt) => res1.push(prt));
    res.forEach((rs) => res3.push(rs.preferences));

    res1.forEach((rs3, i) =>
      res.forEach((rs, j) =>
        rs.preferences?.forEach((prf) =>
          rs3.id === prf ? res4.push({ room: rs.name, pref: rs3 }) : null
        )
      )
    );
    let grouped = res4.reduce(function (r, a) {
      r[a.room] = r[a.room] || [];
      r[a.room].push(a);
      return r;
    }, []);
    let prefrencesData = [];

    grouped[room].forEach((grp) => prefrencesData.push(grp.pref.name));

    const newDates = { ...this.state.chosenDates };
    const copyDate = date.split(" ")[0] === "Fri";
    const keys = Object.keys(newDates);
    let nextIndex = keys.indexOf(date) + 1;
    let nextItem = keys[nextIndex];
    newDates[date].room[key].room = room;
    copyDate
      ? (newDates[nextItem].room = cloneDeep(newDates[date].room))
      : (newDates[date].room[key].room = room);
    newDates[date].room[key].preferences = prefrencesData;

    this.updateState({ chosenDates: newDates, prdata: prefrencesData });
  };

  addRoomsPreferences = async (date, rPrefre, key) => {
    const newDates = { ...this.state.chosenDates };
    const copyDate = date.split(" ")[0] === "Fri";
    const keys = Object.keys(newDates);
    let nextIndex = keys.indexOf(date) + 1;
    let nextItem = keys[nextIndex];
    // newDates[date].room[key].preferences = temp;
    copyDate
      ? (newDates[nextItem].room = cloneDeep(newDates[date].room))
      : this.setState({ pdata: this.state.prefrencesData });
    this.updateState({ chosenDates: newDates });
  };

  updatePeopleQTY = async (qty, type, date) => {
    const newDates = { ...this.state.chosenDates };
    const copyDate = date.split(" ")[0] === "Fri";
    let d = new Date(date);
    let nextItem = moment(d.setDate(d.getDate() + 1)).format("ddd MMM DD YYYY");
    newDates[date].people[type] = qty;
    this.roomPlacement(date);
    if (copyDate) {
      const people = newDates[date].people;
      const totalPeople =
        people.couple * 2 +
        people.private * 2 +
        people.men +
        people.woman +
        people.boys +
        people.girls;
      newDates[nextItem]["food"].seudes = {
        ...newDates[nextItem]["food"].seudes,
        people: totalPeople,
      };
    }
    copyDate
      ? (newDates[nextItem].people = cloneDeep(newDates[date].people)) &&
        (newDates[nextItem].room = cloneDeep(newDates[date].room))
      : (newDates[date].people[type] = qty);
    // newDates[date].people[type] = qty;
    this.updateState({ chosenDates: newDates });
  };

  updateRoomsQTY = async (qty, type, date) => {
    const newDates = { ...this.state.chosenDates };
    const copyDate = date.split(" ")[0] === "Fri";
    const keys = Object.keys(newDates);
    let nextIndex = keys.indexOf(date) + 1;
    let nextItem = keys[nextIndex];
    newDates[date].placement[type] = qty;
    if (type === "dormBeds") {
      newDates[date].discount = newDates[date].placement.dormBeds * 5;
    }

    this.updateState({ chosenDates: newDates });
  };

  roomPlacement = async (date) => {
    const people = this.state.chosenDates[date].people;
    const totalPeople =
      people.couple * 2 +
      people.private * 2 +
      people.men +
      people.woman +
      people.boys +
      people.girls;
    const rooms = [];
    const room = [];
    let roomsCount = 0;

    for (let i = 0; i < totalPeople; i++) {
      if (i % 2 === 0) {
        roomsCount++;
        rooms.push(roomsCount);
        room.push({ room: "", preferences: [] });
      }
    }

    const newDates = { ...this.state.chosenDates };
    newDates[date].rooms = rooms;
    newDates[date].room = room;
    this.updateState({ chosenDates: newDates });
  };

  applyToDates = async (date, key) => {
    const { chosenDates } = this.state;
    let totalPeople;
    Object.keys(chosenDates).forEach((res, i) => {
      if (["Fri"].includes(res.split(" ")[0])) {
        (chosenDates[res].food.breakfast = cloneDeep(
          chosenDates[date].food.breakfast
        )) &&
          (chosenDates[res].food.lunch = cloneDeep(
            chosenDates[date].food.lunch
          )) &&
          // (chosenDates[res].people = cloneDeep(chosenDates[date].people)) &&
          (chosenDates[res].room = cloneDeep(chosenDates[date].room)) &&
          (chosenDates[res].visitors = cloneDeep(chosenDates[date].visitors)) &&
          (chosenDates[res].placement = cloneDeep(
            chosenDates[date].placement
          )) &&
          (chosenDates[res].rooms = cloneDeep(chosenDates[date].rooms));
      }
      // &&
      // (chosenDates[res].room.preferences =
      //   chosenDates[date].room.preferences)
      if (["Sat"].includes(res.split(" ")[0])) {
        const newDates = { ...this.state.chosenDates };

        const keys = Object.keys(newDates);
        let nextIndex = keys.indexOf(res) - 1;
        let nextItem = keys[nextIndex];
        const people = newDates[nextItem].people;
        totalPeople =
          people.couple * 2 +
          people.private * 2 +
          people.men +
          people.woman +
          people.boys +
          people.girls;

        (chosenDates[res].food.breakfast = {
          people: 0,
          time: "",
          VIP: false,
        }) &&
          (chosenDates[res].food.lunch = {
            people: 0,
            time: "",
            VIP: false,
            preferences: [],
          }) &&
          (chosenDates[res].food.dinner = {
            people: 0,
            time: "",
            VIP: false,
            preferences: [],
          }) &&
          (chosenDates[res].food.suedes = {
            people: 0,
            VIP: false,
          }) &&
          // (chosenDates[res].people = cloneDeep(chosenDates[date].people)) &&
          (chosenDates[res].room = cloneDeep(chosenDates[date].room)) &&
          (chosenDates[res].placement = cloneDeep(
            chosenDates[date].placement
          )) &&
          (chosenDates[res].food.seudes = cloneDeep(chosenDates[date].seudes));
      }

      let dateArray = sortBy(Object.keys(chosenDates), (o) => new moment(o));
      let lastDate = dateArray[dateArray.length - 1];
      // console.log(lastDate, lastDate === res);

      if (lastDate === res) {
        (chosenDates[res].food.breakfast = cloneDeep(
          chosenDates[date].food.breakfast
        )) &&
          (chosenDates[res].food.lunch = cloneDeep(
            chosenDates[date].food.lunch
          )) &&
          (chosenDates[res].food.dinner = cloneDeep(
            chosenDates[date].food.dinner
          ));
      } else {
        console.log(res);
        chosenDates[res] = cloneDeep(chosenDates[date]);
      }

      if (["Sat"].includes(res.split(" ")[0])) {
        chosenDates[res].food.seudes = {
          ...chosenDates[res].food.seudes,
          people: totalPeople,
        };

        chosenDates[res].discount = 0;
      }
    });

    this.updateState({ chosenDates });
  };

  addVisitors = async (date, type, qty) => {
    const newDates = { ...this.state.chosenDates };
    const data = { type: type, room: "", placed: false };
    let removeType = newDates[date].visitors.filter((obj) => {
      return obj.type !== type;
    });
    newDates[date].visitors = removeType;

    times(qty, () => newDates[date].visitors.push(data));

    this.updateState({ chosenDates: newDates });
  };

  handleChange = async (key, e) => {
    store.dispatch(addOrder(key, e));
    store.dispatch(addOrder("total", this.state.total));
    this.updateState(this.state);
  };

  handleChange2 = (key, e) => {
    store.dispatch(addOrder(key, e));
  };

  calculateCosts = (entity, cost, isVip = false, preferences = []) => {
    if (!entity) return 0;
    const { pref, vip, reg } = cost;
    return preferences.length < 1
      ? (isVip ? vip : reg) * entity
      : preferences
          .map((item) => (isVip ? vip : reg) * entity + pref[item])
          .reduce((p, c) => p + c, 0);
  };
  handleCustomer = () => {
    const { orderData, customer } = this.props;
    const selectedCustomer =
      (this.props.match && this.props.match.params.customerId) ||
      (customer && customer.id) ||
      (orderData && orderData[0].customerId);
    const test = this.props.contactsData?.map((d, i) => {
      if (d.id === selectedCustomer) {
        this.setState({ contactPersonName: d.firstName + " " + d.lastName });
        this.setState({ contactPersonInfo: d.phones ? d.phones[0] : "" });
        this.handleChange2("contactPersonName", d.firstName + " " + d.lastName);
        this.handleChange2("contactPersonInfo", d.phones ? d.phones[0] : "");
      }
      return;
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { chosenDates, loading } = this.state;
    const {
      orderData,
      note,
      visible,
      contactPersonName,
      contactPersonInfo,
    } = this.props;
    if (loading) return <Skeleton active />;

    const date = visible
      ? orderData && orderData[0].dates
      : this.state.chosenDates;
    const dt = Object.keys(date).map((res) => new Date(res));

    const arrivalDate =
      dt.length > 0 ? new Date(Math.min.apply(null, dt)) : new Date();
    const departureDate =
      dt.length > 0 ? new Date(Math.max.apply(null, dt)) : new Date();
    let contactName =
      (contactPersonName && contactPersonName) ||
      (orderData && orderData[0].contactPersonName) ||
      (this.state.contactPersonName && this.state.contactPersonName);

    return (
      <div>
        {this.props.match.params.customerId ? (
          <Row gutter={16}>
            <Col span={12}>
              <Typography.Title level={3}>
                Order contact person
              </Typography.Title>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button type="primary" onClick={() => this.handleCustomer()}>
                  Order Contact is Customer
                </Button>{" "}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator("name", {
                  initialValue: contactName,
                })(
                  <Input
                    placeholder="Name"
                    onChange={(e) =>
                      this.handleChange2("contactPersonName", e.target.value)
                    }
                    // value={contactName && contactName}
                    // value={contactPersonName && contactPersonName}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator("contactinfo", {
                  initialValue:
                    this.state.contactPersonInfo ||
                    (contactPersonInfo && contactPersonInfo) ||
                    (orderData && orderData[0].contactPersonInfo),
                })(
                  <Input
                    placeholder="Contact info"
                    onChange={(e) =>
                      this.handleChange2("contactPersonInfo", e.target.value)
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        ) : null}
        <Typography.Title level={3}>Choose Dates</Typography.Title>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item rules={[{ required: true }]}>
              {getFieldDecorator("dates", {
                initialValue: [moment(arrivalDate), moment(departureDate)],
                rules: [
                  {
                    required: true,
                    message: "Please select dates!",
                  },
                ],
              })(
                <DatePicker.RangePicker
                  size="large"
                  format="MM/DD/YYYY"
                  separator="to"
                  // value={
                  //   visible ? [moment(arrivalDate), moment(departureDate)] : ""
                  // }
                  onChange={this.onTimeChange}
                  style={{ marginBottom: "16px" }}
                />
              )}
            </Form.Item>
            <br />
            <Form.Item rules={[{ required: true }]}>
              {getFieldDecorator("arrival", {
                initialValue:
                  visible && orderData && orderData[0].arrivalTime
                    ? moment(orderData && orderData[0].arrivalTime, "h:mm a")
                    : moment("16:00", "h:mm a"),
                rules: [
                  {
                    required: true,
                    message: "Please select time!",
                  },
                ],
              })(
                <TimePicker
                  size="large"
                  placeholder="Arrival"
                  format="h:mm a"
                  use12Hours
                  // defaultValue={moment("16:00", "h:mm a")}
                  minuteStep={15}
                  // value={
                  //   (arrivalTime && arrivalTime) ||
                  //   (visible &&
                  //     orderData &&
                  //     moment(orderData && orderData[0].arrivalTime, "h:mm a"))
                  // }
                  onChange={(e) => this.handleChange("arrivalTime", e)}
                />
              )}
              {getFieldDecorator("departure", {
                initialValue:
                  visible && orderData && orderData[0].departureTime
                    ? moment(orderData && orderData[0].departureTime, "h:mm a")
                    : moment("11:00", "h:mm a"),
                rules: [
                  {
                    required: false,
                    message: "Please select time!",
                  },
                ],
              })(
                <TimePicker
                  size="large"
                  placeholder="Departure"
                  format="h:mm a"
                  use12Hours
                  defaultOpenValue={moment("11:00", "h:mm a")}
                  minuteStep={15}
                  // value={
                  //   (departureTime && departureTime) ||
                  //   (orderData &&
                  //     moment(orderData && orderData[0].departureTime, "h:mm a"))
                  // }
                  onChange={(e) => this.handleChange("departureTime", e)}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input.TextArea
                rows={4}
                placeholder="Order notes"
                defaultValue={
                  (note && note) || (orderData && orderData[0].note)
                }
                onChange={(e) => this.handleChange("note", e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col span={16}>
            {Object.keys(chosenDates).length === 0 &&
            chosenDates.constructor === Object ? null : (
              <Collapse defaultActiveKey="0">
                {Object.keys(chosenDates)
                  .sort((a, b) => {
                    var keyA = new Date(a);
                    var keyB = new Date(b);
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                  })
                  .map((currentDate, i) => {
                    // console.info("NewOrderForm.js", {
                    //   currentDate: currentDate,
                    // });
                    return (
                      <Collapse.Panel
                        header={currentDate}
                        key={i}
                        style={{
                          backgroundColor:
                            currentDate.split(" ")[0] !== "Sat"
                              ? "#fafafa"
                              : "#c5e8d7",
                        }}
                      >
                        <DatePanel
                          form={this.props.form}
                          data={this.props.dates}
                          updatePeopleQTY={this.updatePeopleQTY}
                          date={currentDate}
                          food={chosenDates}
                          addFood={this.addFood}
                          addFoodTime={this.addFoodTime}
                          addFoodVIP={this.addFoodVIP}
                          addFoodPreferences={this.addFoodPreferences}
                          addRoomsPreferences={this.addRoomsPreferences}
                          addVisitors={this.addVisitors}
                          addRooms={this.addRooms}
                          updateRoomsQTY={this.updateRoomsQTY}
                          applyToDates={this.applyToDates}
                          pdata={this.state.pdata}
                          index={i}
                          isLast={
                            visible
                              ? currentDate === departureDate.toDateString()
                              : i === Object.keys(chosenDates).length - 1
                          }
                        />
                      </Collapse.Panel>
                    );
                  })}
              </Collapse>
            )}
          </Col>
          <Col span={8}>
            {Object.keys(chosenDates).length === 0 &&
            this.state.chosenDates.constructor === Object ? null : (
              <OrderCost
                {...{ ...this.formattedData }}
                total={this.state.total}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

// const mapDispatchToProps = dispatch =>
//   bindActionCreators({ addOrder }, dispatch);

const mapStateToProps = ({ orderReducer }) => ({
  orderData: orderReducer.orderData,
  allOrders: orderReducer.getOrderData,
  visible: orderReducer.visible,
  arrivalTime: orderReducer.arrivalTime,
  departureTime: orderReducer.departureTime,
  note: orderReducer.note,
  dates: orderReducer.dates,
  customer: orderReducer.customer,
  roomPrice: orderReducer.cost,
  preferences: orderReducer.preferences,
  foodPrice: orderReducer.foodPrices,
  foodPreferences: orderReducer.foodPreferences,
  contactsData: orderReducer.contacts,
});

export default connect(mapStateToProps)(withRouter(NewOrderForm));
