import React from "react";
// prettier-ignore
import { Row, Col, Tabs, DatePicker, Form, Input, Button, Skeleton, message, Select, InputNumber, Switch } from "antd";
import { connect } from "react-redux";
import {
  addOrder,
  holdReleseHandler,
  paymentTypePayNow
} from "../../actions/orderActionCreator";
import { store } from "../../store";
import OrderCost from "./OrderCost";
import { axiosInstance } from "../../httpHandler";
import { fromatOrder } from "./_calc";
import { withRouter } from "react-router-dom";
import PaymentMethods from "./PaymentMethods";

class PaymentForm extends React.Component {
  state = {
    cards: [0],
    cash: [0],
    check: [0],
    chosenDates: {},
    // cost: {},
    loading: true,
    total: 0,
    cost: {
      food: {},
      lodging: {
        regular: 0,
        couple: 0,
        private: 0,
        pref: {}
      }
    },
    breakfast: {},
    lunch: {},
    dinner: {},
    seudes: {},
    cardsData: [],
    selectedCard: [],
    creditCards: [
      {
        cardName: "",
        cardNumber: "",
        cardCVV: "",
        cardExpiry: "",
        paymentAmount: ""
      }
    ],
    discount: 0,
    sunTotal: 0
  };
  formattedData = {};

  currencies = ["$", "?", "€", "?", "zl", "Ft"];

  isLoading = loading => this.setState({ loading });

  async componentDidMount() {
    this.isLoading(true);
    const { orderData, visible, dates, totalCost } = this.props;
    let discountTotal = 0;

    Object.keys(dates).map(d => {
      discountTotal += dates[d].discount;
    });
    this.handleChange("discount", discountTotal);

    console.log(dates, orderData);
    await axiosInstance.get("/contacts", { handlerEnabled: true }).then(res => {
      this.setState({ loading: false });
      res.data.contacts.map(d => {
        const customerId = this.props.customer
          ? this.props.customer.id
          : this.props.orderData?.[0].customerId ||
            this.props.match.params.customerId;

        if (d.id === customerId) {
          console.info("PaymentForm.js", { true: d });
          this.setState({
            cardsData: d.customerCards ? d.customerCards : []
          });
        }
      });
    });

    this.props.roomPrice &&
      this.props.roomPrice.map(d => {
        this.setState({
          cost: {
            lodging: {
              ...this.state.cost.lodging,
              regular: d.regular,
              private: d.private,
              couple: d.couple
            }
          }
        });
      });
    let pref = {};
    this.props.preferences.map(r => {
      pref[r.name] = r.price;
    });
    this.setState({ cost: { lodging: { ...this.state.cost.lodging, pref } } });

    this.props.foodPrice.map(p => {
      if (p.id === "breakfast") {
        this.setState({ breakfast: p });
      } else if (p.id === "lunch") {
        this.setState({ lunch: p });
      } else if (p.id === "dinner") {
        this.setState({ dinner: p });
      } else if (p.id === "seudes") {
        this.setState({ seudes: p });
      }
    });
    const breakfastData = this.props.foodPreferences.breakfast;
    const lunchData = this.props.foodPreferences.lunch;
    const dinnerData = this.props.foodPreferences.dinner;
    const seudesData = this.props.foodPreferences.seudes;

    let obj = {
      breakfest: {
        pref: {}
      },
      lunch: {
        pref: {}
      },
      dinner: { pref: {} },
      seuda: { pref: {} }
    };
    breakfastData.map(r => {
      obj.breakfest.pref[r.name] = r.price;
    });
    lunchData.map(r => {
      obj.lunch.pref[r.name] = r.price;
    });
    dinnerData.map(r => {
      obj.dinner.pref[r.name] = r.price;
    });
    seudesData.map(r => {
      obj.seuda.pref[r.name] = r.price;
    });

    obj.breakfest.reg = this.state.breakfast ? this.state.breakfast.regular : 0;
    obj.breakfest.vip = this.state.breakfast.vip;
    obj.lunch.reg = this.state.lunch.regular;
    obj.lunch.vip = this.state.lunch.vip;
    obj.dinner.reg = this.state.dinner.regular;
    obj.dinner.vip = this.state.dinner.vip;
    obj.seuda.reg = this.state.seudes.regular;
    obj.seuda.vip = this.state.seudes.vip;

    await this.setState({
      cost: {
        ...this.state.cost,
        food: obj
      }
    });

    this.formattedData = fromatOrder({
      chosenDates: dates,
      cost: this.state.cost,
      discount: this.state.discount,
      variant: this.props.variant,
      order: this.props.orderData && this.props.orderData[0]
    });
    this.setState({
      total: this.formattedData.total,
      subTotal: this.formattedData.total
    });
    const chosenDates = visible ? orderData[0].dates : dates;

    this.isLoading(false);
    this.countDiscount(discountTotal);
  }

  // handleClickHoldRelese = () => {
  //   // this.setState({ paymentHold: !this.state.paymentHold });
  //   console.log("paymentHold", this.props.paymentHold);
  //   this.props.dispatch(holdReleseHandler(!this.props.paymentHold));
  //   console.log("paymentHold", this.props.paymentHold)
  // };

  removePayment(type, i) {
    const oldState = [...this.state[type]];
    const updatedState = oldState.filter(val => {
      return val !== i;
    });
    this.setState({
      [type]: updatedState
    });
  }

  increaseMethod = type => {
    const oldState = [...this.state[type]];
    oldState.push(oldState.length);
    this.setState({
      [type]: oldState
    });
  };

  handleChange = (key, e) => {
    store.dispatch(addOrder(key, e));
    // console.info("PaymentForm.js", { e: e });
  };
  getTotal = async total => {
    this.setState({ total });
    // store.dispatch(addOrder("total", total));
  };

  addCreditCards = () => {
    const { creditCards } = this.state;

    creditCards.push({
      cardName: "",
      cardNumber: "",
      cardCVV: "",
      cardExpiry: "",
      paymentAmount: ""
    });
    this.setState({ creditCards });
  };

  removeCard = key => {
    const { creditCards } = this.state;
    creditCards.splice(key, 1);
    this.setState({ creditCards });
  };

  handleCreditcards = (key, value, i) => {
    const { creditCards } = this.state;
    creditCards[i] = {
      ...creditCards[i],
      [key]: value
    };
    this.setState({ creditCards });
    store.dispatch(addOrder("creditCards", creditCards));
  };

  handleCard = key => {
    // console.info("PaymentForm.js", { key: key });
    const { creditCards } = this.state;
    creditCards.map((cards, i) => {
      // console.info("PaymentForm.js", { match: i === key });
      if (i === key) {
        axiosInstance
          .post(`/processcard`, cards, {
            handlerEnabled: true
          })
          .then(res => {
            // console.info("SingleOrder.js", { res: res });
            // console.info("SingleOrder.js", { resData: res.data });
            const resstts = res.data
              .split("&")
              .map(obj => obj.split("="))
              .find(v => v[0] === "xStatus")[1];
            const error = res.data
              .split("&")
              .map(obj => obj.split("="))
              .find(v => v[0] === "xError")[1];
            if (resstts === "Approved") {
              message.success("Payment has done successfully!");
            } else if (resstts === "Error") {
              message.error(error.replace(/[+]/g, " "));
            }
          });
      }
    });
  };

  handleSelectedCard = v => {
    let data = [];
    if (v === undefined) {
      this.setState({ selectedCard: null }, () =>
        store.dispatch(addOrder("selectedCard", this.state.selectedCard))
      );
    }
    let object = JSON.parse(v);

    this.setState(
      {
        object: object
      },
      () => {
        this.fixCard();
      }
    );
  };

  onchangetab = key => {
    if (key === "1") {
      store.dispatch(paymentTypePayNow("card"));
    } else if (key === "2") {
      store.dispatch(paymentTypePayNow("cash"));
    }
  };

  changeSwitch(e) {
    console.log("[switched to]", e)
    this.props.dispatch(holdReleseHandler(!e));
    
  }

  fixCard = () => {
    let data = [];
    let cardAmount = this.state.selectedCardAmount || 0;
    let amount = "xAuthAmount";
    let object = this.state.object;
    object[amount] = cardAmount;
    data.push(object);
    this.setState({ selectedCard: data }, () => {
      store.dispatch(addOrder("selectedCard", this.state.selectedCard));
    });
  };
  countDiscount = disc => {
    this.setState({ discount: disc, subTotal: this.state.total - disc });
    store.dispatch(addOrder("total", this.state.total - disc));
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    // console.info("PaymentForm.js", { totalCost: this.props.totalCost });
    const { chosenDates, cost, loading } = this.state;
    if (loading) return <Skeleton active />;
    // const currency = (
    //   <Select defaultValue={this.currencies[0]} style={{ width: 80 }}>
    //     {this.currencies.map(currency => (
    //       <Select.Option value={currency}>{currency}</Select.Option>
    //     ))}
    //   </Select>
    // );

    return (
      <Row gutter={[36]}>
        <Col
          span={12}
          style={{
            border: "1px solid #e8e8e8",
            borderRadius: "5px",
            backgroundColor: "#fafafa"
          }}
        >
          <Tabs animated={false} onChange={this.onchangetab}>
            <Tabs.TabPane
              tab="Hold/Pay with credit card"
              key="1"
              style={{ padding: "16px" }}
            >
              <Form.Item>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Choose existing card"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // value={
                  //   this.props.customerCards && this.props.customerCards[0]
                  // }
                  onChange={this.handleSelectedCard}
                  allowClear
                >
                  {this.state.cardsData &&
                    this.state.cardsData.length > 0 &&
                    this.state.cardsData.map((d, i) => {
                      // console.info("PaymentForm.js", { d: d });
                      return (
                        <Select.Option value={JSON.stringify(d)} key={i}>
                          {d.cardEnding.substr(12, 4)}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item>
                <center>
                  <p>Or</p>
                </center>
              </Form.Item>
              {this.state.creditCards.length > 0 &&
                this.state.creditCards.map((card, ind) => {
                  return (
                    <div key={ind}>
                      <Form.Item rules={[{ required: true }]}>
                        {getFieldDecorator("cardName", {
                          rules: [
                            {
                              required: false,
                              message: "Please enter card name!"
                            }
                          ]
                        })(
                          <Input
                            value={this.state.creditCards[ind].cardName}
                            placeholder="Name on card"
                            onChange={e => {
                              this.handleCreditcards(
                                "cardName",
                                e.target.value,
                                ind
                              );
                              this.setState({ index: ind });
                            }}
                          />
                        )}
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]}>
                        {getFieldDecorator("cardNumber", {
                          rules: [
                            {
                              required: false,
                              message: "Please enter card number!"
                            },
                            {
                              min: 15,
                              message:
                                "Card number must be minimum 15 characters long."
                            },
                            {
                              max: 16,
                              message:
                                "Card number must be maximum 16 characters long."
                            }
                          ]
                        })(
                          <Input
                            value={this.state.creditCards[ind].cardNumber}
                            placeholder="Card number"
                            onChange={e => {
                              this.handleCreditcards(
                                "cardNumber",
                                e.target.value,
                                ind
                              );
                              this.setState({ index: ind });
                            }}
                          />
                        )}
                      </Form.Item>
                      <Row>
                        <Col span={12}>
                          <Form.Item rules={[{ required: true }]}>
                            {getFieldDecorator("cardExpiry", {
                              rules: [
                                {
                                  required: false,
                                  message: "Please enter card expiry!"
                                }
                              ]
                            })(
                              <DatePicker.MonthPicker
                                value={this.state.creditCards[ind].cardExpiry}
                                placeholder="Exp."
                                format="MMYY"
                                onChange={e => {
                                  this.handleCreditcards("cardExpiry", e, ind);
                                  this.setState({ index: ind });
                                }}
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item rules={[{ required: true }]}>
                            {getFieldDecorator("cardCVV", {
                              initialValue: this.state.creditCards[ind].cardCVV,
                              rules: [
                                {
                                  required: false,
                                  message: "Please enter cvv!"
                                }
                              ]
                            })(
                              <Input
                                placeholder="CCV"
                                value={this.state.creditCards[ind].cardCVV}
                                onChange={e => {
                                  this.handleCreditcards(
                                    "cardCVV",
                                    e.target.value,
                                    ind
                                  );
                                  this.setState({ index: ind });
                                }}
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item>
                        <Form.Item rules={[{ required: true }]}>
                          {getFieldDecorator("cardAmount", {
                            rules: [
                              {
                                required: false,
                                message: "Please enter amount!"
                              }
                            ]
                          })(
                            <Input.Search
                              // defaultValue="$750"
                              enterButton="Process card"
                              // onSearch={() => this.handleCard(card)}
                              size="large"
                              onChange={e => {
                                this.state.selectedCard?.length > 0
                                  ? this.setState(
                                      { selectedCardAmount: e.target.value },
                                      () => {
                                        this.fixCard();
                                      }
                                    )
                                  : this.handleCreditcards(
                                      "paymentAmount",
                                      parseInt(e.target.value),
                                      ind
                                    );
                                this.setState({ index: ind });
                              }}
                            />
                          )}
                        </Form.Item>
                        {this.state.cards.length !== 0 ? (
                          <Button onClick={i => this.removeCard(i)}>
                            Remove
                          </Button>
                        ) : null}
                      </Form.Item>
                      <hr />
                    </div>
                  );
                })}
              <Form.Item>
                <Button type="primary" onClick={() => this.addCreditCards()}>
                  Save new card
                </Button>
              </Form.Item>
              <div className="holdrelesePayment">
              <Switch checkedChildren="Pay" unCheckedChildren="Hold" onChange={(e) => this.changeSwitch(e)} defaultChecked={!this.props.paymentHold} />
                {/* <Button onClick={this.handleClickHoldRelese}>
                  {this.props.paymentHold === false
                    ? "Hold Payment"
                    : "Relese Payment"}
                </Button> */}
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Other methods" key="2" style={{ padding: "16px" }}>
              <PaymentMethods
                form={this.props.form}
                total={this.state.subTotal}
              />
            </Tabs.TabPane>
          </Tabs>
        </Col>
        <Col span={12}>
          <OrderCost
            {...{ ...this.formattedData }}
            total={
              this.props.paymentTypePaynow === "cash" && this.props.grandTotal
                ? this.props.grandTotal
                : this.state.subTotal
            }
            selectedcurrency={
              this.props.paymentTypePaynow === "cash" &&
              this.props.selectedcurrency
                ? this.props.selectedcurrency
                : "USD"
            }
            // chosenDates={chosenDates}
            // cost={cost}
            // getTotal={this.getTotal}
          />
        </Col>
        <Col span={12}></Col>
        {this.props.authUser &&
        this.props.authUser.user?.userType === "Admin" ? (
          <Col span={12}>
            <Form.Item>
              <InputNumber
                placeholder="Discount"
                style={{ width: "100%" }}
                value={this.state.discount}
                onChange={e => {
                  this.countDiscount(e);
                  this.handleChange("discount", e);
                }}
                type="number"
                size="large"
                min={0}
              />{" "}
            </Form.Item>
          </Col>
        ) : null}
      </Row>
    );
  }
}

const mapStateToProps = ({ orderReducer }) => ({
  orderData: orderReducer.orderData,
  visible: orderReducer.visible,
  dates: orderReducer.dates,
  totalCost: orderReducer.total,
  customer: orderReducer.customer,
  customerCards: orderReducer.customerCards,
  authUser: orderReducer.authUser,
  roomPrice: orderReducer.cost,
  preferences: orderReducer.preferences,
  foodPrice: orderReducer.foodPrices,
  foodPreferences: orderReducer.foodPreferences,
  paymentHold: orderReducer.paymentHold,
  grandTotal: orderReducer.grandTotal,
  paymentTypePaynow: orderReducer.paymentTypePaynow,
  selectedcurrency: orderReducer.selectedcurrency
});

export default connect(mapStateToProps)(withRouter(PaymentForm));
