import React from "react";
import "antd/dist/antd.css";

import {
  FaBed,
  FaShoppingBag,
  FaMoneyBillAlt,
  FaBusAlt,
  FaSwimmingPool,
} from "react-icons/fa";
import { GiMeal } from "react-icons/gi";

import { Row, Col, Modal, Button } from "antd";

import NewOrder from "./NewOrder";
import { connect } from "react-redux";
import {
  showModal,
  clearData,
  getRoomPrice,
  getPreferences,
  getFoodPreferences,
  getFoodPrice,
  getCurrency,
  getContacts,
} from "../../actions/orderActionCreator";
import DonationForm from "./DonationForm";
import FoodForm from "./FoodForm";
import ProductsForm from "./ProductsForm";
import ShuttleForm from "./ShuttleForm";
import MikvahForm from "./MikvahForm";
import SoftInquiryForm from "./SoftInquiryForm";

class TransactionModal extends React.Component {
  state = {
    visible: false,
    orderType: null,
  };

  showModal = () => {
    this.setState({
      visible: true,
      orderType: null,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.props.dispatch(showModal(false));
    this.setState({
      visible: false,
    });
    this.props.dispatch(clearData());
  };

  handleClose = () => {
    this.setState({
      visible: false,
    });
  };

  // startLodgingOrder = () => {
  //   this.setState({
  //     orderType: 'lodging',
  //   });
  // };

  setOrderType = (type) => {
    this.setState({
      orderType: type,
    });
  };
  componentDidMount() {
    this.props.dispatch(getRoomPrice());
    this.props.dispatch(getPreferences());
    this.props.dispatch(getFoodPrice());
    this.props.dispatch(getFoodPreferences());
    this.props.dispatch(getCurrency());
    this.props.dispatch(getContacts());
  }
  render() {
    const styles = {
      ProductBtn: {
        boxShadow: "0 0 15px rgba(0,0,0,0.1)",
        border: "1px solid #eee",
        padding: "36px",
        textAlign: "center",
        borderRadius: "5px",
        cursor: "pointer",
        backgroundColor: "#fff",
      },
      ProductBtnP: {
        marginBottom: 0,
        marginTop: "5px",
        fontWeight: "bold",
      },
    };

    let importForm = <p>Initial</p>;

    switch (this.state.orderType) {
      case "lodging":
        importForm = <NewOrder closeModel={() => this.handleClose()} />;
        break;
      case "donation":
        importForm = <DonationForm closeModel={() => this.handleClose()} />;
        break;
      case "food":
        importForm = <FoodForm closeModel={() => this.handleClose()} />;
        break;
      case "products":
        importForm = <ProductsForm closeModel={() => this.handleClose()} />;
        break;
      case "shuttle":
        importForm = <ShuttleForm closeModel={() => this.handleClose()} />;
        break;
      case "mikvah":
        importForm = <MikvahForm closeModel={() => this.handleClose()} />;
        break;
      case "softInquiry":
        importForm = <SoftInquiryForm closeModel={() => this.handleClose()} />;
        break;
      default:
        importForm = <p>Default</p>;
    }

    return (
      <div>
        <Button type="primary" shape="round" onClick={this.showModal}>
          {this.props.btnName}
        </Button>
        <Modal
          title={
            this.state.orderType
              ? this.state.orderType === "softInquiry"
                ? "New soft inquiry"
                : "New " + this.state.orderType + " order"
              : "New transaction"
          }
          visible={this.state.visible}
          onCancel={this.handleCancel}
          maskClosable={false}
          footer={
            this.state.orderType ? (
              <>
                <Button key="back" onClick={this.handleCancel}>
                  Cancel
                </Button>
                {/* <Button key="submit" type="primary" onClick={this.handleOk}>
                  {this.props.visible ? "Update" : "Submit"}
                </Button> */}
              </>
            ) : null
          }
          width={this.state.orderType === "lodging" ? "1190px" : "521px"}
        >
          {this.state.orderType === null ? (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div
                  style={styles.ProductBtn}
                  onClick={() => this.setOrderType("lodging")}
                >
                  <FaBed style={{ fontSize: "24px" }} />
                  <p style={styles.ProductBtnP}>Lodging</p>
                </div>
              </Col>
              <Col span={12}>
                <div
                  style={styles.ProductBtn}
                  onClick={() => this.setOrderType("food")}
                >
                  <GiMeal style={{ fontSize: "24px" }} />
                  <p style={styles.ProductBtnP}>Food</p>
                </div>
              </Col>
              <Col span={12}>
                <div
                  style={styles.ProductBtn}
                  onClick={() => this.setOrderType("products")}
                >
                  <FaShoppingBag style={{ fontSize: "24px" }} />
                  <p style={styles.ProductBtnP}>Products</p>
                </div>
              </Col>
              <Col span={12}>
                <div
                  style={styles.ProductBtn}
                  onClick={() => this.setOrderType("shuttle")}
                >
                  <FaBusAlt style={{ fontSize: "24px" }} />
                  <p style={styles.ProductBtnP}>Shuttle</p>
                </div>
              </Col>
              <Col span={12}>
                <div
                  style={styles.ProductBtn}
                  onClick={() => this.setOrderType("mikvah")}
                >
                  <FaSwimmingPool style={{ fontSize: "24px" }} />
                  <p style={styles.ProductBtnP}>Mikvah</p>
                </div>
              </Col>
              <Col span={12}>
                <div
                  style={styles.ProductBtn}
                  onClick={() => this.setOrderType("donation")}
                >
                  <FaMoneyBillAlt style={{ fontSize: "24px" }} />
                  <p style={styles.ProductBtnP}>Donation</p>
                </div>
              </Col>
              <Col span={24}>
                <div
                  style={{ ...styles.ProductBtn, padding: "10px" }}
                  onClick={() => this.setOrderType("softInquiry")}
                >
                  <p style={styles.ProductBtnP}>Soft inquiry</p>
                </div>
              </Col>
            </Row>
          ) : (
            importForm
          )}
        </Modal>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   return {
//     visible: state.visible
//   };
// };

const mapStateToProps = ({ orderReducer }) => ({
  visible: orderReducer.visible,
});

export default connect(mapStateToProps)(TransactionModal);
