import React, { Suspense } from 'react';
import {
  message,
  Row,
  Col,
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  Skeleton,
} from 'antd';

import Logo from '../../assets/img/logo-black.png';
import Bg from '../../assets/img/kerestir.jpg';
import fbConfig from '../../config/fbConfig';
import firebase from 'firebase';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../httpHandler';

// import Widget from "../../components/Widget/Widget";
const Widget = React.lazy(() => import('../../components/Widget/Widget'));

class LoginComponent extends React.Component {
  state = { err: null, userEmail: '' };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ err: true });
        this.handleLogin(values);
      }
    });
  };
  login = async (values) => {
    try {
      await fbConfig
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      await fbConfig
        .auth()
        .signInWithEmailAndPassword(values.username, values.password);
    } catch (error) {
      this.setState({
        err: false,
      });
      message.error(error.message);
    }
  };
  handleLogin = async (values) => {
    try {
      axiosInstance
        .post('/isUserExist', { email: values.username })
        .then(async (res) => {
          if (res.data && res.data.userExist) {
            this.login(values);
          } else {
            message.error('user does not exist');
            this.setState({
              err: false,
            });
          }
        });
    } catch (error) {
      this.setState({
        err: false,
      });
      message.error(error.message);
    }
  };
  forgotPassword = async () => {
    if (this.state.userEmail) {
      axiosInstance
        .get(`/forgotpassword/${this.state.userEmail}`)
        .then(async (res) => {
          if (res.data.success) {
            await firebase
              .auth()
              .sendPasswordResetEmail(this.state.userEmail)
              .then((res) =>
                message.success('Please Check Your Mail For Further Process.')
              )
              .catch((error) => message.error(error.message));
          } else {
            message.error('Email Does Not Exist.');
          }
        });
    } else {
      message.warn('Enter Email For Reset Password.');
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div
        style={{
          height: '100vh',
          backgroundImage: 'url(' + Bg + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      >
        <Row
          type='flex'
          justify='center'
          align='middle'
          style={{
            height: '100vh',
            backgroundColor: '#00000090',
          }}
        >
          <Col style={{ width: '400px', maxWidth: '100%' }}>
            <Suspense fallback={<Skeleton active />}>
              <Widget padding='50px 40px'>
                <img
                  src={Logo}
                  style={{ display: 'block', margin: '0 auto' }}
                  alt='Logo'
                />
                <Form
                  onSubmit={this.handleSubmit}
                  className='login-form'
                  style={{ marginTop: '50px' }}
                >
                  <Form.Item>
                    {getFieldDecorator('username', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your username!',
                        },
                      ],
                    })(
                      <Input
                        size='large'
                        prefix={
                          <Icon
                            type='user'
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                        onChange={(e) =>
                          this.setState({ userEmail: e.target.value })
                        }
                        placeholder='Username'
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('password', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your Password!',
                        },
                      ],
                    })(
                      <Input
                        size='large'
                        prefix={
                          <Icon
                            type='lock'
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                        type='password'
                        placeholder='Password'
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('remember', {
                      valuePropName: 'checked',
                      initialValue: true,
                    })(<Checkbox>Remember me</Checkbox>)}
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type='primary'
                      htmlType='submit'
                      className='login-form-button'
                      block
                      size='large'
                      disabled={this.state.err}
                      loading={this.state.err}
                    >
                      Log in
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Link
                      className='login-form-forgot'
                      to='/'
                      onClick={this.forgotPassword}
                    >
                      Forgot password
                    </Link>
                  </Form.Item>
                </Form>
              </Widget>
            </Suspense>
          </Col>
        </Row>
      </div>
    );
  }
}

const Login = Form.create({ name: 'normal_login' })(LoginComponent);

export default Login;
