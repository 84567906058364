import React from "react";
// prettier-ignore
import { Row, Col, Form, Typography, Input, Select, Button, Checkbox,Modal,message } from "antd";
import { connect } from "react-redux";
// prettier-ignore
import { addCustomer, addOrder,getContacts } from "../../actions/orderActionCreator";
import { store } from "../../store";
import { KEY } from "../../AppConstants";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import { withRouter } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import { axiosInstance } from "../../httpHandler";
import ReactPhoneInput from "react-phone-input-2";
import "../../style/main.css";

class CustomerForm extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    phones: [],
    emails: [],
    addresses: [{ address: "", address2: "", city: "", state: "", zip: "" }],
    showCustomer: false,
    contactsData: [],
    search: "",
    searchVal: "",
    index: null,
    phone: "",
    customerData: "",
    memo: "",
    index2: 0,
    memos: [],
  };

  componentDidMount() {
    let custId =
      this.props.location && this.props.location.pathname.split("/")[2];
    custId && custId.length > 10 && this.handleCustomerChange("id", custId);
  }
  handleNoResult = () => {
    console.log("No results for ", this.state.search);
  };

  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    this.setState({
      search: { [this.state.index]: "" },
      searchVal: geocodedPrediction.formatted_address,
    });
    this.handleAddress(
      "address",
      geocodedPrediction.formatted_address,
      this.state.index
    );
  };

  addDetail = (type) => {
    const oldState = { ...this.state };
    oldState[type].push(oldState[type].length + 2);
    this.setState(oldState);
  };

  showCustomer = () => {
    this.setState({ showCustomer: !this.state.showCustomer }, () => {
      this.props.newCustomer(this.state.showCustomer);
    });
    store.dispatch(addCustomer("customerType", "1-Time"));
  };

  handleChange = (key, e) => {
    store.dispatch(addCustomer(key, e));
  };

  handleChange2 = (key, e) => {
    store.dispatch(addOrder(key, e));
  };

  handleCustomerChange = (key, e) => {
    const { contactsData } = this.props;
    this.setState({ index2: 0 }, () => {
      contactsData.map((contact) => {
        if (contact.id === e) {
          console.info("CustomerForm.js", { 1: e, 2: contact.id });
          this.setState(
            {
              customerData: contact,
              memos: contact.memo ? contact.memo : [],
              customerId: contact.id,
            },
            () => {
              if (this.state.memos.length) {
                this.showMemoModal(this.state.memos);
              }
            }
          );
        }
      });
    });
    store.dispatch(addCustomer(key, e));
  };
  handleAddress = (key, value, i) => {
    const { addresses } = this.state;
    addresses[i] = { ...addresses[i], [key]: value };
    this.setState({ addresses });
    store.dispatch(addCustomer("addresses", addresses));
  };

  addAddress = () => {
    const { addresses } = this.state;
    addresses.push({
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    });
    this.setState({ addresses });
  };

  handleCustomer = () => {
    const { orderData, customer } = this.props;
    const selectedCustomer =
      (customer && customer.id) ||
      (orderData && orderData[0].customerId) ||
      (this.props.match && this.props.match.params.customerId);
    const test = this.props.contactsData?.map((d, i) => {
      if (d.id === selectedCustomer) {
        this.setState({
          contactPersonName: d.firstName + " " + d.lastName,
        });
        this.setState({
          contactPersonInfo: d.phones ? d.phones[0] : "",
        });
        this.handleChange2("contactPersonName", d.firstName + " " + d.lastName);
        this.handleChange2("contactPersonInfo", d.phones ? d.phones[0] : "");
      }
      return;
    });
  };

  showMemoModal = () => {
    let thiss = this;
    Modal.confirm({
      title: "Account memo",
      content: this.state.memos[this.state.index2],
      cancelText: "Dismiss",
      okText: "Delete memo",
      onCancel() {
        thiss.setState(
          {
            index2: thiss.state.memos.length > 0 ? thiss.state.index2 + 1 : 0,
          },
          () => {
            if (thiss.state.index2 <= thiss.state.memos.length - 1) {
              thiss.showMemoModal(thiss.state.memos);
            }
          }
        );
      },
      onOk() {
        let a = thiss.state.memos;
        a.splice(thiss.state.index2, 1);
        thiss.setState({ memos: a }, () => {
          axiosInstance
            .put(
              `/deletememo/${thiss.state.customerId}`,
              {
                memo: thiss.state.memos,
              },
              { handlerEnabled: true }
            )
            .then((res) => {
              message.success("Memo Deleted succesfully");
              thiss.props.dispatch(getContacts());

              if (thiss.state.index2 <= thiss.state.memos.length - 1) {
                thiss.showMemoModal(thiss.state.memos);
              }
            })
            .catch((err) => console.log(err));
        });
      },
    });
  };

  render() {
    const { showCustomer, phones, emails } = this.state;
    const { getFieldDecorator } = this.props.form;
    let custID =
      this.props.location &&
      this.props.location.pathname.split("/")[2]?.length > 10
        ? this.props.location.pathname.split("/")[2]
        : "";
    // prettier-ignore
    const { orderData, customer, contactPersonName, contactPersonInfo } = this.props;
    return (
      <Form style={{ margin: "24px auto" }}>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Typography.Title level={3}>Customer</Typography.Title>
          </Col>
          <Col span={12}>
            {showCustomer ? (
              <Form.Item rules={[{ required: true }]}>
                <Select
                  showSearch
                  placeholder="Choose existing customer"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={
                    (customer && customer.id) ||
                    (orderData && orderData[0].customerId) ||
                    (custID && custID)
                  }
                  onChange={(e) => this.handleCustomerChange("id", e)}
                  allowClear
                >
                  {this.props.contactsData?.map((data, i) => {
                    return (
                      <Select.Option value={data.id} key={i}>
                        {data.firstName + " " + data.lastName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item rules={[{ required: true }]}>
                {getFieldDecorator("customer", {
                  initialValue:
                    (customer && customer.id) ||
                    (orderData && orderData[0].customerId) ||
                    (custID && custID),
                  rules: [
                    {
                      required: true,
                      message: "Please select customer!",
                    },
                  ],
                })(
                  <Select
                    showSearch
                    placeholder="Choose existing customer"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    // value={
                    //   (customer && customer.id) ||
                    //   (orderData && orderData[0].customerId)
                    // }
                    onChange={(e) => this.handleCustomerChange("id", e)}
                    allowClear
                  >
                    {this.props.contactsData?.map((data, i) => {
                      return (
                        <Select.Option value={data.id} key={i}>
                          {(data.firstName ? data.firstName : "") +
                            "  " +
                            (data.lastName ? data.lastName : "")}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            <Checkbox onClick={this.showCustomer}>Add New Customer</Checkbox>
            <Button type="primary" onClick={() => this.handleCustomer()}>
              Order Contact is Customer
            </Button>
          </Col>
          <Col span={24}>
            <Typography.Title level={3}>Order contact person</Typography.Title>
          </Col>
          <Col span={12}>
            <Form.Item>
              {getFieldDecorator("contactPersonName", {
                initialValue:
                  this.state.contactPersonName ||
                  (contactPersonName && contactPersonName) ||
                  (orderData && orderData[0].contactPersonName),
              })(
                <Input
                  placeholder="Name"
                  onChange={(e) =>
                    this.handleChange2("contactPersonName", e.target.value)
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              {getFieldDecorator("contactPersonInfo", {
                initialValue:
                  this.state.contactPersonInfo ||
                  (contactPersonInfo && contactPersonInfo) ||
                  (orderData && orderData[0].contactPersonInfo),
              })(
                <Input
                  placeholder="Contact info"
                  onChange={(e) =>
                    this.handleChange2("contactPersonInfo", e.target.value)
                  }
                />
              )}
            </Form.Item>
          </Col>
          {showCustomer && (
            <React.Fragment>
              <Col span={24}>
                <Typography.Title level={3}>
                  Customer Information
                </Typography.Title>
              </Col>
              <Col span={12}>
                <Form.Item rules={[{ required: true }]}>
                  <Input
                    placeholder="First name"
                    onChange={(e) =>
                      this.handleChange("firstName", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item rules={[{ required: true }]}>
                  <Input
                    placeholder="Last name"
                    onChange={(e) =>
                      this.handleChange("lastName", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item labelCol={6} wrapperCol={8}>
                  <Select
                    mode="multiple"
                    placeholder="Please select tags"
                    onChange={(e) => this.handleChange("tags", e)}
                    style={{ width: "100%" }}
                  >
                    <Select.Option key={1} value="Reguler">
                      Reguler
                    </Select.Option>
                    <Select.Option key={2} value="VIP">
                      VIP
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item labelCol={6} wrapperCol={8}>
                  <Select
                    placeholder="Select Customer Type"
                    onChange={(e) => this.handleChange("customerType", e)}
                    defaultValue="1-Time"
                    style={{ width: "100%" }}
                  >
                    <Select.Option key={1} value="1-Time">
                      1-Time
                    </Select.Option>
                    <Select.Option key={2} value="Recurring">
                      Recurring
                    </Select.Option>
                    <Select.Option key={3} value="Travel agent">
                      Travel agent
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ReactPhoneInput
                      country={"us"}
                      inputClass="phoneInputStyle"
                      buttonClass="phoneDropDown2"
                      onChange={(value, country, e, v) => {
                        phones.length > 0 ? (phones[0] = v) : phones.push(v);

                        this.setState({ phones });
                        this.handleChange("phones", phones);
                      }}
                    />
                    <Button
                      type="primary"
                      style={{
                        borderBottomLeftRadius: "none !important",
                        borderTopLeftRadius: "none !important",
                      }}
                      onClick={() => {
                        let a = phones;
                        a.push("");
                        this.setState({ phones });
                        this.handleChange("phones", phones);
                      }}
                    >
                      Add Phone
                    </Button>
                  </div>
                  {phones &&
                    phones.map((phone, i) =>
                      i > 0 ? (
                        <div className="mt-2">
                          <ReactPhoneInput
                            country={"us"}
                            inputClass="phoneInputStyle"
                            buttonClass="phoneDropDown2"
                            key={i}
                            value={phone || ""}
                            onChange={(value, country, e, v) => {
                              phones[i] = v;

                              this.setState({
                                phones: phones,
                              });
                              this.handleChange("phones", phones);
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )
                    )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item rules={[{ required: true }]}>
                  <Input.Search
                    type="email"
                    placeholder="Email"
                    enterButton="Add email"
                    onChange={(v) => {
                      emails.length > 0
                        ? (emails[0] = v.target.value)
                        : emails.push(v.target.value);

                      this.setState({ emails });
                      this.handleChange("emails", emails);
                    }}
                    onSearch={(v) => {
                      emails.push("");
                      this.setState({ emails });
                      this.handleChange("emails", emails);
                    }}
                  />

                  {emails &&
                    emails.map((email, i) =>
                      i > 0 ? (
                        <Input
                          key={i}
                          type="tel"
                          value={email || ""}
                          onChange={(e) => {
                            emails[i] = e.target.value;
                            this.setState({
                              emails: emails,
                            });
                            this.handleChange("emails", emails);
                          }}
                        />
                      ) : (
                        ""
                      )
                    )}
                </Form.Item>
              </Col>
              <Col span={8}>
                {this.state.addresses.length > 0 &&
                  this.state.addresses.map((address, i) => (
                    <Form.Item key={i} rules={[{ required: true }]}>
                      <ReactGoogleMapLoader
                        params={{
                          key: KEY.MAP_KEY,
                          libraries: "places,geocode",
                        }}
                        render={(googleMaps) =>
                          googleMaps && (
                            <ReactGooglePlacesSuggest
                              googleMaps={googleMaps}
                              autocompletionRequest={{
                                input: this.state.search[i],
                              }}
                              onNoResult={this.handleNoResult}
                              onSelectSuggest={this.handleSelectSuggest}
                              onStatusUpdate={this.handleStatusUpdate}
                              textNoResults="No Address Found"
                              customRender={(prediction) => (
                                <div className="customWrapper">
                                  {prediction
                                    ? prediction.description
                                    : "No Address Found"}
                                </div>
                              )}
                            >
                              <Form.Item>
                                <Input
                                  placeholder={"Address "}
                                  value={this.state.addresses[i].address}
                                  onChange={(e) => {
                                    this.handleAddress(
                                      "address",
                                      e.target.value,
                                      i
                                    );
                                    this.setState({
                                      search: {
                                        [i]: e.target.value,
                                      },
                                    });
                                    this.setState({
                                      index: i,
                                    });
                                  }}
                                />
                              </Form.Item>
                            </ReactGooglePlacesSuggest>
                          )
                        }
                      />
                    </Form.Item>
                  ))}
              </Col>

              <Col span={24} label="kvitel">
                <Form.Item>
                  <TextArea
                    allowClear
                    placeholder="Enter kvitel"
                    onChange={(e) =>
                      this.handleChange("kvitel", e.target.value)
                    }
                    rows={5}
                  />
                </Form.Item>
              </Col>
            </React.Fragment>
          )}
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = ({ orderReducer }) => ({
  orderData: orderReducer.orderData,
  customer: orderReducer.customer,
  contactPersonName: orderReducer.contactPersonName,
  contactPersonInfo: orderReducer.contactPersonInfo,
  visible: orderReducer.visible,
  contactsData: orderReducer.contacts,
});

export default connect(mapStateToProps)(withRouter(CustomerForm));
