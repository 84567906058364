// prettier-ignore
import { Button, Col, Form, Input, message, Modal, Row, Select, Typography, } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { Component } from "react";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import ReactGoogleMapLoader from "react-google-maps-loader";

import { KEY } from "../../AppConstants";
import { store } from "../../store";
import {
  addCustomer,
  clearData,
  getContacts,
} from "../../actions/orderActionCreator";
import { connect } from "react-redux";
import { axiosInstance } from "../../httpHandler";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { NavLink } from "react-router-dom";

class AddCustomer extends Component {
  state = {
    visible: false,
    phones: [],
    emails: [],
    addresses: [{ address: "", address2: "", city: "", state: "", zip: "" }],
    search: "",
    searchVal: "",
  };
  componentDidMount() {
    store.dispatch(addCustomer("customerType", "1-Time"));
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      phones: [],
      emails: [],
      addresses: [{ address: "", address2: "", city: "", state: "", zip: "" }],
      search: "",
      searchVal: "",
    });
    this.props.form.resetFields();
  };
  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    this.setState({
      search: { [this.state.index]: "" },
      searchVal: geocodedPrediction.formatted_address,
    });
    this.handleAddress("address", geocodedPrediction.formatted_address, 0);
  };
  handleChange = (key, e) => {
    store.dispatch(addCustomer(key, e));
  };
  handleAddress = (key, value, i) => {
    const { addresses } = this.state;
    addresses[i] = { ...addresses[i], [key]: value };
    this.setState({ addresses });
    store.dispatch(addCustomer("addresses", addresses));
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        axiosInstance
          .post(
            "/addCustomer",
            { customer: this.props.customer },
            { handlerEnabled: true }
          )
          .then((res) => {
            message.success("Customer Added Successfully");
            this.setState({ visible: false });
            store.dispatch(getContacts());
            store.dispatch(clearData());
            this.setState({
              phones: [],
              emails: [],
              addresses: [
                { address: "", address2: "", city: "", state: "", zip: "" },
              ],
            });
          })
          .catch((error) => message.error(error));
      }
    });
  };
  render() {
    const { phones, emails } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Button
          type="primary"
          shape="round"
          onClick={() => this.setState({ visible: true })}
        >
          + Add Customer
        </Button>
        {this.props.general ? (
          <></>
        ) : (
          <Button className="ml-2" type="primary" shape="round">
            <NavLink to={"/customers/General"} style={{ color: "white" }}>
              General Customer
            </NavLink>
          </Button>
        )}

        <Modal
          title="Add Customer"
          visible={this.state.visible}
          onOk={this.handleSubmit}
          onCancel={this.handleCancel}
          width={1000}
          okText={"Submit"}
        >
          <Form>
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Typography.Title level={3}>
                  Customer Information
                </Typography.Title>
              </Col>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator("firstName", {
                    rules: [
                      {
                        required: true,
                        message: "Please Add First Name!",
                      },
                    ],
                  })(
                    <Input
                      placeholder="First name"
                      onChange={(e) =>
                        this.handleChange("firstName", e.target.value)
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator("lastName", {
                    rules: [
                      {
                        required: true,
                        message: "Please Add Last name!",
                      },
                    ],
                  })(
                    <Input
                      placeholder="Last name"
                      onChange={(e) =>
                        this.handleChange("lastName", e.target.value)
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Select
                    mode="multiple"
                    placeholder="Please select tags"
                    onChange={(e) => this.handleChange("tags", e)}
                    style={{ width: "100%" }}
                  >
                    <Select.Option key={1} value="Reguler">
                      Reguler
                    </Select.Option>
                    <Select.Option key={2} value="VIP">
                      VIP
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Select
                    placeholder="Select Customer Type"
                    onChange={(e) => this.handleChange("customerType", e)}
                    defaultValue="1-Time"
                    style={{ width: "100%" }}
                  >
                    <Select.Option key={1} value="1-Time">
                      1-Time
                    </Select.Option>
                    <Select.Option key={2} value="Recurring">
                      Recurring
                    </Select.Option>
                    <Select.Option key={3} value="Travel agent">
                      Travel agent
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ReactPhoneInput
                      country={"us"}
                      // value={"919898363748"}
                      onChange={(value, country, e, v) => {
                        phones.length > 0 ? (phones[0] = v) : phones.push(v);

                        this.setState({ phones });
                        this.handleChange("phones", phones);
                      }}
                    />
                    <Button
                      type="primary"
                      style={{
                        borderBottomLeftRadius: "none !important",
                        borderTopLeftRadius: "none !important",
                      }}
                      onClick={() => {
                        let a = phones;
                        a.push("");
                        this.setState({ phones });
                        this.handleChange("phones", phones);
                      }}
                    >
                      Add Phone
                    </Button>
                  </div>

                  {phones &&
                    phones.map((phone, i) =>
                      i > 0 ? (
                        <div className="mt-2">
                          <ReactPhoneInput
                            inputClass="phoneInputStyle"
                            buttonClass="phoneDropDown2"
                            country={"us"}
                            onChange={(value, country, e, v) => {
                              phones[i] = v;

                              this.setState({ phones });
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )
                    )}
                </Form.Item>
              </Col>
              <Col span={4}></Col>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        // type: "email",
                        required: emails.length ? false : true,
                        message: "Please Enter Your Email!",
                      },
                    ],
                  })(
                    <Input.Search
                      type="email"
                      placeholder="Email"
                      enterButton="Add email"
                      onChange={(v) => {
                        emails.length > 0
                          ? (emails[0] = v.target.value)
                          : emails.push(v.target.value);

                        this.setState({ emails });
                        this.handleChange("emails", emails);
                      }}
                      onSearch={(v) => {
                        emails.push("");
                        this.setState({ emails });
                        this.handleChange("emails", emails);
                      }}
                    />
                  )}

                  {emails &&
                    emails.map((email, i) =>
                      i > 0 ? (
                        <Input
                          key={i}
                          type="tel"
                          value={email || ""}
                          onChange={(e) => {
                            emails[i] = e.target.value;
                            this.setState({
                              emails: emails,
                            });
                          }}
                        />
                      ) : (
                        ""
                      )
                    )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <ReactGoogleMapLoader
                    params={{
                      key: KEY.MAP_KEY,
                      libraries: "places,geocode",
                    }}
                    render={(googleMaps) =>
                      googleMaps && (
                        <ReactGooglePlacesSuggest
                          googleMaps={googleMaps}
                          autocompletionRequest={{
                            input: this.state.search[0],
                          }}
                          onNoResult={this.handleNoResult}
                          onSelectSuggest={this.handleSelectSuggest}
                          onStatusUpdate={this.handleStatusUpdate}
                          textNoResults="No Address Found"
                          customRender={(prediction) => (
                            <div className="customWrapper">
                              {prediction
                                ? prediction.description
                                : "No Address Found"}
                            </div>
                          )}
                        >
                          <Form.Item>
                            <Input
                              placeholder={"Address "}
                              value={this.state.addresses[0].address}
                              onChange={(e) => {
                                this.handleAddress(
                                  "address",
                                  e.target.value,
                                  0
                                );
                                this.setState({
                                  search: { 0: e.target.value },
                                });
                              }}
                            />
                          </Form.Item>
                        </ReactGooglePlacesSuggest>
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={24} label="kvitel">
                <Form.Item>
                  <TextArea
                    allowClear
                    placeholder="Enter kvitel"
                    onChange={(e) =>
                      this.handleChange("kvitel", e.target.value)
                    }
                    rows={5}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = ({ orderReducer }) => {
  return {
    customer: orderReducer.customer,
  };
};
const NewAddCustomer = Form.create({ name: "customer" })(AddCustomer);

export default connect(mapStateToProps)(NewAddCustomer);
