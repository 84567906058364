import React, { lazy, Suspense } from "react";
// prettier-ignore
import { Typography, Tabs, Form, Table, Button, Modal, Input, Collapse, Tag, Icon, Select, InputNumber, message, Skeleton } from "antd";
import { axiosInstance } from "../../../httpHandler";
import {
  getRoomPrice,
  getRooms,
  getPreferences,
  getBuilding,
} from "../../../actions/orderActionCreator";
import { connect } from "react-redux";
const { confirm } = Modal;
const RoomsTable = lazy(() => import("./RoomsTable"));
class Lodging extends React.Component {
  state = {
    visible: false,
    added: false,
    modal: {
      action: true,
      type: "building",
    },
    preferences: [],
    prefName: "",
    prefPrice: 0,
    beds: 0,
    buildings: [],
    buildingName: "",
    roomName: "",
    roomPreference: [],
    roomData: [],
    cost: {},
  };

  componentDidMount() {
    this.fetchData();
  }

  getAdded = (res) => {
    this.setState({ added: res });
  };

  fetchData = () => {
    // this.setState();
    this.props.dispatch(getRooms());
    this.props.dispatch(getPreferences());
    this.props.dispatch(getBuilding());
    this.props.dispatch(getRoomPrice());
  };

  componentWillReceiveProps(nextProps) {
    nextProps &&
      this.setState({
        roomData: nextProps.roomData,
        preferences: nextProps.preferences,
        buildings: nextProps.buildings,
        cost: nextProps.cost?.[0],
      });
  }
  showModal = (action, type, data, id) => {
    console.info("Lodging.js", { data: data, id: id });
    if (action === "Edit" && type === "Preference") {
      data.map((d) => {
        this.setState({ prefName: d.name, prefPrice: d.price, id: id });
      });
    } else if (action === "Edit" && type === "Building") {
      data.map((d) => {
        this.setState({ buildingName: d.name, id: id });
      });
    } else if (action === "Add" && type === "Room") {
      this.setState({ id: id });
    } else if (action === "Edit" && type === "Room") {
      console.info("Lodging.js", { data: data });
      const pref = [];
      data.map((d) => {
        d.preferences.map((p) => {
          pref.push(p.id);
        });
        this.setState({
          roomName: d.name,
          roomPreference: pref,
          id: d.id,
          beds: d?.beds,
          buildingId: d.building,
        });
      });
    }
    this.setState(
      {
        visible: true,
        modal: {
          action: action,
          type: type,
        },
      },
      () => {
        this.setState({ visible: true });
      }
    );
  };

  handleOk = (e) => {
    const data = { name: this.state.prefName, price: this.state.prefPrice };
    const roomData = {
      name: this.state.roomName,
      preferences: this.state.roomPreference,
      beds: this.state.beds,
      id: this.state.id,
      building: this.state.buildingId,
    };
    const buildingdata = { name: this.state.buildingName };
    if (
      this.state.modal.type === "Preference" &&
      this.state.modal.action === "Add"
    ) {
      return axiosInstance
        .post("/addpreferences", data, { handlerEnabled: true })
        .then((res) => {
          this.fetchData();
          this.setState({
            prefName: "",
            prefPrice: 0,
            visible: false,
          });
          message.success("Preference has been added successfully.");
        });
    } else if (
      this.state.modal.type === "Preference" &&
      this.state.modal.action === "Edit"
    ) {
      return axiosInstance
        .patch(`/editpreference/${this.state.id}`, data, {
          handlerEnabled: true,
        })
        .then((res) => {
          this.setState({
            prefName: "",
            prefPrice: 0,
            visible: false,
            id: "",
          });
          this.fetchData();
          message.success("Preference has been updated successfully.");
        });
    } else if (
      this.state.modal.type === "Building" &&
      this.state.modal.action === "Add"
    ) {
      return axiosInstance
        .post("/addbuilding", buildingdata, { handlerEnabled: true })
        .then((res) => {
          this.fetchData();
          message.success("Building has been added successfully.");
          this.setState({
            buildingName: "",
            visible: false,
          });
        });
    } else if (
      this.state.modal.type === "Building" &&
      this.state.modal.action === "Edit"
    ) {
      return axiosInstance
        .patch(`/editbuilding/${this.state.id}`, buildingdata, {
          handlerEnabled: true,
        })
        .then((res) => {
          this.fetchData();
          message.success("Building has been updated successfully.");
          this.setState({
            buildingName: "",
            id: "",
            visible: false,
          });
        });
    } else if (
      this.state.modal.type === "Room" &&
      this.state.modal.action === "Add"
    ) {
      return axiosInstance
        .post("/addroom", roomData, { handlerEnabled: true })
        .then((res) => {
          this.fetchData();
          message.success("Room has been added successfully.");
          this.setState({
            roomName: "",
            roomPreference: [],
            visible: false,
            added: true,
          });
        });
    } else if (
      this.state.modal.type === "Room" &&
      this.state.modal.action === "Edit"
    ) {
      return axiosInstance
        .patch(`/editroom/${roomData.id}`, roomData, {
          handlerEnabled: true,
        })
        .then(async (res) => {
          await this.setState({
            buildingName: "",
            id: "",
            visible: false,
            added: true,
          });
          await this.fetchData();
          await message.success("Room has been updated successfully.");
        });
    }

    // this.setState({
    //   visible: false
    // });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      prefName: "",
      prefPrice: 0,
      buildingName: "",
      roomName: "",
      roomPreference: [],
    });
  };

  handleCollapseButtonClick = (type, e, data, id) => {
    e.stopPropagation();
    if (type === "add") this.showModal("Add", "Room", "", id);
    if (type === "edit") this.showModal("Edit", "Building", data, id);
  };

  showConfirm = (id) => {
    let confirmThis = this;
    confirm({
      title: `Do you want to delete Preference?`,
      content: "When clicked the OK button, this preference will be deleted.",
      onOk() {
        axiosInstance
          .delete(`/deletepreference/${id}`, { handlerEnabled: true })
          .then((res) => {
            message.success("Preference has been deleted successfully!");
            if (res) {
              confirmThis.fetchData();
            }
          })
          .catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };
  showConfirmDelete = (id) => {
    let confirmThis = this;
    confirm({
      title: `Do you want to delete Building?`,
      content: "When clicked the OK button, this building will be deleted.",
      onOk() {
        axiosInstance
          .delete(`/deletebuilding/${id}`, { handlerEnabled: true })
          .then((res) => {
            message.success("Building has been deleted successfully!");
            if (res) {
              confirmThis.fetchData();
            }
          })
          .catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  handleEdit = (type) => {
    return axiosInstance
      .patch(`/editroomprice/${type}`, this.state[type], {
        handlerEnabled: true,
      })
      .then((res) => {
        this.fetchData();
        message.success("Prices has been updated successfully.");
      });
  };

  render() {
    const prefrData = [
      {
        key: "1",
        preference: "No steps",
        price: 15,
      },
      {
        key: "2",
        preference: "VIP",
        price: 20,
      },
      {
        key: "3",
        preference: "Close proximity",
        price: 10,
      },
      {
        key: "4",
        preference: "Private room",
        price: 35,
      },
      {
        key: "5",
        preference: "Privat bathroom",
        price: 5,
      },
    ];
    const prefData = this.props.preferences?.map((d, i) => ({
      key: i,
      preference: d.name,
      price: d.price,
      id: d.id,
    }));

    console.info("Lodging.js", { prefData: prefData });
    const prefColumns = [
      {
        title: "Preference",
        dataIndex: "preference",
        key: "preference",
        render: (preference) => <strong>{preference}</strong>,
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (price) => "$" + price.toFixed(2),
      },
      {
        title: "Actions",
        dataIndex: "id",
        key: "id",
        render: (id) => (
          <div>
            <Button
              type="primary"
              shape="circle"
              onClick={() => {
                this.showModal(
                  "Edit",
                  "Preference",
                  this.props.preferences?.filter((pref) => pref.id === id),
                  id
                );
              }}
            >
              <Icon type="edit" />
            </Button>
            <Button
              type="danger"
              onClick={() => this.showConfirm(id)}
              shape="circle"
              style={{ marginLeft: "5px" }}
            >
              <Icon type="delete" />
            </Button>
          </div>
        ),
      },
    ];

    const extraButtons = (data, id) => (
      <React.Fragment>
        <Button
          type="primary"
          shape="circle"
          onClick={(e) => {
            this.handleCollapseButtonClick("add", e, "", id);
          }}
        >
          <Icon type="plus" />
        </Button>
        <Button
          type="outlined"
          shape="circle"
          onClick={(e) => {
            this.handleCollapseButtonClick("edit", e, data, id);
          }}
          style={{ marginLeft: "5px" }}
        >
          <Icon type="edit" />
        </Button>
        <Button
          type="danger"
          shape="circle"
          onClick={() => this.showConfirmDelete(id)}
          style={{ marginLeft: "5px" }}
        >
          <Icon type="delete" />
        </Button>
      </React.Fragment>
    );

    return (
      <div>
        <strong>Lodging Pricing</strong>
        <Form
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 22 }}
          style={{ margin: "24px 0" }}
        >
          <Form.Item label="Regular price">
            <InputNumber
              value={this.state.cost?.regular}
              defaultValue={0}
              onChange={(e) =>
                this.setState({
                  cost: { ...this.state.cost, regular: e },
                })
              }
              min={1}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            />{" "}
          </Form.Item>
          <Form.Item label="Couple price">
            <InputNumber
              value={this.state.cost?.couple}
              defaultValue={0}
              onChange={(e) =>
                this.setState({
                  cost: { ...this.state.cost, couple: e },
                })
              }
              min={1}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            />{" "}
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              type="primary"
              onClick={() => this.handleEdit("cost")}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
        <Typography.Title level={3}>Lodging Settings</Typography.Title>
        <Tabs defaultActiveKey="1" animated={false}>
          <Tabs.TabPane tab="Rooms" key="1">
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                this.showModal("Add", "Building");
              }}
            >
              + Add Building
            </Button>
            <br />
            <br />
            <strong>Buildings</strong>
            <br />
            <br />
            <Collapse>
              {this.props.buildings &&
                this.props.buildings.map((b, i) => {
                  return (
                    <Collapse.Panel
                      disabled={!this.props.roomData?.length}
                      header={b.name}
                      key={i}
                      extra={extraButtons(
                        this.props.buildings?.filter(
                          (build) => build.id === b.id
                        ),
                        b.id
                      )}
                    >
                      <Suspense fallback={<Skeleton active />}>
                        <RoomsTable
                          id={b.id}
                          roomData={this.state.roomData}
                          added={this.state.added}
                          getAdded={this.getAdded}
                          showModal={this.showModal}
                        />
                      </Suspense>
                      {/* <Table
                      style={{ marginTop: "24px" }}
                      dataSource={dataSource}
                      columns={columns}
                      size="small"
                      pagination={false}
                    /> */}
                    </Collapse.Panel>
                  );
                })}
            </Collapse>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Preferences" key="2">
            <strong>Preferences</strong>
            <br />
            <br />
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                this.showModal("Add", "Preference");
              }}
            >
              + Add Preference
            </Button>
            <Table
              dataSource={prefData}
              columns={prefColumns}
              style={{ marginTop: "24px" }}
            />
          </Tabs.TabPane>
        </Tabs>
        <Modal
          title={
            <React.Fragment>
              {this.state.modal.action} {this.state.modal.type}
            </React.Fragment>
          }
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form
            style={{ margin: "50px 0" }}
            layout={
              this.state.modal.type === "Preference" ? "inline" : "horizontal"
            }
          >
            {this.state.modal.type !== "Preference" ? (
              <Form.Item label={this.state.modal.type + " Name"}>
                <Input
                  value={this.state.buildingName || this.state.roomName}
                  onChange={(e) => {
                    this.state.modal.type === "Room"
                      ? this.setState({ roomName: e.target.value })
                      : this.setState({ buildingName: e.target.value });
                  }}
                />
              </Form.Item>
            ) : (
              <React.Fragment>
                <Form.Item label="Name">
                  <Input
                    value={this.state.prefName}
                    onChange={(e) => {
                      this.setState({ prefName: e.target.value });
                    }}
                  />
                </Form.Item>
                <Form.Item label="Price">
                  <InputNumber
                    value={this.state.prefPrice}
                    onChange={(e) => {
                      this.setState({ prefPrice: e });
                    }}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </React.Fragment>
            )}
            {this.state.modal.type === "Room" ? (
              <Form.Item label="Beds">
                <InputNumber
                  value={this.state.beds}
                  onChange={(e) => {
                    this.setState({ beds: e });
                  }}
                  min={0}
                  placeholder="Beds"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            ) : null}
            {this.state.modal.type === "Room" ? (
              <Form.Item label="Prefrences">
                <Select
                  mode="tags"
                  value={this.state.roomPreference && this.state.roomPreference}
                  onChange={(e) => {
                    this.setState({ roomPreference: e });
                  }}
                >
                  {this.state.preferences.map((pref, i) => {
                    return (
                      <Select.Option value={pref.id} key={i}>
                        {pref.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            ) : null}
          </Form>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = ({ orderReducer }) => {
  return {
    cost: orderReducer.cost,
    preferences: orderReducer.preferences,
    buildings: orderReducer.buildings,
    roomData: orderReducer.roomData,
  };
};

export default connect(mapStateToProps)(Lodging);
