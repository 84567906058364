import React from "react";
import { Typography, Table, Button, DatePicker, Form, Skeleton } from "antd";
import { connect } from "react-redux";
import { axiosInstance } from "../../httpHandler";
import moment from "moment";
import { Link } from "react-router-dom";

class TodaysLodging extends React.Component {
  state = {
    date: moment(new Date()).format("ddd MMM DD YYYY"),
    data: [],
    mainData: { customer: [], data: [], order: [] },
    datas: [],
  };

  componentDidMount() {
    this.fetchData();
  }

  changeDate = async (e) => {
    await this.setState(
      {
        date: moment(e).format("ddd MMM DD YYYY"),
        mainData: { ...this.state.mainData, data: [] },
      },
      () => {
        this.fetchData();
      }
    );
  };

  fetchData() {
    this.setState({ data: [] }, () => {
      const orderData =
        this.props.getOrderData && this.props.getOrderData.orders;
      if (this.props.getOrderData && this.props.getOrderData.orders) {
        this.setState(
          {
            data: this.props.getOrderData && this.props.getOrderData.orders,
          },
          () => {
            this.mainData();
          }
        );
      } else {
        axiosInstance
          .get("/orders", { handlerEnabled: true })
          .then(async (response) => {
            await this.setState({ data: response.data.orders }, () => {
              this.mainData();
            });
          });
      }
      // const ordData = this.state.data;
      // const data = [];
      // const data2 = [];
      // const customer = [];
      // const order = [];
      // ordData.length > 0 &&
      //   ordData.map((arr, i) => {
      //     Object.entries(arr.dates).map((res, i) => {
      //       if (res[0] === this.state.date) {
      //         // console.info("TodaysLodging.js", { res: res[0] === this.state.date });
      //         // console.info("TodaysLodging.js", { data: res[1] });
      //         // console.info("TodaysLodging.js", { dataArray: data });
      //         data2.push({
      //           data: res[1],
      //           id: arr.orderId,
      //           customer: arr.customer
      //         });
      //         data.push(res[1]);
      //         order.push(arr.orderId);
      //         customer.push(arr.customer);
      //         this.setState({
      //           mainData: {
      //             customer: customer,
      //             data: data,
      //             order: order
      //           },
      //           datas: data2
      //         });
      //       }
      //     });
      //   });
      // })
      // .catch(error => {});
    });
  }

  mainData = () => {
    const ordData = this.state.data;
    const data = [];
    const data2 = [];
    const customer = [];
    const order = [];
    ordData.length > 0 &&
      ordData.map((arr, i) => {
        arr.dates &&
          Object.entries(arr.dates).map((res, i) => {
            if (res[0] === this.state.date) {
              data2.push({
                data: res[1],
                id: arr.orderId,
                customer: arr.customer,
              });
              data.push(res[1]);
              order.push(arr.orderId);
              customer.push(arr.customer);
              this.setState({
                mainData: {
                  customer: customer,
                  data: data,
                  order: order,
                },
                datas: data2,
              });
            }
          });
      });
  };

  render() {
    const { mainData } = this.state;
    const roomData =
      this.state.datas.length > 0 &&
      this.state.datas
        .filter((dt) => dt.data.room.length > 0)
        .map(
          (data, i) =>
            // this.state.mainData.customer.map((c, i) => ({
            ({
              key: i,
              room: data.data.room,
              preferences: data.data.room,
              customer: data.customer.firstName + " " + data.customer.lastName,
              orderId: data.id,
            })
          // }))
        );
    // console.info("TodaysLodging.js", { roomData: roomData });
    const dataSource = [
      {
        key: "1",
        building: "Main",
        room: "Main 1",
        customer: "Yaakov Green",
        orderId: "KR2356",
      },
      {
        key: "2",
        building: "Main",
        room: "Main 4",
        customer: "Yaakov Green",
        orderId: "KR2356",
      },
      {
        key: "3",
        building: "Main",
        room: "Main 5B",
        customer: "Yaakov Green",
        orderId: "KR2356",
      },
      {
        key: "4",
        building: "Boretzky",
        room: "Boretzky 1",
        customer: "Shmiel Szoffer",
        orderId: "KR3165",
      },
      {
        key: "5",
        building: "Boretzky",
        room: "Boretzky 2",
        customer: "Shmiel Szoffer",
        orderId: "KR3165",
      },
      {
        key: "7",
        building: "Shisha",
        room: "Shisha 2",
        customer: "Shulem Gross",
        orderId: "KR36215",
      },
      {
        key: "8",
        building: "Sisha",
        room: "Shisha 4",
        customer: "Shulem Gross",
        orderId: "KR36215",
      },
      {
        key: "9",
        building: "Lomosh",
        room: "Lomosh 3",
        customer: "Shulem Gross",
        orderId: "KR36215",
      },
    ];

    const columns = [
      {
        title: "Room",
        dataIndex: "room",
        key: "room",
        render: (room) => {
          let rm = "";
          room.map((r) => {
            console.info("TodaysLodging.js", { room: r });

            return (rm = r.room);
          });
          return <strong>{rm}</strong>;
        },
      },
      {
        title: "Building",
        dataIndex: "preferences",
        key: "preferences",
        render: (preferences) => {
          let building = "";
          preferences.map((r) => {
            return (building = r.room);
          });
          return <strong>{building.split(" ")[0]}</strong>;
        },
      },
      {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        render: (customer) => {
          // console.info("TodaysLodging.js", { customer: customer });
          return <strong>{customer}</strong>;
        },
      },
      {
        title: "Order ID",
        dataIndex: "orderId",
        key: "orderId",
        render: (orderId) => {
          return (
            <Link to={`/orders/` + orderId}>
              <strong>{"KRO" + orderId}</strong>
            </Link>
          );
        },
      },
    ];
    return (
      <React.Fragment>
        <Typography.Title level={3}>
          Rooms for {moment(this.state.date).format("dddd DD/MM/YYYY")}
        </Typography.Title>
        <Form layout="inline">
          <Form.Item label="Switch date">
            <DatePicker
              onChange={(e) => {
                this.changeDate(e);
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary">Submit</Button>
          </Form.Item>
        </Form>

        {this.state.mainData.data.length > 0 ? (
          <Table
            dataSource={roomData}
            columns={columns}
            style={{ marginTop: "24px" }}
          />
        ) : (
          <Skeleton active />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ orderReducer }) => ({
  getOrderData: orderReducer.getOrderData,
});

export default connect(mapStateToProps)(TodaysLodging);
