// prettier-ignore

import {
  ADD_ORDER,
  ADD_ORDER_REQUEST,
  ADD_USER,
  ADD_USER_ERROR,
  GET_USERS,
  GET_USERS_REQUEST,
  GET_USERS_ERROR,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_ERROR,
  ADD_CUSTOMER,
  CLEAR_DATA,
  GET_ORDER_SUCCESS,
  GET_ORDER_ERROR,
  GET_ORDER_REQUEST,
  SHOW_MODAL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_ERROR,
  GET_SINGLE_ORDER_REQUEST,
  GET_SINGLE_ORDER_SUCCESS,
  GET_SINGLE_ORDER_ERROR,
  GET_CONTACTS,
  GET_ROOM_PRICE,
  GET_PREFERENCES,
  GET_BUILDINGS,
  GET_ROOMS,
  GET_FOOD_PRICE,
  GET_FOOD_PREFERENCES,
  GET_SINGLE_CUSTOMER,
  GET_CURRENCY,
  GET_SOFT_INQUIRIES,
  GET_TRANSACTIONS,
  GET_ORDER_BY_DATE_REQUEST,
  GET_ORDER_BY_DATE,
  GET_ORDER_BY_DATE_ERROR,
  GET_TRANSACTIONS_ORDER_ID,
  ADD_DONATION_REQUEST,
  ADD_DONATION_SUCCESS,
  ADD_DONATION_ERROR,
  GET_ROOMS_REQUEST,
  GET_NOTES,
  GET_AUTH_USER_DATA,
  TODAYS_ARRIVALS,
  TODAYS_DEPARTURES,
  TODAYS_CURRENT_ORDERS,
  UPDATE_REDUCER,
  ADD_PAYMENT,
  HOLD_RELESE_HANDLER,
  PAYMENT_TYPE_PAY_NOW
} from "./orderActionTypes";
import axios from "axios";
import { APICONFIG } from "../AppConstants";
import { axiosInstance } from "../httpHandler";
import { message } from "antd";
import firebaseApp from "../config/fbConfig";

export const getContacts = () => {
  return dispatch => {
    axiosInstance.get("/contacts", { handlerEnabled: true }).then(res => {
      dispatch({
        type: GET_CONTACTS,
        payload: res.data.contacts,
        loading: false
      });
    });
  };
};

export const addOrder = (key, data) => {
  return dispatch => {
    dispatch({
      type: ADD_ORDER,
      payload: data,
      key: key
    });
  };
};

export const addOrderData = data => {
  return dispatch => {
    dispatch({
      type: ADD_ORDER_REQUEST
    });
    axios({
      method: "POST",
      url: `${APICONFIG.API_BASE_URL}/orders`,
      data
    })
      .then(response => {
        dispatch({
          type: ADD_ORDER_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: ADD_ORDER_ERROR,
          payload: error
        });
      });
  };
};

export const addCustomer = (key, payload) => dispatch =>
  dispatch({ type: ADD_CUSTOMER, payload, key });

export const addUser = data => {
  return dispatch => {
    // axios({
    //   method: "POST",
    //   url: `${APICONFIG.API_BASE_URL}/users/add`,
    //   data
    // })
    axiosInstance
      .post("/users/add", data, { handlerEnabled: true })
      .then(async response => {
        dispatch({
          type: ADD_USER,
          payload: response.data
        });
        message.success("User has been added successfully.");
        await firebaseApp.auth().sendPasswordResetEmail(response.data.email);
        dispatch(getUsers());
      })
      .catch(error => {
        dispatch({
          type: ADD_USER_ERROR,
          payload: error
        });
      });
  };
};

export const getOrderData = () => {
  return dispatch => {
    dispatch({
      type: GET_ORDER_REQUEST,
      loading: true
    });
    axiosInstance
      .get("/orders", { handlerEnabled: true })
      .then(response => {
        dispatch({
          type: GET_ORDER_SUCCESS,
          payload: response.data,
          loading: false
        });
      })
      .catch(error => {
        dispatch({
          type: GET_ORDER_ERROR,
          loading: false
        });
      });
  };
};

export const getSingleOrderData = id => {
  return dispatch => {
    dispatch({ type: GET_SINGLE_ORDER_REQUEST, loading: true });
    axiosInstance
      .get(`/order/${id}`, { handlerEnabled: true })
      .then(async response => {
        dispatch({
          type: GET_SINGLE_ORDER_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({ type: GET_SINGLE_ORDER_ERROR, loading: false });
      });
  };
};

export const clearData = () => dispatch => {
  dispatch({ type: CLEAR_DATA });
};
export const updateReducer = data => dispatch => {
  dispatch({ type: UPDATE_REDUCER, payload: data });
};
export const addPayment = data => dispatch => {
  dispatch({ type: ADD_PAYMENT, payload: data });
};

// export const showModal = visible => {
//   console.info("orderActionCreator.js", { visible: visible });
//   return dispatch => {
//     dispatch({ type: SHOW_MODAL, visible: visible });
//     // !visible && dispatch(clearData());
//   };
// };

export const showModal = (visible, orderId, orderType, disabled = false) => {
  console.info("orderActionCreator.js", {
    visible,
    orderId,
    orderType,
    disabled
  });
  return dispatch => {
    dispatch({
      type: SHOW_MODAL,
      visible: visible,
      orderId: orderId,
      orderType,
      disabled
    });
    if (visible !== true) {
      // dispatch(clearData());
    }
  };
};

export const updateOrderData = (id, data) => {
  return dispatch => {
    dispatch({ type: UPDATE_ORDER_REQUEST, loading: true });
    axiosInstance
      .patch(
        `/order/${id}`,
        {
          data
        },
        { handlerEnabled: true }
      )
      .then(async function(response) {
        // const storageRef = firebaseApp.storage().ref();
        // const urls = await addDownloadUrls(response.data.loan, storageRef);
        dispatch({
          type: UPDATE_ORDER_SUCCESS,
          payload: response.data,
          loading: false,
          updateOrderSuccess: true
        });
        dispatch(getOrderData());
        dispatch(showModal(false));
        dispatch(clearData());
      })
      .catch(function(error) {
        dispatch({ type: UPDATE_ORDER_ERROR, loading: false });
      });
  };
};

export const getUsers = () => {
  return dispatch => {
    dispatch({ type: GET_USERS_REQUEST, loading: true });
    axiosInstance
      .get(`/users`, { handlerEnabled: true })
      .then(async function(response) {
        dispatch({
          type: GET_USERS,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({ type: GET_USERS_ERROR, loading: false });
      });
  };
};
export const getRoomPrice = () => {
  return dispatch => {
    axiosInstance.get("/getroomprice", { handlerEnabled: true }).then(res => {
      dispatch({
        type: GET_ROOM_PRICE,
        payload: res.data.data
      });
    });
  };
};
export const getRooms = () => {
  return dispatch => {
    dispatch({ type: GET_ROOMS_REQUEST, loading: true });
    axiosInstance.get("/getrooms", { handlerEnabled: true }).then(res => {
      dispatch({
        type: GET_ROOMS,
        payload: res.data.data,
        loading: false
      });
    });
  };
};
export const getPreferences = () => {
  return dispatch => {
    axiosInstance.get("/getpreferences", { handlerEnabled: true }).then(res => {
      dispatch({
        type: GET_PREFERENCES,
        payload: res.data.data
      });
    });
  };
};
export const getBuilding = () => {
  return dispatch => {
    axiosInstance.get("/getbuilding", { handlerEnabled: true }).then(res => {
      dispatch({
        type: GET_BUILDINGS,
        payload: res.data.data
      });
    });
  };
};

export const getFoodPrice = () => {
  return dispatch => {
    axiosInstance.get("/getfoodprice", { handlerEnabled: true }).then(res => {
      dispatch({
        type: GET_FOOD_PRICE,
        payload: res.data.data
      });
    });
  };
};
export const getFoodPreferences = () => {
  return dispatch => {
    axiosInstance
      .get("/getfoodpreferences", { handlerEnabled: true })
      .then(res => {
        dispatch({
          type: GET_FOOD_PREFERENCES,
          payload: res.data.data
        });
      });
  };
};

export const getSingleCustomer = id => {
  return dispatch => {
    axiosInstance.get(`/customer/${id}`, { handlerEnabled: true }).then(res => {
      dispatch({
        type: GET_SINGLE_CUSTOMER,
        payload: res.data
      });
    });
  };
};
export const getOrderByDate = (date,key) => {
  return dispatch => {
    dispatch({ type: GET_ORDER_BY_DATE_REQUEST,  loading: key === "placeRoom" || key === "removeRoom" || key === "applyToRoom" ? false : true });
    axiosInstance
      .get(`/getOrdersByDate/${date}`, { handlerEnabled: true })
      .then(res => {
        dispatch({
          type: GET_ORDER_BY_DATE,
          payload: res.data
        });
      })
      .catch(error =>
        dispatch({ type: GET_ORDER_BY_DATE_ERROR, loading: false })
      );
  };
};

export const addDonation = data => {
  return dispatch => {
    dispatch({ type: ADD_DONATION_REQUEST, loading: true });
    axiosInstance
      .post(`/addDonation`, data, { handlerEnabled: true })
      .then(res => {
        dispatch({
          type: ADD_DONATION_SUCCESS,
          payload: res,
          loading: false
        });
      })
      .catch(error => dispatch({ type: ADD_DONATION_ERROR, loading: false }));
  };
};

export const getNotes = () => {
  return dispatch => {
    axiosInstance
      .get(`/notes`, { handlerEnabled: true })
      .then(res => {
        dispatch({ type: GET_NOTES, payload: res.data });
      })
      .catch(error => console.log(error));
  };
};

export const getAuthUserData = email => {
  return dispatch => {
    axiosInstance
      .get(`/getAuthUser/${email}`, { handlerEnabled: true })
      .then(res => {
        dispatch({ type: GET_AUTH_USER_DATA, payload: res.data });
      })
      .catch(error => console.log(error));
  };
};
export const getCurrency = () => {
  return dispatch => {
    axiosInstance
      .get(`/getcurrency`, { handlerEnabled: true })
      .then(res => {
        dispatch({ type: GET_CURRENCY, payload: res.data });
      })
      .catch(error => console.log(error));
  };
};
export const getInquiries = () => {
  return dispatch => {
    axiosInstance
      .get(`/getinquiry`, { handlerEnabled: true })
      .then(res => {
        dispatch({ type: GET_SOFT_INQUIRIES, payload: res.data });
      })
      .catch(error => console.log(error));
  };
};
export const getTransactions = () => {
  return dispatch => {
    axiosInstance
      .get(`/transactions`, { handlerEnabled: true })
      .then(res => {
        dispatch({ type: GET_TRANSACTIONS, payload: res.data });
      })
      .catch(error => console.log(error));
  };
};

/**
 *
 * @param {String} orderId  orderid/orderno number of the order
 * @param {*} orderNo  if there is  orderno Instead of orderid false else true
 */
export const getTransactionsByOrderId = (orderId, orderNo = false) => {
  return dispatch => {
    axiosInstance
      .get(`/transactions/${orderId}/${orderNo}`, { handlerEnabled: true })
      .then(res => {
        dispatch({ type: GET_TRANSACTIONS_ORDER_ID, payload: res.data });
      })
      .catch(error => console.log(error));
  };
};
export const todaysArrivals = date => {
  return dispatch => {
    axiosInstance
      .get(`/todaysArrival/${date}`, {
        handlerEnabled: true
      })
      .then(res => {
        dispatch({ type: TODAYS_ARRIVALS, payload: res.data });
      })
      .catch(() => console.log("Error"));
  };
};
export const todaysDepartures = date => {
  return dispatch => {
    axiosInstance
      .get(`/todaysDeparture/${date}`, { handlerEnabled: true })
      .then(res => {
        dispatch({ type: TODAYS_DEPARTURES, payload: res.data });
      })
      .catch(() => message.error("Error"));
  };
};

export const todaysCurrentOrders = date => {
  return dispatch => {
    axiosInstance
      .get(`/todayCurrentOrders/${date}`, {
        handlerEnabled: true
      })
      .then(res => {
        dispatch({ type: TODAYS_CURRENT_ORDERS, payload: res.data });
      })
      .catch(() => console.log("Error"));
  };
};

export const holdReleseHandler = data => {
  return dispatch => {
    dispatch({
      type: HOLD_RELESE_HANDLER,
      payload: data
    });
  };
};

export const paymentTypePayNow = data => {
  return dispatch => {
    dispatch({
      type: PAYMENT_TYPE_PAY_NOW,
      payload: data
    });
  };
};
