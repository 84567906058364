import React from "react";
import "antd/dist/antd.css";
import { Modal, Button } from "antd";
import NewOrder from "../NewOrder/NewOrder";
import { connect } from "react-redux";
import { showModal, clearData } from "../../actions/orderActionCreator";
import DonationForm from "../NewOrder/DonationForm";
import FoodForm from "../NewOrder/FoodForm";
import ProductsForm from "../NewOrder/ProductsForm";
import ShuttleForm from "../NewOrder/ShuttleForm";
import MikvahForm from "../NewOrder/MikvahForm";

class CustNewOrder extends React.Component {
  state = {
    visible: false,
    orderForm: false,
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.props.dispatch(showModal(false));
    this.props.dispatch(clearData());
  };

  handleClose = () => {
    this.props.dispatch(showModal(false));
  };

  startLodgingOrder = () => {
    this.setState({
      orderForm: true,
    });
  };

  render() {
    let importForm = <p>Initial</p>;

    switch (this.props.orderType) {
      case "Lodging":
        importForm = (
          <NewOrder closeModel={() => this.handleClose()} variant="edit" />
        );
        break;
      case "Donation":
        importForm = (
          <DonationForm
            closeModel={() => this.handleClose()}
            variant="edit"
            disabled={this.props.disabled}
          />
        );
        break;
      case "Foods":
        importForm = (
          <FoodForm
            closeModel={() => this.handleClose()}
            variant="edit"
            disabled={this.props.disabled}
          />
        );
        break;
      case "Products":
        importForm = (
          <ProductsForm
            closeModel={() => this.handleClose()}
            variant="edit"
            disabled={this.props.disabled}
          />
        );
        break;
      case "Shuttle":
        importForm = (
          <ShuttleForm
            closeModel={() => this.handleClose()}
            variant="edit"
            disabled={this.props.disabled}
          />
        );
        break;
      case "Mikvah":
        importForm = (
          <MikvahForm
            closeModel={() => this.handleClose()}
            variant="edit"
            disabled={this.props.disabled}
          />
        );
        break;

      default:
        importForm = <p>Default</p>;
    }
    return (
      <div>
        <Modal
          title={"Update " + this.props.orderType + " Order"}
          visible={this.props.visible}
          onCancel={this.handleCancel}
          footer={
            this.props.orderType ? (
              <Button key="back" onClick={this.handleCancel}>
                Cancel
              </Button>
            ) : null
          }
          width={this.props.orderType === "Lodging" ? "1190px" : "521px"}
        >
          {importForm}
        </Modal>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   return {
//     visible: state.visible
//   };
// };

const mapStateToProps = ({ orderReducer }) => ({
  visible: orderReducer.visible,
  orderType: orderReducer.orderType,
  disabled: orderReducer.disabled,
});

export default connect(mapStateToProps)(CustNewOrder);
