export const handleCountCurrency = (amount, e, currency) => {
  let value = 0;
  if (e === "RUBL") {
    value = amount * currency.Rubl;
  } else if (e === "POUND") {
    value = amount * currency.Pound;
  } else if (e === "ZlATY") {
    value = amount * currency.Zlaty;
  } else if (e === "SHEKEL") {
    value = amount * currency.Shekel;
  } else if (e === "HRYVNIA") {
    value = amount * currency.Hryvnia;
  } else if (e === "EURO") {
    value = amount * currency.Euro;
  } else if (e === "USD") {
    value = amount;
  }

  return value;
};

export const handleCountCurrency2 = (amount, e, currency) => {
  let value = 0;
  if (e === "RUBL") {
    value = amount / currency.Rubl;
  } else if (e === "POUND") {
    value = amount / currency.Pound;
  } else if (e === "ZlATY") {
    value = amount / currency.Zlaty;
  } else if (e === "SHEKEL") {
    value = amount / currency.Shekel;
  } else if (e === "HRYVNIA") {
    value = amount / currency.Hryvnia;
  } else if (e === "EURO") {
    value = amount / currency.Euro;
  } else if (e === "USD") {
    value = amount;
  }
  return Math.round(value * 100) / 100;
};
