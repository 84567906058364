import React, { Component } from "react";
import moment from "moment";
// prettier-ignore
import { Form, Input, Radio, InputNumber, DatePicker, TimePicker, Button, Col, Select, Row, Checkbox, message, Typography, Modal, } from "antd";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import { KEY } from "../../AppConstants";
import {
  getContacts,
  addOrder,
  addCustomer,
  getOrderData,
} from "../../actions/orderActionCreator";
import { store } from "../../store";
import { connect } from "react-redux";
import { axiosInstance } from "../../httpHandler";
import TextArea from "antd/lib/input/TextArea";
import ReactPhoneInput from "react-phone-input-2";
import {
  handleCountCurrency,
  handleCountCurrency2,
} from "../../config/currencyConvertor";

class ShuttleForm extends Component {
  state = {
    shuttleType: "from",
    people: 0,
    price: 0,
    date: new Date(),
    customerId: "",
    time: "",
    selectedCard: [],
    dAddresses: [{ address: "", address2: "", city: "", state: "", zip: "" }],
    addresses: [{ address: "", address2: "", city: "", state: "", zip: "" }],
    search: "",
    search1: "",
    searchVal: "",
    searchVal1: "",
    index: null,
    paymentType: "cash",
    existingCustomer: true,
    userCards: [],
    creditCards: [
      {
        cardName: "",
        cardNumber: "",
        cardCVV: "",
        cardExpiry: "",
        paymentAmount: "",
      },
    ],
    cheque: {
      amount: "",
      chequeName: "",
      chequeDate: "",
      accountNumber: "",
      currency: "USD",
      routingNo: "",
    },
    card: [],
    refNumber: "",
    total: 0,
    discount: 0,
    currencyTotal: 0,
    paidCurrency: "USD",
    customerData: "",
    memos: [],
    custId: "",
    index2: 0,
    usdDonation: 0,
    donation: 0,
    grandTotal: 0,
  };

  countCurrency = (amount, e, update = false) => {
    const currency = this.props.currency.data;
    let value = 0;
    value = handleCountCurrency(amount, e, currency);

    update && this.setState({ grandTotal: Math.round(value * 100) / 100 });

    this.setState({ currencyTotal: Math.round(value * 100) / 100 }, () =>
      this.setState(
        {
          donation: this.state.grandTotal - Math.round(value * 100) / 100,
        },
        () =>
          this.props.form.setFields({
            donation: {
              value: Math.round(this.state.donation * 100) / 100,
            },
            currencyTotal: {
              value: this.state.grandTotal,
            },
          })
      )
    );
  };

  handleAddNewCustomer = (e) => {
    this.setState({
      existingCustomer: !e.target.checked,
      customer: "",
    });
    store.dispatch(addCustomer("customerType", "1-Time"));
  };
  handleSelectedCard = (v) => {
    let data = [];
    let object = v ? JSON.parse(v) : null;
    data.push(object);

    this.setState({
      card: v ? data : [],
    });
  };
  handleChange = (key, value) => {
    this.setState({ [key]: value });
    // store.dispatch(addCustomer(key, value));
  };
  handleNewCustomer = (key, value) => {
    // this.setState({ [key]: value });
    store.dispatch(addCustomer(key, value));
  };

  handlePaymentTypeChange = (e) => {
    this.setState({
      paymentType: e.target.value,
    });
  };
  handleChange2 = (key, value) => {
    this.setState({ [key]: value });
    store.dispatch(addCustomer(key, value));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        if (this.props.variant === "edit") {
          const data = {
            customerId: this.state.custId,
            shuttleType: this.state.shuttleType,
            people: this.state.people,
            price: this.state.price,
            total: this.state.total,
            date: this.state.date,
            time: this.state.time,
            dAddresses: this.state.dAddresses,
            discount: this.state.discount,
            // currencyTotal: this.state.currencyTotal,
            // paidCurrency: this.state.paidCurrency,
            // donation: this.state.donation,
            // usdDonation: this.state.usdDonation,
          };
          axiosInstance
            .patch(`/editOrder/${this.props.orderData[0].id}`, data, {
              handlerEnabled: true,
            })
            .then(async (res) => {
              console.info("NewOrder.js", { res: res });
              message.success("Order Updated");

              this.props.dispatch(getOrderData());

              this.props.closeModel();
            })
            .catch((error) => console.log(error));
        } else {
          const data = {
            shuttleOrder: {
              shuttleType: this.state.shuttleType,
              people: this.state.people,
              price: this.state.price,
              total: this.state.total,
              date: this.state.date,
              time: this.state.time,
              dAddresses: this.state.dAddresses,
              discount: this.state.discount,
              currencyTotal: this.state.currencyTotal,
              paidCurrency: this.state.paidCurrency,
              donation: this.state.donation,
              usdDonation: this.state.usdDonation,
            },
            payment: {
              addresses: this.state.addresses,
              paymentType: this.state.paymentType,
              creditCards: this.state.creditCards,
              cheque: this.state.cheque,
              card: this.state.card,
              cardRefNumber: this.state.cardRefNumber,
              refnumber: this.state.refNumber,
              cardAmount: this.state.cardAmount,
            },
            customerId: this.state.customerId,
            customer: { ...this.props.customer, ...this.state.creditCards },
            addresses: this.state.addresses,
            existingCustomer: this.state.existingCustomer,
          };

          axiosInstance
            .post("/shuttleOrder", data, { handlerEnabled: true })
            .then(async (res) => {
              console.info("NewOrder.js", { res: res });
              // message.success("Processing complete!");
              if (res.data.Error) {
                // const resStatus = res.data.Error.split("&")
                //   .map((obj) => obj.split("="))
                //   .find((v) => v[0] === "xStatus")[1];
                const error = res.data.Error.split("&")
                  .map((obj) => obj.split("="))
                  .find((v) => v[0] === "xError")[1];
                message.error(error.replace(/[+]/g, " "));
              } else {
                message.success("Processing complete!");
                this.props.dispatch(getOrderData());

                await this.props.closeModel();
              }
            })
            .catch((error) => console.log(error));
        }
      }
    });
  };
  countDiscount = (e) => {
    let total = this.state.people * this.state.price - e;
    this.setState({
      discount: e,
      total,
    });
    this.countCurrency(total, this.state.paidCurrency);
  };
  countTotal = (people, price) => {
    let total = people * price - this.state.discount;
    this.setState({ total });
    this.countCurrency(total, this.state.paidCurrency, true);
  };
  handleAddress = (key, value, i) => {
    const { addresses } = this.state;
    addresses[i] = { ...addresses[i], [key]: value };
    this.setState({ addresses });
  };
  handleDAddress = (key, value, i) => {
    const { dAddresses } = this.state;
    dAddresses[i] = { ...dAddresses[i], [key]: value };
    this.setState({ dAddresses });
  };
  handleCreditcards = (key, value, i) => {
    const { creditCards } = this.state;
    creditCards[i] = {
      ...creditCards[i],
      [key]: value,
    };
    this.setState({ creditCards });
    // store.dispatch(addCustomer("creditCards", creditCards));
  };

  fixCard = () => {
    let data = [];
    let cardAmount = this.state.selectedCardAmount || 0;
    let amount = "xAuthAmount";
    let object = this.state.object;
    object[amount] = cardAmount;
    data.push(object);
    this.setState({ selectedCard: data }, () => {
      store.dispatch(addOrder("selectedCard", this.state.selectedCard));
    });
  };

  getUserCards = (e) => {
    let cards = [];
    this.props.contactsData.map(
      (cust, i) => cust.id === e && cards.push(cust.customerCards)
    );
    this.setState({ userCards: cards[0] || [] });
  };
  componentDidMount() {
    this.props.dispatch(getContacts());
    if (this.props.orderData) {
      this.setState(
        {
          custId: this.props.orderData[0].customerId,
          shuttleType: this.props.orderData[0].shuttleType,
          time: this.props.orderData[0].time,
          dAddresses: this.props.orderData[0].address,
          people: this.props.orderData[0].people,
          price: this.props.orderData[0].price,
          date: moment(this.props.orderData[0].orderDate),
          paidCurrency: this.props.orderData[0].paidCurrency,
          discount: this.props.orderData[0].discount,
        },
        () => {
          let total =
            this.state.people * this.state.price - this.state.discount;
          this.setState({ total });
          this.countCurrency(total, this.state.paidCurrency, true);
        }
      );
    }
  }

  handleCheque = (key, value) => {
    const { cheque } = this.state;
    let a = cheque;
    a = {
      ...a,
      [key]: value,
    };
    this.setState({ cheque: a });
    // store.dispatch(addOrder("cheque", a));
  };

  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    this.setState({
      search: { [this.state.index]: "" },
      searchVal: geocodedPrediction.formatted_address,
    });
    this.handleAddress(
      "address",
      geocodedPrediction.formatted_address,
      this.state.index
    );
  };
  handleSelectSuggest2 = (geocodedPrediction, originalPrediction) => {
    this.setState({
      search1: { [this.state.index]: "" },
      searchVal1: geocodedPrediction.formatted_address,
    });
    this.handleDAddress(
      "address",
      geocodedPrediction.formatted_address,
      this.state.index
    );
  };
  handleCustomerChange = (key, value) => {
    const { contactsData } = this.props;
    this.setState({ index2: 0 }, () => {
      contactsData.map((contact) => {
        if (contact.id === value) {
          console.info("CustomerForm.js", { 1: value, 2: contact.id });
          this.setState(
            {
              customerData: contact,
              memos: contact.memo ? contact.memo : [],
              custId: contact.id,
            },
            () => {
              if (this.state.memos.length) {
                this.showMemoModal(this.state.memos);
              }
            }
          );
        }
      });
    });
    this.setState({ [key]: value });
  };
  showMemoModal = () => {
    let thiss = this;
    Modal.confirm({
      title: "Account memo",
      content: this.state.memos[this.state.index2],
      cancelText: "Dismiss",
      okText: "Delete memo",
      onCancel() {
        thiss.setState(
          { index2: thiss.state.memos.length > 0 ? thiss.state.index2 + 1 : 0 },
          () => {
            if (thiss.state.index2 <= thiss.state.memos.length - 1) {
              thiss.showMemoModal(thiss.state.memos);
            }
          }
        );
      },
      onOk() {
        let a = thiss.state.memos;
        a.splice(thiss.state.index2, 1);
        thiss.setState({ memos: a }, () => {
          axiosInstance
            .put(
              `/deletememo/${thiss.state.custId}`,
              {
                memo: thiss.state.memos,
              },
              { handlerEnabled: true }
            )
            .then((res) => {
              message.success("Memo Deleted succesfully");
              thiss.props.dispatch(getContacts());
              if (thiss.state.index2 <= thiss.state.memos.length - 1) {
                thiss.showMemoModal(thiss.state.memos);
              }
            })
            .catch((err) => console.log(err));
        });
      },
    });
  };

  handleDonation = (e) => {
    let donation = Math.round((e - this.state.currencyTotal) * 100) / 100;
    let usdDonation = handleCountCurrency2(
      donation,
      this.state.paidCurrency,
      this.props.currency?.data
    );
    this.setState({
      donation,
      usdDonation,
    });
  };
  render() {
    const { getFieldDecorator, setFields } = this.props.form;
    let currencies = [
      { sym: "$", value: "USD" },
      { sym: "zl", value: "ZlATY" },
      { sym: "₽", value: "RUBL" },
      { sym: "£", value: "POUND" },
      { sym: "₪", value: "SHEKEL" },
      { sym: "₴", value: "HRYVNIA" },
      { sym: "€", value: "EURO" },
    ];
    const currencyOptions = (
      <Select
        disabled={this.props.disabled}
        value={this.state.paidCurrency}
        style={{ width: 80 }}
        className="select-before"
        onChange={(e) => {
          this.setState({ currency: e, paidCurrency: e });
          this.countCurrency(this.state.total, e);
        }}
      >
        {currencies.map((currency, i) => (
          <Select.Option value={currency.value} key={i}>
            {currency.sym}
          </Select.Option>
        ))}
      </Select>
    );
    return (
      <div>
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          onSubmit={this.handleSubmit}
          maskClosable={false}
        >
          <Form.Item label="Shuttle type">
            <Radio.Group
              disabled={this.props.disabled}
              defaultValue={this.state.shuttleType}
              buttonStyle="solid"
              onChange={(e) => this.handleChange("shuttleType", e.target.value)}
            >
              <Radio.Button value="from">From</Radio.Button>
              <Radio.Button value="to">To</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Address">
            <ReactGoogleMapLoader
              params={{
                key: KEY.MAP_KEY,
                libraries: "places,geocode",
              }}
              render={(googleMaps) =>
                googleMaps && (
                  <ReactGooglePlacesSuggest
                    googleMaps={googleMaps}
                    autocompletionRequest={{
                      input: this.state.search1[0],
                    }}
                    onNoResult={this.handleNoResult}
                    onSelectSuggest={this.handleSelectSuggest2}
                    onStatusUpdate={this.handleStatusUpdate}
                    textNoResults="No Address Found"
                    customRender={(prediction) => (
                      <div className="customWrapper">
                        {prediction
                          ? prediction.description
                          : "No Address Found"}
                      </div>
                    )}
                  >
                    <Form.Item>
                      <Input
                        disabled={this.props.disabled}
                        placeholder={"Address"}
                        value={this.state.dAddresses[0].address}
                        onChange={(e) => {
                          this.handleDAddress("address", e.target.value, 0);
                          this.setState({
                            search1: { 0: e.target.value },
                          });
                          this.setState({ index: 0 });
                        }}
                      />
                    </Form.Item>
                  </ReactGooglePlacesSuggest>
                )
              }
            />
          </Form.Item>

          <Form.Item label="People">
            {getFieldDecorator("people", {
              initialValue: this.state.people,
              rules: [
                {
                  required: true,
                  message: "Please enter Peoples Count!",
                },
              ],
            })(
              <InputNumber
                disabled={this.props.disabled}
                style={{ width: "100%" }}
                min={0}
                onChange={(e) => {
                  this.handleChange("people", e);
                  this.countTotal(e, this.state.price);
                }}
                defaultValue={this.state.people}
              />
            )}
          </Form.Item>
          <Form.Item label="Price">
            {getFieldDecorator("price", {
              initialValue: this.state.price,
              rules: [
                {
                  required: true,
                  message: "Please enter Price!",
                },
              ],
            })(
              <InputNumber
                disabled={this.props.disabled}
                type="number"
                style={{ width: "100%" }}
                onChange={(e) => {
                  // this.handleChange("price", e);
                  this.setState({ price: e });
                  this.countTotal(this.state.people, e);
                }}
                min={0}
                defaultValue={this.state.price}
              />
            )}
          </Form.Item>
          <Form.Item label="Date">
            {getFieldDecorator("date", {
              initialValue: moment(this.state.date),
              rules: [
                {
                  required: true,
                  message: "Please select the date!",
                },
              ],
            })(
              <DatePicker
                disabled={this.props.disabled}
                placeholder="Select Date"
                style={{ width: "100%" }}
                defaultValue={moment(this.state.date)}
                onChange={(e) =>
                  this.handleChange("date", moment(e).format("MM/DD/YYYY"))
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Time">
            {getFieldDecorator("time", {
              initialValue: this.state.time
                ? moment(this.state.time, "LT")
                : moment("9:00", "h:mm a"),
              // value: this.state.time
              //   ? moment(this.state.time, "LT")
              //   : moment("9:00", "h:mm a"),

              rules: [
                {
                  required: true,
                  message: "Please Select The Time!",
                },
              ],
            })(
              <TimePicker
                disabled={this.props.disabled}
                placeholder="Time"
                format="h:mm a"
                use12Hours
                defaultOpenValue={moment("9:00", "h:mm a")}
                style={{ width: "100px" }}
                onChange={(e) =>
                  this.handleChange("time", moment(e).format("LT"))
                }
              />
            )}
          </Form.Item>
          {this.props.authUser &&
          this.props.authUser.user?.userType === "Admin" ? (
            <Form.Item label="Discount" className="mt-2">
              <InputNumber
                disabled={this.props.disabled}
                placeholder="Discount"
                value={this.state.discount}
                style={{ width: "100%" }}
                onChange={(e) => {
                  this.countDiscount(e);
                  this.handleChange("discount", e);
                }}
                type="number"
                size="large"
                min={0}
              />{" "}
            </Form.Item>
          ) : null}
          <Typography.Title level={3} style={{ textAlign: "center" }}>
            Total:{" "}
            {this.state.paymentType === "cash"
              ? currencies.map((currency, i) =>
                  currency.value === this.state.paidCurrency ? currency.sym : ""
                )
              : "$"}{" "}
            {this.state.paymentType === "cash"
              ? this.state.currencyTotal
              : this.state.total}
          </Typography.Title>
          <Form.Item label="Donation">
            {getFieldDecorator("donation", {
              initialValue: this.state.donation,
              value: this.state.donation,
            })(
              <InputNumber
                disabled={this.props.disabled}
                placeholder="Donation"
                style={{ width: "100%" }}
                onChange={(e) => {
                  this.handleChange("donation", e);
                  this.setState(
                    {
                      grandTotal: this.state.currencyTotal + e,
                    },
                    () =>
                      setFields({
                        currencyTotal: {
                          value: this.state.grandTotal,
                        },
                      })
                  );
                }}
                step={0.01}
                type="number"
                size="large"
                min={0}
                // defaultValue={this.state.donation}
              />
            )}
          </Form.Item>
          {this.state.existingCustomer ? (
            <Form.Item
              disabled={this.props.disabled}
              label="Choose existing customer"
              wrapperCol={10}
              labelCol={12}
            >
              <Select
                disabled={this.props.disabled}
                showSearch
                placeholder="Choose existing customer"
                value={this.state.custId}
                onChange={(e) => {
                  this.handleCustomerChange("customerId", e);
                  this.getUserCards(e);
                }}
                filterOption={(input, option) => {
                  return option.props.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {this.props.contactsData?.map((contact, i) => (
                  <Select.Option value={contact.id} key={i}>
                    {contact.firstName + " " + contact.lastName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="First name" labelCol={6} wrapperCol={8}>
                  <Input
                    placeholder="First name"
                    onChange={(e) =>
                      this.handleNewCustomer("firstName", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Last name" labelCol={6} wrapperCol={8}>
                  <Input
                    placeholder="Last name"
                    onChange={(e) =>
                      this.handleNewCustomer("lastName", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Customer Type" labelCol={6} wrapperCol={8}>
                  <Select
                    placeholder="Select Customer Type"
                    onChange={(e) => this.handleNewCustomer("customerType", e)}
                    defaultValue="1-Time"
                  >
                    <Select.Option key={1} value="1-Time">
                      1-Time
                    </Select.Option>
                    <Select.Option key={2} value="Recurring">
                      Recurring
                    </Select.Option>
                    <Select.Option key={3} value="Travel agent">
                      Travel agent
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="tags" labelCol={6} wrapperCol={8}>
                  <Select
                    mode="multiple"
                    placeholder="Please select tags"
                    onChange={(e) => this.handleNewCustomer("tags", e)}
                    style={{ width: "100%" }}
                  >
                    <Select.Option key={1} value="Reguler">
                      Reguler
                    </Select.Option>
                    <Select.Option key={2} value="VIP">
                      VIP
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Phone" labelCol={6} wrapperCol={8}>
                  <ReactPhoneInput
                    country={"us"}
                    inputClass="phoneInputStyle"
                    buttonClass="phoneDropDown2"
                    onChange={(value, country, e, v) => {
                      let a = [];
                      a.push(v);
                      this.handleNewCustomer("phones", a);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Email" labelCol={6} wrapperCol={8}>
                  <Input
                    placeholder="Email"
                    onChange={(e) => {
                      let a = [];
                      a.push(e.target.value);
                      this.handleNewCustomer("emails", a);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                {this.state.addresses.length > 0 &&
                  this.state.addresses.map((address, i) => (
                    <Form.Item
                      key={i}
                      rules={[{ required: true }]}
                      wrapperCol={10}
                      labelCol={6}
                    >
                      <ReactGoogleMapLoader
                        params={{
                          key: KEY.MAP_KEY,
                          libraries: "places,geocode",
                        }}
                        render={(googleMaps) =>
                          googleMaps && (
                            <ReactGooglePlacesSuggest
                              googleMaps={googleMaps}
                              autocompletionRequest={{
                                input: this.state.search[i],
                              }}
                              onNoResult={this.handleNoResult}
                              onSelectSuggest={this.handleSelectSuggest}
                              onStatusUpdate={this.handleStatusUpdate}
                              textNoResults="No Address Found"
                              customRender={(prediction) => (
                                <div className="customWrapper">
                                  {prediction
                                    ? prediction.description
                                    : "No Address Found"}
                                </div>
                              )}
                            >
                              <Form.Item
                                wrapperCol={10}
                                labelCol={6}
                                label="Address"
                              >
                                <Input
                                  placeholder={"Address"}
                                  value={this.state.addresses[i].address}
                                  onChange={(e) => {
                                    this.handleAddress(
                                      "address",
                                      e.target.value,
                                      i
                                    );
                                    this.setState({
                                      search: { [i]: e.target.value },
                                    });
                                    this.setState({ index: i });
                                  }}
                                />
                              </Form.Item>
                            </ReactGooglePlacesSuggest>
                          )
                        }
                      />
                    </Form.Item>
                  ))}
              </Col>{" "}
              <Col span={24}>
                <Form.Item label="kvitel">
                  <TextArea
                    allowClear
                    onChange={(e) =>
                      this.handleNewCustomer("kvitel", e.target.value)
                    }
                    rows={5}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Form.Item>
            <Checkbox
              onChange={(e) => this.handleAddNewCustomer(e)}
              disabled={this.props.disabled | (this.props.variant === "edit")}
            >
              Add new customer
            </Checkbox>
          </Form.Item>
          {!this.props.disabled && (
            <>
              <Form.Item label="Payment type" labelCol={4} wrapperCol={20}>
                <Radio.Group
                  disabled={this.props.disabled}
                  defaultValue="cash"
                  buttonStyle="solid"
                  onChange={(e) => this.handlePaymentTypeChange(e)}
                >
                  <Radio.Button value="cash">Cash</Radio.Button>
                  <Radio.Button value="cheque">Check</Radio.Button>
                  <Radio.Button value="card">Credit card</Radio.Button>
                  <Radio.Button value="quickpay">Quickpay</Radio.Button>
                  <Radio.Button value="offlinecard">
                    Offline Credit Card
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              {this.state.paymentType === "cash" ? (
                <Row>
                  <Col span={24}>
                    <Form.Item>
                      {getFieldDecorator("currencyTotal", {
                        value: this.state.grandTotal,
                        initialValue: this.state.grandTotal,
                      })(
                        <Input
                          disabled={this.props.disabled}
                          size="large"
                          style={{ width: "100%" }}
                          addonBefore={currencyOptions}
                          onChange={(e) => {
                            this.handleDonation(e.target.value);
                            this.setState({ grandTotal: e.target.value }, () =>
                              setFields({
                                donation: {
                                  value: this.state.donation,
                                },
                              })
                            );
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}
              {this.state.paymentType === "cheque" ? (
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Check name" labelCol={6} wrapperCol={8}>
                      {getFieldDecorator("chequename", {
                        rules: [
                          {
                            required: true,
                            message: "Add Name Written On Check!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Name on check"
                          onChange={(e) =>
                            this.handleCheque("chequeName", e.target.value)
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Check date" labelCol={6} wrapperCol={8}>
                      {getFieldDecorator("chequedate", {
                        rules: [
                          {
                            required: true,
                            message: "Please Select date written On Check!",
                          },
                        ],
                      })(
                        <DatePicker
                          placeholder="Check date"
                          onChange={(e) =>
                            this.handleCheque(
                              "chequeDate",
                              moment(e).format("ddd MMM DD YYYY")
                            )
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Account number"
                      labelCol={6}
                      wrapperCol={8}
                    >
                      {getFieldDecorator("accountNumber", {
                        rules: [
                          {
                            required: true,
                            message: "Please add Your Account Number!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Account number"
                          onChange={(e) =>
                            this.handleCheque("accountNumber", e.target.value)
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Routing number"
                      labelCol={6}
                      wrapperCol={8}
                    >
                      {getFieldDecorator("routingNo", {
                        rules: [
                          {
                            required: true,
                            message: "Please add Routing Number!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Routing number"
                          onChange={(e) =>
                            this.handleCheque("routingNo", e.target.value)
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}
              {this.state.paymentType === "card" ? (
                <Row gutter={16}>
                  {this.state.existingCustomer &&
                  this.state.userCards?.length ? (
                    <Col span={24}>
                      <Form.Item label="Choose existing card">
                        <Select
                          onChange={(e) => this.handleSelectedCard(e)}
                          placeholder="Choose existing card"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          allowClear
                        >
                          {this.state.userCards?.map((card, i) => (
                            <Select.Option value={JSON.stringify(card)} key={i}>
                              Card ending with {card.cardEnding.substr(12, 4)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <div>or</div>
                    </Col>
                  ) : null}

                  {this.state.creditCards.length > 0 &&
                    this.state.creditCards.map((card, ind) => {
                      return (
                        <div key={ind}>
                          <Col span={24}>
                            <Form.Item label="Name on card">
                              {getFieldDecorator("cardname", {
                                rules: [
                                  {
                                    required:
                                      this.state.card?.length > 0
                                        ? false
                                        : true,
                                    message: "Please enter Card Name!",
                                  },
                                ],
                              })(
                                <Input
                                  value={this.state.creditCards[ind].cardName}
                                  placeholder="Name on card"
                                  onChange={(e) => {
                                    this.handleCreditcards(
                                      "cardName",
                                      e.target.value,
                                      ind
                                    );
                                    this.setState({ index: ind });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item label="Card number">
                              {getFieldDecorator("cardNumber", {
                                rules: [
                                  {
                                    required:
                                      this.state.card?.length > 0
                                        ? false
                                        : true,
                                    message: "Please enter card number!",
                                  },
                                  {
                                    min: 15,
                                    message:
                                      "Card number must be minimum 15 characters long.",
                                  },
                                  {
                                    max: 16,
                                    message:
                                      "Card number must be maximum 16 characters long.",
                                  },
                                ],
                              })(
                                <Input
                                  value={this.state.creditCards[ind].cardNumber}
                                  placeholder="Card number"
                                  onChange={(e) => {
                                    this.handleCreditcards(
                                      "cardNumber",
                                      e.target.value,
                                      ind
                                    );
                                    this.setState({ index: ind });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Exp">
                              {getFieldDecorator("expirydate", {
                                rules: [
                                  {
                                    required:
                                      this.state.card?.length > 0
                                        ? false
                                        : true,
                                    message: "Please select Expiry date!",
                                  },
                                ],
                              })(
                                <DatePicker.MonthPicker
                                  value={this.state.creditCards[ind].cardExpiry}
                                  placeholder="Exp."
                                  format="YYYY/MM"
                                  onChange={(e) => {
                                    this.handleCreditcards(
                                      "cardExpiry",
                                      e,
                                      ind
                                    );
                                    this.setState({ index: ind });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="CCV">
                              {getFieldDecorator("cardCCV", {
                                rules: [
                                  {
                                    required:
                                      this.state.card?.length > 0
                                        ? false
                                        : true,
                                    message: "Please enter cvv number!",
                                  },
                                ],
                              })(
                                <Input
                                  placeholder="CCV"
                                  value={this.state.creditCards[ind].cardCVV}
                                  onChange={(e) => {
                                    this.handleCreditcards(
                                      "cardCVV",
                                      e.target.value,
                                      ind
                                    );
                                    this.setState({ index: ind });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              rules={[{ required: true }]}
                              wrapperCol={24}
                            >
                              {getFieldDecorator("cardAmount", {
                                rules: [
                                  {
                                    required:
                                      this.state.selectedCard?.length > 0
                                        ? false
                                        : true,
                                    message: "Please enter amount!",
                                  },
                                ],
                              })(
                                <Input.Search
                                  // defaultValue="$750"
                                  enterButton="Process card"
                                  // onSearch={() => this.handleCard(card)}
                                  size="large"
                                  onChange={(e) => {
                                    this.state.selectedCard?.length > 0
                                      ? this.setState(
                                          {
                                            selectedCardAmount: e.target.value,
                                          },
                                          () => {
                                            this.fixCard();
                                          }
                                        )
                                      : this.handleCreditcards(
                                          "paymentAmount",
                                          parseInt(e.target.value),
                                          ind
                                        );
                                    this.setState({ index: ind });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </div>
                      );
                    })}
                </Row>
              ) : null}
              {this.state.paymentType === "quickpay" ? (
                <Form.Item label="Reference number">
                  {getFieldDecorator("ref Number", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter Reference number!",
                      },
                    ],
                  })(
                    <Input
                      placeholder="Reference number"
                      onChange={(e) =>
                        this.handleChange2("refNumber", e.target.value)
                      }
                    />
                  )}
                </Form.Item>
              ) : null}
              {this.state.paymentType === "offlinecard" ? (
                <>
                  <Form.Item label="Reference number">
                    {getFieldDecorator("card ref Number", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Reference number!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Reference number"
                        onChange={(e) =>
                          this.handleChange2("cardRefNumber", e.target.value)
                        }
                      />
                    )}
                  </Form.Item>
                  <Form.Item rules={[{ required: true }]}>
                    {getFieldDecorator("offcardAmount", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter amount!",
                        },
                      ],
                    })(
                      <Input.Search
                        // defaultValue="$750"
                        enterButton="Process"
                        // onSearch={() => this.handleCard(card)}
                        size="large"
                        onChange={(e) => {
                          this.setState({ cardAmount: e.target.value });
                        }}
                      />
                    )}
                  </Form.Item>
                </>
              ) : null}
              <Form.Item>
                <Button
                  disabled={this.props.disabled}
                  type="primary"
                  htmlType="submit"
                >
                  {this.props.variant === "edit" ? "Update" : "Submit"}
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </div>
    );
  }
}
const NewShuttleForm = Form.create({ name: "Shuttle" })(ShuttleForm);

const mapStateToProps = ({ orderReducer }) => {
  return {
    contactsData: orderReducer.contacts,
    customer: orderReducer.customer,
    authUser: orderReducer.authUser,
    currency: orderReducer.currency,
    orderData: orderReducer.orderData,
  };
};
export default connect(mapStateToProps)(NewShuttleForm);
