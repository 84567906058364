import React, { Suspense, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Icon,
  Statistic,
  Skeleton,
  DatePicker,
  Button
} from "antd";

import Widget from "../../components/Widget/Widget";
import TodaysArrivalsDatatable from "../../components/DataTable/TodaysArrivalsDatatable";
import TodaysDepartureDatatable from "../../components/DataTable/TodaysDepartureDatatable";
import TodaysCurrentOrdersDatatable from "../../components/DataTable/TodaysCurrentOrdersDatatable";
import NewNote from "./NewNote";
import NotesList from "../../components/DataTable/NotesList";
import moment from "moment";

const TodaysMealDataTable = React.lazy(() =>
  import("../../components/DataTable/TodaysMealDataTable")
);

const Dashboard = () => {
  const [date, setDate] = useState(new Date());
  return (
    <Row gutter={16}>
      <Col xs={12} md={6}>
        <Widget>
          <Typography.Title
            level={3}
            style={{ color: "#4285F4", marginBottom: "25px" }}
          >
            <Icon type="rise" /> Sales
          </Typography.Title>
          <Row>
            <Col span={12}>
              <Statistic title="Month" value={112893} prefix="$" />
            </Col>
            <Col span={12}>
              <Statistic title="Quarter" value={365489} prefix="$" />
            </Col>
          </Row>
        </Widget>
      </Col>
      <Col xs={12} md={6}>
        <Widget>
          <Typography.Title
            level={3}
            style={{ color: "#DB4437", marginBottom: "25px" }}
          >
            <Icon type="file-protect" /> Todays Guests
          </Typography.Title>
          <Statistic title="Guests" value={32} />
        </Widget>
      </Col>
      <Col xs={12} md={6}>
        <Widget>
          <Typography.Title
            level={3}
            style={{ color: "#F4B400", marginBottom: "25px" }}
          >
            <Icon type="dollar" /> Todays Meals
          </Typography.Title>
          <Statistic title="Meals" value={45} />
        </Widget>
      </Col>
      <Col xs={12} md={6}>
        <Widget>
          <Typography.Title
            level={3}
            style={{ color: "#0F9D58", marginBottom: "25px" }}
          >
            <Icon type="user" /> Customers
          </Typography.Title>
          <Row>
            <Col span={12}>
              <Statistic title="Month" value={65} />
            </Col>
            <Col span={12}>
              <Statistic title="Quarter" value={189} />
            </Col>
          </Row>
        </Widget>
      </Col>
      {/* <Col span={24}>
                <Typography.Title level={3}>Past Due Orders</Typography.Title>
                <DataTable type='past-due' />
            </Col> */}
      <Col span={24}>
        <Widget>
          <Typography.Title level={3}>
            Details For {moment(date).format("dddd DD/MM/YYYY")}
          </Typography.Title>
          <Button
              style={{
                border: "0",
                top: "-4px",
              }}
              type="default"
              onClick={() => {
                let e = moment(new Date(date)).add(-1, "days");
                setDate(e);
              }}
            >
              <Icon type="caret-left" />
            </Button>
          <DatePicker
            onChange={(e) => {
              setDate(e ? e : moment(date));
            }}
            defaultValue={moment(date)}
            value={moment(date)}
          />
          <Button
              style={{
                border: "0",
                top: "-4px"
              }}
              type="default"
              onClick={() => {
                let e = moment(new Date(date)).add(1, "days");
                setDate(e);
              }}
            > <Icon type="caret-right" />
            </Button>
        </Widget>
      </Col>
      <Col span={18}>
        <Widget>
          <Suspense fallback={<Skeleton active />}>
            <TodaysMealDataTable Date={date} />
          </Suspense>
        </Widget>
      </Col>
      <Col span={6}>
        <Widget>
          <Row gutter={16}>
            <Col span={16}>
              <Typography.Title level={3}>Todays notes</Typography.Title>
            </Col>
            <Col span={8}>
              <NewNote />
            </Col>
          </Row>
          <NotesList />
        </Widget>
      </Col>
      <Col span={24}>
        <Widget>
          <Typography.Title level={3}>
            Arrivals for {moment(date).format("dddd DD/MM/YYYY")}
          </Typography.Title>
          <TodaysArrivalsDatatable type="orders" Date={date} />
        </Widget>
      </Col>
      <Col span={24}>
        <Widget>
          <Typography.Title level={3}>
            Departures for {moment(date).format("dddd DD/MM/YYYY")}
          </Typography.Title>
          <TodaysDepartureDatatable type="orders" Date={date} />
        </Widget>
      </Col>
      <Col span={24}>
        <Widget>
          <Typography.Title level={3}>
            Current Orders for {moment(date).format("dddd DD/MM/YYYY")}
          </Typography.Title>
          <TodaysCurrentOrdersDatatable type="orders" Date={date} />
        </Widget>
      </Col>
    </Row>
  );
};

export default Dashboard;
