import React, { Suspense } from "react";
import { Typography, Skeleton } from "antd";
import AddCustomer from "../../components/Customer/AddCustomer";

// import CustomerDataTable from "../../components/DataTable/CustomerDataTable";
const CustomerDataTable = React.lazy(() =>
  import("../../components/DataTable/CustomerDataTable")
);
const Customers = () => {
  return (
    <div>
      {/* <Typography.Title level={3}>Customers</Typography.Title> */}
      <Suspense fallback={<Skeleton active />}>
        <CustomerDataTable type="customer" />
      </Suspense>
    </div>
  );
};

export default Customers;
