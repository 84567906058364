import React, { Component } from 'react';
// prettier-ignore
import { Form, Table, InputNumber, Typography, Checkbox, Input, Select, Row, Col, Radio, Button, DatePicker, message,Modal } from "antd";
import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import { KEY } from '../../AppConstants';
import { axiosInstance } from '../../httpHandler';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  getContacts,
  addCustomer,
  addOrder,
  getOrderData,
} from '../../actions/orderActionCreator';
import { store } from '../../store';
import TextArea from 'antd/lib/input/TextArea';
import ReactPhoneInput from 'react-phone-input-2';
import {
  handleCountCurrency,
  handleCountCurrency2,
} from '../../config/currencyConvertor';

class ProductsForm extends Component {
  state = {
    products: [
      { itemName: 'Book', price: 15, quantity: 0, total: 0 },
      { itemName: 'CD', price: 18, quantity: 0, total: 0 },
      { itemName: 'Sandwich', price: 5, quantity: 0, total: 0 },
      { itemName: 'Cake', price: 5, quantity: 0, total: 0 },
      { itemName: 'Watter', price: 5, quantity: 0, total: 0 },
      { itemName: 'Milk', price: 5, quantity: 0, total: 0 },
      { itemName: 'Coffee', price: 5, quantity: 0, total: 0 },
      { itemName: 'Fruit', price: 5, quantity: 0, total: 0 },
    ],
    paymentType: 'cash',
    existingCustomer: true,
    currency: 'USD',
    amount: '',
    customer: '',
    totalAmount: 0,
    addresses: [{ address: '', address2: '', city: '', state: '', zip: '' }],
    creditCards: [
      {
        cardName: '',
        cardNumber: '',
        cardCVV: '',
        cardExpiry: '',
        paymentAmount: '',
      },
    ],
    cheque: {
      amount: '',
      chequeName: '',
      chequeDate: '',
      accountNumber: '',
      currency: 'USD',
      routingNo: '',
    },

    refNumber: '',
    search: '',
    searchVal: '',
    index: null,
    userCards: [],
    card: [],
    selectedCardAmount: '',
    discount: 0,
    total: 0,
    paidCurrency: 'USD',
    currencyTotal: 0,
    customerData: '',
    memos: [],
    customerId: '',
    index2: 0,
    donation: 0,
    usdDonation: 0,
    grandTotal: 0,
  };

  countCurrency = (amount, e, update = false) => {
    const currency = this.props.currency.data;
    let value = 0;
    value = handleCountCurrency(amount, e, currency);
    update && this.setState({ grandTotal: value });

    this.setState(
      {
        currencyTotal: Math.round(value * 100) / 100,
      },
      () =>
        this.setState(
          {
            donation: this.state.grandTotal - Math.round(value * 100) / 100,
          },
          () =>
            this.props.form.setFields({
              donation: {
                value: Math.round(this.state.donation * 100) / 100,
              },
              currencyTotal: {
                value: this.state.grandTotal,
              },
            })
        )
    );
  };

  handlePaymentTypeChange = (e) => {
    this.setState({
      paymentType: e.target.value,
    });
  };
  handleChange = (key, value) => {
    store.dispatch(addCustomer(key, value));
  };

  handleChange2 = (key, value) => {
    this.setState({ [key]: value });
  };
  handleAddNewCustomer = (e) => {
    this.setState({
      existingCustomer: !e.target.checked,
      customer: '',
    });
    store.dispatch(addCustomer('customerType', '1-time'));
  };
  handleAddress = (key, value, i) => {
    const { addresses } = this.state;
    addresses[i] = { ...addresses[i], [key]: value };
    this.setState({ addresses });
    store.dispatch(addCustomer('addresses', addresses));
  };
  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    this.setState({
      search: { [this.state.index]: '' },
      searchVal: geocodedPrediction.formatted_address,
    });
    this.handleAddress(
      'address',
      geocodedPrediction.formatted_address,
      this.state.index
    );
  };
  handleCreditcards = (key, value, i) => {
    const { creditCards } = this.state;
    creditCards[i] = {
      ...creditCards[i],
      [key]: value,
    };
    this.setState({ creditCards });
    // store.dispatch(addCustomer("creditCards", creditCards));
  };

  handleCheque = (key, value) => {
    const { cheque } = this.state;
    let a = cheque;
    a = {
      ...a,
      [key]: value,
    };
    this.setState({ cheque: a });
    // store.dispatch(addOrder("cheque", a));
  };
  handleSelectedCard = (v) => {
    let data = [];
    let object = v ? JSON.parse(v) : null;
    data.push(object);
    this.setState({
      card: v ? data : [],
    });
  };
  fixCard = () => {
    let data = [];
    let cardAmount = this.state.selectedCardAmount || 0;
    let amount = 'xAuthAmount';
    let object = this.state.object;
    object[amount] = cardAmount;
    data.push(object);
    this.setState({ selectedCard: data }, () => {
      store.dispatch(addOrder('selectedCard', this.state.selectedCard));
    });
  };

  getUserCards = (e) => {
    let cards = [];
    this.props.contactsData.map(
      (cust, i) => cust.id === e && cards.push(cust.customerCards)
    );
    this.setState({ userCards: cards[0] || [] });
  };

  componentDidMount() {
    this.props.dispatch(getContacts());
    this.state.products
      .filter((p) => p.quantity > 0)
      .map((pr) => console.log('pr', pr));
    if (this.props.orderData && this.props.orderData[0]) {
      let b = [...this.props.orderData[0].products, ...this.state.products];
      let result = b.filter(
        (v, i, a) => a.findIndex((t) => v.itemName === t.itemName) === i
      );

      const sumTotal = (arr) =>
        arr?.reduce((sum, { total }) => sum + total++, 0);
      const totalAmount = sumTotal(result);
      this.countCurrency(totalAmount, this.state.paidCurrency, true);
      this.setState({
        products: result,
        customerId: this.props.orderData[0].customerId,
        totalAmount: totalAmount,
        discount: this.props.orderData[0].discount,
      });
    }
  }
  countDiscount = (e) => {
    const sumTotal = (arr) => arr?.reduce((sum, { total }) => sum + total++, 0);
    const totalAmount = sumTotal(this.state.products) - e;
    this.countCurrency(totalAmount, this.state.paidCurrency);

    this.setState({ totalAmount: totalAmount });
  };
  handleQuantity = (index, value) => {
    const product = [...this.state.products];
    product[index].quantity = value;
    product[index].total = product[index].price * value;
    this.setState({ products: product }, () => {
      const sumTotal = (arr) =>
        arr?.reduce((sum, { total }) => sum + total++, 0);
      const totalAmount = sumTotal(this.state.products) - this.state.discount;
      this.countCurrency(totalAmount, this.state.paidCurrency, true);
      this.setState({ totalAmount: totalAmount });
    });
  };

  submitProduct = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        if (this.props.variant === 'edit') {
          const data = {
            products: this.state.products,
            customerId: this.state.customerId,
            total: this.state.totalAmount,
          };
          axiosInstance
            .patch(`/editOrder/${this.props.orderData[0].id}`, data, {
              handlerEnabled: true,
            })
            .then(async (res) => {
              console.info('NewOrder.js', { res: res });
              message.success('Order Updated');

              this.props.dispatch(getOrderData());

              this.props.closeModel();
            })
            .catch((error) => console.log(error));
        } else {
          await axiosInstance
            .post(
              `/addProducts`,
              { customer: this.props.customer || [], data: this.state },
              {
                handlerEnabled: true,
              }
            )
            .then(async (res) => {
              console.info('NewOrder.js', { res: res });
              if (res.data.Error) {
                // const resStatus = res.data.Error.split("&")
                //   .map((obj) => obj.split("="))
                //   .find((v) => v[0] === "xStatus")[1];
                const error = res.data.Error.split('&')
                  .map((obj) => obj.split('='))
                  .find((v) => v[0] === 'xError')[1];
                message.error(error.replace(/[+]/g, ' '));
              } else {
                this.props.closeModel();
                message.success('Processing complete!');
                this.props.dispatch(getOrderData());
              }
            });
        }
      }
    });
  };

  handleCustomerChange = (key, value) => {
    const { contactsData } = this.props;
    this.setState({ index2: 0 }, () => {
      contactsData.map((contact) => {
        if (contact.id === value) {
          console.info('CustomerForm.js', { 1: value, 2: contact.id });
          this.setState(
            {
              customerData: contact,
              memos: contact.memo ? contact.memo : [],
              customerId: contact.id,
            },
            () => {
              if (this.state.memos.length) {
                this.showMemoModal(this.state.memos);
              }
            }
          );
        }
      });
    });
    this.setState({ [key]: value });
  };
  showMemoModal = () => {
    let thiss = this;
    Modal.confirm({
      title: 'Account memo',
      content: this.state.memos[this.state.index2],
      cancelText: 'Dismiss',
      okText: 'Delete memo',
      onCancel() {
        thiss.setState(
          { index2: thiss.state.memos.length > 0 ? thiss.state.index2 + 1 : 0 },
          () => {
            if (thiss.state.index2 <= thiss.state.memos.length - 1) {
              thiss.showMemoModal(thiss.state.memos);
            }
          }
        );
      },
      onOk() {
        let a = thiss.state.memos;
        a.splice(thiss.state.index2, 1);
        thiss.setState({ memos: a }, () => {
          axiosInstance
            .put(
              `/deletememo/${thiss.state.customerId}`,
              {
                memo: thiss.state.memos,
              },
              { handlerEnabled: true }
            )
            .then((res) => {
              message.success('Memo Deleted succesfully');
              thiss.props.dispatch(getContacts());
              if (thiss.state.index2 <= thiss.state.memos.length - 1) {
                thiss.showMemoModal(thiss.state.memos);
              }
            })
            .catch((err) => console.log(err));
        });
      },
    });
  };
  handleDonation = (e) => {
    let donation = Math.round((e - this.state.currencyTotal) * 100) / 100;
    let usdDonation = handleCountCurrency2(
      donation,
      this.state.paidCurrency,
      this.props.currency?.data
    );
    this.setState({
      donation,
      usdDonation,
    });
  };
  render() {
    const dataSource = this.state.products.map((d, i) => ({
      key: i,
      item: d.itemName,
      price: d.price,
      total: d.quantity * d.price,
      qty: { quantity: d.quantity, i: i },
    }));

    const columns = [
      {
        title: 'Item',
        dataIndex: 'item',
        key: 'item',
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: (price) => '$' + price.toLocaleString(),
      },
      {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
        render: (res) => (
          <InputNumber
            onChange={(v) => this.handleQuantity(res.i, v)}
            disabled={this.props.disabled}
            value={res.quantity}
          />
        ),
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        render: (total) => '$' + total.toLocaleString(),
      },
    ];

    const { getFieldDecorator, setFields } = this.props.form;
    let currencies = [
      { sym: '$', value: 'USD' },
      { sym: 'zl', value: 'ZlATY' },
      { sym: '₽', value: 'RUBL' },
      { sym: '£', value: 'POUND' },
      { sym: '₪', value: 'SHEKEL' },
      { sym: '₴', value: 'HRYVNIA' },
      { sym: '€', value: 'EURO' },
    ];
    const currencyOptions = (
      <Select
        disabled={this.props.disabled}
        value={this.state.paidCurrency}
        style={{ width: 80 }}
        className='select-before'
        onChange={(e) => {
          this.setState({ currency: e, paidCurrency: e });
          this.countCurrency(this.state.totalAmount, e);
        }}
      >
        {currencies.map((currency, i) => (
          <Select.Option value={currency.value} key={i}>
            {currency.sym}
          </Select.Option>
        ))}
      </Select>
    );
    return (
      <div>
        <Table
          size='small'
          pagination={false}
          dataSource={dataSource}
          columns={columns}
        />
        {this.props.authUser &&
        this.props.authUser.user?.userType === 'Admin' ? (
          <Col span={24}>
            <Form.Item className='mt-2'>
              <InputNumber
                disabled={this.props.disabled}
                placeholder='Discount'
                value={this.state.discount}
                style={{ width: '100%' }}
                onChange={(e) => {
                  this.countDiscount(e);
                  this.setState({ discount: e });
                }}
                type='number'
                size='large'
                min={0}
              />{' '}
            </Form.Item>
          </Col>
        ) : null}
        <Typography.Title level={3} style={{ textAlign: 'center' }}>
          Total:{' '}
          {this.state.paymentType === 'cash'
            ? currencies.map((currency, i) =>
                currency.value === this.state.paidCurrency ? currency.sym : ''
              )
            : '$'}{' '}
          {this.state.paymentType === 'cash'
            ? this.state.currencyTotal
            : this.state.totalAmount}
        </Typography.Title>
        <Form.Item>
          {getFieldDecorator('donation', {
            // initialValue: this.state.donation,
            value: this.state.donation,
          })(
            <InputNumber
              disabled={this.props.disabled}
              placeholder='Donation'
              style={{ width: '100%' }}
              onChange={(e) => {
                this.handleChange2('donation', e);
                this.setState(
                  {
                    grandTotal: this.state.currencyTotal + e,
                  },
                  () =>
                    setFields({
                      currencyTotal: {
                        value: this.state.grandTotal,
                      },
                    })
                );
              }}
              step={0.01}
              type='number'
              size='large'
              min={0}
            />
          )}
        </Form.Item>
        <Form layout='vertical' onSubmit={this.submitProduct}>
          {this.state.existingCustomer ? (
            <Form.Item label='Choose existing customer'>
              <Select
                disabled={this.props.disabled}
                showSearch
                value={this.state.customerId && this.state.customerId}
                placeholder='Choose existing customer'
                onChange={(e) => {
                  this.handleCustomerChange('customerId', e);
                  this.getUserCards(e);
                }}
                filterOption={(input, option) => {
                  return option.props.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {this.props.contactsData?.map((contact, i) => (
                  <Select.Option value={contact.id} key={i}>
                    {contact.firstName + ' ' + contact.lastName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label='First name'>
                  <Input
                    placeholder='First name'
                    onChange={(e) =>
                      this.handleChange('firstName', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Last name'>
                  <Input
                    placeholder='Last name'
                    onChange={(e) =>
                      this.handleChange('lastName', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Customer Type'>
                  <Select
                    placeholder='Select Customer Type'
                    onChange={(e) => this.handleChange('customerType', e)}
                    defaultValue='1-Time'
                  >
                    <Select.Option key={1} value='1-Time'>
                      1-Time
                    </Select.Option>
                    <Select.Option key={2} value='Recurring'>
                      Recurring
                    </Select.Option>
                    <Select.Option key={3} value='Travel agent'>
                      Travel agent
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='tags' labelCol={6} wrapperCol={8}>
                  <Select
                    mode='multiple'
                    placeholder='Please select tags'
                    onChange={(e) => this.handleChange('tags', e)}
                    style={{ width: '100%' }}
                  >
                    <Select.Option key={1} value='Reguler'>
                      Reguler
                    </Select.Option>
                    <Select.Option key={2} value='VIP'>
                      VIP
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Phone'>
                  <ReactPhoneInput
                    country={'us'}
                    inputClass='phoneInputStyle'
                    buttonClass='phoneDropDown'
                    onChange={(value, country, e, v) => {
                      let a = [];
                      a.push(v);
                      this.handleChange('phones', a);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Email'>
                  <Input
                    placeholder='Email'
                    onChange={(e) => {
                      let a = [];
                      a.push(e.target.value);
                      this.handleChange('emails', a);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                {this.state.addresses.length > 0 &&
                  this.state.addresses.map((address, i) => (
                    <Form.Item key={i} rules={[{ required: true }]}>
                      <ReactGoogleMapLoader
                        params={{
                          key: KEY.MAP_KEY,
                          libraries: 'places,geocode',
                        }}
                        render={(googleMaps) =>
                          googleMaps && (
                            <ReactGooglePlacesSuggest
                              googleMaps={googleMaps}
                              autocompletionRequest={{
                                input: this.state.search[i],
                              }}
                              onNoResult={this.handleNoResult}
                              onSelectSuggest={this.handleSelectSuggest}
                              onStatusUpdate={this.handleStatusUpdate}
                              textNoResults='No Address Found'
                              customRender={(prediction) => (
                                <div className='customWrapper'>
                                  {prediction
                                    ? prediction.description
                                    : 'No Address Found'}
                                </div>
                              )}
                            >
                              <Form.Item>
                                <Input
                                  placeholder={'Address'}
                                  value={this.state.addresses[i].address}
                                  onChange={(e) => {
                                    this.handleAddress(
                                      'address',
                                      e.target.value,
                                      i
                                    );
                                    this.setState({
                                      search: { [i]: e.target.value },
                                    });
                                    this.setState({ index: i });
                                  }}
                                />
                              </Form.Item>
                            </ReactGooglePlacesSuggest>
                          )
                        }
                      />
                    </Form.Item>
                  ))}
              </Col>{' '}
              <Col span={24}>
                <Form.Item label='kvitel'>
                  <TextArea
                    allowClear
                    onChange={(e) =>
                      this.handleChange('kvitel', e.target.value)
                    }
                    rows={5}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Form.Item>
            <Checkbox
              onChange={(e) => this.handleAddNewCustomer(e)}
              disabled={this.props.disabled || this.props.variant === 'edit'}
            >
              Add new customer
            </Checkbox>
          </Form.Item>
          {!this.props.disabled && (
            <>
              {' '}
              <Form.Item label='Payment type'>
                <Radio.Group
                  disabled={this.props.disabled}
                  defaultValue='cash'
                  buttonStyle='solid'
                  onChange={(e) => this.handlePaymentTypeChange(e)}
                >
                  <Radio.Button value='cash'>Cash</Radio.Button>
                  <Radio.Button value='cheque'>Check</Radio.Button>
                  <Radio.Button value='card'>Credit card</Radio.Button>
                  <Radio.Button value='quickpay'>Quickpay</Radio.Button>
                  <Radio.Button value='offlinecard'>
                    Offline Credit Card
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              {this.state.paymentType === 'cash' ? (
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item>
                      {' '}
                      {getFieldDecorator('currencyTotal', {
                        value: this.state.grandTotal,
                        initialValue: this.state.grandTotal,
                      })(
                        <Input
                          disabled={this.props.disabled}
                          size='large'
                          style={{ width: '100%' }}
                          addonBefore={currencyOptions}
                          onChange={(e) => {
                            this.handleDonation(e.target.value);
                            this.setState({ grandTotal: e.target.value }, () =>
                              setFields({
                                donation: {
                                  value: this.state.donation,
                                },
                              })
                            );
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}
              {this.state.paymentType === 'cheque' ? (
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label='Check name'>
                      {getFieldDecorator('chequename', {
                        rules: [
                          {
                            required: true,
                            message: 'Add Name Written On Check!',
                          },
                        ],
                      })(
                        <Input
                          placeholder='Name on check'
                          onChange={(e) =>
                            this.handleCheque('chequeName', e.target.value)
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label='Check date'>
                      {getFieldDecorator('chequedate', {
                        rules: [
                          {
                            required: true,
                            message: 'Please Select date written On Check!',
                          },
                        ],
                      })(
                        <DatePicker
                          placeholder='Check date'
                          onChange={(e) =>
                            this.handleCheque(
                              'chequeDate',
                              moment(e).format('ddd MMM DD YYYY')
                            )
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label='Account number'>
                      {getFieldDecorator('accountNumber', {
                        rules: [
                          {
                            required: true,
                            message: 'Please add Your Account Number!',
                          },
                        ],
                      })(
                        <Input
                          placeholder='Account number'
                          onChange={(e) =>
                            this.handleCheque('accountNumber', e.target.value)
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label='Routing number'>
                      {getFieldDecorator('routingNo', {
                        rules: [
                          {
                            required: true,
                            message: 'Please add Routing Number!',
                          },
                        ],
                      })(
                        <Input
                          placeholder='Routing number'
                          onChange={(e) =>
                            this.handleCheque('routingNo', e.target.value)
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}
              {this.state.paymentType === 'card' ? (
                <Row gutter={16}>
                  {this.state.existingCustomer &&
                  this.state.userCards.length ? (
                    <Col span={24}>
                      <Form.Item label='Choose existing card'>
                        <Select
                          onChange={(e) => this.handleSelectedCard(e)}
                          placeholder='Choose existing card'
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          allowClear
                        >
                          {this.state.userCards.map((card, i) => (
                            <Select.Option key={i} value={JSON.stringify(card)}>
                              Card ending with {card.cardEnding.substr(12, 4)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <div>or</div>
                    </Col>
                  ) : null}

                  {this.state.creditCards.length > 0 &&
                    this.state.creditCards.map((card, ind) => {
                      return (
                        <div key={ind}>
                          <Col span={24}>
                            <Form.Item label='Name on card'>
                              {getFieldDecorator('cardname', {
                                rules: [
                                  {
                                    required:
                                      this.state.card.length > 0 ? false : true,
                                    message: 'Please enter Card Name!',
                                  },
                                ],
                              })(
                                <Input
                                  value={this.state.creditCards[ind].cardName}
                                  placeholder='Name on card'
                                  onChange={(e) => {
                                    this.handleCreditcards(
                                      'cardName',
                                      e.target.value,
                                      ind
                                    );
                                    this.setState({ index: ind });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item label='Card number'>
                              {getFieldDecorator('cardNumber', {
                                rules: [
                                  {
                                    required:
                                      this.state.card.length > 0 ? false : true,
                                    message: 'Please enter card number!',
                                  },
                                  {
                                    min: 15,
                                    message:
                                      'Card number must be minimum 15 characters long.',
                                  },
                                  {
                                    max: 16,
                                    message:
                                      'Card number must be maximum 16 characters long.',
                                  },
                                ],
                              })(
                                <Input
                                  value={this.state.creditCards[ind].cardNumber}
                                  placeholder='Card number'
                                  onChange={(e) => {
                                    this.handleCreditcards(
                                      'cardNumber',
                                      e.target.value,
                                      ind
                                    );
                                    this.setState({ index: ind });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label='Exp'>
                              {getFieldDecorator('expirydate', {
                                rules: [
                                  {
                                    required:
                                      this.state.card.length > 0 ? false : true,
                                    message: 'Please select Expiry date!',
                                  },
                                ],
                              })(
                                <DatePicker.MonthPicker
                                  value={this.state.creditCards[ind].cardExpiry}
                                  placeholder='Exp.'
                                  format='YYYY/MM'
                                  onChange={(e) => {
                                    this.handleCreditcards(
                                      'cardExpiry',
                                      e,
                                      ind
                                    );
                                    this.setState({ index: ind });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label='CCV'>
                              {getFieldDecorator('cardCCV', {
                                rules: [
                                  {
                                    required:
                                      this.state.card.length > 0 ? false : true,
                                    message: 'Please enter cvv number!',
                                  },
                                ],
                              })(
                                <Input
                                  placeholder='CCV'
                                  value={this.state.creditCards[ind].cardCVV}
                                  onChange={(e) => {
                                    this.handleCreditcards(
                                      'cardCVV',
                                      e.target.value,
                                      ind
                                    );
                                    this.setState({ index: ind });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item rules={[{ required: true }]}>
                              {getFieldDecorator('cardAmount', {
                                rules: [
                                  {
                                    required:
                                      this.state.selectedCard?.length > 0
                                        ? false
                                        : true,
                                    message: 'Please enter amount!',
                                  },
                                ],
                              })(
                                <Input.Search
                                  // defaultValue="$750"
                                  enterButton='Process card'
                                  // onSearch={() => this.handleCard(card)}
                                  size='large'
                                  onChange={(e) => {
                                    this.state.selectedCard?.length > 0
                                      ? this.setState(
                                          {
                                            selectedCardAmount: e.target.value,
                                          },
                                          () => {
                                            this.fixCard();
                                          }
                                        )
                                      : this.handleCreditcards(
                                          'paymentAmount',
                                          parseInt(e.target.value),
                                          ind
                                        );
                                    this.setState({ index: ind });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </div>
                      );
                    })}
                </Row>
              ) : null}
              {this.state.paymentType === 'quickpay' ? (
                <Form.Item label='Reference number'>
                  {getFieldDecorator('ref Number', {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter Reference number!',
                      },
                    ],
                  })(
                    <Input
                      placeholder='Reference number'
                      onChange={(e) =>
                        this.handleChange2('refNumber', e.target.value)
                      }
                    />
                  )}
                </Form.Item>
              ) : null}
              {this.state.paymentType === 'offlinecard' ? (
                <>
                  <Form.Item label='Reference number'>
                    {getFieldDecorator('card ref Number', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter Reference number!',
                        },
                      ],
                    })(
                      <Input
                        placeholder='Reference number'
                        onChange={(e) =>
                          this.handleChange2('cardRefNumber', e.target.value)
                        }
                      />
                    )}
                  </Form.Item>
                  <Form.Item rules={[{ required: true }]}>
                    {getFieldDecorator('offcardAmount', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter amount!',
                        },
                      ],
                    })(
                      <Input.Search
                        // defaultValue="$750"
                        enterButton='Process'
                        // onSearch={() => this.handleCard(card)}
                        size='large'
                        onChange={(e) => {
                          this.setState({ cardAmount: e.target.value });
                        }}
                      />
                    )}
                  </Form.Item>
                </>
              ) : null}
              <Form.Item>
                <Button
                  disabled={this.props.disabled}
                  type='primary'
                  htmlType='submit'
                >
                  {this.props.variant === 'edit' ? 'Update' : 'Submit'}
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = ({ orderReducer }) => {
  return {
    contactsData: orderReducer.contacts,
    customer: orderReducer.customer,
    authUser: orderReducer.authUser,
    currency: orderReducer.currency,
    orderData: orderReducer.orderData,
  };
};

const ProductForm = Form.create({ name: 'products' })(ProductsForm);

export default connect(mapStateToProps)(ProductForm);
