import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCXQ1QPN2uBSAx98yYJ-qEZDjjxim3qBKY",
  databaseURL: "https://kerestir-168d8.firebaseio.com",
  projectId: "kerestir-168d8",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export default firebaseApp;
