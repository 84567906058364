import React from "react";
import { Route, Redirect } from "react-router-dom";
import firebaseApp from "../../config/fbConfig";
import Spin from "antd/es/spin";
import "antd/es/spin/style/css";

class PublicRoute extends React.Component {
  stateChangeListner = null;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isAuthenticated: false,
    };
  }

  componentDidMount() {
    this.stateChangeListner = firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          loading: false,
          isAuthenticated: true,
        });
      } else {
        this.setState({
          loading: false,
          isAuthenticated: false,
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.stateChangeListner) {
      // unsubscribe
      this.stateChangeListner();
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) =>
          this.state.isAuthenticated ? (
            this.state.loading ? (
              <div style={{ textAlign: "center", margin: "20px 0" }}>
                <Spin size="large" />
              </div>
            ) : (
              <Redirect to="/dashboard" />
            )
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  }
}

export default PublicRoute;
