import React from "react";
import { Route, Switch } from "react-router-dom";

import Food from "../containers/Settings/Food/Food";
import Lodging from "../containers/Settings/Lodging/Lodging";
import Dashboard from "../containers/Dashboard/Dashboard";
import Orders from "../containers/Orders/Orders";
import Customers from "../containers/Customers/Customers";
import SingleOrder from "../containers/SingleOrder/SingleOrder";
import TodaysMeals from "../containers/Dashboard/TodaysMeals";
import TodaysLodging from "../containers/Dashboard/TodaysLodging";
import Users from "../containers/Users/Users";
import RoomPlacement from "../containers/RoomPlacement/RoomPlacement";
import SingleCustomer from "../containers/SingleCustomer/SingleCustomer";
import Currencies from "../containers/Settings/Currencies/Currencies";
import SoftInquiries from "../containers/Orders/SoftInquiries";
import RoomAvailability from "../containers/AvailabilityCalendar/RoomAvailability";
import Reports from "../containers/Reports/Reports";
const Routes = () => {
  return (
    <Switch>
      <Route path="/dashboard" exact component={Dashboard} />
      <Route path="/dashboard/meals" exact component={TodaysMeals} />
      <Route path="/dashboard/rooms" exact component={TodaysLodging} />
      <Route path="/orders" exact component={Orders} />
      <Route path="/orders/soft" exact component={SoftInquiries} />
      <Route path="/orders/:id" component={SingleOrder} />
      <Route path="/customers" exact component={Customers} />
      <Route path="/customers/:customerId" exact component={SingleCustomer} />
      <Route path="/settings/food" exact component={Food} />
      <Route path="/settings/lodging" exact component={Lodging} />
      <Route path="/settings/currency" exact component={Currencies} />
      <Route path="/reports" exact component={Reports} />
      <Route path="/users" exact component={Users} />
      <Route path="/room-placement" exact component={RoomPlacement} />
      <Route path="/availability-calendar" exact component={RoomAvailability} />
    </Switch>
  );
};

export default Routes;
