import React, { lazy, Suspense } from "react";
// prettier-ignore
import { Typography, Tabs, Form, InputNumber, Table, Button, Icon, Modal, Input, message, Skeleton } from "antd";
import { axiosInstance } from "../../../httpHandler";
import {
  getFoodPreferences,
  getFoodPrice,
} from "../../../actions/orderActionCreator";
import { connect } from "react-redux";

const { confirm } = Modal;

class Food extends React.Component {
  state = {
    visible: false,
    breakfastPrice: {},
    lunchPrice: {},
    dinnerPrice: {},
    seudesPrice: {},
    type: "breakfast",
    breakfast: [],
    lunch: [],
    dinner: [],
    seudes: [],
    name: "",
    price: 0,
    action: "",
  };

  componentDidMount() {
    this.fetchData();
  }
  componentWillReceiveProps(nextProps) {
    nextProps &&
      nextProps.foodPrices.map((d) => {
        if (d.id === "breakfast") {
          this.setState({
            breakfastPrice: d,
          });
        } else if (d.id === "lunch") {
          this.setState({
            lunchPrice: d,
          });
        } else if (d.id === "dinner") {
          this.setState({
            dinnerPrice: d,
          });
        } else if (d.id === "seudes") {
          this.setState({
            seudesPrice: d,
          });
        }
      });
    nextProps &&
      this.setState({
        roomData: nextProps.foodPrices,
      });
    nextProps.foodPref &&
      this.setState({
        breakfast: nextProps.foodPref.breakfast,
        lunch: nextProps.foodPref.lunch,
        dinner: nextProps.foodPref.dinner,
        seudes: nextProps.foodPref.seudes,
      });
  }
  fetchData = () => {
    this.props.dispatch(getFoodPreferences());
    this.props.dispatch(getFoodPrice());
  };

  showModal = (type, data) => {
    console.info("Food.js", { data: data });
    data &&
      data.map((d) => {
        this.setState({
          name: d.name,
          price: d.price,
          id: d.id,
        });
      });
    this.setState({
      visible: true,
      action: type,
    });
  };

  handleOk = (e) => {
    const data = {
      name: this.state.name,
      price: this.state.price,
      type: this.state.type,
    };
    if (this.state.action === "Add") {
      return axiosInstance
        .post("/addroompreferences", data, { handlerEnabled: true })
        .then((res) => {
          this.fetchData();
          message.success("Preference has been added successfully.");
          this.setState({
            visible: false,
            name: "",
            price: null,
          });
        });
    } else if (this.state.action === "Edit") {
      return axiosInstance
        .patch(`/editroompreferences/${this.state.id}`, data, {
          handlerEnabled: true,
        })
        .then((res) => {
          this.fetchData();
          message.success("Preference has been updated successfully.");
          this.setState({
            visible: false,
            name: "",
            price: null,
          });
        });
    }
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      name: "",
      price: 0,
    });
  };

  handleEdit = (type, data) => {
    return axiosInstance
      .patch(`/editfoodprice/${type}`, data, {
        handlerEnabled: true,
      })
      .then((res) => {
        this.fetchData();
        message.success("Prices has been updated successfully.");
      });
  };

  handleChange = (e) => {
    if (e === "1") {
      this.setState({ type: "breakfast" });
    } else if (e === "2") {
      this.setState({ type: "lunch" });
    } else if (e === "3") {
      this.setState({ type: "dinner" });
    } else if (e === "4") {
      this.setState({ type: "seudes" });
    }
  };

  showConfirm = (id) => {
    let confirmThis = this;
    const data = { type: this.state.type };
    confirm({
      title: `Do you want to delete Preference?`,
      content:
        "When clicked the OK button, this food preference will be deleted.",
      onOk() {
        return axiosInstance
          .post(`/deletefoodpreference/${id}`, data, { handlerEnabled: true })
          .then((res) => {
            message.success("Food preference has been deleted successfully!");
            if (res) {
              confirmThis.fetchData();
            }
          })
          .catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  render() {
    const breakfast =
      this.state.breakfast?.length > 0 &&
      this.state.breakfast.map((d, i) => ({
        id: d.id,
        key: i,
        preference: d.name,
        price: d.price,
      }));
    const lunch =
      this.state.lunch?.length > 0 &&
      this.state.lunch.map((d, i) => ({
        id: d.id,
        key: i,
        preference: d.name,
        price: d.price,
      }));
    const dinner =
      this.state.dinner?.length > 0 &&
      this.state.dinner.map((d, i) => ({
        id: d.id,
        key: i,
        preference: d.name,
        price: d.price,
      }));
    const seudes =
      this.state.seudes?.length > 0 &&
      this.state.seudes?.map((d, i) => ({
        id: d.id,
        key: i,
        preference: d.name,
        price: d.price,
      }));
    const dataSource = [
      {
        key: "1",
        preference: "Fish",
        price: 5,
      },
      {
        key: "2",
        preference: "Chicken",
        price: 3,
      },
      {
        key: "3",
        preference: "Beef",
        price: 10,
      },
    ];

    const columns = [
      {
        title: "Preference",
        dataIndex: "preference",
        key: "preference",
        render: (preference) => <strong>{preference}</strong>,
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (price) => "$" + price.toFixed(2),
      },
      {
        title: "Actions",
        dataIndex: "id",
        key: "id",
        render: (id) => (
          <div>
            <Button
              type="primary"
              shape="circle"
              onClick={() =>
                this.showModal(
                  "Edit",
                  this.state[this.state.type].filter((d) => d.id === id)
                )
              }
            >
              <Icon type="edit" />
            </Button>
            <Button
              type="danger"
              shape="circle"
              onClick={() => this.showConfirm(id)}
              style={{ marginLeft: "5px" }}
            >
              <Icon type="delete" />
            </Button>
          </div>
        ),
      },
    ];
    return (
      <div>
        <Typography.Title level={3}>Food Settings</Typography.Title>
        <Tabs
          onChange={this.handleChange}
          defaultActiveKey="1"
          animated={false}
        >
          <Tabs.TabPane tab="Breakfest" key="1">
            <strong>Breakfest Pricing</strong>
            <Form
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 22 }}
              style={{ margin: "24px 0" }}
            >
              <Form.Item label="Regular price">
                <InputNumber
                  value={this.state.breakfastPrice.regular}
                  onChange={(e) =>
                    this.setState({
                      breakfastPrice: {
                        ...this.state.breakfastPrice,
                        regular: e,
                      },
                    })
                  }
                  min={1}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
              <Form.Item label="VIP price">
                <InputNumber
                  value={this.state.breakfastPrice.vip}
                  onChange={(e) =>
                    this.setState({
                      breakfastPrice: { ...this.state.breakfastPrice, vip: e },
                    })
                  }
                  min={1}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />{" "}
              </Form.Item>
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  onClick={() =>
                    this.handleEdit("breakfast", this.state.breakfastPrice)
                  }
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
            <strong>Breakfest Preferences</strong>
            <br />
            <br />
            <Button
              type="primary"
              shape="round"
              onClick={() => this.showModal("Add")}
            >
              + Add Preference
            </Button>
            {breakfast ? (
              <Table
                dataSource={breakfast}
                columns={columns}
                style={{ marginTop: "24px" }}
              />
            ) : (
              <Skeleton active />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Lunch" key="2">
            <strong>Lunch Pricing</strong>
            <Form
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 22 }}
              style={{ margin: "24px 0" }}
            >
              <Form.Item label="Regular price">
                <InputNumber
                  value={this.state.lunchPrice.regular}
                  onChange={(e) =>
                    this.setState({
                      lunchPrice: { ...this.state.lunchPrice, regular: e },
                    })
                  }
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
              <Form.Item label="VIP price">
                <InputNumber
                  value={this.state.lunchPrice.vip}
                  onChange={(e) =>
                    this.setState({
                      lunchPrice: { ...this.state.lunchPrice, vip: e },
                    })
                  }
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  onClick={() =>
                    this.handleEdit("lunch", this.state.lunchPrice)
                  }
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
            <strong>Lunch Preferences</strong>
            <br />
            <br />
            <Button
              type="primary"
              shape="round"
              onClick={() => this.showModal("Add")}
            >
              + Add Preference
            </Button>
            {lunch ? (
              <Table
                dataSource={lunch}
                columns={columns}
                style={{ marginTop: "24px" }}
              />
            ) : (
              <Skeleton active />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Dinner" key="3">
            <strong>Dinner Pricing</strong>
            <Form
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 22 }}
              style={{ margin: "24px 0" }}
            >
              <Form.Item label="Regular price">
                <InputNumber
                  value={this.state.dinnerPrice.regular}
                  onChange={(e) =>
                    this.setState({
                      dinnerPrice: { ...this.state.dinnerPrice, regular: e },
                    })
                  }
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
              <Form.Item label="VIP price">
                <InputNumber
                  value={this.state.dinnerPrice.vip}
                  onChange={(e) =>
                    this.setState({
                      dinnerPrice: { ...this.state.dinnerPrice, vip: e },
                    })
                  }
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  onClick={() =>
                    this.handleEdit("dinner", this.state.dinnerPrice)
                  }
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
            <strong>Dinner Preferences</strong>
            <br />
            <br />
            <Button
              type="primary"
              shape="round"
              onClick={() => this.showModal("Add")}
            >
              + Add Preference
            </Button>
            <Table
              dataSource={dinner}
              columns={columns}
              style={{ marginTop: "24px" }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Seudes" key="4">
            <strong>Seudes Pricing</strong>
            <Form
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 22 }}
              style={{ margin: "24px 0" }}
            >
              <Form.Item label="Regular price">
                <InputNumber
                  value={this.state.seudesPrice.regular}
                  onChange={(e) =>
                    this.setState({
                      seudesPrice: { ...this.state.seudesPrice, regular: e },
                    })
                  }
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
              <Form.Item label="VIP price">
                <InputNumber
                  value={this.state.seudesPrice.vip}
                  onChange={(e) =>
                    this.setState({
                      seudesPrice: { ...this.state.seudesPrice, vip: e },
                    })
                  }
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  onClick={() =>
                    this.handleEdit("seudes", this.state.seudesPrice)
                  }
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
            <strong>Seudes Preferences</strong>
            <br />
            <br />
            <Button
              type="primary"
              shape="round"
              onClick={() => this.showModal("Add")}
            >
              + Add Preference
            </Button>
            <Table
              dataSource={seudes}
              columns={columns}
              style={{ marginTop: "24px" }}
            />
          </Tabs.TabPane>
        </Tabs>
        <Modal
          title={`${this.state.action} preference`}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form layout="inline" style={{ margin: "50px 0" }}>
            <Form.Item label="Name">
              <Input
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </Form.Item>
            <Form.Item label="Price">
              <InputNumber
                value={this.state.price}
                onChange={(e) => this.setState({ price: e })}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = ({ orderReducer }) => {
  return {
    foodPref: orderReducer.foodPreferences,
    foodPrices: orderReducer.foodPrices,
  };
};

export default connect(mapStateToProps)(Food);
