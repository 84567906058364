import React from "react";
import moment from "moment";
// prettier-ignore
import { Row, Col, Tabs, InputNumber, Switch, Button, TimePicker, Select, Form } from "antd";
import { axiosInstance } from "../../httpHandler";

export default class DatePanel extends React.Component {
  state = {
    people: {
      couples: 0,
      men: 0,
      woman: 0,
      boys: 0,
      girls: 0,
      babies: 0,
      private: 0,
    },
    rooms: [],
    food: {
      breakfast: {},
      lunch: { preferences: [] },
      dinner: { preferences: [] },
      seudes: {},
    },
    breakfastData: [],
    lunchData: [],
    dinnerData: [],
    seudesData: [],
    roomsData: [],
    preferencesData: [],
    roomsDatas: [],
    pData: [],
    c: [],
  };

  updatePeopleQTY = (qty, type) => {
    const stateUpdate = { ...this.state.people };

    stateUpdate[type] = qty;
    this.setState(
      {
        people: stateUpdate,
      },
      this.roomPlacement
    );
  };

  roomPlacement = () => {
    const people = this.state.people;
    const totalPeople =
      people.couple * 2 +
      people.private * 2 +
      people.men +
      people.woman +
      people.boys +
      people.girls;
    const rooms = [];
    let roomsCount = 0;

    for (let i = 0; i < totalPeople; i++) {
      if (i % 2 === 0) {
        roomsCount++;
        rooms.push(roomsCount);
      }
    }

    let stateUpdate = { ...this.state.rooms };
    stateUpdate = rooms;
    this.setState({
      rooms: stateUpdate,
    });
  };

  handlelunchPreferences = (e) => {
    const pref = [];
    pref.push(e);
    this.setState({
      food: {
        ...this.state.food,
        lunch: { ...this.state.food.lunch, preferences: pref },
      },
    });
  };
  handledinnerPreferences = (e) => {
    const pref = [];
    pref.push(e);
    this.setState({
      food: {
        ...this.state.food,
        dinner: { ...this.state.food.dinner, preferences: pref },
      },
    });
  };

  componentDidMount() {
    axiosInstance
      .get("/getfoodpreferences", { handlerEnabled: true })
      .then((res) => {
        this.setState({
          breakfastData: res.data.data.breakfast,
          lunchData: res.data.data.lunch,
          dinnerData: res.data.data.dinner,
          seudesData: res.data.data.seudes,
        });
      });

    // axiosInstance
    //   .get("/getbuildingrooms", { handlerEnabled: true })
    //   .then((res) => {
    //     this.setState({
    //       roomsData: res.data.data,
    //     });
    //   });

    // axiosInstance
    //   .get("/getRoomPreference", { handlerEnabled: true })
    //   .then((res) => {
    //     this.setState({
    //       preferencesData: res.data.data,
    //     });
    //   });
  }

  // handleRooms = (i, e) => {
  //   console.info("DatePanel.js", { e: e });
  //   const room = [...this.state.rooms];
  //   room[i].room = e;
  //   this.setState({
  //     rooms: room
  //   });
  // };

  render() {
    const { getFieldDecorator } = this.props.form;
    const foodTitle =
      this.props.date.split(" ")[0] === "Sat" ? "Seudes" : "Food";
    let b = this.state.pData;
    const peoples = this.props.food[this.props.date].people;
    let maxDormBeds =
      peoples.boys + peoples.girls + peoples.woman + peoples.men;
    console.log("__", maxDormBeds);

    return (
      <div>
        <Tabs>
          {this.props.date.split(" ")[0] !== "Sat" && !this.props.isLast ? (
            <Tabs.TabPane tab="Lodging" key="1">
              <Row>
                <Col span={8}>
                  <Form.Item label="Couple">
                    <InputNumber
                      min={0}
                      value={
                        this.props.food[this.props.date].people.couple &&
                        this.props.food[this.props.date].people.couple
                      }
                      onChange={(qty) => {
                        this.props.updatePeopleQTY(
                          qty,
                          "couple",
                          this.props.date
                        );
                        this.props.addVisitors(this.props.date, "couple", qty);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Men">
                    <InputNumber
                      min={0}
                      value={
                        this.props.food[this.props.date].people.men &&
                        this.props.food[this.props.date].people.men
                      }
                      onChange={(qty) => {
                        this.props.updatePeopleQTY(qty, "men", this.props.date);
                        this.props.addVisitors(this.props.date, "men", qty);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Women">
                    <InputNumber
                      min={0}
                      value={
                        this.props.food[this.props.date].people.woman &&
                        this.props.food[this.props.date].people.woman
                      }
                      onChange={(qty) => {
                        this.props.updatePeopleQTY(
                          qty,
                          "woman",
                          this.props.date
                        );
                        this.props.addVisitors(this.props.date, "woman", qty);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Boys">
                    <InputNumber
                      min={0}
                      value={
                        this.props.food[this.props.date].people.boys &&
                        this.props.food[this.props.date].people.boys
                      }
                      onChange={(qty) => {
                        this.props.updatePeopleQTY(
                          qty,
                          "boys",
                          this.props.date
                        );
                        this.props.addVisitors(this.props.date, "boys", qty);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Girls">
                    <InputNumber
                      min={0}
                      value={
                        this.props.food[this.props.date].people.girls &&
                        this.props.food[this.props.date].people.girls
                      }
                      onChange={(qty) => {
                        this.props.updatePeopleQTY(
                          qty,
                          "girls",
                          this.props.date
                        );
                        this.props.addVisitors(this.props.date, "girls", qty);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Babies">
                    <InputNumber
                      min={0}
                      value={
                        this.props.food[this.props.date].people.babies &&
                        this.props.food[this.props.date].people.babies
                      }
                      onChange={(qty) => {
                        this.props.updatePeopleQTY(
                          qty,
                          "babies",
                          this.props.date
                        );
                        this.props.addVisitors(this.props.date, "babies", qty);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Private">
                    <InputNumber
                      min={0}
                      value={
                        this.props.food[this.props.date].people.private &&
                        this.props.food[this.props.date].people.private
                      }
                      onChange={(qty) => {
                        this.props.updatePeopleQTY(
                          qty,
                          "private",
                          this.props.date
                        );
                        this.props.addVisitors(this.props.date, "private", qty);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <strong>Placement</strong>
                </Col>
                <Col span={8}>
                  <Form.Item label="Private rooms">
                    <InputNumber
                      min={0}
                      value={
                        this.props.food[this.props.date].placement
                          .privateRooms &&
                        this.props.food[this.props.date].placement.privateRooms
                      }
                      onChange={(qty) => {
                        this.props.updateRoomsQTY(
                          qty,
                          "privateRooms",
                          this.props.date
                        );
                      }}
                      placeholder="Private Rooms"
                      style={{ width: "100px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Regular beds">
                    <InputNumber
                      min={0}
                      value={
                        this.props.food[this.props.date].placement
                          .regulerBeds &&
                        this.props.food[this.props.date].placement.regulerBeds
                      }
                      placeholder="Reguler Beds"
                      onChange={(qty) => {
                        this.props.updateRoomsQTY(
                          qty,
                          "regulerBeds",
                          this.props.date
                        );
                      }}
                      style={{ width: "100px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Dorm beds">
                    <InputNumber
                      min={0}
                      max={maxDormBeds}
                      value={
                        this.props.food[this.props.date].placement.dormBeds &&
                        this.props.food[this.props.date].placement.dormBeds
                      }
                      placeholder="Dorm Beds"
                      onChange={(qty) => {
                        this.props.updateRoomsQTY(
                          qty,
                          "dormBeds",
                          this.props.date
                        );
                      }}
                      style={{ width: "100px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
          ) : null}
          <Tabs.TabPane tab={foodTitle} key="2">
            {this.props.date.split(" ")[0] === "Sat" ? (
              <Row>
                <Col span={8}>
                  <Form.Item label="Seudes for">
                    <InputNumber
                      min={0}
                      onChange={(qty) =>
                        this.props.addFood(this.props.date, qty, "seudes")
                      }
                      value={
                        this.props.food[this.props.date].food &&
                        this.props.food[this.props.date].food.seudes.people
                      }
                    />
                    <br />
                    <Switch
                      checkedChildren="VIP"
                      unCheckedChildren="VIP"
                      onChange={(checked) =>
                        this.props.addFoodVIP(
                          this.props.date,
                          checked ? true : false,
                          "seudes"
                        )
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={8}>
                  <Form.Item label="Breakfest">
                    <InputNumber
                      min={0}
                      placeholder="people"
                      style={{ width: "100px" }}
                      value={
                        this.props.food[this.props.date].food.breakfast
                          .people &&
                        this.props.food[this.props.date].food.breakfast.people
                      }
                      onChange={(qty) =>
                        this.props.addFood(this.props.date, qty, "breakfast")
                      }
                    />
                    <br />
                    {getFieldDecorator("aa", {
                      initialValue: moment("9:00", "h:mm a"),
                      rules: [
                        {
                          required: true,
                          message: "Please select dates!",
                        },
                      ],
                    })(
                      <TimePicker
                        placeholder="Time"
                        format="h:mm a"
                        use12Hours
                        defaultOpenValue={moment("9:00", "h:mm a")}
                        minuteStep={15}
                        value={
                          this.props.food[this.props.date].food.breakfast
                            .time &&
                          moment(
                            this.props.food[this.props.date].food.breakfast
                              .time,
                            "h:mm a"
                          )
                        }
                        style={{ width: "100px" }}
                        onChange={(time) =>
                          this.props.addFoodTime(
                            this.props.date,
                            time,
                            "breakfast"
                          )
                        }
                      />
                    )}
                    <br />
                    <Select
                      mode="multiple"
                      style={{ minWidth: "100px" }}
                      placeholder="Preferences"
                      value={
                        this.props.food[this.props.date].food.breakfast
                          .preferences &&
                        this.props.food[this.props.date].food.breakfast
                          .preferences
                      }
                      dropdownMatchSelectWidth={false}
                      onChange={(e) =>
                        this.props.addFoodPreferences(
                          this.props.date,
                          e,
                          "breakfast"
                        )
                      }
                    >
                      {this.state.breakfastData.map((d, i) => (
                        <Select.Option value={d.name} key={i}>
                          {d.name}
                        </Select.Option>
                      ))}
                    </Select>
                    <br />
                    <Switch
                      checkedChildren="VIP"
                      unCheckedChildren="VIP"
                      checked={
                        this.props.food[this.props.date].food.breakfast.VIP &&
                        this.props.food[this.props.date].food.breakfast.VIP
                      }
                      onChange={(checked) => {
                        this.props.addFoodVIP(
                          this.props.date,
                          checked ? true : false,
                          "breakfast"
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Lunch">
                    <InputNumber
                      min={0}
                      placeholder="people"
                      style={{ width: "100px" }}
                      value={
                        this.props.food[this.props.date].food.lunch.people &&
                        this.props.food[this.props.date].food.lunch.people
                      }
                      onChange={(qty) =>
                        this.props.addFood(this.props.date, qty, "lunch")
                      }
                    />
                    <br />
                    {getFieldDecorator("oo", {
                      initialValue: moment("13:00", "h:mm a"),
                      rules: [
                        {
                          required: true,
                          message: "Please select dates!",
                        },
                      ],
                    })(
                      <TimePicker
                        size="small"
                        placeholder="Time"
                        format="h:mm a"
                        use12Hours
                        defaultOpenValue={moment("13:00", "h:mm a")}
                        minuteStep={15}
                        style={{ width: "100px" }}
                        value={
                          this.props.food[this.props.date].food.lunch.time &&
                          moment(
                            this.props.food[this.props.date].food.lunch.time,
                            "h:mm a"
                          )
                        }
                        onChange={(time) =>
                          this.props.addFoodTime(this.props.date, time, "lunch")
                        }
                      />
                    )}
                    <br />
                    <Select
                      mode="multiple"
                      style={{ minWidth: "100px" }}
                      placeholder="Preferences"
                      dropdownMatchSelectWidth={false}
                      value={
                        this.props.food[this.props.date].food.lunch
                          .preferences &&
                        this.props.food[this.props.date].food.lunch.preferences
                      }
                      onChange={(e) =>
                        this.props.addFoodPreferences(
                          this.props.date,
                          e,
                          "lunch"
                        )
                      }
                    >
                      {this.state.lunchData.map((d, i) => (
                        <Select.Option value={d.name} key={i}>
                          {d.name}
                        </Select.Option>
                      ))}
                    </Select>
                    <br />
                    <Switch
                      checkedChildren="VIP"
                      unCheckedChildren="VIP"
                      checked={
                        this.props.food[this.props.date].food.lunch.VIP &&
                        this.props.food[this.props.date].food.lunch.VIP
                      }
                      onChange={(checked) => {
                        this.props.addFoodVIP(
                          this.props.date,
                          checked ? true : false,
                          "lunch"
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
                {this.props.date.split(" ")[0] !== "Fri" ? (
                  <Col span={8}>
                    <Form.Item label="Dinner">
                      <InputNumber
                        min={0}
                        placeholder="people"
                        style={{ width: "100px" }}
                        value={
                          this.props.food[this.props.date].food.dinner.people &&
                          this.props.food[this.props.date].food.dinner.people
                        }
                        onChange={(qty) =>
                          this.props.addFood(this.props.date, qty, "dinner")
                        }
                      />
                      <br />
                      {getFieldDecorator("ad", {
                        initialValue: moment("18:00", "h:mm a"),
                        rules: [
                          {
                            required: true,
                            message: "Please select dates!",
                          },
                        ],
                      })(
                        <TimePicker
                          size="small"
                          placeholder="Time"
                          format="h:mm a"
                          use12Hours
                          defaultOpenValue={moment("18:00", "h:mm a")}
                          minuteStep={15}
                          value={
                            this.props.food[this.props.date].food.dinner.time &&
                            moment(
                              this.props.food[this.props.date].food.dinner.time,
                              "h:mm a"
                            )
                          }
                          style={{ width: "100px" }}
                          onChange={(time) =>
                            this.props.addFoodTime(
                              this.props.date,
                              time,
                              "dinner"
                            )
                          }
                        />
                      )}
                      <br />
                      <Select
                        mode="multiple"
                        style={{ minWidth: "100px" }}
                        placeholder="Preferences"
                        dropdownMatchSelectWidth={false}
                        value={
                          this.props.food[this.props.date].food.dinner
                            .preferences &&
                          this.props.food[this.props.date].food.dinner
                            .preferences
                        }
                        onChange={(e) =>
                          this.props.addFoodPreferences(
                            this.props.date,
                            e,
                            "dinner"
                          )
                        }
                      >
                        {this.state.dinnerData.map((d, i) => (
                          <Select.Option value={d.name} key={i}>
                            {d.name}
                          </Select.Option>
                        ))}
                        {/* <Select.Option value="beef">Beef</Select.Option>
                        <Select.Option value="beefPlus">Beef +</Select.Option>
                        <Select.Option value="breadedCutlets">
                          Breaded cutlets
                        </Select.Option>
                        <Select.Option value="chickenBottoms">
                          Chicken bottoms
                        </Select.Option>
                        <Select.Option value="fish">Fish</Select.Option>
                        <Select.Option value="turkey">Turkey</Select.Option> */}
                      </Select>
                      <br />
                      <Switch
                        checkedChildren="VIP"
                        unCheckedChildren="VIP"
                        checked={
                          this.props.food[this.props.date].food.dinner.VIP &&
                          this.props.food[this.props.date].food.dinner.VIP
                        }
                        onChange={(checked) => {
                          this.props.addFoodVIP(
                            this.props.date,
                            checked ? true : false,
                            "dinner"
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                ) : null}
              </Row>
            )}
          </Tabs.TabPane>
          {/* <Select.OptGroup label="Main">
            <Select.Option value="Main 1">Main 1</Select.Option>
            <Select.Option value="Main 2">Main 2</Select.Option>
            <Select.Option value="Main 3">Main 3</Select.Option>
            <Select.Option value="Main 4">Main 4</Select.Option>
            <Select.Option value="Main 5A">Main 5A</Select.Option>
            <Select.Option value="Main 5B">Main 5B</Select.Option>
            <Select.Option value="Main 5BB">Main 5BB</Select.Option>
          </Select.OptGroup>
          <Select.OptGroup label="Boretzky">
            <Select.Option value="Boretzky 1">
              Boretzky 1
            </Select.Option>
            <Select.Option value="Boretzky 2">
              Boretzky 2
            </Select.Option>
          </Select.OptGroup>
          <Select.OptGroup label="Taylor">
            <Select.Option value="Taylor Outside">
              Taylor Outside
            </Select.Option>
            <Select.Option value="Taylor Inside">
              Taylor Inside
            </Select.Option>
          </Select.OptGroup>
          <Select.OptGroup label="Tony">
            <Select.Option value="Tony Outside">
              Tony Outside
            </Select.Option>
            <Select.Option value="Tony Inside">
              Tony Inside
            </Select.Option>
          </Select.OptGroup>
          <Select.OptGroup label="Diamond">
            <Select.Option value="Diamond 1">
              Diamond 1
            </Select.Option>
            <Select.Option value="Diamond 2">
              Diamond 2
            </Select.Option>
            <Select.Option value="Diamond 3">
              Diamond 3
            </Select.Option>
            <Select.Option value="Diamond 4">
              Diamond 4
            </Select.Option>
            <Select.Option value="Diamond 5">
              Diamond 5
            </Select.Option>
          </Select.OptGroup>
          <Select.OptGroup label="Shisha">
            <Select.Option value="Shisha 1">Shisha 1</Select.Option>
            <Select.Option value="Shisha 2">Shisha 2</Select.Option>
            <Select.Option value="Shisha 3">Shisha 3</Select.Option>
          </Select.OptGroup>
          <Select.OptGroup label="Anna">
            <Select.Option value="Anna 1">Anna 1</Select.Option>
            <Select.Option value="Anna 2">Anna 2</Select.Option>
            <Select.Option value="Anna 3">Anna 3</Select.Option>
          </Select.OptGroup>
          <Select.OptGroup label="Nobilis">
            <Select.Option value="Nobilis 1">
              Nobilis 1
            </Select.Option>
            <Select.Option value="Nobilis 2">
              Nobilis 2
            </Select.Option>
            <Select.Option value="Nobilis 3">
              Nobilis 3
            </Select.Option>
            <Select.Option value="Nobilis 4">
              Nobilis 4
            </Select.Option>
            <Select.Option value="Nobilis 5">
              Nobilis 5
            </Select.Option>
          </Select.OptGroup>
          <Select.OptGroup label="Lomosh">
            <Select.Option value="Lomosh 1">Lomosh 1</Select.Option>
            <Select.Option value="Lomosh 2">Lomosh 2</Select.Option>
            <Select.Option value="Lomosh 3">Lomosh 3</Select.Option>
          </Select.OptGroup>
          <Select.OptGroup label="Lefkowitz">
            <Select.Option value="Lefkowitz 1">
              Lefkowitz 1
            </Select.Option>
            <Select.Option value="Lefkowitz 2">
              Lefkowitz 2
            </Select.Option>
            <Select.Option value="Lefkowitz 3">
              Lefkowitz 3
            </Select.Option>
          </Select.OptGroup> */}
          {
            // this.props.food[this.props.date].rooms.length > 0 &&
            // this.props.date.split(" ")[0] !== "Sat" &&
            // !this.props.isLast ? (
            // <Tabs.TabPane tab="Rooms" key="3">
            //   <Row gutter={16}>
            //     {this.props.food[this.props.date].rooms.map((roomNum, i) => {
            //       return (
            //         <Col span={8} key={i}>
            //           <Select
            //             showSearch
            //             value={
            //               this.props.food[this.props.date].room[i].room &&
            //               this.props.food[this.props.date].room[i].room
            //             }
            //             placeholder={"Room " + roomNum}
            //             style={{ width: "100%", marginTop: 5, marginRight: 5 }}
            //             onChange={(e) =>
            //               this.props.addRooms(
            //                 this.props.date,
            //                 e,
            //                 i,
            //                 this.state.preferencesData,
            //                 this.state.roomsData
            //               )
            //             }
            //           >
            //             {this.state.roomsData &&
            //               this.state.roomsData.map((building) => {
            //                 const allPreferences = this.state.preferencesData;
            //                 // const {
            //                 //   preferences: currentPreferences,
            //                 // } = this.state.pData;
            //                 return (
            //                   <Select.OptGroup label={building.buildingName}>
            //                     {building.rooms.map((room) => {
            //                       const roomPreferences = room?.preferences
            //                         ?.map((id) => {
            //                           let preferenceName = undefined;
            //                           allPreferences &&
            //                             allPreferences.forEach((preference) => {
            //                               if (preference.id === id)
            //                                 preferenceName = preference.name;
            //                             });
            //                           return preferenceName;
            //                         })
            //                         .filter(
            //                           (preference) => preference !== undefined
            //                         );
            //                       const matchedPreferences = b[
            //                         i
            //                       ]?.filter((preference) =>
            //                         roomPreferences?.includes(preference)
            //                       );
            //                       const includesAllPreferences =
            //                         b[i]?.length === matchedPreferences?.length;
            //                       return (
            //                         <Select.Option
            //                           value={room.name}
            //                           disabled={!includesAllPreferences}
            //                         >
            //                           {room.name}
            //                         </Select.Option>
            //                       );
            //                     })}
            //                   </Select.OptGroup>
            //                 );
            //               })}
            //           </Select>
            //           <br />
            //           <Select
            //             placeholder="Preferences"
            //             mode="multiple"
            //             value={b[i]}
            //             key={i}
            //             onChange={(e) => {
            //               this.props.addRoomsPreferences(this.props.date, e, i);
            //               b[i] = e;
            //               this.setState({ pData: b });
            //             }}
            //             style={{
            //               minWidth: "100%",
            //               marginTop: 5,
            //               marginRight: 5,
            //             }}
            //           >
            //             {this.state.preferencesData.map((p) => (
            //               <Select.Option value={p.name}>{p.name}</Select.Option>
            //             ))}
            //             {/* <Select.Option value="No steps">No steps</Select.Option>
            //           <Select.Option value="Private room">
            //             Private room <Tag>+ $35</Tag>
            //           </Select.Option>
            //           <Select.Option value="Private bathroom">
            //             Private bathroom
            //           </Select.Option>
            //           <Select.Option value="VIP">VIP</Select.Option>
            //           <Select.Option value="Close proximity">
            //             Close proximity
            //           </Select.Option> */}
            //           </Select>
            //         </Col>
            //       );
            //     })}
            //   </Row>
            // </Tabs.TabPane>
            // ) : null
          }
        </Tabs>
        {(this.props.index === 0 && this.props.date.split(" ")[0] !== "Fri") ||
        (this.props.index === 2 && this.props.date.split(" ")[0] === "Sun") ? (
          <Button
            type="primary"
            style={{ marginTop: "16px" }}
            onClick={() =>
              this.props.applyToDates(this.props.date, this.props.index)
            }
          >
            Apply to all dates
          </Button>
        ) : null}
      </div>
    );
  }
}
