import React, { Component } from "react";
import moment from "moment";
import { sortBy } from "lodash";
import {
  getOrderData,
  showModal,
  todaysArrivals,
  todaysCurrentOrders,
} from "../../actions/orderActionCreator";
import { connect } from "react-redux";
// prettier-ignore
import { Tag, Tooltip, Button, Modal, message, Skeleton, Table, Form, Alert, Row, Col,  Radio, Checkbox, Input, InputNumber, Select, DatePicker, } from "antd";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../httpHandler";
import { GiMeal } from "react-icons/gi";
import {
  FaBed,
  FaBusAlt,
  FaMoneyBillAlt,
  FaShoppingBag,
  FaSwimmingPool,
} from "react-icons/fa";
import { handleCountCurrency } from "../../config/currencyConvertor";
const { confirm } = Modal;

const iconMap = {
  Foods: <GiMeal fontSize={22} />,
  Lodging: <FaBed fontSize={22} />,
  Donation: <FaMoneyBillAlt fontSize={22} />,
  Shuttle: <FaBusAlt fontSize={22} />,
  Products: <FaShoppingBag fontSize={22} />,
  Mikvah: <FaSwimmingPool fontSize={22} />,
};

class DataTable extends Component {
  state = {
    showCheckoutModal: false,
    totalAmount: 0,
    additional: 0,
    keyReturned: true,
    lateLodging: 0,
    lateFood: 0,
    notReturnedKeyFee: 0,
    otherFees: 0,
    donation: 0,
    discount: 0,
    checkoutMemo: "",
    orderId: "",
    status: false,
    customerPayments: [],
    paidData: [],
    type: "",
    selectedCard: [],
    paymentModal: false,
    creditCards: {
      cardName: "",
      cardNumber: "",
      cardCVV: "",
      cardExpiry: "",
      paymentAmount: "",
    },
    cashAmount: "",
    cashCurrency: "",
    routingNo: "",
    accountNo: "",
    chequeAmount: "",
    accountName: "",
    repeatNo: "",
    date: moment(new Date()).format("ddd MMM DD YYYY"),
    searchDate: "",
    searchedColumn: "",
    paidCurrency: "USD",
    currencyTotal: 0,
    customer: "",
  };
  currencies = [
    { sym: "$", value: "USD" },
    { sym: "zl", value: "ZlATY" },
    { sym: "₽", value: "RUBL" },
    { sym: "£", value: "POUND" },
    { sym: "₪", value: "SHEKEL" },
    { sym: "₴", value: "HRYVNIA" },
    { sym: "€", value: "EURO" },
  ];

  componentDidMount() {
    this.fetchData();
  }
  componentWillReceiveProps(nextProps) {
    nextProps.currentOrders?.orders &&
      this.setState({ orders: nextProps.currentOrders.orders });
    if (this.state.date !== moment(nextProps.Date).format("ddd MMM DD YYYY")) {
      this.setState(
        {
          date: moment(nextProps.Date).format("ddd MMM DD YYYY"),
        },
        () => this.fetchData()
      );
    }
  }
  fetchData = () => {
    this.props.dispatch(todaysCurrentOrders(this.state.date));
  };
  tableData = {};

  showConfirm = (id, key) => {
    let confirmThis = this;
    confirm({
      title: `Do you want to delete ${id} order?`,
      content: "When clicked the OK button, this order will be deleted.",
      onOk() {
        axiosInstance
          .delete(`/order/${key}`, { handlerEnabled: true })
          .then((res) => {
            message.success("Order has been deleted successfully!");
            if (res) {
              confirmThis.props.dispatch(getOrderData());
              confirmThis.fetchData();
            }
          })
          .catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  showCheckoutModal = (data, id, total, customerId, order) => {
    this.setState(
      {
        orderId: id,
        showCheckoutModal: true,
        paidData: data,
        totalAmount: total,
        customerId: customerId,
        customer: order[0]?.customer,
      },
      () => {
        this.fetchCustomerCard();
        let data = [];
        this.state.paidData.map((d) => {
          return d.payments?.map((r) => {
            data.push(r);
          });
        });
        this.setState({ customerPayments: data }, () => {
          const sumTotal = (arr) =>
            arr?.reduce((sum, { amount }) => sum + amount++, 0);
          const totalAmount = sumTotal(this.state.customerPayments);
          this.setState({ paidAmount: totalAmount });
        });
      }
    );
  };

  fetchCustomerCard = async () => {
    await axiosInstance
      .get("/contacts", { handlerEnabled: true })
      .then((res) => {
        this.setState({ loading: false });
        res.data.contacts.map((d) => {
          const customerId = this.state.customerId;

          if (d.id === customerId) {
            this.setState({
              cardsData: d.customerCards ? d.customerCards : [],
            });
          }
        });
      });
  };

  handleCheckoutOk = async () => {
    const lateLodging = this.state.lateLodging;
    const lateFood = this.state.lateFood;
    const notReturnedKeyFee = this.state.notReturnedKeyFee;
    const otherFees = this.state.otherFees;

    // Misc.
    const donation = this.state.donation;
    const discount = this.state.discount;

    const result =
      lateLodging +
      lateFood +
      notReturnedKeyFee +
      otherFees -
      (donation + discount);

    const data = {
      memo: this.state.checkoutMemo,
      status: this.state.status,
      keyReturned: this.state.keyReturned,
      lateLodging: this.state.lateLodging,
      lateFood: this.state.lateFood,
      notReturnedKeyFee: this.state.notReturnedKeyFee,
      otherFees: this.state.otherFees,
      donation: this.state.donation,
      discount: this.state.discount,
      addAmount: result,
      orderId: this.state.orderId,
      total: this.state.totalAmount,
    };

    if (this.state.paidAmount < this.state.totalAmount) {
      message.error("Customer Have Some Payments Remaining");
    } else {
      await axiosInstance
        .post(`/checkout`, data, { handlerEnabled: true })
        .then((res) => {
          if (res) {
            message.success("Order has been checked out successfully!");
            this.props.dispatch(getOrderData());
            this.fetchData();
            this.setState({ showCheckoutModal: false });
          }
        })
        .catch(() => console.log("Oops errors!"));
    }
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchDate: selectedKeys,
      searchedColumn: dataIndex,
    });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <DatePicker.RangePicker
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys}
          onChange={(e) => setSelectedKeys(e)}
          style={{ width: 250, marginBottom: 8, display: "block" }}
        />

        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 110, height: 30, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 110, height: 30 }}
        >
          Reset
        </Button>
      </div>
    ),

    onFilter: (value, record) => {
      const { searchDate } = this.state;
      let from = new Date(moment(searchDate[0]).format("MM/DD/YYYY"));
      let to = new Date(moment(searchDate[1]).format("MM/DD/YYYY"));
      let dateToCheck;
      if (typeof record[dataIndex] === "object") {
        const dt = Object.keys(record[dataIndex]).map((res, i) => {
          return new Date(res);
        });
        const arrivalDate = new Date(Math.min.apply(null, dt));
        const departureDate = new Date(Math.max.apply(null, dt));
        if (arrivalDate <= to && departureDate >= from) {
          return record[dataIndex];
        }
      } else {
        dateToCheck = new Date(moment(record[dataIndex]).format("MM/DD/YYYY"));
        if (dateToCheck <= to && dateToCheck >= from && record[dataIndex]) {
          return record[dataIndex];
        }
      }
    },
  });

  handleCheckin = (id) => {
    axiosInstance
      .put(`/checkin`, { orderId: id }, { handlerEnabled: true })
      .then((res) => {
        if (res) {
          message.success("Order has been checked in successfully!");
          this.fetchData();
          this.props.dispatch(getOrderData());
        }
      })
      .catch(() => console.log("Oops errors!"));
  };
  handleCheckoutCancel = () => {
    this.setState({
      showCheckoutModal: false,
      keyReturned: false,
      lateLodging: 0,
      lateFood: 0,
      notReturnedKeyFee: 0,
      otherFees: 0,
      donation: 0,
      discount: 0,
      checkoutMemo: "",
      orderId: "",
      status: false,
    });
  };
  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  showPaymentModal = () => {
    this.setState({ paymentModal: true });
  };
  handlePaymentCancel = () => {
    this.setState({ paymentModal: false });
  };
  handlePaymentModalOk = (currencyTotal = 0) => {
    this.props.form.validateFieldsAndScroll(
      ["cardName", "cardNumber", "cardExpiry", "cardAmount", "cardCVV"],
      (err, values) => {
        if (!err) {
          const data = this.state.paidData
            .filter((r) => r.amount > 0)
            .map((d) => d);
          const card = {
            data: data,
            creditCards: this.state.object,
            card: this.state.creditCards,
            type: this.state.type,
            additional: this.state.additional,
            customer: this.state.customer,
          };
          const cash = {
            cashAmount: this.state.paidData[0].amount,
            currencyTotal,
            cashCurrency: this.state.cashCurrency,

            data: data,
            type: this.state.type,
            additional: this.state.additional,
            customer: this.state.customer,
          };
          const check = {
            data: data,
            type: this.state.type,
            additional: this.state.additional,
            accountName: this.state.accountName,
            routingNo: this.state.routingNo,
            accountNo: this.state.accountNo,
            customer: this.state.customer,
          };
          const body =
            this.state.type === "card"
              ? card
              : this.state.type === "cash"
              ? cash
              : this.state.type === "check"
              ? check
              : null;
          axiosInstance
            .post("/addpayment", body, { handlerEnabled: true })
            .then((res) => {
              if (res.data.success) {
                message.success("Payment has been done successfully!");
                this.setState(
                  {
                    paymentModal: false,
                    selectedCard: [],
                    paidData: [],
                    type: "",
                    customerPayments: [],
                  },
                  () => {
                    this.state.paidData.map((d, i) => {
                      this.updateRecord(i, `amount`);
                    });
                    this.fetchData();
                    this.props.dispatch(getOrderData());
                  }
                );
              } else {
                message.error("Something went wrong!");
              }
            });
        }
      }
    );
  };

  handleSelectedCard = (v) => {
    let data = [];

    let object = JSON.parse(v);

    this.setState(
      {
        object: object,
      },
      () => {
        this.fixCard();
      }
    );
  };

  changeAmount = (id, value) => {
    let state = [...this.state.paidData];
    state[id].amount = value;
    this.setState({
      paidData: state,
    });
  };

  fixCard = () => {
    let data = [];
    let cardAmount = this.state.selectedCardAmount || 0;
    let amount = "xAuthAmount";
    let object = this.state.object;
    object[amount] = cardAmount;
    data.push(object);
    this.setState({ selectedCard: data });
    //, () => {
    //   store.dispatch(addOrder("selectedCard", this.state.selectedCard));
    // });
  };

  countAmount = (arr) => {
    let d = 0;
    if (arr) {
      arr.length > 0 &&
        arr.reduce((sum, { amount }) => (d = sum + amount++), 0);
    }
    return d;
    // arr.length > 0 &&
    //   arr
    //     .filter((elt, eltIndex) =>
    //       arr.some(
    //         (sameNameElt, sameNameEltIndex) =>
    //           sameNameElt.paymentId === elt.paymentId &&
    //           sameNameEltIndex === eltIndex
    //       )
    //     )
    //     .reduce((sum, { amount }) => sum + amount++, 0);
  };

  render() {
    const remainingData =
      this.state.paidData.length > 0 &&
      this.state.paidData?.map((arr, i) => ({
        key: arr.id,
        id: {
          orderId: arr.orderId,
          mainId: arr.id,
          index: i,
          total: arr.total,
          remainingAmount: this.countAmount(arr.payments),
        },
        total: arr.total,
      }));

    const paymentColumns = [
      {
        title: "Order ID",
        dataIndex: "id",
        key: "id",
        render: (id) => (
          <strong>
            <Link to={"/orders/" + id.orderId}>{id.orderId}</Link>
          </strong>
        ),
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        render: (total) => <span>${total}</span>,
      },
      {
        title: "Balance",
        dataIndex: "id",
        key: "id",
        render: (id) => <span>${id.total - id.remainingAmount}</span>,
      },
      {
        title: "Amount",
        dataIndex: "id",
        key: "id",
        render: (id) => (
          <InputNumber
            disabled={id.total - id.remainingAmount < 0}
            max={id.total - id.remainingAmount}
            min={0}
            onChange={(e) => {
              this.changeAmount(id.index, e);
            }}
          />
        ),
      },
    ];

    const ordData = this.props.getOrderData && this.props.getOrderData.orders;
    const currency = (
      <Select
        // defaultValue="USD"
        value={this.state.paidCurrency}
        style={{ width: 80 }}
        onChange={(e) => {
          this.setState({ cashCurrency: e, paidCurrency: e });
        }}
      >
        {this.currencies.map((currency, i) => (
          <Select.Option value={currency.value} key={i}>
            {currency.sym}
          </Select.Option>
        ))}
      </Select>
    );
    const sumTotal = (arr) =>
      arr?.reduce((sum, { amount }) => sum + amount++, 0);
    const totalAmount = sumTotal(this.state.customerPayments);
    const currencyTotal = handleCountCurrency(
      this.state.paidData && this.state.paidData[0]?.amount,
      this.state.paidCurrency,
      this.props.currency?.data
    );
    const { getFieldDecorator } = this.props.form;
    const orderData = sortBy(
      this.state.orders &&
        this.state.orders.map((arr, i) => ({
          key: i,
          id: `KRO${arr.orderId}`,
          ordId: arr.id,
          customer:
            arr.customer.firstName && arr.customer.lastName
              ? arr.customer.firstName + " " + arr.customer.lastName
              : arr.customer,
          customerId: arr.customerId,
          firstName: arr.customer && arr.customer.firstName,
          lastName: arr.customer && arr.customer.lastName,
          address1: arr.customer && arr.customer.address1,
          address2: arr.customer && arr.customer.address2,
          city: arr.customer && arr.customer.city,
          state: arr.customer && arr.customer.state,
          zip: arr.customer && arr.customer.zip,
          date: arr.dates || arr.date,
          till: "06/06/2020",
          orderType: arr.type,
          checkInOut: {
            orderType: arr.type,
            date: arr.dates || arr.date,
            status: arr.status,
            paidData: [
              {
                id: arr.id,
                amount: 0,
                total: arr.total,
                orderId: arr.orderId,
                payments: arr.payments,
              },
            ],
            ordId: arr.id,
            total: arr.total,
            customerId: arr.customerId,
            payments: arr.payments,
          },
          people: arr.dates,
          status: arr.status,
          total: arr.total,
          phone: (arr.customer?.phones && arr.customer.phones[0]) || [],
          email: (arr.customer?.emails && arr.customer.emails[0]) || [],
          contactPersonInfo: arr.contactPersonInfo,
          contactPersonName: arr.contactPersonName,
          checkout: arr.checkout && arr.checkout,
        })),
      ["id"]
    ).reverse();

    this.tableData = {
      dataSource: orderData,
      columns: [
        {
          title: "Order ID",
          dataIndex: "id",
          key: "id",
          render: (id) => (
            <Link to={{ pathname: `orders/` + id, state: { ordData } }}>
              <strong>{id}</strong>
            </Link>
          ),
        },
        {
          title: "Customer",
          dataIndex: "customer",
          key: "customer",
          render: (customer, data) => (
            <strong>
              <Link to={`/customers/${data.customerId}`}>{customer}</Link>
            </strong>
          ),
        },
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          sorter: (a, b) => {
            let first;
            let second;
            if (typeof a.date === "object") {
              const dt = Object.keys(a.date).map((res, i) => new Date(res));
              first = new Date(Math.min.apply(null, dt));
            } else {
              first = a.date;
            }
            if (typeof b.date === "object") {
              const dt = Object.keys(b.date).map((res, i) => new Date(res));
              second = new Date(Math.min.apply(null, dt));
            } else {
              second = b.date;
            }
            return moment(first).unix() - moment(second).unix();
          },

          render: (date) => {
            if (typeof date === "object") {
              const dt = Object.keys(date).map((res, i) => new Date(res));
              const ldt = new Date(Math.min.apply(null, dt));
              return <strong>{moment(ldt).format("MM/DD/YYYY")}</strong>;
            } else {
              return <strong>{moment(date).format("MM/DD/YYYY")}</strong>;
            }
          },
          ...this.getColumnSearchProps("date"),
        },
        {
          title: "Order Type",
          dataIndex: "orderType",
          key: "orderType",
          render: (orderType) => iconMap[orderType],
        },
        {
          title: "People",
          dataIndex: "people",
          key: "people",
          render: (input) => {
            if (typeof input === "object") {
              const data = Object.values(input);
              const p = (data && data[data.length - 1].people) || {};
              const title = Object.keys(p).map((type, i) => (
                <span key={i}>
                  <strong>
                    {type[0].toUpperCase() + type.slice(1).toLowerCase()}:{" "}
                  </strong>
                  {p[type]} {Object.keys(p).length !== i + 1 ? <br /> : ""}
                </span>
              ));
              return (
                <Tooltip title={title}>
                  <Tag>
                    {p.girls +
                      p.woman +
                      p.men +
                      p.couple * 2 +
                      p.babies +
                      p.boys}
                  </Tag>
                </Tooltip>
              );
            } else {
              return null;
            }
          },
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (status, date) => {
            let color = "green";
            const colors = {
              Inquiry: "#f06292",
              Confirmed: "#66bb6a",
              Current: "#03a9f4",
              Past: "grey",
              Closed: "lightgrey",
              "Checked in": "green",
              "Checked out": "red",
            };
            if (typeof date.date === "object") {
              const dt = Object.keys(date.date).map((res, i) => {
                return new Date(res);
              });
              const CurrentDate = new Date();
              const arrivalDate = new Date(Math.min.apply(null, dt));
              const departureDate = new Date(Math.max.apply(null, dt));
              const stts =
                status === "Confirmed" &&
                CurrentDate <= departureDate &&
                CurrentDate >= arrivalDate
                  ? "Current"
                  : status;

              Object.keys(colors).forEach(
                (clr) => stts === clr && (color = colors[clr])
              );
              return (
                <Tag color={color} key={status}>
                  {stts}
                </Tag>
              );
            } else {
              return null;
            }
          },
          filters: [
            { text: "Inquiry", value: "Inquiry" },
            { text: "Confirmed", value: "Confirmed" },
            { text: "Current", value: "Current" },
            { text: "Past", value: "Past" },
            { text: "Closed", value: "Closed" },
            { text: "Checked in", value: "Checked in" },
            { text: "Checked out", value: "Checked out" },
          ],
          onFilter: (value, record) => record.status?.indexOf(value) === 0,
        },
        {
          title: "Total",
          dataIndex: "total",
          key: "total",
          render: (total) => total && "$" + total,
        },
        {
          title: "Contact Person Info",
          dataIndex: "contactPersonInfo",
          key: "phone",
        },
        {
          title: "Contact Person Name",
          dataIndex: "contactPersonName",
          key: "email",
          // render: (email, id) => (
          //   <a
          //     href={
          //       "mailto:" + email + "?subject=Re: Kerestir Order #231234" + id
          //     }
          //   >
          //     {email}
          //   </a>
          // ),
        },
        {
          title: "Check in/out",
          dataIndex: "checkInOut",
          key: "checkInOut",
          render: (checkInOut, data) => {
            let cOrder;
            if (typeof checkInOut.date === "object") {
              const dt = Object.keys(checkInOut.date).map((res, i) => {
                return new Date(res);
              });

              const CurrentDate = new Date();
              const arrivalDate = new Date(Math.min.apply(null, dt));
              const departureDate = new Date(Math.max.apply(null, dt));
              cOrder =
                CurrentDate <= departureDate && CurrentDate >= arrivalDate
                  ? true
                  : false;
            }

            return (
              <>
                {checkInOut.orderType === "Lodging" && cOrder && (
                  <div>
                    {["Checked in", "Checked out", "Closed", "draft"].includes(
                      checkInOut.status
                    ) ? (
                      ""
                    ) : (
                      <Button
                        type="primary"
                        shape="circle"
                        icon="login"
                        onClick={() => {
                          this.handleCheckin(checkInOut.ordId);
                        }}
                      />
                    )}
                  </div>
                )}
                {checkInOut.status === "Checked in" && (
                  <Button
                    type="danger"
                    shape="circle"
                    icon="logout"
                    onClick={() =>
                      this.showCheckoutModal(
                        checkInOut.paidData,
                        checkInOut.ordId,
                        checkInOut.total,
                        checkInOut.customerId,
                        this.state.orders.filter(
                          (order) => order.id === checkInOut.ordId
                        )
                      )
                    }
                    style={{ marginLeft: "5px" }}
                  />
                )}
              </>
            );
          },
        },
        {
          title: "Actions",
          key: "actions",
          render: (orderData) => (
            <span>
              <Button
                type="primary"
                shape="circle"
                icon="edit"
                onClick={() => {
                  this.props.dispatch(
                    showModal(
                      true,
                      this.state.orders.filter(
                        (order) => order.id === orderData.ordId
                      ),
                      orderData.orderType
                    )
                  );
                }}
              />
              <Button
                type="danger"
                shape="circle"
                icon="delete"
                onClick={() => this.showConfirm(orderData.id, orderData.ordId)}
                style={{ marginLeft: "5px" }}
              />
            </span>
          ),
        },
      ],
    };

    let dataType = {};
    switch (this.props.type) {
      case "orders":
        dataType = this.tableData;
        break;
      default:
        dataType = {
          dataSource: [],
          dataType: [],
        };
    }
    return this.state.orders ? (
      <>
        <Table
          dataSource={dataType.dataSource}
          columns={dataType.columns}
          pagination={{ pageSize: 5 }}
        />
        <Modal
          title="Checkout"
          visible={this.state.showCheckoutModal}
          onOk={this.handleCheckoutOk}
          onCancel={this.handleCheckoutCancel}
        >
          <div onClock={this.showPaymentModal}>
            <Alert
              message="Paid"
              description={
                <>
                  {this.state.paidAmount < this.state.totalAmount ? (
                    <Link onClick={this.showPaymentModal}>Pay now</Link>
                  ) : (
                    ""
                  )}
                </>
              }
              type={
                this.state.paidAmount < this.state.totalAmount
                  ? "error"
                  : "success"
              }
              showIcon
            />
          </div>
          <Modal
            title="New Payment"
            visible={this.state.paymentModal}
            onOk={() => this.handlePaymentModalOk(currencyTotal)}
            onCancel={this.handlePaymentCancel}
          >
            {this.state.paidData.length > 0 ? (
              <div>
                <Table columns={paymentColumns} dataSource={remainingData} />{" "}
                <p style={{ textAlign: "center" }}>
                  <strong>
                    Total:{" "}
                    {this.currencies.map((currency, i) =>
                      currency.value === this.state.paidCurrency
                        ? currency.sym
                        : ""
                    )}{" "}
                    {currencyTotal}
                  </strong>{" "}
                </p>
              </div>
            ) : (
              <Skeleton active />
            )}

            {/* <InputNumber
              placeholder="Additional amount"
              onChange={(e) => {
                this.setState({ additional: e });
              }}
            /> */}
            <Select
              placeholder="Payment type"
              style={{ width: 120 }}
              onChange={(e) => {
                this.setState({ type: e });
              }}
            >
              <Select.Option value="card">Credit card</Select.Option>
              <Select.Option value="cash">Cash</Select.Option>
              <Select.Option value="check">Check</Select.Option>
            </Select>
            <br />
            <br />
            {this.state.type === "card" ? (
              <div>
                <Form.Item>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Choose existing card"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // value={this.state.selectedCard[0]}
                    onChange={this.handleSelectedCard}
                    allowClear
                  >
                    {this.state.cardsData &&
                      this.state.cardsData.length > 0 &&
                      this.state.cardsData.map((d, i) => {
                        return (
                          <Select.Option value={JSON.stringify(d)} key={i}>
                            {d.cardEnding.substr(12, 4)}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item rules={[{ required: true }]}>
                  {getFieldDecorator("cardName", {
                    rules: [
                      {
                        required:
                          this.state.selectedCard.length > 0 ? false : true,
                        message: "Please enter card name!",
                      },
                    ],
                  })(
                    <Input
                      value={this.state.creditCards.cardName}
                      placeholder="Name on card"
                      onChange={(e) => {
                        this.setState({
                          creditCards: {
                            ...this.state.creditCards,
                            cardName: e.target.value,
                          },
                        });
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item rules={[{ required: true }]}>
                  {getFieldDecorator("cardNumber", {
                    rules: [
                      {
                        required:
                          this.state.selectedCard.length > 0 ? false : true,
                        message: "Please enter card number!",
                      },
                      {
                        min: 15,
                        message:
                          "Card number must be minimum 15 characters long.",
                      },
                      {
                        max: 16,
                        message:
                          "Card number must be maximum 16 characters long.",
                      },
                    ],
                  })(
                    <Input
                      value={this.state.creditCards.cardNumber}
                      placeholder="Card number"
                      onChange={(e) => {
                        this.setState({
                          creditCards: {
                            ...this.state.creditCards,
                            cardNumber: e.target.value,
                          },
                        });
                      }}
                    />
                  )}
                </Form.Item>
                <Row>
                  <Col span={12}>
                    <Form.Item rules={[{ required: true }]}>
                      {getFieldDecorator("cardExpiry", {
                        rules: [
                          {
                            required:
                              this.state.selectedCard.length > 0 ? false : true,
                            message: "Please enter card expiry!",
                          },
                        ],
                      })(
                        <DatePicker.MonthPicker
                          value={this.state.creditCards.cardExpiry}
                          placeholder="Exp."
                          format="MMYY"
                          onChange={(e) => {
                            this.setState({
                              creditCards: {
                                ...this.state.creditCards,
                                cardExpiry: e,
                              },
                            });
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item rules={[{ required: true }]}>
                      {getFieldDecorator("cardCVV", {
                        rules: [
                          {
                            required:
                              this.state.selectedCard.length > 0 ? false : true,
                            message: "Please enter cvv!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="CCV"
                          value={this.state.creditCards.cardCVV}
                          onChange={(e) => {
                            this.setState({
                              creditCards: {
                                ...this.state.creditCards,
                                cardCVV: e.target.value,
                              },
                            });
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item></Form.Item>
                <hr />
              </div>
            ) : this.state.type === "cash" ? (
              <Form.Item>
                <Input
                  addonBefore={currency}
                  onChange={(e) =>
                    this.setState({
                      cashAmount: parseInt(e.target.value),
                    })
                  }
                  disabled
                  // value={totalAmount + this.state.additional}
                />
              </Form.Item>
            ) : this.state.type === "check" ? (
              <div>
                <Form.Item>
                  <Input
                    placeholder="Account name"
                    value={this.state.accountName}
                    onChange={(e) =>
                      this.setState({ accountName: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <Input
                    placeholder="Routing number"
                    value={this.state.routingNo}
                    onChange={(e) =>
                      this.setState({
                        routingNo: e.target.value,
                      })
                    }
                  />
                  <Row>
                    <Col span={12}>
                      <Input
                        placeholder="Account number"
                        value={this.state.accountNo}
                        onChange={(e) =>
                          this.setState({
                            accountNo: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <Input
                        placeholder="Repeat account number"
                        value={this.state.repeatNo}
                        onChange={(e) =>
                          this.setState({
                            repeatNo: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Input
                    disabled
                    size="large"
                    // value={totalAmount + this.state.additional}
                  />
                </Form.Item>
                <hr />
              </div>
            ) : (
              ""
            )}
          </Modal>
          <Form
            layout="horizontal"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            labelAlign="left"
          >
            <Form.Item style={{ marginBottom: 0, marginTop: "16px" }}>
              <Checkbox
                onChange={(e) => this.handleChange("status", e.target.checked)}
                value={this.state.status}
              >
                Room was checked
              </Checkbox>
            </Form.Item>
            <Form.Item label="Keys" style={{ marginBottom: 0 }}>
              <Radio.Group
                defaultValue={
                  this.state.keyReturned ? "returned" : "not-returned"
                }
                buttonStyle="solid"
                // value={this.state.keyReturned ? "returned" : "not-returned"}
                onChange={(e) =>
                  this.handleChange(
                    "keyReturned",
                    e.target.value === "returned" ? true : false
                  )
                }
              >
                <Radio.Button value="returned">Returned</Radio.Button>
                <Radio.Button value="not-returned">Not returned</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <strong>Fees</strong>
            <Form.Item label="Late lodging" style={{ marginBottom: 0 }}>
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                min={0}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(e) => this.handleChange("lateLodging", e)}
                value={this.state.lateLodging}
              />
            </Form.Item>
            <Form.Item label="Late food" style={{ marginBottom: 0 }}>
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                value={this.state.lateFood}
                onChange={(e) => this.handleChange("lateFood", e)}
              />
            </Form.Item>
            <Form.Item label="Didn't return key" style={{ marginBottom: 0 }}>
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(e) => this.handleChange("notReturnedKeyFee", e)}
                value={this.state.notReturnedKeyFee}
                min={0}
              />
            </Form.Item>
            <Form.Item label="Other" style={{ marginBottom: 0 }}>
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(e) => this.handleChange("otherFees", e)}
                value={this.state.otherFees}
                min={0}
              />
            </Form.Item>
            <strong>Misc.</strong>
            <Form.Item label="Donation" style={{ marginBottom: 0 }}>
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(e) => this.handleChange("donation", e)}
                value={this.state.donation}
                min={0}
              />
            </Form.Item>
            <Form.Item label="Discount" style={{ marginBottom: 0 }}>
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(e) => this.handleChange("discount", e)}
                value={this.state.discount}
                min={0}
              />
            </Form.Item>
            <Form.Item label="Checkout memo" style={{ marginBottom: 0 }}>
              <Input.TextArea
                onChange={(e) =>
                  this.handleChange("checkoutMemo", e.target.value)
                }
                value={this.state.checkoutMemo}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    ) : (
      <div>
        <Skeleton active />
      </div>
    );
  }
}

const mapStateToProps = ({ orderReducer }) => ({
  currentOrders: orderReducer.currentOrders,
  currency: orderReducer.currency,
});

const DTable = Form.create({})(DataTable);

export default connect(mapStateToProps)(DTable);
