import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PublicRoute from "../components/PublicRoute/PublicRoute";
import PrivateRoue from "../components/PrivateRoute/PrivateRoute";

import Login from "../containers/Login/Login";
import AppLayout from "../Layout/AppLayout";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <PublicRoute path="/" exact component={Login} />
        <PrivateRoue path="/" component={AppLayout} />
      </Switch>
    </Router>
  );
};

export default Routes;
