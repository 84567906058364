import React from "react";
import { Route, Redirect } from "react-router-dom";
import firebaseApp from "../../config/fbConfig";
import Spin from "antd/es/spin";
import "antd/es/spin/style/css";
import { store } from "../../store";
import { getAuthUserData } from "../../actions/orderActionCreator";

class PrivateRoute extends React.Component {
  stateChangeListner = null;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isAuthenticated: false,
    };
  }

  componentDidMount() {
    this.stateChangeListner = firebaseApp.auth().onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
        // User is signed in.
        store.dispatch(getAuthUserData(user.email));
        user.getIdToken().then(function (data) {
          localStorage.setItem("idToken", data);
        });
        this.setState({
          loading: false,
          isAuthenticated: true,
        });
      } else {
        // No user is signed in.
        this.setState({
          loading: false,
          isAuthenticated: false,
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.stateChangeListner) {
      // unsubscribe
      this.stateChangeListner();
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) =>
          this.state.isAuthenticated ? (
            <Component {...props} />
          ) : this.state.loading ? (
            <div style={{ textAlign: "center", margin: "20px 0" }}>
              <Spin size="large" />
            </div>
          ) : (
            <Redirect
              to={{ pathname: "/", state: { from: this.props.location } }}
            />
          )
        }
      />
    );
  }
}

export default PrivateRoute;
