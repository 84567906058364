import React, { Component } from "react";
import { Icon, List, Skeleton } from "antd";
import { getNotes } from "../../actions/orderActionCreator";
import { connect } from "react-redux";

class NotesList extends Component {
  state = {
    notes: null,
  };
  componentDidMount() {
    this.props.dispatch(getNotes());
  }
  render() {
    const { notes } = this.props;
    let data = [];
    notes?.notes &&
      notes.notes.map((note) => {
        data.push({
          note: note.note,
          id: note.id,
        });
      });

    return this.props.notes ? (
      <List
        bordered
        dataSource={data}
        style={{ width: "100%" }}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Icon type="edit" style={{ color: "#1890ff" }} />,
              <Icon type="delete" style={{ color: "red" }} />,
            ]}
          >
            {item.note}
          </List.Item>
        )}
        pagination={{ pageSize: 5 }}
      />
    ) : (
      <Skeleton />
    );
  }
}

const mapStateToProps = ({ orderReducer }) => {
  return {
    notes: orderReducer.notes,
  };
};

export default connect(mapStateToProps)(NotesList);
