import React, { lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import { message, Layout, Menu, Icon, Skeleton } from "antd";

import Logo from "../assets/img/logo-white.png";

import LoggedInRoutes from "../Routes/LoggedInRoutes";

import firebaseApp from "../config/fbConfig";

import AddUser from "../components/Users/AddUser";
import AddCustomer from "../components/Customer/AddCustomer";
const TranscationModal = lazy(() =>
  import("../components/NewOrder/TransactionModal")
);

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class AppLayout extends React.Component {
  state = {
    collapsed: false,
  };

  handleLogout = async () => {
    try {
      await firebaseApp.auth().signOut();
      await this.props.history.push(`/`);
    } catch (error) {
      message.error(error.message);
    }
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  render() {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}
        >
          <img
            style={{ width: "80%", padding: "16px", marginLeft: "10%" }}
            alt="Logo"
            src={Logo}
          />

          <Menu
            theme="dark"
            mode="vertical"
            selectedKeys={[this.props.location.pathname]}
          >
            <SubMenu
              key="1"
              title={
                <span>
                  {/* <Link to="/dashboard" /> */}
                  <Icon type="home" />
                  <span>Dashboard</span>
                </span>
              }
            >
              <Menu.Item key="/dashboard">
                <Link to="/dashboard" />
                Dashboard
              </Menu.Item>
              <Menu.Item key="/dashboard/rooms">
                <Link to="/dashboard/rooms" />
                Todays Rooms
              </Menu.Item>
              <Menu.Item key="/dashboard/meals">
                <Link to="/dashboard/meals" />
                Todays Meals
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="5"
              title={
                <span>
                  <Icon type="bank" />
                  <span>Orders</span>
                </span>
              }
            >
              <Menu.Item key="/orders">
                <Link to="/orders" />
                All orders
              </Menu.Item>
              <Menu.Item key="/orders/soft">
                <Link to="/orders/soft" />
                Soft inquiries
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="/room-placement">
              <Link to="/room-placement" />
              <Icon type="file-done" />
              <span>Room Placement</span>
            </Menu.Item>
            <Menu.Item key="/availability-calendar">
              <Link to="/availability-calendar" />
              <Icon type="calendar" />
              <span>Availability calendar</span>
            </Menu.Item>

            <SubMenu
              key="8"
              title={
                <span>
                  <Icon type="team" />
                  <span>Customers</span>
                </span>
              }
            >
              <Menu.Item key="2" disabled>
                <AddCustomer general />
              </Menu.Item>
              <Menu.Item key="/customers">
                <Link to="/customers" />
                <span>All Customers</span>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="10"
              title={
                <span>
                  <Icon type="team" />
                  <span>Users</span>
                </span>
              }
            >
              <Menu.Item key="11" disabled>
                <AddUser />
              </Menu.Item>
              <Menu.Item key="/users">
                <Link to="/users" />
                All Users
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="9"
              title={
                <span>
                  <Icon type="setting" />
                  <span>Settings</span>
                </span>
              }
            >
              <Menu.Item key="/settings/lodging">
                <Link to="/settings/lodging" />
                Lodging
              </Menu.Item>
              <Menu.Item key="/settings/food">
                <Link to="/settings/food" />
                Food
              </Menu.Item>
              <Menu.Item key="/settings/currency">
                <Link to="/settings/currency" />
                Currency
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="/reports">
              <Link to="/reports" />
              <Icon type="file-done" />
              <span>Reports</span>
            </Menu.Item>

            {/* <Menu.Item key="12">
              <Link to="/users" />
              <Icon type="team" />
              Users
            </Menu.Item> */}
            <Menu.Item key="13" onClick={this.handleLogout}>
              <Icon type="logout" />
              <span>Logout</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header style={{ background: "#fff", paddingLeft: "16px" }}>
            <Suspense fallback={<Skeleton active />}>
              <TranscationModal btnName="New Transaction" />
            </Suspense>
          </Header>
          <Content style={{ margin: "16px" }}>
            <div style={{ padding: 24, background: "#fff", minHeight: 360 }}>
              <LoggedInRoutes />
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Designed &amp; developed by{" "}
            <a
              href="https://iconstudiosny.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;Icon Studios
            </a>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default AppLayout;
