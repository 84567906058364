import React, { Component } from "react";
import { Button, DatePicker, Skeleton, Table } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { getTransactions } from "../../actions/orderActionCreator";
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import font from "../../assets/Fonts/Rubik-Regular.ttf";
import { axiosInstance } from "../../httpHandler";

Font.register({
  family: "Rubik",
  src: font,
});
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    padding: 45,
    fontFamily: "Rubik",
  },
  section: {
    margin: 5,
  },
  text: {
    border: 1,
    fontSize: 16,
  },
  texttitle: {
    marginBottom: 10,
  },

  table: {
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { margin: "0", flexDirection: "row" },
  tableRowHeader: {
    margin: "0",
    flexDirection: "row",
    backgroundColor: "lightblue",
  },
  tableCol: {
    width: "11.1111%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2: {
    width: "11.1111%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 9,
  },
});
class Transactions extends Component {
  state = {
    dateRange: [],
    transactions: [],
    generatePdf: false,
  };
  componentWillReceiveProps(props) {
    props.transactions.transactions?.length > 0 &&
      this.getTransactionData(props.transactions.transactions);
  }
  componentDidMount() {
    let dates = [];
    let date = new Date();
    dates.push(
      moment(
        new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7),
        "MM/DD/YYYY"
      )
    );
    dates.push(moment(date, "MM/DD/YYYY"));
    this.props.dispatch(getTransactions());
    this.setState({ dateRange: dates, generatePdf: true });
  }
  generatePDF = (data) => {
    return this.state.generatePdf ? (
      <Document>
        <Page size="A4" orientation="portrait" style={styles.page}>
          <View style={styles.table}>
            <View style={styles.tableRowHeader}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Transaction</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>customer</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Date</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Amount</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Type</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Currency</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>USD Value</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Transaction No</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Last 4</Text>
              </View>
            </View>
            {data.map((dt, i) => (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{dt.number}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{dt.customer}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{dt.Date}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{dt.amount}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{dt.Type}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{dt.Currency}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{dt.Value}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{dt.TransactionNumber}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{dt.last4}</Text>
                </View>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    ) : (
      ""
    );
  };
  getTransactionData = (transactions) => {
    const { dateRange } = this.state;

    let from = new Date(moment(dateRange[0]).format("MM/DD/YYYY"));
    let to = new Date(moment(dateRange[1]).format("MM/DD/YYYY"));
    let dateToCheck;
    let transactionsData = [];
    transactions &&
      transactions.map((transaction) => {
        dateToCheck = new Date(moment(transaction.date).format("MM/DD/YYYY"));
        if (dateToCheck <= to && dateToCheck >= from) {
          transactionsData.push(transaction);
        }
      });
    this.setState({ transactions: transactionsData });
  };
  custName = (customer) => {
    if (typeof customer === "object") {
      let fName = customer.firstName ? customer.firstName : "";
      let lName = customer.lastName ? customer.lastName : "";
      return fName + "  " + lName;
    } else {
      return customer;
    }
  };
  setCurrency = (e) => {
    if (e === "RUBL") {
      return { name: "RUBL", symbol: "₽" };
    } else if (e === "POUND") {
      return { name: "POUND", symbol: "£" };
    } else if (e === "ZlATY") {
      return { name: "ZlATY", symbol: "zł" };
    } else if (e === "SHEKEL") {
      return { name: "SHEKEL", symbol: "₪" };
    } else if (e === "HRYVNIA") {
      return { name: "HRYVNIA", symbol: "₴" };
    } else if (e === "USD") {
      return { name: "USD", symbol: "$" };
    } else if (e === "EURO") {
      return { name: "EURO", symbol: "€" };
    }
  };

  updateRecords = async (transactions) => {
    let data = [];
    transactions.forEach((el) => {
      data.push(el.key);
    });
    await axiosInstance
      .put(`/updateTransactionDownload`, data, { handlerEnabled: true })
      .then((res) => {
        this.props.dispatch(getTransactions());
      })
      .catch((error) => console.log(error));
  };
  render() {
    const { transactions } = this.state;

    let transactionDataSource =
      transactions &&
      transactions.map((transaction, i) => ({
        key: i,
        number: i + 1,
        customer: transaction.customer,
        Date: moment(transaction.date).format("MM/DD/YYYY"),
        amount: transaction.amount || 0,
        Downloaded: transaction.downloaded || "No",
        Type: transaction.type,
        Currency: this.setCurrency(transaction.currency),
        Value: "$" + (transaction.usdValue || transaction.amount),
        TransactionNumber: transaction.transactionNo || "-",
        last4: transaction.ending || "-",
        downloaded: transaction.downloaded || "No",
      }));
    let csvData =
      transactions &&
      transactions.map((transaction, i) => ({
        key: transaction.id,
        number: i + 1,
        customer: this.custName(transaction.customer),
        Date: moment(transaction.date).format("MM/DD/YYYY"),
        amount:
          (transaction.currency &&
            transaction.amount &&
            this.setCurrency(transaction.currency).symbol +
              " " +
              transaction.amount.toLocaleString()) ||
          "-",
        Downloaded: transaction.downloaded,
        Type: transaction.type,
        Currency: this.setCurrency(transaction.currency)?.name || "-",
        Value: "$" + (transaction.usdValue || transaction.amount),
        TransactionNumber: transaction.transactionNo || " - ",
        last4: transaction.ending || " - ",
      }));

    const columns = [
      {
        title: "Transaction #",
        dataIndex: "number",
        key: "number",
      },
      {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        render: (customer) => (
          <p>
            {typeof customer === "object"
              ? customer.firstName + " " + customer.lastName
              : customer}
          </p>
        ),
      },
      {
        title: "Date",
        dataIndex: "Date",
        key: "Date",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (amount, row) =>
          row.Currency &&
          amount &&
          row.Currency.symbol + amount.toLocaleString(),
      },
      {
        title: "Type",
        dataIndex: "Type",
        key: "Type",
      },
      {
        title: "Currency",
        dataIndex: "Currency",
        key: "Currency",
        render: (Currency) => Currency && Currency.name,
      },
      {
        title: "USD Value",
        dataIndex: "Value",
        key: "Value",
        render: (Value) => Value.toLocaleString(),
      },
      {
        title: "Transaction Number",
        dataIndex: "TransactionNumber",
        key: "TransactionNumber",
      },
      {
        title: "Last 4 Digits",
        dataIndex: "last4",
        key: "last4",
      },
      {
        title: "Downloaded",
        dataIndex: "downloaded",
        key: "downloaded",
      },
    ];
    const headers = [
      {
        label: "Transaction #",
        key: "number",
      },
      {
        label: "Customer",
        key: "customer",
      },
      {
        label: "Date",
        key: "Date",
      },
      {
        label: "Amount",
        key: "amount",
      },
      {
        label: "Type",
        key: "Type",
      },
      {
        label: "Currency",
        key: "Currency",
      },
      {
        label: "USD Value",
        key: "Value",
      },
      {
        label: "Transaction Number",
        key: "TransactionNumber",
      },
      {
        label: "Last 4 Digits",
        key: "last4",
      },
      {
        label: "Downloaded",
        key: "Downloaded",
      },
    ];
    return (
      <div>
        <DatePicker.RangePicker
          onChange={(e) => {
            this.setState({ dateRange: e }, () =>
              this.getTransactionData(this.props.transactions.transactions)
            );
          }}
          value={this.state.dateRange}
        />
        <Button
          shape="round"
          type="primary"
          style={{ margin: "0 0 16px 16px" }}
          onClick={() => this.updateRecords(csvData)}
          disabled={this.state.transactions.length > 0 ? false : true}
        >
          {this.state.generatePdf && (
            <PDFDownloadLink
              document={this.generatePDF(csvData)}
              fileName={
                "Transactions_" +
                moment(this.state.dateRange[0]).format("MM/DD/YYYY") +
                " to " +
                moment(this.state.dateRange[1]).format("MM/DD/YYYY") +
                ".pdf"
              }
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download PDF"
              }
            </PDFDownloadLink>
          )}
        </Button>
        <Button
          shape="round"
          type="primary"
          style={{ margin: "0 0 16px 16px" }}
          disabled={this.state.transactions.length > 0 ? false : true}
        >
          <CSVLink
            data={csvData}
            filename={
              "Transactions_" +
              moment(this.state.dateRange[0]).format("MM/DD/YYYY") +
              " to " +
              moment(this.state.dateRange[1]).format("MM/DD/YYYY") +
              ".csv"
            }
            headers={headers}
            onClick={() => this.updateRecords(csvData)}
            style={{ textDecoration: "none" }}
            // enclosingCharacter={`"`}
          >
            Download CSV
          </CSVLink>
        </Button>
        {this.state.transactions.length > 0 ? (
          <Table dataSource={transactionDataSource} columns={columns} />
        ) : (
          <Skeleton active />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ orderReducer }) => {
  return {
    transactions: orderReducer.transactions,
  };
};
export default connect(mapStateToProps)(Transactions);
