import React, { Component } from "react";
import { Button, Col, DatePicker, Form, Input, message, Row } from "antd";
import moment from "moment";
import { axiosInstance } from "../../httpHandler";
import { getInquiries } from "../../actions/orderActionCreator";
import { store } from "../../store";

class SoftInquiryForm extends Component {
  state = {
    customerName: "",
    date: moment(new Date()).format("DD/MM/YYYY"),
    description: "",
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    await axiosInstance
      .post(`/softInquiry`, this.state, { handlerEnabled: true })
      .then((res) => {
        message.success("Inquiry Added Successfully");
        store.dispatch(getInquiries());
        this.props.closeModel();
      })
      .catch((error) => console.log("error"));
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Name">
              <Input
                placeholder="Customer name"
                onChange={(e) =>
                  this.handleChange("customerName", e.target.value)
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Date">
              <DatePicker
                placeholder="Choose date"
                style={{ width: "100%" }}
                onChange={(e) =>
                  this.handleChange("date", moment(e).format("DD/MM/YYYY"))
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Description">
          <Input.TextArea
            placeholder="Order description"
            rows={6}
            onChange={(e) => this.handleChange("description", e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default SoftInquiryForm;
