import {
  ADD_ORDER,
  GET_ORDER,
  CLEAR_DATA,
  ADD_ORDER_REQUEST,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_ERROR,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_ORDER_ERROR,
  ADD_CUSTOMER,
  SHOW_MODAL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_ERROR,
  GET_SINGLE_ORDER_REQUEST,
  GET_SINGLE_ORDER_SUCCESS,
  GET_SINGLE_ORDER_ERROR,
  ADD_USER,
  ADD_USER_ERROR,
  GET_USERS_REQUEST,
  GET_USERS,
  GET_USERS_ERROR,
  GET_CONTACTS,
  GET_ROOM_PRICE,
  GET_PREFERENCES,
  GET_BUILDINGS,
  GET_ROOMS,
  GET_FOOD_PRICE,
  GET_FOOD_PREFERENCES,
  GET_SINGLE_CUSTOMER,
  GET_ORDER_BY_DATE,
  GET_ORDER_BY_DATE_REQUEST,
  GET_ORDER_BY_DATE_ERROR,
  ADD_DONATION_SUCCESS,
  GET_ROOMS_REQUEST,
  GET_NOTES,
  GET_AUTH_USER_DATA,
  GET_CURRENCY,
  GET_SOFT_INQUIRIES,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_ORDER_ID,
  TODAYS_ARRIVALS,
  TODAYS_DEPARTURES,
  TODAYS_CURRENT_ORDERS,
  UPDATE_REDUCER,
  ADD_PAYMENT,
  HOLD_RELESE_HANDLER,
  PAYMENT_TYPE_PAY_NOW
} from "../actions/orderActionTypes";
let initialState = {
  foodPreferences: [],
  paymentHold: false
};
const orderReducers = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ORDER:
      return {
        ...state,
        [action.key]: action.payload
      };
    case GET_ORDER:
      return {
        ...state,
        ORDERInfo: action.payload
      };
    case ADD_ORDER_REQUEST:
      return {
        ...state
      };
    case ADD_ORDER_SUCCESS:
      return {
        ...state,
        ORDERData: action.payload
      };
    case ADD_ORDER_ERROR:
      return {
        ...state
      };
    case GET_ORDER_REQUEST:
      return {
        ...state
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        getOrderData: action.payload,
        updateOrderSuccess: action.updateOrderSuccess
      };
    case GET_ORDER_ERROR:
      return {
        ...state
      };
    case GET_USERS_REQUEST:
      return {
        ...state
      };
    case GET_USERS:
      return {
        ...state,
        getUsers: action.payload
      };
    case GET_USERS_ERROR:
      return {
        ...state
      };
    case GET_SINGLE_ORDER_REQUEST:
      return {
        ...state,
        orderLoader: true
      };
    case GET_SINGLE_ORDER_SUCCESS:
      return {
        ...state,
        singleOrder: action.payload
      };
    case GET_SINGLE_ORDER_ERROR:
      return {
        ...state,
        orderLoader: false
      };
    case ADD_CUSTOMER:
      return {
        ...state,
        customer: {
          ...state.customer,
          [action.key]: action.payload
        }
      };
    case ADD_USER:
      return {
        ...state,
        USERInfo: action.payload
      };
    case ADD_USER_ERROR:
      return {
        ...state
      };
    case SHOW_MODAL:
      return {
        ...state,
        visible: action.visible,
        orderType: action.orderType,
        mtype: action.mtype,
        disabled: action.disabled,
        orderData: action.orderId ? action.orderId : []
      };
    case CLEAR_DATA:
      return {
        ...state,
        customer: null,
        contactPersonName: null,
        contactPersonInfo: null,
        note: null,
        arrivalTime: null,
        departureTime: null,
        dates: null,
        totalCost: null,
        cardEnding: null,
        paymentAmount: null,
        cashAmount: null,
        routingNo: null,
        chequeAmount: null,
        orderData: null,
        selectedCard: null,
        paymentHold: false
      };

    case UPDATE_ORDER_REQUEST:
      return {
        ...state
      };
    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        updateOrderSuccess: action.updateOrderSuccess
      };
    case UPDATE_ORDER_ERROR:
      return {
        ...state
      };
    case GET_CONTACTS:
      return {
        ...state,
        contacts: action.payload
      };

    case GET_ROOM_PRICE:
      return {
        ...state,
        cost: action.payload
      };
    case GET_PREFERENCES:
      return {
        ...state,
        preferences: action.payload
      };
    case GET_BUILDINGS:
      return {
        ...state,
        buildings: action.payload
      };
    case GET_ROOMS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_ROOMS:
      return {
        ...state,
        roomData: action.payload,
        loading: false
      };
    case GET_FOOD_PRICE:
      return { ...state, foodPrices: action.payload };
    case GET_FOOD_PREFERENCES:
      return { ...state, foodPreferences: action.payload };
    case GET_SINGLE_CUSTOMER:
      return { ...state, cust: action.payload };
    case GET_ORDER_BY_DATE:
      return { ...state, ordersByDate: action.payload, loading: false };
    case GET_ORDER_BY_DATE_REQUEST:
      return {
        ...state,
        // loading: true
        loading: action.loading
      };
    case GET_ORDER_BY_DATE_ERROR:
      return {
        ...state,
        loading: false
      };
    case GET_NOTES:
      return {
        ...state,
        notes: action.payload
      };

    case ADD_DONATION_SUCCESS:
      return { ...state, res: action.payload };

    case GET_AUTH_USER_DATA:
      return { ...state, authUser: action.payload };
    case GET_CURRENCY:
      return { ...state, currency: action.payload };
    case GET_SOFT_INQUIRIES:
      return { ...state, inquiries: action.payload };
    case GET_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case GET_TRANSACTIONS_ORDER_ID:
      return { ...state, transactionsByOrder: action.payload };
    case TODAYS_ARRIVALS:
      return { ...state, arrivals: action.payload };
    case TODAYS_DEPARTURES:
      return { ...state, departures: action.payload };
    case TODAYS_CURRENT_ORDERS: 
      return {...state, currentOrders: action.payload}
    case UPDATE_REDUCER:
      return {
        ...state,
        roomData: action.payload
      };
    case ADD_PAYMENT:
      return {
        ...state,
        payment: action.payload
      };
    case HOLD_RELESE_HANDLER:
      return {
        ...state,
        paymentHold: action.payload
      };
    case PAYMENT_TYPE_PAY_NOW:
      return {
        ...state,
        paymentTypePaynow: action.payload
      };
    default:
      return state;
  }
};
export default orderReducers;
