import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";
import orderReducer from "./reducer/orderReducer";

const reducers = { orderReducer };
const middleware = [thunk];

export const store = createStore(
  combineReducers(reducers),
  composeWithDevTools({ trace: true })(applyMiddleware(...middleware))
);
